// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const AccessRequestApprovedRolesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  .no-values-row {
    display: flex;

    .no-values {
      flex: auto;
    }
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    color: gray;
    font-weight: normal;

    .header-role-label {
      flex: 0 0 40%;
      text-align: left;
      font-size: 0.9rem;
    }

    .header-ttl-value {
      flex: 0 0 60%;
      text-align: left;
      font-size: 0.9rem;
    }
  }

  > div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .role-label {
    flex: 0 0 40%;
    text-align: left;
    font-weight: normal;
  }

  .ttl-value {
    flex: 0 0 60%;
    text-align: left;
  }
`;

export const AccessRequestDurationSection = styled.div`
  display: flex;
  direction: column;
  justify-content: space-between;
  align-items: baseline;
`;

export const AccessRequestRoleSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  .header-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .header-selected-label {
      flex: 0 0 30%;
      text-align: left;
      font-weight: normal;
      font-size: 0.9rem;
    }

    .header-role-value {
      flex: 0 0 70%;
      text-align: left;
      font-weight: normal;
      font-size: 0.9rem;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .selected-label {
    flex: 0 0 30%;
    text-align: left;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .role-value {
    flex: 0 0 70%;
    text-align: left;
  }
`;

export const AccessRequestReasonSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionSubheader = styled.div`
  font-size: 14px;
  color: #757575;
  margin: 1em 0 2em 0;
`;
