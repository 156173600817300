import React, {createContext, useCallback, useContext, useMemo} from 'react';

import type {
  ChartAggOption,
  ChartAreaOption,
} from './../../../util/plotHelpers/chartTypes';
import type {RunSetQuery} from './../../../util/queryTypes';
import {key} from './../../../util/runs';
import type {Key} from './../../../util/runTypes';

type PanelGroupingSettingsType = {
  aggregatePanelRuns: boolean;
  aggregatePanelMetrics: boolean;
  groupAgg: ChartAggOption | undefined;
  groupArea: ChartAreaOption | undefined;
  isRunsetGrouped: (runSetID: string) => boolean;
  getGroupKeysByRunsetId: (runSetID: string) => Key[];
  isAnyRunsetGrouped: boolean;
};

const PanelGroupingSettings = createContext<PanelGroupingSettingsType>({
  aggregatePanelRuns: false,
  aggregatePanelMetrics: false,
  groupAgg: 'mean',
  groupArea: 'minmax',
  isRunsetGrouped: () => false,
  getGroupKeysByRunsetId: () => [],
  isAnyRunsetGrouped: false,
});

type PanelGroupingSettingsProps = {
  aggregate: boolean;
  aggregateMetrics: boolean;
  children: React.ReactNode;
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  groupBy: string;
  runSets: RunSetQuery[];
  useRunsTableGroupingInPanels: boolean;
};

/**
 * Panel grouping can be configured by a few different options:
 * 1: if there is grouping applied in the workspace (runset.grouping)
 * 2: if there is grouping applied in the panel (config.aggregate)
 * 3: if there is grouping on the metrics (config.aggregateMetrics)
 * 4: workspace grouping can be turned on/off for panels with a button on the workspace
 *
 * If grouping is applied at the panel level it needs to overwrite the grouping applied at the workspace level. Disabling grouping from the workspace shouldn't affect the grouping configured at the panel level.
 */
export const PanelGroupingContextProvider = ({
  aggregate,
  aggregateMetrics,
  children,
  groupAgg,
  groupArea,
  groupBy,
  runSets,
  useRunsTableGroupingInPanels,
}: PanelGroupingSettingsProps) => {
  const panelGroupKeys = useMemo(() => {
    return groupBy != null ? [key('config', groupBy)] : [];
  }, [groupBy]);

  const groupKeysByRunsetId = useMemo(() => {
    const initialValue: Record<string, Key[] | undefined> = {};
    return runSets.reduce((acc, runset) => {
      acc[runset.id] = runset?.grouping ?? [];
      return acc;
    }, initialValue);
  }, [runSets]);

  const getGroupKeysByRunsetId = useCallback(
    (runSetID: string) => {
      if (aggregate) {
        return panelGroupKeys ?? [];
      }
      if (useRunsTableGroupingInPanels) {
        return groupKeysByRunsetId[runSetID] ?? [];
      }

      return [];
    },
    [
      aggregate,
      panelGroupKeys,
      useRunsTableGroupingInPanels,
      groupKeysByRunsetId,
    ]
  );

  const isRunsetGrouped = useCallback(
    (runSetID: string) =>
      aggregateMetrics || getGroupKeysByRunsetId(runSetID).length > 0,
    [getGroupKeysByRunsetId, aggregateMetrics]
  );

  const value = useMemo(
    () => ({
      aggregatePanelRuns: aggregate,
      aggregatePanelMetrics: aggregateMetrics,
      groupAgg: panelGroupKeys.length > 0 ? groupAgg : undefined,
      groupArea: panelGroupKeys.length > 0 ? groupArea : undefined,
      getGroupKeysByRunsetId,
      isRunsetGrouped,
      isAnyRunsetGrouped: runSets.some(runset => isRunsetGrouped(runset.id)),
    }),
    [
      aggregate,
      aggregateMetrics,
      groupAgg,
      groupArea,
      panelGroupKeys,
      getGroupKeysByRunsetId,
      isRunsetGrouped,
      runSets,
    ]
  );

  return (
    <PanelGroupingSettings.Provider value={value}>
      {children}
    </PanelGroupingSettings.Provider>
  );
};

export const usePanelGroupingSettings = () => {
  const value = useContext(PanelGroupingSettings);

  return value;
};
