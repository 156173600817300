import React, {createContext, useContext, useMemo} from 'react';

import {runsLinePlotConfigDefaults} from '../defaults';
import {RunsLinePlotConfig} from '../types';
import {useKeyInfoQueryContext} from './../../../components/MultiRunWorkspace/KeyInfoQueryContext';
import {
  useRampFlagCoordinateZooming,
  useRampFlagNonMonotonicXAxis,
} from './../../../util/rampFeatureFlags';
import {useDetermineAggregationType} from './useDetermineAggregationType';
import {useRunsLinePlotSettings} from './useRunsLinePlotSettings';

type RunsLinePlotContextType = {
  /**
   * The ramp flag for if coordinated zooming is enabled
   */
  allowsCoordinatedZooming: boolean;
  /**
   * Whether the plot is full fidelity or not.
   */
  isFullFidelity: boolean;
  /**
   * Whether the plot is a sampling fallback or not. This is true if the panel _should be in full fidelity_ but some condition (warnings, errors, etc...) is forcing it back to sampling mode
   */
  isSamplingFallback: boolean;
  /**
   * Flag if a render error has occurred. This is currently a bespoke handler for the bucketing query (which does not have full parity with an RSDQ) and any unexpected condition inside that handler will eject the panel to a sampled plot.
   */
  setRenderError: (error: boolean) => void;
  /**
   * Temporary updater to save things to the workspace spec
   */
  updateWorkspaceConfig: (config: Partial<RunsLinePlotConfig>) => void;
  /**
   * Granular detail on the configuration settings to display when forcing a fallback to sampling mode. The existence of any messages in this list also is used to control the fallback when we hit known conditions that aren't supported in a bucketed plot
   */
  warningMessages: string[];
  /**
   * Whether the windowing is enabled for the line plot - this only applies to sampled plots currently. Controls whether the data will be stiched together or aggregated over "buckets"
   * e.g. windowing true turns points on different lines into [[1,2]], [[2,4]] => [1.5, 3]
   * e.g. windowing false turns points on different lines into  [[1,2]], [[2,4]] => [[1,2], [2,4]]
   */
  windowing: boolean;
};

/**
 * The goal here is to consolidate the logic for determining the state of the line plot
 *
 * At a high level this will influence whether we do the query through an RSDQ or a b(ucketing)RSDQ, and hopefully in the future these options will feed into the construction of the line itself so that we're not doing a string of memoized mutations all the way down the component tree to manipulate the data (and suffer the render/perf cost of doing so) in order to render the UI.
 */

export const RunsLinePlotContext = createContext<RunsLinePlotContextType>({
  allowsCoordinatedZooming: false,
  isFullFidelity: false,
  isSamplingFallback: false,
  setRenderError: () => {},
  updateWorkspaceConfig: () => {},
  warningMessages: [],
  windowing: true,
});

export function useKeyInfoInfo(metrics: string[], xAxis?: string) {
  const {
    keyInfoQuery: {historyKeyInfo},
  } = useKeyInfoQueryContext();

  const isMonotonic =
    xAxis === '_step' || historyKeyInfo?.keys[xAxis ?? '']?.monotonic === true;

  const isHistogram = metrics.some(metric => {
    const typeCounts =
      historyKeyInfo.keys[metric]?.typeCounts?.flatMap(t => t.type) ?? [];
    return typeCounts.some(type => type === 'histogram');
  });

  const value = useMemo(
    () => ({
      isMonotonic,
      isHistogram,
    }),
    [isMonotonic, isHistogram]
  );

  return value;
}

export const RunsLinePlotContextProvider = ({
  children,
  config,
  entityName,
  updateConfig,
}: {
  children: React.ReactNode;
  config: RunsLinePlotConfig;
  entityName: string;
  updateConfig: (config: Partial<RunsLinePlotConfig>) => void;
}) => {
  const [hasRenderError, setRenderError] = React.useState(false);

  const allowsCoordinatedZooming = useRampFlagCoordinateZooming(entityName);
  const allowsNonMonotonicXAxis = useRampFlagNonMonotonicXAxis(entityName);

  const {isMonotonic, isHistogram} = useKeyInfoInfo(
    config.metrics ?? [],
    config.xAxis
  );

  const {pointVisualizationMethod} = useRunsLinePlotSettings(config);

  const {lineType, warningMessages} = useDetermineAggregationType(config, {
    allowsNonMonotonicXAxis,
    hasRenderError,
    isMonotonic,
    pointVisualizationMethod,
  });

  const value = useMemo(
    () => ({
      allowsCoordinatedZooming,
      isFullFidelity: lineType !== 'SAMPLED' && !isHistogram,
      /**
       * A sampling warning shows up under the following conditions:
       * 1. The workspace is in bucketing mode
       * 2. The chart has active sampling warnings that caused the fallback
       */
      isSamplingFallback:
        lineType === 'SAMPLED' && pointVisualizationMethod !== 'sampling',
      setRenderError,
      updateWorkspaceConfig: updateConfig,
      warningMessages,
      // defaults to true
      // https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1725552052483829?thread_ts=1725551993.730519&cid=C062UKHTAHG
      windowing: config.windowing ?? runsLinePlotConfigDefaults.windowing,
    }),
    [
      allowsCoordinatedZooming,
      config.windowing,
      isHistogram,
      lineType,
      pointVisualizationMethod,
      updateConfig,
      warningMessages,
    ]
  );

  return (
    <RunsLinePlotContext.Provider value={value}>
      {children}
    </RunsLinePlotContext.Provider>
  );
};
RunsLinePlotContext.displayName = 'RunsLinePlotContext';

export const useRunsLinePlotContext = () => {
  return useContext(RunsLinePlotContext);
};
