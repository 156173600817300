import React, {memo, useMemo} from 'react';

import {
  overrideLineColors,
  overrideLineTitles,
  overrideLineWidths,
  overrideMarks,
} from '../../../util/plotHelpers/chart';
import {Line} from '../../../util/plotHelpers/types';
import {useDerivedLinesConfiguration} from '../config/useDerivedLinesConfiguration';
import {usePanelConfigContext} from '../PanelConfigContext';
import {usePanelInteractionContext} from '../PanelInteractionContext';
import {usePanelZoom2} from '../zoom/PanelZoomContext2';
import {isMetricKeyAbsoluteRuntime} from '../zoom/utils';
import {useIsInLoadedReportContext} from './../../../pages/ReportPage/LoadedReportContext';
import {startPerfTimer} from './../../../util/profiler';
import {ConfigWrapper} from './../Config';
import {GraphWrapper} from './../Graph';
import {usePanelTimeContext} from './../PanelTimeContext';
import {RunsLinePlotPanelProps} from './../types';
import {useZoomHistoryData} from './../zoom/useZoomHistoryData';
import {removeBoundaryPoints} from './lineFilters/removeBoundaryPoints';
import {useCachedBucketedData} from './useCachedBucketedData';
import {useLines} from './useLines';

type PanelRunsLinePlotProps = RunsLinePlotPanelProps;

const featureSupport = {
  grouping: false,
};

const PanelOutliersRunsLinePlotComp: React.FC<
  PanelRunsLinePlotProps
> = props => {
  const {numBucketsByPanelWidth} = usePanelInteractionContext();
  const isInReport = useIsInLoadedReportContext();
  const {timeFactor} = usePanelTimeContext();

  const {config: runsLinePlotConfig} = props;

  const {isSingleRun} = usePanelConfigContext();

  const {queryZoom, queryZoomStep} = usePanelZoom2();

  const queryZoomRange = useMemo(() => {
    if (isMetricKeyAbsoluteRuntime(runsLinePlotConfig.xAxis ?? '')) {
      return {
        min: queryZoomStep[0],
        max: queryZoomStep[1],
      };
    }
    return {
      min: queryZoom.xAxisMin ?? null,
      max: queryZoom.xAxisMax ?? null,
    };
  }, [runsLinePlotConfig.xAxis, queryZoom, queryZoomStep]);

  const {data, loading, error} = useCachedBucketedData({
    pageQuery: props.pageQuery,
    runsLinePlotConfig,
    queryZoomRange,
    numBuckets: numBucketsByPanelWidth,
  });

  useZoomHistoryData(runsLinePlotConfig.xAxis ?? '', data);

  const {endPerfTimer} = startPerfTimer(`creating lines for outliers`);
  /**
   * When deriving the lines to show the run data on the plot we need to merge configuration data from multiple sources in order to draw the lines correctly.
   */
  const derivedLinesConfiguration = useDerivedLinesConfiguration({
    customRunColors: props.customRunColors,
    customRunNames: props.customRunNames,
    semanticLegendSettings: props.semanticLegendSettings ?? {},
    runsLinePlotConfig,
    /**
     * I don't know what to do if there are multiple runsets - generally we can assume one
     */
    runSets: props.pageQuery.runSets ?? [],
    zoomTimestep: timeFactor,
  });

  const linesInitial = useLines(
    derivedLinesConfiguration,
    data.histories.data,
    data.isAggregated[props.pageQuery.id] ?? false,
    {
      isInReport,
      numBucketsByPanelWidth: numBucketsByPanelWidth ?? 1000,
    }
  );
  const lines = useMemo(
    () =>
      removeBoundaryPoints(
        linesInitial,
        {
          min: queryZoom.xAxisMin ?? null,
          max: queryZoom.xAxisMax ?? null,
        },
        runsLinePlotConfig.xAxis === '_timestamp' ? 1000 : 1
      ),
    [linesInitial, queryZoom, runsLinePlotConfig.xAxis]
  );

  const linesWithTitleOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideSeriesTitles == null) {
      return lines;
    }
    return overrideLineTitles(
      lines as Line[],
      runsLinePlotConfig.overrideSeriesTitles,
      !isSingleRun
    );
  }, [lines, runsLinePlotConfig.overrideSeriesTitles, isSingleRun]);
  const linesWithColorOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideColors == null) {
      return linesWithTitleOverride;
    }
    return overrideLineColors(
      linesWithTitleOverride as Line[],
      runsLinePlotConfig.overrideColors,
      !isSingleRun
    );
  }, [linesWithTitleOverride, runsLinePlotConfig.overrideColors, isSingleRun]);

  const linesWithMarkOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideMarks == null) {
      return linesWithColorOverride;
    }
    return overrideMarks(
      linesWithColorOverride as Line[],
      runsLinePlotConfig.overrideMarks,
      !isSingleRun
    );
  }, [linesWithColorOverride, runsLinePlotConfig.overrideMarks, isSingleRun]);

  const linesWithWidthOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideLineWidths == null) {
      return linesWithMarkOverride;
    }
    return overrideLineWidths(
      linesWithMarkOverride as Line[],
      runsLinePlotConfig.overrideLineWidths,
      !isSingleRun
    );
  }, [
    linesWithMarkOverride,
    runsLinePlotConfig.overrideLineWidths,
    isSingleRun,
  ]);

  endPerfTimer();

  if (props.configMode) {
    return (
      <ConfigWrapper
        {...props}
        // @ts-ignore
        data={data}
        hasError={!!error}
        featureSupport={featureSupport}
        // @ts-ignore
        lines={linesWithWidthOverride}
        lineCount={lines.length}
        loading={loading}
      />
    );
  }
  return (
    <GraphWrapper
      {...props}
      // @ts-ignore
      data={data}
      hasError={!!error}
      lineCount={lines.length}
      // @ts-ignore
      lines={linesWithWidthOverride}
      loading={loading}
      runNameTruncationType={
        props.pageQuery.runSets?.[0]?.runNameTruncationType
      }
    />
  );
};

export const PanelOutliersRunsLinePlot: React.FC<PanelRunsLinePlotProps> = memo(
  PanelOutliersRunsLinePlotComp
);
PanelOutliersRunsLinePlot.displayName = 'PanelOutliersRunsLinePlot';
