import React from 'react';
import {twMerge} from 'tailwind-merge';

export const SettingIndentedRow = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={twMerge(
        'flex flex-col',
        'border-l-2 border-moon-350',
        'ml-10',
        className
      )}>
      {children}
    </div>
  );
};
