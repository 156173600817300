import {KeyboardShortcut} from '@wandb/weave/common/components/elements/KeyboardShortcut';
import {isMac} from '@wandb/weave/common/util/browser';
import {useBooleanState} from '@wandb/weave/common/util/hooks';
import {IconSearch} from '@wandb/weave/components/Icon';
import {TooltipDeprecated} from '@wandb/weave/components/TooltipDeprecated';
import React, {FC, memo, useCallback} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {ActivationMethods} from '../../../services/analytics/types';
import {
  useIsAdminModeActiveAndIsNotLocalEnv,
  viewingAs,
} from '../../../util/admin';
import {useNavContext, useNavUpdaterContext} from '../../NavContextProvider';
import {useSearchNavAnalytics} from '../useSearchNavAnalytics';
import {Button, Wrapper} from './NavResources';

const NavSearchButtonComp: FC = () => {
  const {searchActive} = useNavContext();
  const {toggleSearch} = useNavUpdaterContext();
  const {trackSearchNavOnScreen, trackAppLauncherOnScreen} =
    useSearchNavAnalytics();
  const {
    state: tooltipOpen,
    setTrue: openTooltip,
    setFalse: closeTooltip,
  } = useBooleanState(false);

  const onClick = useCallback(() => {
    if (!searchActive) {
      trackSearchNavOnScreen(ActivationMethods.MouseClick);
      trackAppLauncherOnScreen(ActivationMethods.MouseClick);
    }
    toggleSearch();
  }, [
    searchActive,
    toggleSearch,
    trackSearchNavOnScreen,
    trackAppLauncherOnScreen,
  ]);

  const isAdminMode =
    useIsAdminModeActiveAndIsNotLocalEnv() || viewingAs() !== '';

  return (
    <Wrapper onClick={onClick} marginRight={7}>
      <TooltipDeprecated
        position="bottom center"
        open={tooltipOpen && !searchActive}
        onOpen={openTooltip}
        onClose={closeTooltip}
        trigger={
          <Button
            active={searchActive}
            showAdminBanner={isAdminMode}
            aria-label="Global search">
            <Icon />
          </Button>
        }>
        Global search <Shortcut keys={[isMac ? `Cmd` : `Ctrl`, `K`]} />
      </TooltipDeprecated>
    </Wrapper>
  );
};

const NavSearchButton = memo(NavSearchButtonComp);

export default NavSearchButton;

const Icon = styled(IconSearch)`
  display: block;
  width: 24px;
  height: 24px;
`;

const Shortcut = styled(KeyboardShortcut)`
  margin-left: 10px;
`;
