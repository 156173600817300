import React, {FC, memo} from 'react';

import {GALLERY_PATH_SEGMENT} from '../../../routes/paths';
import {
  useIsAdminModeActiveAndIsNotLocalEnv,
  viewingAs,
} from '../../../util/admin';
import {usePersistentRumViewContext} from '../../../util/datadog';
import {useColorMode} from '../../../util/useColorMode';
import {isInIframe} from '../../../util/window';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useNavContext} from '../../NavContextProvider';
import ImpersonatingButton from './ImpersonatingButton';
import NavLeft from './NavLeft';
import NavRight from './NavRight';
import * as S from './SearchNav.styles';

const SearchNav: FC = memo(() => {
  const {isDarkMode} = useColorMode();
  const {
    hidden,
    params: {entityName, projectName},
  } = useNavContext();
  usePersistentRumViewContext('entityName', entityName);
  usePersistentRumViewContext('projectName', projectName);

  // hide the search nav in gallery for mobile
  const currentPath: string = window.location.pathname.split('/')[1] ?? '';
  const isMobileGallery = currentPath === GALLERY_PATH_SEGMENT;
  const isSignup = currentPath === 'signup';
  const showAdminBanner = useIsAdminModeActiveAndIsNotLocalEnv();
  const showImpersonatingBanner = viewingAs() !== '';

  if (hidden) {
    return null;
  }
  // TODO: this component is getting re-rendered on each poll...
  return (
    <S.SearchNav
      $isNightMode={isDarkMode}
      showAdminBanner={showAdminBanner}
      showImpersonatingBanner={showImpersonatingBanner}
      className={`search-nav ${
        isMobileGallery ? 'hide-in-mobile-gallery' : ''
      }`}
      id="searchNav">
      {!isInIframe() && (
        <S.ExperimentalTestBar
          id="search-nav-experimental-test-bar"
          showAdminBanner={showAdminBanner}
          showImpersonatingBanner={showImpersonatingBanner}
        />
      )}
      <NavLeft />
      {showImpersonatingBanner && <ImpersonatingButton />}
      <NavRight isSignup={isSignup} />
    </S.SearchNav>
  );
});
SearchNav.displayName = 'SearchNav';

export default SearchNav;
