import {XAxisFormatValues} from '../../WorkspaceDrawer/Settings/types';

export function isDatetimeAxisFormat(
  xAxis: string,
  xAxisFormat: XAxisFormatValues | undefined
) {
  if (
    xAxis === '_timestamp' ||
    xAxis === 'Wall Time' ||
    xAxisFormat === 'datetime'
  ) {
    return true;
  }
  return false;
}
