import * as _ from 'lodash';

import {LinesFromDataProps} from '../../components/PanelRunsLinePlot/types';
import {RunsData} from '../../containers/RunsDataLoader';
import {
  evaluateExpression,
  Expression,
  expressionToString,
  metricsInExpression,
} from '../../util/expr';
import * as RunHelpers from '../../util/runhelpers';
import * as Run from '../../util/runs';
import {startPerfTimer} from '../profiler';
import * as RunTypes from '../runTypes';
import {bucketLine} from './bucket';
import {getBucketSpec} from './buckets/getBucketSpec';
import {evaluateLineExpressionX} from './evaluateLineExpressionX';
import {hasMultiMetricExpression} from './expressions';
import {linesFromRunsets} from './lines';
import {
  getAllMetrics,
  getMetricIdentifiersFromExpressions,
} from './plotHelpers';
import {makePointsByRunAndMetric} from './pointsByRunAndMetric';
import {Line, Point, RunSetInfo} from './types';

export interface LineResult {
  lineCount: number;
  linesByKeyAndMetricName: Line[][][];
  /**
   * Grouping type is only used to determine if the grouping rules are because of options (grouping run, aggregating metrics) inside the panel config. This is used to determine line coloring. No other known uses of this information are known as of this change.
   */
  groupingType: 'Panel' | null;
  groupKeys: RunTypes.Key[];
}

export function lineResultsForRunset({
  runs,
  histories,
  config: configFromRoot,
  newConfig,
  runSetInfo,
}: {
  runs: RunsData['filtered'];
  histories: RunsData['histories'];
  config: LinesFromDataProps;
  newConfig: {
    expressionKeys: RunTypes.Key[];
  };
  runSetInfo?: RunSetInfo;
}): LineResult {
  const numHistoryPoints = histories.data.reduce(
    (acc, history) => acc + history.history.length,
    0
  );
  const perfContext = {
    aggregateCalculations: configFromRoot.aggregateCalculations,
    aggregateMetrics: configFromRoot.aggregateMetrics,
    complexity: runs.length * numHistoryPoints * configFromRoot.yAxis.length,
    expressions: configFromRoot.expressions,
    numHistories: numHistoryPoints,
    numMetrics: configFromRoot.yAxis.length,
    numRuns: runs.length,
    smoothingType: configFromRoot.smoothingType,
    smoothParam: configFromRoot.smoothingParam,
    xAxis: configFromRoot.xAxis,
  };
  const {endPerfTimer} = startPerfTimer('Line results for runset', perfContext);
  // this adds the defaults- this should be defaulted higher up
  // but leaving this to preserve consistency with existing behavior
  // TODO: double check the defaulting and remove this
  const lineConfig = {
    ...configFromRoot,
    expressions: configFromRoot.expressions ?? [],
    smoothingParam: configFromRoot.smoothingParam ?? 0,
    smoothingType: configFromRoot.smoothingType ?? 'exponential',
  };

  const useMedian = lineConfig.groupLine === 'median';
  const groupKeys =
    lineConfig.groupKeysByRunsetId?.[runSetInfo?.id ?? ''] ?? [];

  const runsetLines = {lines: [] as Line[], lineCount: 0};

  // yAxis is an array of strings corresponding to multiple metrics
  // Addings the metrics for optional expressions
  const {expressionMetricIdentifiers, xExpressionMetricIdentifiers} =
    getMetricIdentifiersFromExpressions(
      lineConfig.expressions,
      lineConfig.xExpression
    );
  const metricNames = getAllMetrics(
    lineConfig.yAxis,
    expressionMetricIdentifiers,
    xExpressionMetricIdentifiers
  );

  const sharedRootArgs = {
    aggregateCalculations: lineConfig.aggregateCalculations,
    groupArea: lineConfig.groupArea,
    groupLine: lineConfig.groupLine,
    showOriginalAfterSmoothing: lineConfig.showOriginalAfterSmoothing,
    smoothingParam: lineConfig.smoothingParam,
    smoothingType: lineConfig.smoothingType,
    xAxis: lineConfig.xAxis,
    xAxisFormat: lineConfig.xAxisFormat,
    windowing: lineConfig.windowing,
  };
  const sharedDerivedArgs = {
    extraVars: newConfig.expressionKeys,
    groupKeys,
    useMedian,
    yAxisLog: lineConfig.yLogScale != null ? lineConfig.yLogScale : false,
  };

  const runsetRuns = RunHelpers.runsetData(
    runs,
    histories,
    runSetInfo ? runSetInfo.id : undefined
  );

  const pointsByRunAndMetric = makePointsByRunAndMetric(
    runsetRuns,
    sharedRootArgs.xAxis
  );

  if (lineConfig.aggregateMetrics) {
    const runsetLinesForY = linesFromRunsets(
      sharedRootArgs,
      {
        ...sharedDerivedArgs,
        metricNames,
        runsetRuns,
      },
      pointsByRunAndMetric
    );
    runsetLinesForY.lines.forEach(line => {
      line.metricName = metricNames.join(' ');
    });
    runsetLines.lines = _.concat(runsetLines.lines, runsetLinesForY.lines);
    runsetLines.lineCount += runsetLinesForY.lineCount;
  } else {
    metricNames.forEach(metricName => {
      const args = {
        ...sharedDerivedArgs,
        runsetRuns,
        metricNames: [metricName],
      };

      const runsetLinesForY = linesFromRunsets(
        sharedRootArgs,
        args,
        pointsByRunAndMetric
      );

      runsetLinesForY.lines.forEach(line => {
        line.metricName = metricName;
      });
      runsetLines.lines = _.concat(runsetLines.lines, runsetLinesForY.lines);
      runsetLines.lineCount += runsetLinesForY.lineCount;
    });
  }

  let linesGroupedByName = _.values(
    _.groupBy(runsetLines.lines, line => line.name)
  );

  // handle expressions for x value
  if (lineConfig.xExpression != null) {
    linesGroupedByName.forEach(lineGroup => {
      const filteredLineGroup = lineGroup.filter(l => !l.aux);
      if (filteredLineGroup.length === 0) {
        return;
      }

      evaluateLineExpressionX(
        lineConfig.xExpression as Expression, // this is safe because we checked for null above
        filteredLineGroup,
        xExpressionMetricIdentifiers,
        'x'
      );

      // We make extra lines to use for the xExpression now we need to delete them
      // if they aren't used somewhere else
      runsetLines.lines = runsetLines.lines.filter(line => {
        return (
          !_.includes(xExpressionMetricIdentifiers, line.metricName) ||
          _.includes(lineConfig.yAxis, line.metricName) ||
          _.includes(expressionMetricIdentifiers, line.metricName)
        );
      });

      linesGroupedByName = _.values(
        _.groupBy(runsetLines.lines, line => line.name)
      );
    });
  }

  // Then map the line data according to the expression
  const exprLines: Line[] = [];

  // If there is an expression with multiple metrics, we need to sample down the lines so that they line up for the expression

  const isMultiMetricExpression = hasMultiMetricExpression(
    lineConfig.expressions ?? []
  );

  if (isMultiMetricExpression) {
    // deal with the fact that the samples don't line up
    // we make a smaller set of buckets and compute an average value across the buckets

    const allLines = linesGroupedByName.flat();
    const bucketSpec = getBucketSpec(allLines);
    if (bucketSpec != null) {
      const sampledLines = allLines.map(line => {
        return bucketLine(line, bucketSpec);
      });
      linesGroupedByName = _.values(_.groupBy(sampledLines, line => line.name));
    }
  }

  // If expressions are set, we show the derived expressions instead of the metrics
  // This section could be made much more performant if it becomes a bottleneck
  if (lineConfig.expressions != null && lineConfig.expressions.length > 0) {
    lineConfig.expressions.forEach(expr => {
      const metricIdentifiers = expr != null ? metricsInExpression(expr) : [];

      linesGroupedByName.forEach(lineGroup => {
        const filteredLineGroup = lineGroup.filter(l => !l.aux);

        const xValsToY = new Map<number, {[key: string]: number}>();
        filteredLineGroup.forEach(line => {
          line.data.forEach(p => {
            if (
              metricIdentifiers.find(
                identifier => identifier === line.metricName
              )
            ) {
              const obj = xValsToY.get(p.x);

              if (obj == null) {
                const newObj: {[key: string]: number} = {};
                if (line.vars != null) {
                  Object.entries(line.vars).forEach(([key, value]) => {
                    newObj[key] = value;
                  });
                }

                newObj[line.metricName || ''] = p.y;

                xValsToY.set(p.x, newObj);
              } else {
                obj[line.metricName || ''] = p.y;
              }
            }
          });
        });
        const exprData: Point[] = [];
        const keys = Array.from(xValsToY.keys());
        // Requires x values to be sorted, but they already are
        keys.forEach(key => {
          exprData.push({
            x: key,
            y: evaluateExpression(expr, xValsToY.get(key) || {}),
          });
        });

        const exprStr = expressionToString(expr);

        const newLine = {
          name: filteredLineGroup[0].name,
          run: filteredLineGroup[0].run,
          title: filteredLineGroup[0].name + ': ' + exprStr,
          metricName: exprStr,
          data: exprData,
        } as Line;
        exprLines.push(newLine);
      });
    });
  }

  let normalOrExprLines = runsetLines.lines;
  if (exprLines.length > 0) {
    normalOrExprLines = exprLines;
  }

  const namedLines = normalOrExprLines.map(line => {
    return {
      ...line,
      uniqueId: Run.uniqueId(line.run!, groupKeys),
    };
  });

  // Finally, pair all mean & average lines together to make it easier to
  // do coloring later.
  // let groupedLines: Line[][];
  const groupedLines = _.values(
    _.groupBy(namedLines, line => `${line.uniqueId ?? ''}:${line.metricName}`)
  );

  const groupedLinesByKeyAndMetric = groupedLines.map(lg =>
    _.values(_.groupBy(lg, line => line.metricName))
  );

  endPerfTimer();
  return {
    lineCount: runsetLines.lineCount,
    linesByKeyAndMetricName: groupedLinesByKeyAndMetric, // [runOrGroupKey][metricName][line]
    groupKeys,
    groupingType:
      lineConfig.aggregateMetrics || lineConfig.aggregatePanelRuns
        ? 'Panel'
        : null,
  };
}
