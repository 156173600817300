import {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import * as ViewHooks from '../../state/views/hooks';
import {Ref as PanelRef} from '../../state/views/panel/types';
import {getEntityNameFromPath} from '../../util/cache';
import {
  useRampFlagEmbeddedPanels,
  useRampFlagFullScreenWorkspacePanels,
} from '../../util/rampFeatureFlags';
import {
  allowsFullScreenPanelsByQueryParams,
  isEmbeddedPanelPath,
  isFullScreenWorkspacePanelByIdPath,
} from './utils';

export const useConfigXBounds = (panelRef: PanelRef) => {
  const initialConfigState = ViewHooks.usePart(panelRef);

  /**
   * In a runs line plot panel we need to pass in the saved xAxisMin and xAxisMax values so that we can set the initial zoom on the queryManager, otherwise it will default to null | null for the min | max
   */
  const configXBounds = useMemo(() => {
    if (
      'xAxisMin' in initialConfigState.config &&
      'xAxisMax' in initialConfigState.config
    ) {
      return {
        xAxisMin: initialConfigState.config?.xAxisMin ?? null,
        xAxisMax: initialConfigState.config?.xAxisMax ?? null,
      };
    }
    return {};
  }, [initialConfigState.config]);

  return configXBounds;
};

export const useDetectPanelVariant = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const panelDisplayName = searchParams.get('panelDisplayName');
  const panelSectionName = searchParams.get('panelSectionName');

  const isEnabledFullScreenPanel = useRampFlagFullScreenWorkspacePanels(
    getEntityNameFromPath()
  );
  const isFullScreenPanelById =
    isEnabledFullScreenPanel && isFullScreenWorkspacePanelByIdPath(currentPath);
  const isPageWithPanels = allowsFullScreenPanelsByQueryParams(currentPath);

  const isFullScreenPanelByNameAndSectionName =
    isEnabledFullScreenPanel &&
    panelDisplayName &&
    panelSectionName &&
    isPageWithPanels;

  const isFullScreenPanelByName =
    !isFullScreenPanelByNameAndSectionName &&
    isEnabledFullScreenPanel &&
    panelDisplayName &&
    isPageWithPanels;

  const isEmbeddedPanel =
    useRampFlagEmbeddedPanels(getEntityNameFromPath()) &&
    isEmbeddedPanelPath(currentPath);

  const isFullScreenPanelPage =
    isFullScreenPanelById ||
    isFullScreenPanelByName ||
    isFullScreenPanelByNameAndSectionName;

  return {
    isEmbeddedPanel,
    isFullScreenPanelById,
    isFullScreenPanelByName,
    isEnabledFullScreenPanel,
    isFullScreenPanelByNameAndSectionName,
    isFullScreenPanelPage,
  };
};

export const useGoToBaseUrl = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    isFullScreenPanelById,
    isFullScreenPanelByName,
    isFullScreenPanelByNameAndSectionName,
  } = useDetectPanelVariant();

  const goToBaseURL = () => {
    const currentPath = location.pathname;
    const queryParams = location.search;

    if (isFullScreenPanelById) {
      const panelIndex = currentPath.indexOf('/panel/');
      if (panelIndex !== -1) {
        const basePath = currentPath.substring(0, panelIndex);
        history.replace(`${basePath}${queryParams}`);
      } else {
        history.replace(`/${queryParams}`);
      }
    } else if (isFullScreenPanelByName) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('panelDisplayName');
      history.replace(`${window.location.pathname}?${urlParams.toString()}`);
    } else if (isFullScreenPanelByNameAndSectionName) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('panelDisplayName');
      urlParams.delete('panelSectionName');
      history.replace(`${window.location.pathname}?${urlParams.toString()}`);
    }
  };

  return goToBaseURL;
};
