import ModifiedDropdown from '@wandb/weave/common/components/elements/ModifiedDropdown';
import {mediaStrings} from '@wandb/weave/common/types/media';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Icon, Label} from 'semantic-ui-react';

import {useProjectFieldsQuery} from '../state/graphql/projectFieldsQuery';
import {useDebounceState} from '../util/hooks';
import * as QueryTS from '../util/queryts';
import {Query} from '../util/queryTypes';
import {useRampFlagDisableFuzzySearch} from '../util/rampFeatureFlags';
import {Config as TableSettings} from '../util/runfeed';
import * as Run from '../util/runs';
import {keyFromString, rawConfigKeyDisplayName} from '../util/runs';
import * as UIHelpers from '../util/uihelpers';

const DEFAULT_TYPES = ['string', 'number', 'boolean', 'other', ...mediaStrings];
const DEFAULT_COLUMNS = [
  'config',
  'summary_metrics',
  'aggregations_min',
  'aggregations_max',
];
const RESULT_LIMIT = 50;

type ProjectFieldSelectorPropsInner = {
  autoPick?: boolean; // automatically picks a value if value not set
  className?: string;
  closeOnChange?: boolean;
  columns?: string[]; // filter to config or summary_metrics else use DEFAULT_COLUMNS
  defaultKeys?: string[]; // extra elements for the dropdown added to the ones loaded in
  disabled?: boolean;
  fluid?: boolean;
  focusOnMount?: boolean;
  inline?: boolean;
  placeholder?: string;
  reorderable?: boolean;
  searchByKeyAndText?: boolean; // switches to searching by both key and display text, rather than just key
  selection?: boolean; // selection defaults to true for legacy reasons
  types?: string[]; // filter to passed in types else use DEFAULT_TYPES

  filterBy?(key: string): void;
} & (ProjectFieldSelectorSingleProps | ProjectFieldSelectorMultiProps);

type NamedProjectFieldSelectorProps = ProjectFieldSelectorPropsInner & {
  entityName: string;
  projectName: string;
  tableSettings?: TableSettings;
};

interface ProjectFieldSelectorSingleProps {
  multi: false;
  value?: string;
  setValue(value: string): void;
}

interface ProjectFieldSelectorMultiProps {
  multi: true;
  value?: string[];
  setValue(value: string[]): void;
}

const ProjectFieldSelector: React.FC<
  NamedProjectFieldSelectorProps &
    ReturnType<typeof useProjectFieldSelectorProps>
> = React.memo(
  ({
    className,
    closeOnChange,
    filterBy,
    focusOnMount,
    inline,
    keys,
    loading,
    multi,
    open,
    reorderable,
    search,
    searchByKeyAndText,
    selection,
    setOpen,
    setSearch,
    setValue,
    value,
    placeholder,
    // take extra unused props and don't pass them through
    entityName: _0,
    projectName: _1,
    defaultKeys: _2,
    types: _3,
    columns: _4,
    autoPick: _5,
    tableSettings: _6,

    ...passthrough
  }) => {
    const filteredKeys = filterBy ? keys.filter(filterBy) : keys;

    const dropdownOptions = filteredKeys.map(key =>
      key
        ? UIHelpers.beautify({key, value: key, text: key})
        : {key, value: key, text: '<none>'}
    );

    return (
      <ModifiedDropdown
        {...(passthrough as {
          [key: string]: never;
        })}
        className={'project-field-selector ' + className}
        closeOnChange={closeOnChange}
        placeholder={placeholder}
        enableReordering={reorderable}
        inline={inline}
        lazyLoad
        loading={loading}
        resultLimit={RESULT_LIMIT}
        resultLimitMessage="Results truncated. Type to search more."
        multiple={multi}
        onSearchChange={(e, {searchQuery}) => {
          setSearch(searchQuery);
        }}
        options={dropdownOptions}
        search={options => options}
        searchInput={focusOnMount ? {autoFocus: focusOnMount} : undefined}
        searchQuery={search}
        selection={selection} // selection defaults to true for legacy reasons
        selectOnNavigation={false}
        value={value}
        onChange={(e, {value: newValue}) => {
          setSearch('');
          if (multi) {
            (setValue as (v: string[]) => void)(newValue as string[]);
          } else {
            (setValue as (v: string) => void)(newValue as string);
          }
        }}
        renderLabel={(item, index, defaultLabelProps) => {
          const onRemove = defaultLabelProps.onRemove!;

          return (
            <Label {...defaultLabelProps} className="multi-group-label">
              {item.text}
              <Icon
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                  onRemove(e, defaultLabelProps)
                }
                name="delete"
              />
            </Label>
          );
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOpen(false);
          setSearch('');
        }}
      />
    );
  }
);

export const projectFieldSelectorSearchDelayMS = 200;

type GetProjectFieldsQueryVariablesParams = {
  entityName: string;
  projectName: string;
  types?: string[];
  columns?: string[];
  count?: number;
  pattern?: string | null;
};

type ProjectFieldsQueryVariables =
  Required<GetProjectFieldsQueryVariablesParams>;

export function getProjectFieldsQueryVariables({
  entityName,
  projectName,
  types = DEFAULT_TYPES,
  columns = DEFAULT_COLUMNS,
  count = RESULT_LIMIT,
  pattern = null,
}: GetProjectFieldsQueryVariablesParams): ProjectFieldsQueryVariables {
  return {
    entityName,
    projectName,
    types,
    columns,
    count,
    pattern,
  };
}

export function deriveCustomSearchByEntity(
  entityName: string,
  search: string,
  debouncedSearch: string,
  isFuzzySearchDisabled = useRampFlagDisableFuzzySearch
): [searchPattern: string | null, searchRegex: RegExp] {
  const disableFuzzySearch = isFuzzySearchDisabled(entityName);
  return [
    makeSearchPattern(debouncedSearch, disableFuzzySearch),
    makeSearchRegex(search, disableFuzzySearch),
  ];
}

function useProjectFieldSelectorProps(props: NamedProjectFieldSelectorProps) {
  const [open, setOpen] = useState(false);
  const [search, searchDebounced, setSearch] = useDebounceState(
    '',
    projectFieldSelectorSearchDelayMS
  );

  const [searchPattern, searchRegex] = deriveCustomSearchByEntity(
    props.entityName,
    search,
    searchDebounced
  );

  const gqlVars = getProjectFieldsQueryVariables({
    entityName: props.entityName,
    projectName: props.projectName,
    types: props.types,
    columns: props.columns,
    pattern: searchPattern,
  });

  const projectFieldsData = useProjectFieldsQuery({
    ...gqlVars,
    skip: !open && !props.autoPick,
  });

  const {loading, keys} = projectFieldsData;

  // filter out aggregation keys (eg min/max) that are not visible
  const activeKeys = keys.filter(key => {
    let aggregationVisible = {};
    if (props.tableSettings) {
      aggregationVisible = props.tableSettings.aggregationVisible || {};
    }
    return Run.filterAggregationKeys(aggregationVisible, key);
  });

  const filteredKeys = useMemo(() => {
    let fkeys: string[];
    if (search === '') {
      fkeys = [...(props.defaultKeys || []), ...activeKeys];
      // when not searching we always need to include the selected value(s)
      if (props.value != null) {
        if (props.multi) {
          fkeys = [
            ...fkeys,
            ...props.value.filter(k => fkeys.indexOf(k) === -1),
          ];
        } else if (fkeys.indexOf(props.value) === -1) {
          fkeys = [...fkeys, props.value];
        }
      }
    } else {
      const dropdownFilterKeys = (props.defaultKeys || []).filter(k => {
        const key = keyFromString(k);
        const keyName = searchKeyFromKeyString(k);
        const displayText = key != null ? Run.keyDisplayName(key) : k;
        return (
          searchRegex.test(keyName) ||
          (props.searchByKeyAndText && searchRegex.test(displayText))
        );
      });
      fkeys = [...activeKeys, ...dropdownFilterKeys];
      // in multi-select mode, we have to include all the filtered out selected
      // keys or they won't be rendered
      if (props.multi && props.value != null) {
        fkeys = [...fkeys, ...props.value.filter(k => fkeys.indexOf(k) === -1)];
      }
    }
    fkeys = _.sortBy(fkeys, k => {
      const key = keyFromString(k);
      if (key == null) {
        return 1;
      }
      return rawConfigKeyDisplayName(key.name).toLowerCase() ===
        search.toLowerCase()
        ? 0
        : 1;
    });
    return fkeys;
  }, [
    props.defaultKeys,
    activeKeys,
    search,
    searchRegex,
    props.value,
    props.multi,
    props.searchByKeyAndText,
  ]);

  // We pass everything that can change here, but since the types switch on
  // multi, we can't destructure and get the right types out.
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // don't allow auto-picking empty string
    let doubleFilteredKeys = filteredKeys.filter(k => k !== '');
    if (props.filterBy) {
      doubleFilteredKeys = filteredKeys.filter(props.filterBy);
    }
    if (props.autoPick && doubleFilteredKeys.length > 0) {
      if (!props.multi && props.value == null) {
        props.setValue(doubleFilteredKeys[0]);
      }
      if (props.multi && props.value == null) {
        props.setValue([doubleFilteredKeys[0]]);
      }
    }
  }, [props.autoPick, props.multi, props.value, filteredKeys]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    loading: loading || search !== searchDebounced,
    keys: filteredKeys,
    search,
    setSearch,
    open,
    setOpen,
  };
}

function searchKeyFromKeyString(keyString: string): string {
  const key = Run.keyFromString(keyString);
  if (!key) {
    return '';
  }
  if (key.section === 'tags') {
    return 'tags';
  }
  return key.name;
}

export const NamedProjectFieldSelector = (
  props: NamedProjectFieldSelectorProps
) => {
  const selectedProps = useProjectFieldSelectorProps(props);
  return <ProjectFieldSelector {...props} {...selectedProps} />;
};

type ProjectFieldSelectorProps = ProjectFieldSelectorPropsInner & {
  query: Query;
};

export default (props: ProjectFieldSelectorProps) => {
  const {query, ...passthrough} = props;
  const {entityName, projectName} = QueryTS.useFirstEnabledProject(props.query);

  return (
    <NamedProjectFieldSelector
      {...passthrough}
      entityName={entityName}
      projectName={projectName}
    />
  );
};

export function makeSearchPattern(
  searchText?: string | null,
  disableFuzzySearch?: boolean
): string | null {
  if (!searchText) {
    return null;
  }

  if (disableFuzzySearch) {
    return '%' + searchText + '%';
  }

  return (
    '%' +
    searchText
      .split('')
      .map(c => ('%?_'.indexOf(c) !== -1 ? '\\' + c : c))
      .join('%') +
    '%'
  );
}

export function makeSearchRegex(
  search: string,
  disableFuzzySearch?: boolean
): RegExp {
  if (disableFuzzySearch) {
    return new RegExp('^' + search + '.*$', 'i');
  }

  return new RegExp(
    search
      .split('')
      .map(c => ('^$\\;.*+?-[](){}'.indexOf(c) !== -1 ? '\\' + c : c))
      .join('.*') + '.*$',
    'i'
  );
}
