import {toast} from '@wandb/weave/common/components/elements/Toast';
import {TableMetadata} from '@wandb/weave/common/types/media';
import produce from 'immer';
import _ from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {getProjectInternalId} from '../../components/RunSelector/util';
import * as Generated from '../../generated/graphql';
import * as Redux from '../../types/redux';
import {propagateErrorsContext} from '../../util/errors';
import * as Filter from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import * as QueryTS from '../../util/queryts';
import * as QueryTypes from '../../util/queryTypes';
import {useRampFlagDisableQueryMerging} from '../../util/rampFeatureFlags';
import * as Requests from '../../util/requests';
import {Key, Run} from '../../util/runTypes';
// eslint-disable-next-line import/no-cycle
import {runSetsToRunSetQuery} from '../../util/section';
import {
  useHistoryKeysQuery,
  UseHistoryKeysQueryResult,
  useMultiRunsetHistoryKeysQuery,
} from '../graphql/historyKeysQuery';
import {HistoryKeyInfoQueryVars} from '../graphql/historyKeysQueryTypes';
import {useRunKeysQuery} from '../graphql/runKeysQuery';
import {useWandbConfigQuery} from '../graphql/wandbConfigQuery';
import {
  useApolloClient,
  useDispatch,
  useGatedValue,
  usePropsSelector,
  useSelector,
} from '../hooks';
import * as PollingActions from '../polling/actions';
import * as RunsActions from '../runs-low/actions';
import * as RunsLowLib from '../runs-low/lib';
import * as RunsSelectors from '../runs-low/selectors';
import * as RunsTypes from '../runs-low/types';
import * as CustomRunNamesTypes from '../views/customRunNames/types';
import {usePartMaybe} from '../views/hooks';
import {useRunSets} from '../views/runSet/hooks';
import * as RunSetViewTypes from '../views/runSet/types';
import * as Context from './context';
import * as Types from './types';

enum LoadingState {
  Inited,
  Loading,
  Done,
}

// Props injected by the runs components.
export interface InjectedRunsDataProps {
  loading: boolean;
  data: Types.Data;
  query: Types.Query;

  runsDataQuery: ReturnType<typeof useRunsData>;
}

// It's important that this returns reference-equal results as often
// as possible, for performance of consumers. The unit tests verify
// this behavior.
export const useRunsData = (
  query: Types.Query,
  skip: boolean = false
): {loading: boolean; data: Types.Data} => {
  const dispatch = useDispatch();
  const loadingRef = useRef(LoadingState.Inited);
  const queryIDsRef = useRef([] as string[]);
  const queryIDs = queryIDsRef.current;
  const [page, setPage] = useState(1);

  if (query.page) {
    query = {
      ...query,
      page: {
        size: query.page.size * page,
      },
    };
  }

  // Unregister all queries on unmount
  const unregisterAll = useCallback(() => {
    // Unregister all with redux
    _.values(queryIDsRef.current).forEach(id =>
      dispatch(RunsActions.unregisterQuery(id))
    );
    // Clear our local IDs
    queryIDsRef.current = [];
    // The react-hooks linter wants us to put dispatch in the deps list.
    // We don't because the unit test swaps the whole store (which swaps)
    // dispatch) to do store updates, and we don't want everything to
    // clear when that happens.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Unregister all queries on unmount
  useEffect(() => () => unregisterAll(), [unregisterAll]);

  const previousQueries = usePropsSelector(
    RunsSelectors.makeQueryListSelector,
    queryIDs
  );
  const queries = queryToRunsQueries(query);

  // Disable query merging, if necesssary.
  const disableQueryMerging = useRampFlagDisableQueryMerging(query.entityName);
  if (disableQueryMerging) {
    queries.forEach(q => (q.disableQueryMerging = true));
  }

  // If our queries have changed, compute the new query IDs, and
  // a list of actions to dispatch to redux to make the updates.
  // We track our queryIDs updates in a variable called newQueryIDs
  // so that afterward we can check if we've made changes by
  // deepEqual comparison of newQueryIDs and queryIDs.
  let newQueryIDs = [...queryIDs];

  const actions: Redux.DispatchableAction[] = [];
  if (!skip) {
    for (let i = 0; i < queries.length; i++) {
      if (
        i >= previousQueries.length ||
        (previousQueries[i] != null &&
          RunsLowLib.queryNeedsClear(queries[i], previousQueries[i]!))
      ) {
        if (i < previousQueries.length) {
          actions.push(RunsActions.unregisterQuery(newQueryIDs[i]));
        }
        const register = RunsActions.registerQuery(queries[i]);
        newQueryIDs[i] = register.payload.id;
        actions.push(register);
      } else {
        actions.push(
          RunsActions.updateQueryIfChanged(newQueryIDs[i], queries[i])
        );
      }
    }
    for (let i = queries.length; i < queryIDs.length; i++) {
      actions.push(RunsActions.unregisterQuery(newQueryIDs[i]));
    }
    newQueryIDs = newQueryIDs.slice(0, queries.length);
  }

  // only update queryIDs ref if we've made a change.
  if (!_.isEqual(newQueryIDs, queryIDs)) {
    queryIDsRef.current = newQueryIDs;
  }

  useEffect(() => {
    for (const action of actions) {
      dispatch(action as any);
    }
  });

  const currentlyLoading = usePropsSelector(
    RunsSelectors.makeQueriesLoadingSelector,
    queryIDsRef.current
  );
  if (currentlyLoading === true && loadingRef.current === LoadingState.Inited) {
    loadingRef.current = LoadingState.Loading;
  } else if (
    currentlyLoading === false &&
    loadingRef.current === LoadingState.Loading
  ) {
    loadingRef.current = LoadingState.Done;
  }
  const data = usePropsSelector(
    RunsSelectors.makeRunsDataSelector,
    queryIDsRef.current
  );
  const totalRuns = usePropsSelector(
    RunsSelectors.makeTotalRunsSelector,
    queryIDsRef.current
  );

  // Create a boolean instead of an object, so that useMemo below can
  // ref-equal check.
  const requestedPaging = query.page != null;

  const initialLoading = loadingRef.current !== LoadingState.Done;

  /*
  const cacheKey = useMemo(() => {
    if (queries.length === 0) {
      return null;
    }
    // Ref IDs don't persist through refreshes, so we need to remove them
    return removeRefs(queries);
  }, [queries]);

  const data = useOPFSCache({
    namespace: `useRunsData`,
    key: cacheKey,
    dataFromServer,
    waitingForDataFromServer: initialLoading,
  });
  */

  const result = useMemo(
    () => ({
      loading: currentlyLoading,
      data: {
        ...data,
        initialLoading,
        loading: currentlyLoading,
        entityName: query.entityName,
        projectName: query.projectName,
        totalRuns,
        loadMore: requestedPaging ? setPage : undefined,
      },
    }),
    [
      query.entityName,
      query.projectName,
      requestedPaging,
      currentlyLoading,
      initialLoading,
      data,
      totalRuns,
    ]
  );

  return result;
};

function removeRefs(queries: RunsTypes.Query[]): RunsTypes.Query[] {
  return produce(queries, removeRefsRecursive);

  function removeRefsRecursive(obj: any): void {
    if (obj == null || typeof obj !== `object`) {
      return;
    }
    if (Array.isArray(obj)) {
      obj.forEach(removeRefsRecursive);
      return;
    }
    Object.keys(obj).forEach(key => {
      if (key === `ref`) {
        delete obj[key];
      } else {
        removeRefsRecursive(obj[key]);
      }
    });
  }
}

// Give the results of the useRunsData hook, walk the loaded histories and
//     look for table-file types to load. Load them from file storage, and
//     inject the results into the histories, as if nothing ever happened.
//
// NOTE: Currently has O(n^2) behavior, because it walks the history for each
// file in the history. And maybe worse, it will trigger a rerender for each file
// in the history. We could put a debounce on actually updating results when
// new files are loaded, or batch requests etc.
//
// NOTE: This also has unbounded memory behavior, it never forgets files, and tries
// to load them no matter the size.
export const useLoadHistoryFileTables = (
  query: Types.Query,
  runsDataQuery: ReturnType<typeof useRunsData>
) => {
  const apolloClient = useApolloClient();
  const [res, setRes] = useState(runsDataQuery);
  // Track which file paths we've already started requests for, and never request
  // them again.
  const fileReqsRef = useRef<{[name: string]: boolean}>({});
  // Mapping from file path to loaded table
  const [tables, setTables] = useState<{[name: string]: TableMetadata}>({});
  const curData = runsDataQuery.data;
  const requestedKeys = useMemo(
    () =>
      query.historySpecs != null
        ? query.historySpecs.flatMap(spec => spec.keys)
        : null,
    [query]
  );
  const filterObjectToRequestedKeys = useCallback(
    (obj: {[key: string]: any}) => {
      if (requestedKeys == null) {
        return obj;
      } else {
        return _.pick(obj, requestedKeys);
      }
    },
    [requestedKeys]
  );

  // Generate requests for any files in fetched history/summary that we haven't
  // generated requests for yet.
  useEffect(() => {
    const getRunPath = (run: Types.RunWithRunsetInfo, qq: Types.Query) => {
      const runSetId = run.runsetInfo.id;
      const runSet = qq.queries.find(q => q.id === runSetId);
      if (runSet == null) {
        throw new Error('invalid state');
      }
      return {
        entityName: runSet.entityName,
        projectName: runSet.projectName,
        runName: run.name,
      };
    };
    const maybeFetchVal = async (
      entityName: string,
      projectName: string,
      runName: string,
      v: any
    ) => {
      const isValidTableFile =
        v != null && v._type === 'table-file' && typeof v.path === 'string';
      if (!isValidTableFile || fileReqs[v.path] != null) {
        return;
      }
      fileReqs[v.path] = true;

      const file = await Requests.fetchFileInfo(apolloClient, {
        entityName,
        projectName,
        runName,
        filename: v.path,
      });
      if (file == null || file.sizeBytes === 0) {
        // TODO: We should be retrying this case, this happens when
        // the file is in history but we haven't received metadata back
        // in gorilla yet.
        return;
      }
      Requests.fetchWithRetry(file.directUrl, {
        onSuccess: loadedFile => {
          let parsed: any;
          try {
            parsed = JSON.parse(loadedFile);
          } catch {
            // TODO: This is a hacky way to get out of an infinite loading spinner when the
            // retrieved JSON is invalid. We should handle this downstream somewhere and
            // inform the user that the data is bad.
            console.error(`failed to parse data for ${v.path}`);
            setTables(curTables => ({
              ...curTables,
              [v.path]: {_type: 'invalid'},
            }));
            return;
          }
          if (parsed.columns != null && parsed.data != null) {
            setTables(curTables => ({
              ...curTables,
              [v.path]: {_type: 'table', ...parsed},
            }));
          }
        },
      });
    };
    // Walk summary
    const fileReqs = fileReqsRef.current;
    for (const r of curData.filtered) {
      const {entityName, projectName, runName} = getRunPath(r, query);
      _.forEach(filterObjectToRequestedKeys(r.summary), v =>
        maybeFetchVal(entityName, projectName, runName, v)
      );
    }
    // Walk history
    for (const h of curData.histories.data) {
      const {entityName, projectName, runName} = getRunPath(
        curData.filteredRunsById[h.name],
        query
      );
      for (const row of h.history) {
        _.forEach(filterObjectToRequestedKeys(row), v =>
          maybeFetchVal(entityName, projectName, runName, v)
        );
      }
    }
  }, [apolloClient, query, curData, filterObjectToRequestedKeys]);

  // Whenever curData or tables change, recreate injected data.
  useEffect(() => {
    const updateRow = (row: {[key: string]: any}) => {
      let didUpdate = false;
      for (const k of _.keys(row)) {
        const v = row[k];
        if (
          v != null &&
          v._type === 'table-file' &&
          typeof v.path === 'string'
        ) {
          didUpdate = true;
          const table = tables[v.path];
          if (table != null) {
            row[k] = tables[v.path];
          }
        }
      }
      return didUpdate;
    };
    setRes(curQuery => {
      return produce(runsDataQuery, draft => {
        const data = draft.data;
        for (const r of data.filtered) {
          if (updateRow(r.summary)) {
            // Need to update filteredRunsById too
            data.filteredRunsById[r.name] = r;
          }
        }
        for (const h of data.histories.data) {
          for (const row of h.history) {
            updateRow(row);
          }
        }
      });
    });
  }, [runsDataQuery, tables]);

  // Only update the result when we don't have any table-file entries in
  // summary or history, meaning everything has been loaded and injected.
  // Downstream code (Vega) expects history rows not to be updated for
  // a given query, which is reasonable.
  return useGatedValue(
    res,
    curRes =>
      curRes.data.histories.data.find(hist =>
        hist.history.find(row =>
          Object.values(filterObjectToRequestedKeys(row)).find(
            v => v != null && v._type === 'table-file'
          )
        )
      ) == null &&
      curRes.data.filtered.find(run =>
        Object.values(filterObjectToRequestedKeys(run.summary)).find(
          v => v != null && (v as any)._type === 'table-file'
        )
      ) == null
  );
};

function singleQueryToRunsQueries(
  outerQuery: Types.Query,
  query: QueryTypes.SingleQuery
): RunsTypes.Query {
  if (!Filter.isRunFilter(query.filters)) {
    const error = 'Received non run filter in run query.';
    console.error(error);
    throw new Error(error);
  }

  return {
    aggregationKeys: outerQuery.aggregationKeys,
    enableArtifactCounts: outerQuery.enableArtifactCounts,
    configKeys: outerQuery.configKeys,
    enableBasic: true,
    enableHistoryKeyInfo: outerQuery.historyKeyInfo,
    enableSystemMetrics: true,
    enableTags: outerQuery.enableTags,
    entityName: query.entityName,
    filters: query.filters,
    fullAggregations: outerQuery.fullAggregations,
    fullConfig: outerQuery.fullConfig,
    fullSummary: outerQuery.fullSummary,
    grouping: query.grouping || [],
    historySpecs: outerQuery.historySpecs,
    limit: outerQuery.page != null ? outerQuery.page.size : 10,
    projectName: query.projectName,
    internalProjectId: query.internalProjectId ?? '',
    runsetId: query.id,
    runsetName: query.name,
    sort: query.sort,
    summaryKeys: outerQuery.summaryKeys,
    wandbKeys: outerQuery.wandbKeys,
  };
}

export function queryToRunsQueries(inputQuery: Types.Query): RunsTypes.Query[] {
  if (inputQuery.disabled) {
    return [];
  }
  return inputQuery.queries.map(q => singleQueryToRunsQueries(inputQuery, q));
}

export function useRunsQueryContext(): Context.RunsQueryContext {
  const context = useContext(Context.RunQueryContext);
  if (context.entityName == null || context.projectName == null) {
    throw new Error('context not initialized');
  }
  return context as Context.RunsQueryContext;
}

type UseRunSetsQueryArgs = {
  runSetRefs: RunSetViewTypes.Ref[];
  customRunNamesRef?: CustomRunNamesTypes.Ref;
  includeDisabled?: boolean;
};

export function useRunSetsQuery({
  runSetRefs,
  customRunNamesRef,
  includeDisabled = false,
}: UseRunSetsQueryArgs) {
  const runSets = useRunSets(runSetRefs);
  const customRunNames = usePartMaybe(customRunNamesRef);
  const context = useRunsQueryContext();
  return useMemo(
    () =>
      ({
        id: 'project-page-not-used',
        entityName: context.entityName,
        projectName: context.projectName,
        runName: context.runId,
        filters: context.mergeFilters || Filter.EMPTY_FILTERS,
        sort: QueryTS.CREATED_AT_DESC,
        runSets: runSetsToRunSetQuery(
          includeDisabled ? runSets : runSets.filter(rs => rs.enabled),
          customRunNames
        ),
      } as QueryTypes.Query),
    [
      runSets,
      customRunNames,
      context.entityName,
      context.projectName,
      context.runId,
      context.mergeFilters,
      includeDisabled,
    ]
  );
}

export function useKeyInfoQuery(
  runSetRefs: RunSetViewTypes.Ref[],
  customRunNamesRef: CustomRunNamesTypes.Ref | undefined
): UseHistoryKeysQueryResult {
  const pageQuery = useRunSetsQuery({
    runSetRefs,
    customRunNamesRef,
    includeDisabled: false,
  });
  const {entityName, projectName, sort} =
    QueryTS.useFirstEnabledProject(pageQuery);
  const queryVars = useMemo(() => {
    const filters = Filter.And([
      pageQuery.filters,
      pageQuery.selections || Filter.And([] as Array<FilterTypes.Filter<Key>>),
      Filter.Or(
        (pageQuery.runSets || []).map(rs => {
          // HAX: To avoid grabbing history keys from runs which don't have that information,
          // the backend `project.runs` resolver expects us to pass in a filter for `run.keys_info != null`.
          // However, unfortunately, we can't use 'keys_info' as the filter section
          // because it is always transformed to 'keys_info.keys.name' by `Filter.toMongo`.
          // So we use 'run' as the filter section and 'keys_info' as the filter name,
          // which is transformed to 'keys_info' by `Filter.toMongo`. This is only needed for
          // multiple run workspaces, so we only add it if we're not looking at a single run.
          const keysInfoFilter = {
            key: {section: 'run' as const, name: 'keys_info' as const},
            op: '!=' as const,
            value: null,
          } as const;

          const andArg = [
            rs.filters,
            rs.selections || Filter.And([] as Array<FilterTypes.Filter<Key>>),
          ];

          if (pageQuery.runName == null) {
            andArg.push(keysInfoFilter);
          }

          return Filter.And(andArg);
        })
      ),
    ]);

    return {
      entityName,
      projectName,
      filters,
      sort,
    };
  }, [pageQuery, entityName, projectName, sort]);

  return useHistoryKeysQuery(queryVars);
}

function usePageQueryToHistoryKeysQueryVars(
  pageQuery: QueryTypes.Query
): HistoryKeyInfoQueryVars[] {
  const enabledRunSets = useMemo(
    () => pageQuery.runSets?.filter(rs => rs.enabled) ?? [],
    [pageQuery.runSets]
  );
  const projectInternalIds: string[] = useMemo(
    () =>
      enabledRunSets
        ?.map(rs => getProjectInternalId(rs?.project?.id))
        .filter((id): id is string => id != null),
    [enabledRunSets]
  );
  const projectInfosFromInternalIds =
    Generated.useProjectInfosFromInternalIdsQuery({
      variables: {
        internalIds: projectInternalIds,
      },
    });
  const runSetsWithProjectInfo = useMemo(() => {
    return enabledRunSets.map(rs => {
      // sometimes no project info in runset, seems to be
      // when the runset is for the current project
      if (rs.project?.id != null) {
        const projectInfo =
          projectInfosFromInternalIds.data?.projects?.edges.find(
            p =>
              rs.project?.id != null &&
              p.node?.internalId === getProjectInternalId(rs.project.id)
          );
        return {
          ...rs,
          project: {
            ...rs.project,
            name: projectInfo?.node?.name,
            entityName: projectInfo?.node?.entityName,
          },
        };
      } else if (rs.project?.name != null && rs.project?.entityName != null) {
        return {
          ...rs,
          project: {
            name: rs.project.name,
            entityName: rs.project.entityName,
          },
        };
      }
      return {
        ...rs,
        project: {
          name: pageQuery.projectName,
          entityName: pageQuery.entityName,
        },
      };
    });
  }, [
    enabledRunSets,
    projectInfosFromInternalIds,
    pageQuery.projectName,
    pageQuery.entityName,
  ]);

  const historyKeysQueryVars = useMemo(() => {
    const keyInfoFilter = {
      key: {section: 'run' as const, name: 'keys_info' as const},
      op: '!=' as const,
      value: null,
    } as const;

    const queryVarsArray: HistoryKeyInfoQueryVars[] = [];
    runSetsWithProjectInfo.forEach(rs => {
      const projectName = rs.project?.name;
      const entityName = rs.project?.entityName;
      if (projectName == null || entityName == null) {
        return;
      }
      const runSetFiltersAndSelectionsArray = [
        rs.filters,
        rs.selections || Filter.And([] as Array<FilterTypes.Filter<Key>>),
      ];

      if (pageQuery.runName != null) {
        runSetFiltersAndSelectionsArray.push(keyInfoFilter);
      }
      const runSetFiltersAndSelections = Filter.And(
        runSetFiltersAndSelectionsArray
      );

      const filters = Filter.And([
        pageQuery.filters,
        pageQuery.selections ||
          Filter.And([] as Array<FilterTypes.Filter<Key>>),
        Filter.Or([runSetFiltersAndSelections]),
      ]);

      queryVarsArray.push({entityName, sort: rs.sort, projectName, filters});
    });
    return queryVarsArray;
  }, [
    runSetsWithProjectInfo,
    pageQuery.filters,
    pageQuery.runName,
    pageQuery.selections,
  ]);
  return historyKeysQueryVars;
}

export function useMultiRunsetKeyInfoQuery(
  runSetRefs: RunSetViewTypes.Ref[],
  customRunNamesRef: CustomRunNamesTypes.Ref | undefined
) {
  const pageQuery = useRunSetsQuery({
    runSetRefs,
    customRunNamesRef,
    includeDisabled: false,
  });
  const historyKeysQueryVars = usePageQueryToHistoryKeysQueryVars(pageQuery);
  const historyKeysQuery = useMultiRunsetHistoryKeysQuery(historyKeysQueryVars);
  return historyKeysQuery;
}

export function useRunsWandbConfigQuery(runSetRefs: RunSetViewTypes.Ref[]) {
  const pageQuery = useRunSetsQuery({runSetRefs, includeDisabled: false});
  const filters = Filter.And([
    pageQuery.filters,
    pageQuery.selections || Filter.And([] as Array<FilterTypes.Filter<Key>>),
    Filter.Or(
      (pageQuery.runSets || []).map(rs =>
        Filter.And([
          rs.filters,
          rs.selections || Filter.And([] as Array<FilterTypes.Filter<Key>>),
        ])
      )
    ),
  ]);

  const {entityName, projectName, sort} =
    QueryTS.useFirstEnabledProject(pageQuery);
  return useWandbConfigQuery({
    entityName,
    projectName,
    filters,
    sort,
  });
}

export function useKeysQuery(
  runSetRefs: RunSetViewTypes.Ref[],
  customRunNamesRef: CustomRunNamesTypes.Ref | undefined,
  types: string[] = ['number', 'string', 'boolean', 'other'],
  limit: number = 1000
) {
  const pageQuery = useRunSetsQuery({runSetRefs, customRunNamesRef});
  const runKeysQuery = useRunKeysQuery({
    entityName: pageQuery.entityName,
    projectName: pageQuery.projectName,
    types,
    limit,
  });

  return runKeysQuery;
}

export function useDeleteRun() {
  const [deleteRun] = Generated.useDeleteRunMutation();
  const dispatch = useDispatch();
  return useCallback(
    async (runName: string, mutationArgs: Parameters<typeof deleteRun>[0]) => {
      await deleteRun(mutationArgs);
      dispatch(RunsActions.deleteRun(runName));
    },
    [deleteRun, dispatch]
  );
}

export function useDeleteRuns() {
  const [deleteRuns] = Generated.useDeleteRunsMutation({
    context: propagateErrorsContext(),
  });
  const dispatch = useDispatch();
  return useCallback(
    async (mutationArgs: Parameters<typeof deleteRuns>[0]) => {
      const resp = await deleteRuns(mutationArgs);
      // trigger poll tick to refresh runs data; ensures deleted runs are removed from ui
      dispatch(PollingActions.tick());
      return resp;
    },
    [deleteRuns, dispatch]
  );
}

// This is only used to set notes and displayName. Currently you must
// pass in both values because the backend clears them if you pass undefined.
// A better approach would be to provide update run actions that work
// directly on the store, and then write a saga that watches for them.
// Or maybe we could fetch the state from redux, compute the mutation,
// do the backend mutation (to make sure it succeeds), and then
// update the store.
export function useUpdateRun() {
  const [updateRun] = Generated.useUpsertRunMutation();
  const dispatch = useDispatch();
  return useCallback(
    async (serverID: string, runID: string, vars: RunsTypes.UpdateRunVars) => {
      await updateRun({variables: {id: serverID, ...vars}});
      dispatch(RunsActions.updateRun(runID, vars));
    },
    [dispatch, updateRun]
  );
}

export function useUpdateRunTags() {
  const [updateRun] = Generated.useUpsertRunMutation({
    context: propagateErrorsContext(),
  });
  const dispatch = useDispatch();
  return useCallback(
    (run: Run, tagNames: string[]) => {
      updateRun({
        variables: {
          id: run.id,
          tags: tagNames,
          // Sending undefined values for displayName or notes clears them, so
          // we always need to send both.
          displayName: run.displayName,
          notes: run.notes,
        },
      })
        .then(response => {
          // Update run tags in redux store
          const newTags = response.data?.upsertBucket?.bucket?.tags;
          if (newTags != null) {
            dispatch(RunsActions.updateRunTags({[run.name]: newTags}));
          }
        })
        .catch(error => {
          toast('Failed to update run tags', {type: 'error'});
        });
    },
    [dispatch, updateRun]
  );
}

export function useUpdateGroupRunTags(projectName: string, entityName: string) {
  const [updateGroupRun] = Generated.useUpsertRunGroupMutation({
    context: propagateErrorsContext(),
  });
  const dispatch = useDispatch();
  const queries = useSelector(state => state.runs.queries);

  return useCallback(
    async (run: Run, tagNames: string[]) => {
      const {group: name, notes} = run;

      try {
        const response = await updateGroupRun({
          variables: {
            entityName,
            projectName,
            name,
            notes,
            tags: tagNames,
          },
        });

        // Update run tags in redux store
        const newTags = response.data?.upsertRunGroup?.group?.tags;
        if (newTags == null) {
          return;
        }

        for (const {result} of Object.values(queries)) {
          for (const resultingRun of result) {
            if (!resultingRun.endsWith(`/${run.name}`)) {
              continue;
            }
            dispatch(RunsActions.updateRunTags({[resultingRun]: newTags}));
            return;
          }
        }
      } catch (error) {
        toast('Failed to update group run tags', {type: 'error'});
      }
    },
    [dispatch, updateGroupRun, queries, entityName, projectName]
  );
}

export function useBulkUpdateRunTags() {
  const [modifyRuns] = Generated.useModifyRunsMutation({
    context: propagateErrorsContext(),
  });
  const dispatch = useDispatch();
  return useCallback(
    (variables: {
      entityName: string;
      projectName: string;
      filters: string;
      addTags?: string[];
      removeTags?: string[];
    }) => {
      return modifyRuns({variables})
        .then(response => {
          // Update run tags in redux store
          const updatedRuns = response.data?.modifyRuns?.runsSQL;
          if (updatedRuns != null) {
            const tagMap: {[runName: string]: Generated.RunTag[]} = {};
            updatedRuns.forEach(r => {
              if (r?.name != null) {
                tagMap[r.name] = r?.tags;
              }
            });
            dispatch(RunsActions.updateRunTags(tagMap));
          }
        })
        .catch(error => {
          toast('Failed to bulk update run tags', {type: 'error'});
        });
    },
    [dispatch, modifyRuns]
  );
}
