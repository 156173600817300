import {escapeHTML} from '@wandb/weave/common/util/html';

import {RunWithRunsetInfo} from '../../state/runs/types';
import {CustomRunNames} from '../../state/views/customRunNames/types';
import * as Run from '../../util/runs';
import {Key} from '../runTypes';
import {LegendOrientation, LegendPosition} from './legendTypes';
import {RunDataSeries} from './types';

export function isLegendPosition(s: string): s is LegendPosition {
  return s === 'north' || s === 'south' || s === 'east' || s === 'west';
}

export function isLegendOrientation(s: string): s is LegendOrientation {
  return s === 'horizontal' || s === 'vertical';
}

export function getLegendOverrideKeyBackwardsCompatible(
  line: RunDataSeries,
  useRunOrGroupId: boolean,
  overrides: {[key: string]: any}
) {
  // Previously we always used the useRunOrGroupId=false in the getLegendOverrideKey,
  // so we check for that first.  At some point perhaps we can remove this weird
  // logic but I really don't want to affect anyone's charts.  (This would not affect
  // reports or runs pages, so the risk isn't enormous)
  if (useRunOrGroupId === true) {
    return getLegendOverrideKey(line);
  }

  const oldKey = getLegendOverrideKey(line);
  if (oldKey in overrides) {
    return oldKey;
  }
  return getLegendOverrideKey(line, false);
}

export function getLegendOverrideKey(
  line: RunDataSeries,
  useRunOrGroupId: boolean = true
) {
  // Do not change this function! It is the key for the overrides
  // this is the key for the override maps

  // We don't want to use the runOrGroupId for a per run plot
  // because we want to keep the legend overrides when the
  // user changes the run - see: WB-3257
  if (useRunOrGroupId === false) {
    return line.metricName || '';
  }

  // line.uniqueId is unique per run, but we want our legend override
  // to distinguish between metrics in the case that a plot
  // has multiple metrics for a single line
  return (line.uniqueId || '') + ':' + (line.metricName || '');
}

export function legendHTMLFromRun(
  run: RunWithRunsetInfo,
  keys: string[],
  groupKeys: Key[],
  /** custom run display name overrides from view spec */
  customRunNames?: CustomRunNames
): string {
  const legendFields = keys.map(key => {
    const val = Run.getValueFromKeyString(run, key);

    let formattedValue;
    if (key === 'run:displayName') {
      const isGroup = groupKeys.length > 0;
      if (isGroup) {
        formattedValue = Run.groupedRunDisplayName(run, groupKeys);
      } else {
        const customRunName = Run.getCustomRunName(
          run.name,
          isGroup,
          customRunNames
        );
        formattedValue = customRunName ?? Run.displayValue(val);
      }
    } else if (Run.isTimeKeyString(key)) {
      formattedValue = Run.formatTimestamp(val);
    } else if (Run.isDurationKeyString(key)) {
      formattedValue = Run.formatDuration(val);
    } else {
      formattedValue = Run.displayValue(val);
    }

    return {
      title: Run.keyStringDisplayName(key),
      value: formattedValue,
    };
  });

  return legendFields
    .map(legendField => {
      return (
        "<div><span class='key'>" +
        escapeHTML(legendField.title) +
        "</span>: <span class='value'>" +
        escapeHTML(legendField.value) +
        '</span></div>'
      );
    })
    .join('\n');
}
