import React, {createContext, useContext} from 'react';
import {useParams} from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';

import {useRampFlagLowResolutionFullFidelityQueries} from '../../util/rampFeatureFlags';

type PanelInteractionContextType = {
  numBucketsByPanelWidth: number | undefined;
  setZooming: (isZooming: boolean) => void;
  sizeRef: ReturnType<typeof useResizeObserver>['ref'];
  zooming: boolean;
};

export const PanelInteractionContext =
  createContext<PanelInteractionContextType | null>(null);

export const PanelInteractionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [zooming, setZooming] = React.useState(false);
  const {entityName} = useParams<{entityName: string}>();
  /**
   * We don't have any wrapping elements at this height in the React tree, so we're going to produce a ref to send downstream to the layout component. This will let us avoid creating an extra wrapping div.
   */
  const {ref, width} = useResizeObserver();
  const useLowResolutionQueries =
    useRampFlagLowResolutionFullFidelityQueries(entityName);
  const numBucketsByPanelWidth = getPointsPerPanel(
    useLowResolutionQueries ? width : 1000
  );

  const value = React.useMemo(
    () => ({
      numBucketsByPanelWidth,
      setZooming: (isZooming: boolean) => {
        setZooming(isZooming);
      },
      sizeRef: ref,
      width,
      zooming,
    }),
    [numBucketsByPanelWidth, setZooming, ref, width, zooming]
  );
  return (
    <PanelInteractionContext.Provider value={value}>
      {children}
    </PanelInteractionContext.Provider>
  );
};

function getPointsPerPanel(panelWidth: number | undefined) {
  if (panelWidth === undefined) {
    return undefined;
  }

  if (panelWidth > 425) {
    return 1000;
  }

  if (panelWidth > 300) {
    return 850;
  }
  if (panelWidth > 200) {
    return 700;
  }

  return 550;
}

export function usePanelInteractionContext() {
  const context = useContext(PanelInteractionContext);

  if (!context) {
    console.error(
      'usePanelInteractionContext must be used within a PanelInteractionProvider'
    );

    return {
      numBucketsByPanelWidth: 1000,
      setZooming: () => {},
      sizeRef: null,
      zooming: false,
    };
  }

  return context;
}
