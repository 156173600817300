import {
  DragData,
  DragRef,
} from '@wandb/weave/common/containers/DragDropContainer';
import {mediaStrings} from '@wandb/weave/common/types/media';
import {ID} from '@wandb/weave/common/util/id';
import {isTruthy} from '@wandb/weave/common/util/types';
import {toIncludesObj} from '@wandb/weave/core';
import {compact, groupBy, isEqual, isNumber, sortBy} from 'lodash';

import {
  AddPanel,
  APIAddedPanelSpecs,
  DefaultPanelSpecs,
  FoundAPIAddedPanelSpecs,
  FoundPanelSpecs,
  PanelBankConfig,
  PanelBankConfigState,
  PanelBankDiff,
  PanelBankSectionConfig,
  PanelBankSectionConfigWithVisiblePanels,
  PanelIsActiveParams,
  QueryArgSpecs,
} from '../components/PanelBank/types';
import {RunsLinePlotConfig} from '../components/PanelRunsLinePlot/types';
import {weavePanelForWeaveKey} from '../components/PanelWeave';
import {OrganizationPrefix} from '../components/WorkspaceDrawer/Settings/types';
import {VizMap} from '../state/graphql/historyKeysQuery';
import * as PanelTypes from '../state/views/panel/types';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import type {PartRefFromObjSchema} from '../state/views/types';
import {
  RunHistoryKeyInfo,
  RunHistoryKeyInfoKeys,
  RunHistoryKeyType,
} from '../types/run';
import * as Panels from '../util/panels';
import * as RunHelpers from '../util/runhelpers';
import {
  DEFAULT_X_AXIS,
  PANEL_BANK_CHARTS_NAME,
  PANEL_BANK_CUSTOM_CHARTS_NAME,
  PANEL_BANK_HIDDEN_SECTION_NAME,
  PANEL_BANK_MEDIA_NAME,
  PANEL_BANK_SYSTEM_NAME,
  PANEL_BANK_TABLES_NAME,
} from './panelbankTypes';
import {LayedOutPanel, Panel} from './panelTypes';
import {startPerfTimer} from './profiler';
import {isReservedKey} from './runs';
import {QueryField} from './vega3';

const organizationPrefixIsLast = (autoOrganizePrefix?: OrganizationPrefix) =>
  autoOrganizePrefix === OrganizationPrefix.LastPrefix;

export function isPanel(ref: DragRef | null): ref is PanelTypes.Ref {
  return ref != null && ref.type === 'panel';
}

export function isHidden(
  prevKeysSet: Set<string>,
  key: string,
  panelBankConfigState: PanelBankConfigState
): boolean {
  return (
    prevKeysSet.has(key) && panelBankConfigState !== PanelBankConfigState.Init
  );
}

export function isPanelBankSection(
  ref: DragRef | null
): ref is PanelBankSectionConfigTypes.Ref {
  return ref != null && ref.type === 'panel-bank-section-config'; // o.panels != null;
}

export function isDraggingWithinSection(
  panelBankSectionConfigRef: PartRefFromObjSchema<PanelBankSectionConfigTypes.PanelBankSectionConfigObjSchema>,
  dragData: DragData | null
) {
  return (
    dragData != null &&
    isEqual(dragData.fromSectionRef, panelBankSectionConfigRef)
  );
}

export const keyInfoToAddedPanels = (
  keyViz: {[key: string]: any},
  autoOrganizePrefix?: OrganizationPrefix
) => {
  const addedPanels: APIAddedPanelSpecs = {};
  const setSection = (key: string) => {
    if (key.includes('/')) {
      if (organizationPrefixIsLast(autoOrganizePrefix)) {
        const lastIndex = key.lastIndexOf('/');
        return key.substring(0, lastIndex);
      } else {
        const firstIndex = key.indexOf('/');
        return key.substring(0, firstIndex);
      }
    } else {
      return PANEL_BANK_CUSTOM_CHARTS_NAME;
    }
  };
  for (const viz of Object.keys(keyViz)) {
    const panel_type = keyViz[viz].panel_type;
    const v = keyViz[viz].panel_config;
    const section = setSection(viz);
    if (v != null) {
      addedPanels[viz] = {
        type: 'api-added-panel',
        config: {...v},
        defaultSection: section,
        viewType: panel_type,
      };
    }
  }
  return addedPanels;
};

// This gets called a lot. Keep it fast.
export const keyInfoToDefaultPanelSpecs = (
  keys: RunHistoryKeyInfoKeys,
  keyViz: VizMap,
  autoOrganizePrefix?: OrganizationPrefix,
  definedMetricsMap?: Panels.MetricsDict,
  hiddenMetrics?: Set<string>
) => {
  // for some available metrics, create a flattened mapping
  // of expected panel keys -> metrics

  // the resulting panel bank should contain ALL of the panels described here
  // (even if some are in the Hidden Panels section)
  // (this excludes Charlemagne panels added through wandbs _add_panel api)
  const keyTypes = RunHelpers.keyTypes(keys);
  const defaultPanelSpecs: DefaultPanelSpecs = {};
  const hasKeyViz = Object.keys(keyViz).length > 0;

  let {systemMetrics, remainingMetrics} = groupBy(Object.keys(keys), metric =>
    metric.startsWith('system') ? 'systemMetrics' : 'remainingMetrics'
  );
  systemMetrics = systemMetrics ?? [];
  remainingMetrics = remainingMetrics ?? [];

  for (const template of Object.values(Panels.systemPanelTemplates)) {
    const {match, noMatch} = groupBy(systemMetrics, metric =>
      metric.match(template.regex) ? 'match' : 'noMatch'
    );
    if (match != null && match.length > 0) {
      defaultPanelSpecs[template.key] = {
        type: 'default-panel',
        metrics: match,
        keyType: 'number',
        defaultSection: PANEL_BANK_SYSTEM_NAME,
      };
    }

    systemMetrics = noMatch || [];
  }

  // for the remaining systemMetrics, first look for
  // OpenMetrics and try to group them by
  // the <common_key> in <common_key>.<id>
  // For example, group together
  // system/openmetrics.DCGM.DCGM_FI_DEV_GPU_TEMP.0 and
  // system/openmetrics.DCGM.DCGM_FI_DEV_GPU_TEMP.1
  // into common_key = system/openmetrics.DCGM.DCGM_FI_DEV_GPU_TEMP

  const systemMetricsByCommonKey: {[key: string]: string[]} = {};
  const openMetrics = systemMetrics.filter(metric =>
    metric.startsWith('system/openmetrics')
  );
  for (const metric of openMetrics) {
    // commonKey is everything up to the last dot:
    const commonKey = metric.substring(0, metric.lastIndexOf('.'));
    if (systemMetricsByCommonKey[commonKey] == null) {
      systemMetricsByCommonKey[commonKey] = [];
    }
    // append to the end of the array:
    systemMetricsByCommonKey[commonKey].push(metric);
    // remove the matching metrics from systemMetrics
    systemMetrics = systemMetrics.filter(m => m !== metric);
  }

  for (const commonKey of Object.keys(systemMetricsByCommonKey)) {
    const metrics = systemMetricsByCommonKey[commonKey];
    const sortedMetrics = sortBy(metrics);
    defaultPanelSpecs[sortedMetrics[0]] = {
      type: 'default-panel',
      metrics: sortedMetrics,
      keyType: 'number',
      defaultSection: PANEL_BANK_SYSTEM_NAME,
    };
  }

  const combinedMetrics = [...systemMetrics, ...remainingMetrics];
  // the remaining metrics should all correspond to single-metric default
  // panels
  for (const metric of combinedMetrics) {
    if (['_step', '_runtime', '_timestamp'].includes(metric)) {
      continue;
    }

    const keyType = keyTypes[metric];

    if (hasKeyViz && keyViz[metric]) {
      // skip cases where the visualization is defined with a config, since it is a Charlemagne
      // panel with a key identical to a metric
      if ('panel_config' in keyViz[metric]) {
        continue;
      }
      const {id: storedPanelDefId, ...props} = keyViz[metric];
      const panelDefId =
        storedPanelDefId != null &&
        !(
          storedPanelDefId.startsWith('builtin:') ||
          storedPanelDefId.startsWith('lib:')
        )
          ? `lib:${storedPanelDefId}`
          : storedPanelDefId;
      defaultPanelSpecs[metric] = {
        type: 'legacy-vega',
        config: {
          ...props,
          panelDefId,
        },
        keyType,
        defaultSection: PANEL_BANK_MEDIA_NAME,
      };
      continue;
    }

    const isMedia = (mediaStrings as string[]).includes(keyType);
    const isChart = ['number', 'histogram'].includes(keyType);

    let defaultSection = PANEL_BANK_CHARTS_NAME;
    // hidden metrics are hidden in the hidden section
    if (hiddenMetrics?.has(metric)) {
      defaultSection = PANEL_BANK_HIDDEN_SECTION_NAME;
      // otherwise, if it's a table, it goes in the tables section
    } else if (
      [
        'table-file',
        'partitioned-table',
        'joined-table',
        'wb_trace_tree',
      ].includes(keyType)
    ) {
      defaultSection = PANEL_BANK_TABLES_NAME;
      // otherwise, if it's a media panel, default to the media section
    } else if (isMedia) {
      defaultSection = PANEL_BANK_MEDIA_NAME;
    }
    // any non hidden chart and media metrics are grouped by prefix
    if (
      (hiddenMetrics?.has(metric) == null ||
        hiddenMetrics?.has(metric) === false) &&
      (isMedia || isChart)
    ) {
      // keys containing certain separators get grouped in sections named after the prefix
      const prefix = getPrefixSectionName(metric, autoOrganizePrefix);

      if (prefix != null) {
        defaultSection = prefix;
      }

      if (!isMedia && defaultSection === 'system') {
        defaultSection = PANEL_BANK_SYSTEM_NAME;
      }
    }

    /**
     * Catches system metrics which aren't yet directly linked to a system metric template
     * true system metrics won't get a typecount so they'll fail the keytype part of this block
     * https://weightsandbiases.slack.com/archives/C010Y174QGH/p1734394243895049?thread_ts=1734121881.738369&cid=C010Y174QGH
     */
    const isUnknownSystemMetric = metric.startsWith('system/') && !keyType;
    if (isUnknownSystemMetric) {
      defaultSection = PANEL_BANK_SYSTEM_NAME;
    }

    defaultPanelSpecs[metric] = {
      type: 'default-panel',
      metrics: [metric],
      keyType: keyTypes[metric],
      defaultSection,
      defaultXAxis: definedMetricsMap?.[metric],
    };
  }
  return defaultPanelSpecs;
};

export const getExpectedPanelSpecs = (
  keys: RunHistoryKeyInfoKeys,
  viz: VizMap,
  autoOrganizePrefix?: OrganizationPrefix,
  definedMetricsMap?: Panels.MetricsDict,
  hiddenMetrics?: Set<string>
) => {
  const defaultSpecs = keyInfoToDefaultPanelSpecs(
    keys,
    viz,
    autoOrganizePrefix,
    definedMetricsMap,
    hiddenMetrics
  );
  const apiAddedSpecs = keyInfoToAddedPanels(viz, autoOrganizePrefix);
  return {
    defaultSpecs,
    apiAddedSpecs,
  };
};

export function getPrefixSectionName(
  metric: string,
  autoOrganizePrefix?: OrganizationPrefix
): string | null {
  const sepPos = organizationPrefixIsLast(autoOrganizePrefix)
    ? metric.lastIndexOf('/')
    : metric.indexOf('/');
  if (sepPos === -1) {
    return null;
  }
  return metric.slice(0, sepPos);
}

export const getFoundPanelSpecsDefault = (panelBankPanels: LayedOutPanel[]) => {
  // flatten panels in panel bank into a panel key -> metrics mapping:
  const foundPanelSpecs: FoundPanelSpecs = {};
  for (const panel of panelBankPanels) {
    const key = Panels.getKey(panel);
    if (!key) {
      continue;
    }

    if (
      panel.viewType === 'Media Browser' &&
      panel.config.mediaKeys &&
      panel.config.mediaKeys.length === 1
    ) {
      const mediaKey = panel.config.mediaKeys[0];

      foundPanelSpecs[key] = {
        type: 'default-panel',
        metrics: [mediaKey],
        panelId: panel.__id__,
      };

      continue;
    }

    if (panel.viewType === 'Weave') {
      foundPanelSpecs[key] = {
        type: 'default-panel',
        metrics: [key],
        panelId: panel.__id__,
      };

      continue;
    }

    if (panel.viewType === 'Run History Line Plot') {
      if (key) {
        // multi-metric default panels always have an explicit key:
        foundPanelSpecs[key] = {
          type: 'default-panel',
          metrics: panel.config.metrics || [],
          panelId: panel.__id__,
          defaultXAxis: panel.config.startingXAxis,
        };
      }
    }

    if (panel.viewType === 'Vega') {
      if (key) {
        foundPanelSpecs[key] = {
          type: 'legacy-vega',
          viz: panel.config,
          panelId: panel.__id__,
        };
      }
    }

    // any *other* panels (i.e. panels with more than one metric and no
    // explicit key) are assumed to be custom
  }
  return foundPanelSpecs;
};

export const getFoundPanelSpecsApiAdded = (
  panelBankPanels: LayedOutPanel[],
  addedPanelSpecs: APIAddedPanelSpecs
) => {
  const foundPanelConfigs: FoundAPIAddedPanelSpecs = {};
  for (const panel of panelBankPanels) {
    const key = Panels.getKey(panel);
    if (!key || !addedPanelSpecs[key]) {
      continue;
    }
    foundPanelConfigs[key] = {
      config: panel.config,
      panelId: panel.__id__,
      viewType: panel.viewType,
      type: 'api-added-panel',
    };
  }
  return foundPanelConfigs;
};

export const getFoundPanelSpecs = (
  panels: LayedOutPanel[],
  expectedApiAddedPanelSpecs: APIAddedPanelSpecs
) => {
  const defaultSpecs = getFoundPanelSpecsDefault(panels);
  const apiAddedSpecs = getFoundPanelSpecsApiAdded(
    panels,
    expectedApiAddedPanelSpecs
  );
  return {
    defaultSpecs,
    apiAddedSpecs,
  };
};

export const getPanelBankDiff = (
  expected: ReturnType<typeof getExpectedPanelSpecs>,
  found: ReturnType<typeof getFoundPanelSpecs>,
  autoSectionNames: Set<string>,
  prevKeys: string[],
  panelBankConfigState: PanelBankConfigState,
  isPanelGenEnabled = true
) => {
  // returns a map of operations that will bring the actual panel up to speed
  // with the expected panel
  const {endPerfTimer} = startPerfTimer('getPanelBankDiff');

  const diff: PanelBankDiff = {};

  const prevKeysSet = new Set(prevKeys);
  for (const key of Object.keys(expected.defaultSpecs)) {
    const expectedPanel = expected.defaultSpecs[key];
    const actualPanel = found.defaultSpecs[key];
    const shouldDecompressPanel = autoSectionNames.has(
      expectedPanel.defaultSection
    );

    if (expectedPanel.type === 'legacy-vega') {
      if (!actualPanel) {
        if (isPanelGenEnabled || shouldDecompressPanel) {
          diff[key] = {
            type: 'add',
            spec: expectedPanel,
          };
        }
      } else if (
        actualPanel.type !== 'legacy-vega' ||
        !isEqual(expectedPanel.config, actualPanel.viz)
      ) {
        diff[key] = {
          type: 'update',
          panelId: actualPanel.panelId,
          spec: expectedPanel,
        };
      }

      continue;
    }

    if (actualPanel && actualPanel.type === 'legacy-vega') {
      console.warn(
        `Panel with key '${key}' expected to be non-vega, but was vega`
      );
      continue;
    }

    const metricsInActual = new Set(actualPanel ? actualPanel.metrics : []);

    const metricsDiff = expectedPanel.metrics.filter(
      metric => !metricsInActual.has(metric)
    );

    const expectedDefaultXAxis = expectedPanel.defaultXAxis ?? DEFAULT_X_AXIS;
    const actualDefaultXAxis = actualPanel?.defaultXAxis ?? DEFAULT_X_AXIS;
    if (metricsDiff.length > 0 || expectedDefaultXAxis !== actualDefaultXAxis) {
      // the panel bank is either missing the chart for this key, or it has the
      // chart but needs to add some new metrics to it
      // or update the default x axis
      if (actualPanel) {
        diff[key] = {
          type: 'update',
          panelId: actualPanel.panelId,
          spec: {
            type: 'default-panel',
            metrics: metricsDiff,
            defaultXAxis: expectedDefaultXAxis,
          },
        };
      } else if (isPanelGenEnabled || shouldDecompressPanel) {
        // if we've seen this key before and we're *not* reinitializing the panelbank,
        // that means the user just deleted the chart for this key -- we need to hide it
        diff[key] = {
          type: 'add',
          spec: {
            ...expectedPanel,
            defaultSection: isHidden(prevKeysSet, key, panelBankConfigState)
              ? PANEL_BANK_HIDDEN_SECTION_NAME
              : expectedPanel.defaultSection,
          },
        };
      }
    }
  }
  // handle panels added through add_panel API, which holds the config in the spec
  for (const key of Object.keys(expected.apiAddedSpecs)) {
    const addedPanel = expected.apiAddedSpecs[key];
    const actualPanel = found.apiAddedSpecs[key];
    const shouldDecompressPanel = autoSectionNames.has(
      addedPanel.defaultSection
    );

    if (!actualPanel) {
      if (isPanelGenEnabled || shouldDecompressPanel) {
        diff[key] = {
          type: 'add',
          spec: {
            ...addedPanel,
            defaultSection: isHidden(prevKeysSet, key, panelBankConfigState)
              ? PANEL_BANK_HIDDEN_SECTION_NAME
              : addedPanel.defaultSection,
          },
        };
      }
    } else if (
      !isEqual(addedPanel.config, actualPanel.config) ||
      addedPanel.viewType !== actualPanel.viewType
    ) {
      diff[key] = {
        type: 'update',
        panelId: actualPanel.panelId,
        spec: addedPanel,
      };
    }
  }
  endPerfTimer();
  return diff;
};

export function generateSystemPanel(
  key: keyof typeof Panels.systemPanelTemplates,
  metrics: string[]
) {
  const template = Panels.systemPanelTemplates[key];

  return {
    key,
    viewType: 'Run History Line Plot',
    config: {
      metrics,
      groupBy: 'None',
      chartTitle: template.yAxis,
      yAxisMin: template.percentage ? 0 : undefined,
      yAxisMax: template.percentage ? 100 : undefined,
    } as RunsLinePlotConfig,
  };
}

export function getDefaultPanelConfigInner(
  keyName: string,
  addPanelSpec: AddPanel['spec']
): LayedOutPanel {
  if (addPanelSpec.type === 'legacy-vega') {
    return {
      key: keyName,
      viewType: 'Vega',
      config: addPanelSpec.config,
    } as LayedOutPanel;
  } else if (addPanelSpec.type === 'api-added-panel') {
    return {
      key: keyName,
      viewType: addPanelSpec.viewType,
      config: addPanelSpec.config,
    } as LayedOutPanel;
  }

  const metrics = addPanelSpec.metrics.sort();
  const openMetricsPrefix = 'system/openmetrics.';
  if (keyName.startsWith(openMetricsPrefix)) {
    return {
      key: keyName,
      viewType: 'Run History Line Plot',
      config: {
        metrics,
        groupBy: 'None',
        chartTitle: keyName.slice(
          openMetricsPrefix.length,
          keyName.lastIndexOf('.')
        ),
        yAxisMin: undefined,
        yAxisMax: undefined,
      } as RunsLinePlotConfig,
    } as LayedOutPanel;
  }
  if (keyName in Panels.systemPanelTemplates) {
    return generateSystemPanel(
      keyName as keyof typeof Panels.systemPanelTemplates,
      metrics
    ) as LayedOutPanel;
  }

  if (
    [
      'table-file',
      'partitioned-table',
      'joined-table',
      'wb_trace_tree',
    ].includes(addPanelSpec.keyType)
  ) {
    return {
      viewType: 'Weave',
      config: weavePanelForWeaveKey(keyName, addPanelSpec.keyType),
    } as LayedOutPanel;
  }

  return {
    viewType:
      addPanelSpec.keyType === 'histogram' ||
      addPanelSpec.keyType === 'number' ||
      addPanelSpec.keyType === 'unknown'
        ? 'Run History Line Plot'
        : 'Media Browser',
    config:
      addPanelSpec.keyType === 'histogram' ||
      addPanelSpec.keyType === 'number' ||
      addPanelSpec.keyType === 'unknown'
        ? {
            metrics: [keyName],
            groupBy: 'None',
            legendFields: ['run:displayName'],
            startingXAxis: addPanelSpec.defaultXAxis,
          }
        : {
            mediaKeys: [keyName],
          },
  } as LayedOutPanel;
}

export function getDefaultPanelConfig(
  keyName: string,
  addPanelSpec: AddPanel['spec'],
  isAuto = false
): LayedOutPanel {
  return {
    ...getDefaultPanelConfigInner(keyName, addPanelSpec),
    __id__: ID(),
    isAuto: isAuto,
  };
}

// the panel in this function is expected to come from Immer:
// we change it in place instead of returning a new panel
export function addMetricsToPanelConfig(
  mutablePanel: LayedOutPanel,
  newMetrics: string[]
) {
  // NOTE: this, and the diff infrastructure, could probably be adapted to
  // other kinds of plots in the future with a few changes
  if (
    mutablePanel.viewType !== 'Run History Line Plot' ||
    newMetrics.length === 0
  ) {
    return;
  }

  mutablePanel.config.metrics = [
    ...(mutablePanel.config.metrics || []),
    ...newMetrics,
  ];
}

export function addMetricsToPanelConfigImmutable(
  panel: LayedOutPanel,
  newMetrics: string[]
): LayedOutPanel {
  // NOTE: this, and the diff infrastructure, could probably be adapted to
  // other kinds of plots in the future with a few changes
  if (panel.viewType !== 'Run History Line Plot' || newMetrics.length === 0) {
    return panel;
  }

  return {
    ...panel,
    config: {
      ...panel.config,
      metrics: [...(panel.config.metrics ?? []), ...newMetrics],
    },
  };
}

export function updateDefaultxAxis(
  mutablePanel: LayedOutPanel,
  newXAxis?: string
) {
  if (mutablePanel.viewType !== 'Run History Line Plot') {
    return;
  }
  mutablePanel.config.startingXAxis = newXAxis;
}

export function updateDefaultxAxisImmutable(
  panel: LayedOutPanel,
  newXAxis?: string
): LayedOutPanel {
  if (panel.viewType !== 'Run History Line Plot') {
    return panel;
  }
  return {
    ...panel,
    config: {
      ...panel.config,
      startingXAxis: newXAxis,
    },
  };
}

// Returns the array of keys that are present in panelBankConfig
export function getInitializedKeys(panelBankConfig: PanelBankConfig): string[] {
  // console.time('initializedkeys');
  const initializedKeys = compact(
    panelBankConfig.sections.flatMap(s =>
      compact(s.panels.map(p => Panels.getKey(p)))
    )
  );

  // console.timeEnd('initializedkeys');
  return initializedKeys;
}

// Returns the array of keys that aren't yet present in panelBankConfig
export function getUninitializedKeys(
  initializedKeysLookup: {[key: string]: 1},
  keyInfo: RunHistoryKeyInfo
) {
  const uninitializedKeys = Object.keys(keyInfo.keys).filter(
    k => !isReservedKey(k) && initializedKeysLookup[k] == null
  );
  return uninitializedKeys;
}

const alwaysHaveDataPanelTypes = toIncludesObj([
  'Markdown Panel',
  'Parallel Coordinates Plot',
  'Scatter Plot',
  'Parameter Importance',
  'Run Comparer',
  'Code Comparer',
  'Bar Chart',
]);

// Returns true if we have the data we need to render this panel
export function haveDataForPanel(
  historyKeyInfo: RunHistoryKeyInfo,
  panel: Panel
): boolean {
  if (alwaysHaveDataPanelTypes[panel.viewType]) {
    return true;
  }
  if (panel.viewType === 'Vega2') {
    const userQuery = panel.config.userQuery;
    let queryArgs: Array<undefined | QueryArgSpecs>;
    if (userQuery != null) {
      queryArgs = userQuery.queryFields[0].fields
        // we filter out the config and summary keys, since we don't have an easy
        // way to retrieve all of the configs and summaries for all of the runs
        .filter(
          (field): field is QueryField =>
            field.args != null &&
            field.name !== 'config' &&
            field.name !== 'summary'
        )
        .map(field => field.args) as Array<undefined | QueryArgSpecs>;
    } else {
      return true;
    }
    for (const queryArg of queryArgs) {
      if (queryArg == null) {
        continue;
      }
      const hasData: boolean = queryArg.every(arg => {
        if (arg.name === 'tableColumns') {
          return true;
        }
        if (arg.name === 'tableKey') {
          return historyKeyInfo.keys[arg.value] != null;
        }
        return (
          !Array.isArray(arg.value) ||
          arg.value
            .filter(isTruthy)
            .every(key => historyKeyInfo.keys[key] != null)
        );
      });
      if (!hasData) {
        return false;
      }
    }
  } else {
    const metrics = Panels.getMetrics(panel);
    // this used to check all metrics to exist in order to consider
    // that run's historyKey "have data" for panel.
    // now, it only checks partial keys to exist, changed
    // in https://github.com/wandb/core/pull/8978
    const haveAtLeastOneMetric = metrics.some(
      metric => historyKeyInfo.keys[metric] != null
    );
    // if panel's metrics is empty, we display it so users can config the panel
    if (metrics.length > 0 && !haveAtLeastOneMetric) {
      // run history line plots suppoprt y axes metrics defined with a regex,
      // in which case we need to check if any of the keys match the regex. The
      // metrics matching the regex should be populated in panel.config.metrics
      // by react hooks before this function is called, but the panel state is
      // frequently out of sync, so we need to check for this case here until
      // the state issue is fixed.
      if (panel.viewType === 'Run History Line Plot') {
        if (panel.config.metricRegex != null && panel.config.useMetricRegex) {
          const regex = new RegExp(panel.config.metricRegex);
          for (const key of Object.keys(historyKeyInfo.keys)) {
            if (regex.test(key)) {
              return true;
            }
          }
        }
      }
      return false;
    }
  }
  return true;
}

const alwaysMatchPanelTypes = toIncludesObj([
  'Markdown Panel',
  'Parameter Importance',
  'Run Comparer',
  'Code Comparer',
]);

export function searchQueryMatchesPanel(searchQuery: string, panel: Panel) {
  if (alwaysMatchPanelTypes[panel.viewType]) {
    return true;
  }
  const searchRegex = searchRegexFromQuery(searchQuery);
  if (searchRegex == null) {
    return true;
  }
  return searchRegexMatchesPanel(searchRegex, panel);
}

export function searchRegexMatchesPanel(regex: RegExp, panel: Panel) {
  if (alwaysMatchPanelTypes[panel.viewType]) {
    return true;
  }

  const matchAgainst = [...Panels.getMetrics(panel)];

  if ('chartTitle' in panel.config && panel.config.chartTitle != null) {
    matchAgainst.push(panel.config.chartTitle);
  }

  // custom charts
  for (const settingsProp of [`fieldSettings`, `stringSettings`]) {
    if (
      settingsProp in panel.config &&
      (panel.config as any)[settingsProp] != null
    ) {
      const settings = (panel.config as any)[settingsProp];
      const title = settings.title || settings.Title;
      if (title) {
        matchAgainst.push(title);
      }
    }
  }

  for (const ma of matchAgainst) {
    if (regex.test(ma)) {
      return true;
    }
  }

  return false;
}

export const panelSortingKeyFromPanel = (panel: Panel) => {
  if ('chartTitle' in panel.config) {
    return panel.config.chartTitle;
  }
  if (
    'mediaKeys' in panel.config &&
    panel.config.mediaKeys != null &&
    panel.config.mediaKeys?.length > 0
  ) {
    return panel.config.mediaKeys[0];
  }
  if (
    'metrics' in panel.config &&
    panel.config.metrics != null &&
    panel.config.metrics.length > 0
  ) {
    return panel.config.metrics[0];
  }
  if ('key' in panel && panel.key != null) {
    return panel.key;
  }
  return undefined;
};

export function sortPanelCheck(
  keyA: string | undefined,
  keyB: string | undefined
): number {
  if (keyA == null && keyB == null) {
    return 0;
  }
  if (keyA == null) {
    return -1;
  }
  if (keyB == null) {
    return 1;
  }
  if (isNumber(keyA) && isNumber(keyB)) {
    return keyA - keyB;
  }
  return keyA.toLowerCase() > keyB.toLowerCase() ? 1 : -1;
}

// TODO: move this function into SearchProvider, and handle the error there by setting error state and displaying a message
export function searchRegexFromQuery(searchQuery: string): RegExp | null {
  let searchRegex: RegExp | null = null;
  let query = searchQuery.trim();
  // if the query is a single '*', match everything (even though * isn't technically a valid regex)
  if (query === '*') {
    query = '.*';
  }
  if (query.length > 0) {
    try {
      searchRegex = new RegExp(query, 'i');
    } catch {
      // console.warn(`Invalid search query: ${query}`);
      // since we execute search onChange, we need to catch invalid/incomplete regexes, like '[' without closing ']'
    }
  }
  return searchRegex;
}

export function isHistogram(
  panel: Panel,
  historyKeyInfo: RunHistoryKeyInfo
): boolean {
  const keyTypes = RunHelpers.keyTypes(historyKeyInfo.keys);
  return isHistogramWithKeyTypes(panel, keyTypes);
}

export function isHistogramWithKeyTypes(
  panel: Panel,
  keyTypes: {[key: string]: RunHistoryKeyType}
): boolean {
  const panelKey = Panels.getKey(panel);
  return panelKey != null && keyTypes[panelKey] === 'histogram';
}

// TODO: maybe worth a refactor. instead of testing and returning a boolean, we may want to add metadata to the panel,
//  e.g. panel.isSearchMatch, panel.isHistogram, panel.hasData, etc.
export function panelIsActive({
  section,
  panel,
  isSingleRun,
  searchRegex,
  historyKeyInfo,
  keyTypes,
}: PanelIsActiveParams): boolean {
  // Don't show histograms in multi-run workspaces
  if (!isSingleRun) {
    const hist =
      keyTypes != null
        ? isHistogramWithKeyTypes(panel, keyTypes)
        : isHistogram(panel, historyKeyInfo);
    if (hist) {
      return false;
    }
  }

  if (searchRegex != null && !searchRegexMatchesPanel(searchRegex, panel)) {
    return false;
  }

  if (section.type !== 'grid' && !haveDataForPanel(historyKeyInfo, panel)) {
    return false;
  }

  return true;
}

export function getSectionsWithVisiblePanels(
  sections: readonly PanelBankSectionConfig[],
  mappingFn: (
    s: PanelBankSectionConfig
  ) => PanelBankSectionConfigWithVisiblePanels = defaultSectionToSectionWithVisiblePanelsMapping
): PanelBankSectionConfigWithVisiblePanels[] {
  return sections.map(mappingFn).filter(s => s.visiblePanels.length > 0);
}

export function defaultSectionToSectionWithVisiblePanelsMapping(
  s: PanelBankSectionConfig
): PanelBankSectionConfigWithVisiblePanels {
  return {
    ...s,
    ref: (s as any).ref,
    visiblePanels: [...s.panels],
  };
}

// TODO: remove this when we promote beta panel search to official feature
export const isSectionVisible = (
  showEmptySections: boolean,
  isSearching: boolean,
  renderSection: {
    activePanelRefs: unknown[];
    inactivePanelRefs: unknown[];
    isNameSearchMatch: boolean;
  }
) => {
  if (showEmptySections) {
    return true;
  }
  const isEmptyUserGeneratedSection =
    renderSection.activePanelRefs.concat(renderSection.inactivePanelRefs)
      .length === 0;
  // We always want to show user generated sections when empty -
  // I'm not 100% certain, but I believe this is a workaround for
  // ensuring that when a user adds a new section (which will be empty)
  // that we show that section.
  if (isEmptyUserGeneratedSection) {
    return true;
  }

  if (isSearching && renderSection.isNameSearchMatch) {
    return true;
  }

  return renderSection.activePanelRefs.length > 0;
};
