import {useMemo} from 'react';

import {
  EntityIntegrationsQuery,
  EntityQuery,
  SlackIntegration as Slack,
} from '../../generated/graphql';
import {Entity} from '../../types/graphql';
import {isSlackIntegration} from './utils';

export function useSlackIntegrationsFromEntity(
  entity: Entity | EntityQuery['entity'] | EntityIntegrationsQuery['entity']
) {
  return useMemo(() => {
    const slackIntegrations: Slack[] = [];
    for (const integrationEdge of entity?.integrations?.edges ?? []) {
      if (integrationEdge?.node) {
        const integration = integrationEdge.node;
        if (isSlackIntegration(integration)) {
          slackIntegrations.push(integration);
        }
      }
    }
    return slackIntegrations;
  }, [entity?.integrations?.edges]);
}
