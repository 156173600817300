import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {
  hexToRGB,
  MOON_250,
  OBLIVION,
} from '@wandb/weave/common/css/globals.styles';
import React, {memo, useEffect, useMemo} from 'react';
import {Sparklines, SparklinesBars, SparklinesLine} from 'react-sparklines';

import {trackPlotKeyboardCopy} from '../../services/analytics';
import {useInteractStateWhenOnScreen} from '../../state/interactState/hooks';
import {getTextColor} from '../../util/colors';
import {
  legendTemplateInsertCrosshairValues,
  legendTemplateRemoveCrosshairValues,
} from '../../util/legend';
import {Mark} from '../../util/plotHelpers/types';
import {TruncationType} from '../common/TruncateText/TruncateTextTypes';
import {markToIconNameMap} from '../LineStylePicker';
import {usePanelConfigContext} from '../PanelRunsLinePlot/PanelConfigContext';
import {TooltipNumberOfRunsOptions} from '../WorkspaceDrawer/Settings/types';
import {MarkSeriesPointForCrosshair} from './crosshairUtils';
import {GradientOverlay} from './GradientOverlay';
import {getNeighborsIndexRange} from './utils';

const copyTsvFormattedDataToClipboard = (
  crosshairValues: MarkSeriesPointForCrosshair[]
) => {
  const tsvFormattedData = crosshairValues
    .map(v => legendTemplateRemoveCrosshairValues(v.title) + '\t' + v.y)
    .join('\n');

  navigator.clipboard.writeText(tsvFormattedData);
  trackPlotKeyboardCopy();
};

const getHandleKeyPress = (
  crosshairValues: MarkSeriesPointForCrosshair[]
): EventListener => {
  return (e: unknown) => {
    if ((e as Event).type !== 'keydown') {
      // ruh roh!
      console.error(
        'Did not receive keydown event in keydown event handler!??!'
      );
    }
    const keyboardEvent = e as KeyboardEvent;
    if (
      keyboardEvent.key === 'c' &&
      (keyboardEvent.metaKey || keyboardEvent.ctrlKey) &&
      !keyboardEvent.repeat
    ) {
      copyTsvFormattedDataToClipboard(crosshairValues);
    }
  };
};

type CrosshairFlagContentProps = {
  allowCopyContents: boolean;
  crosshairValues: MarkSeriesPointForCrosshair[];
  highlightRun?: string;
  isHovered: boolean;
  isSparkLines: boolean;
  maxLength?: number;
  truncateLegend: boolean;
  xAxis: string;
  runNameTruncationType?: TruncationType;
};

const hardcodedRelative: React.CSSProperties = {position: 'relative'};
const hardcodedStyles: React.CSSProperties = {width: '100%', height: '100%'};
const CrosshairFlagContentComp: React.FC<CrosshairFlagContentProps> = ({
  allowCopyContents,
  crosshairValues,
  highlightRun,
  isHovered: isHoveredPanel = false,
  isSparkLines = false,
  maxLength,
  truncateLegend,
  xAxis,
  runNameTruncationType,
}) => {
  const {
    colorRunNames,
    highlightedCompanionRunOnly,
    tooltipNumberOfRuns,
    xAxisFormat,
  } = usePanelConfigContext();

  const [domRef, highlightedRunName] = useInteractStateWhenOnScreen(
    i => i.highlight?.['run:name'] ?? ''
  );

  let minimumNeighbors = 0;
  if (!isHoveredPanel && highlightedCompanionRunOnly) {
    minimumNeighbors = 0;
  } else if (tooltipNumberOfRuns === TooltipNumberOfRunsOptions.Single) {
    minimumNeighbors = 0;
  } else if (tooltipNumberOfRuns === TooltipNumberOfRunsOptions.Default) {
    minimumNeighbors = 4;
  } else if (tooltipNumberOfRuns === TooltipNumberOfRunsOptions.All) {
    minimumNeighbors = Infinity;
  }

  useEffect(() => {
    let cleanup;
    if (allowCopyContents) {
      const fn = getHandleKeyPress(crosshairValues);
      document.addEventListener('keydown', fn, {
        /* no options */
      });
      cleanup = () => {
        document.removeEventListener('keydown', fn);
      };
    }
    return cleanup;
  }, [allowCopyContents, crosshairValues]);

  const sortedValues = crosshairValues.sort(
    (a, b) => (b.y as number) - (a.y as number)
  );
  const matchedIndex = sortedValues.findIndex(
    val => val.uniqueId === highlightedRunName
  );

  const [minIndex, maxIndex] = React.useMemo(
    () =>
      getNeighborsIndexRange({
        neighborsPerSide: minimumNeighbors,
        list: sortedValues,
        index: matchedIndex,
      }),
    [sortedValues, matchedIndex, minimumNeighbors]
  );

  /**
   * We don't know if we're in multiple metrics mode so we gather the data to handle either in a single loop
   * Filtered: these are the items to display based on neighboring rules
   * Matched: the hovered run has multiple metrics logged, so we don't show neighbors we just show the exact matches
   *
   * Note: there is a design discussion in progress about how to handle multiple metrics. We experimented with showing just the matching run in the overlay (reverted here: https://github.com/wandb/core/pull/20659) but a customer asked for the revert. Leaving this here for now as we will revisit in the future.
   */
  const {filteredValues: valuesToRender, matchedValues} = sortedValues.reduce(
    (acc, val, index) => {
      if (val.name === highlightedRunName) {
        acc.matchedValues.push(val);
        acc.filteredValues.push(val);
        return acc;
      }

      if (index >= minIndex && index <= maxIndex) {
        acc.filteredValues.push(val);
      }

      return acc;
    },
    {
      filteredValues: [] as MarkSeriesPointForCrosshair[],
      matchedValues: [] as MarkSeriesPointForCrosshair[],
    }
  );

  const showGradients =
    sortedValues.length > valuesToRender.length && // gradients can't be active unless there are greater than nine runs
    valuesToRender.length > 5;

  /**
   * note: the maximum y values will have the lowest indexes because they sort in descending order. The values need to be sorted because in the plot the lines will be ordered from highest value (top of chart) to lowest value (bottom of chart) at any given x value and the overlay needs to match.
   */
  const showHiddenMaxValuesGradient = showGradients && minIndex > 0;
  const showHiddenMinValuesGradient =
    showGradients && maxIndex < sortedValues.length - 1;

  const fixedStyles: React.CSSProperties = useMemo(
    () => ({
      background: 'white',
      border: `1px solid ${MOON_250}`,
      borderRadius: 4,
      boxShadow: '0px 12px 24px 0px rgba(21,24,31,0.16)',
      color: '#333',
      fontFamily: 'Inconsolata, monospace',
      fontSize: 12,
      lineHeight: '141%',
      padding: '8px 12px',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: isSparkLines ? 200 : undefined,
    }),
    [isSparkLines]
  );

  const barStyles = useMemo(
    () => ({
      fill: crosshairValues[0].color,
      fillOpacity: 0.75,
    }),
    [crosshairValues]
  );

  const getIsHighlighted = useMemo(() => {
    return (point: MarkSeriesPointForCrosshair) => {
      if (
        point.uniqueId === highlightRun &&
        crosshairValues != null && // no need to show highlight if only one run
        valuesToRender.length > 1 &&
        matchedValues.length === 1 // no highlighting with multiple metrics
      ) {
        return 'highlighted';
      }

      return '';
    };
  }, [
    crosshairValues,
    highlightRun,
    matchedValues.length,
    valuesToRender.length,
  ]);

  const getPointColor = useMemo(() => {
    return (point: MarkSeriesPointForCrosshair): React.CSSProperties => {
      const baseStyles: React.CSSProperties = {
        alignItems: 'center',
        display: 'flex',
        // eslint-disable-next-line no-extra-boolean-cast
        background: !!getIsHighlighted(point)
          ? hexToRGB(OBLIVION, 0.07)
          : 'none',
        // eslint-disable-next-line no-extra-boolean-cast
        fontWeight: !!getIsHighlighted(point) ? 600 : 400,
      };
      if (colorRunNames) {
        baseStyles.color = getTextColor(point.color);
      }
      return baseStyles;
    };
  }, [colorRunNames, getIsHighlighted]);

  if (isSparkLines) {
    return (
      <div style={fixedStyles}>
        <Sparklines data={crosshairValues[0].values} style={hardcodedStyles}>
          <SparklinesLine color={crosshairValues[0].color} />
          <SparklinesBars barWidth={8} style={barStyles} />
        </Sparklines>

        {allowCopyContents && <>Press CMD+C or CTRL+C to copy this data</>}
      </div>
    );
  }

  // this keeps empty companion boxes from showing up on hover
  if (valuesToRender.length === 0) {
    return null;
  }

  let hotKey = `CMD+C or CTRL+C`;
  const {userAgent = ''} = navigator;
  if (userAgent.includes('Macintosh') || userAgent.includes('Mac OS')) {
    hotKey = 'CMD+C';
  } else if (userAgent.includes('Windows')) {
    hotKey = 'CTRL+C';
  }

  return (
    <div
      data-test="line-plot-crosshair-content"
      style={fixedStyles}
      ref={domRef}>
      <div style={hardcodedRelative}>
        {showHiddenMaxValuesGradient && <GradientOverlay position="top" />}
        {valuesToRender.map((point, i) => {
          return (
            <div
              data-test="line-plot-crosshair-content-row"
              key={point.title + ' ' + i}
              style={getPointColor(point)}>
              <LegacyWBIcon
                style={{
                  color: getTextColor(point.color),
                  fontSize: 18,
                }}
                name={markToIconNameMap[(point.mark as Mark) || 'solid']}
              />

              {legendTemplateInsertCrosshairValues({
                addDefaultTemplateIfCrosshairValuesMissing: true,
                key: point.name + ' ' + i,
                legendTemplate: point.title,
                maxLength: maxLength || 10,
                truncateOutsideOfCrosshairValues: truncateLegend,
                type: 'line',
                values: {
                  x: {xAxis, val: point.x, xAxisFormat: xAxisFormat},
                  y: point.value,
                  mean: point.value,
                  min:
                    point.minmax && point.minmax.length >= 2
                      ? point.minmax[0]
                      : undefined,
                  max:
                    point.minmax && point.minmax.length >= 2
                      ? point?.minmax[1]
                      : undefined,
                  stddev: point.stddev,
                  percent: point.percent,
                  total: point.total,
                  original: point.original,
                },
                runNameTruncationType,
              })}
            </div>
          );
        })}

        {showHiddenMinValuesGradient && <GradientOverlay position="bottom" />}
      </div>
      {allowCopyContents && (
        <span id="copy-values-prompt">Press {hotKey} to copy this data</span>
      )}
    </div>
  );
};
export const CrosshairFlagContent = memo(CrosshairFlagContentComp);
