import {toast} from '@wandb/weave/common/components/elements/Toast';
import {randID} from '@wandb/weave/common/util/data';
import {Button} from '@wandb/weave/components';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {ApolloQueryResult} from 'apollo-client';
import {flowRight as compose} from 'lodash';
import * as querystring from 'querystring';
import * as React from 'react';
import {useCallback, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Table} from 'semantic-ui-react';

import {
  SlackIntegration as GQLSlackIntegration,
  useServerInfoQuery,
} from '../../generated/graphql';
import {
  createSlackIntegrationMutation,
  CreateSlackIntegrationMutationFn,
} from '../../graphql/integrations';
import {captureError} from '../../integrations';
import {Entity} from '../../types/graphql';
import {safeLocalStorage} from '../../util/localStorage';
import {maybePluralize} from '../../util/uihelpers';
import {InstrumentedLoader as Loader} from '../utility/InstrumentedLoader';
import {useSlackIntegrationsFromEntity} from './hooks';
import {SlackIntegrationActions} from './SlackIntegrationActions';

export const SLACK_CODE = 'slack';
export const STATE_STORE_KEY = '__slack_oauth_state';

// We store the request type in the oauth state variable
// This way, when the page is loaded with the query params we
// can make the correct integration consume the tokens
const isSlackOAuthState = (state: string | string[] | undefined) => {
  if (typeof state === 'string') {
    const codes = state.split(':');
    return (codes && codes[0]) === SLACK_CODE;
  } else {
    return false;
  }
};

// Checks if the current page URL is loading after the Slack OAuth step completed
function hasSlackOAuthState(query: string): boolean {
  query = query.startsWith('?') ? query.substring(1) : query;
  const params = querystring.parse(query);
  return isSlackOAuthState(params.state);
}

function openSlackOAuthPage(
  state: string,
  slackClientId: string | undefined,
  redirectUri: string
) {
  const queryStr = querystring.stringify({
    scope: 'incoming-webhook',
    client_id: slackClientId,
    state,
    redirect_uri: redirectUri,
  });
  // eslint-disable-next-line wandb/no-unprefixed-urls
  window.open(`https://slack.com/oauth/authorize?${queryStr}`, '_self');
}

interface SlackIntegrationsTableComponentProps {
  entity: Entity;
  entityRefetch?: () => Promise<ApolloQueryResult<any>>;
  integrationReason?: string;
  createSlackIntegration: CreateSlackIntegrationMutationFn; // from withMutations()
  hideCreate?: boolean;
  hideDisconnect?: boolean;
  disabled?: boolean;
}

const SlackIntegrationsTableComponent: React.FC<SlackIntegrationsTableComponentProps> =
  React.memo(
    ({
      entity,
      entityRefetch,
      integrationReason,
      createSlackIntegration,
      hideCreate,
      hideDisconnect,
      disabled,
    }) => {
      const [isLoading, setIsLoading] = useState(false);

      const history = useHistory();

      // Grab the current URL -- we'll set this as the redirect URL.
      const redirectUriRef = useRef(window.location.href);

      const serverInfo = useServerInfoQuery();

      const slackIntegrations: GQLSlackIntegration[] =
        useSlackIntegrationsFromEntity(entity);

      const startSlackOAuth = useCallback(() => {
        window.analytics?.track('Connect Slack Clicked', {
          location: 'settings',
        });

        const state = `${SLACK_CODE}:wandb:${randID(20)}`;
        safeLocalStorage.setItem(STATE_STORE_KEY, state);
        if (!serverInfo.data) {
          toast({
            type: 'error',
            title: 'Unable to access Slack configuration from server',
            description:
              'Please try refreshing the page or contact support if the problem persists.',
            // set time to 0 to wait for user to close
            time: 0,
          });
          return;
        }

        openSlackOAuthPage(
          state,
          serverInfo.data?.serverInfo?.slackClientID,
          redirectUriRef.current
        );
      }, [serverInfo.data]);

      // Is called on page load when the oauth values are present in the url
      const createSlackIntegrationAfterOAuth = useCallback((): void => {
        // Extract query params
        let queryStr = history.location.search;

        // Remove leading '?' if present
        queryStr = queryStr.startsWith('?') ? queryStr.substring(1) : queryStr;

        const queryParams = querystring.parse(queryStr);
        const code = queryParams.code;
        const serverOAuthState = queryParams.state;

        // The "code" and "state" query params have been injected by Slack OAuth.
        // Remove just these parameters from the URL.
        const searchParams = new URLSearchParams(queryStr);
        searchParams.delete('code');
        searchParams.delete('state');
        history.replace({
          search: searchParams.toString(),
        });

        // Quit early if:
        // - Missing code: means the user is not going through the integration flow.
        // - In progress
        if (code === undefined || typeof code !== 'string' || isLoading) {
          return;
        }

        // Check that state matches across request to prevent xsrf
        // Pop the local state from storage for comparison
        const storedOAuthState = safeLocalStorage.getItem(STATE_STORE_KEY);
        safeLocalStorage.removeItem(STATE_STORE_KEY);

        if (storedOAuthState === undefined) {
          captureError('No stored state ', 'slackintegration1', {
            extra: {oAuthState: serverOAuthState, storedOAuthState},
          });
          return;
        }

        // Check auth state to prevent csrf
        if (serverOAuthState !== storedOAuthState) {
          // Scope the response to the specific integration so we don't hoist responses
          captureError(
            "Oauth state doesn't match local value",
            'slackintegratin2',
            {
              extra: {oAuthState: serverOAuthState, storedOAuthState},
            }
          );
        }

        setIsLoading(true);

        // Strip away the 'code' and 'state' from redirectURI: these are injected from Slack OAuth.
        // If these are included in the redirectURI in the mutation below, we'll get a code_already_used Slack Oauth error
        const fullRedirectURL = new URL(redirectUriRef.current);
        const redirectSearchParams = new URLSearchParams(
          fullRedirectURL.search
        );
        redirectSearchParams.delete('code');
        redirectSearchParams.delete('state');

        let redirectUri = fullRedirectURL.origin + fullRedirectURL.pathname;
        if (redirectSearchParams.size > 0) {
          redirectUri += `?${redirectSearchParams.toString()}`;
        }

        createSlackIntegration({
          code,
          redirectURI: redirectUri,
          entityName: entity.name,
        })
          .then(entityRefetch)
          .then(() => {
            setIsLoading(false);
          });
      }, [
        createSlackIntegration,
        entity.name,
        entityRefetch,
        history,
        isLoading,
      ]);

      // Only act for relevant responses, determined by a code we put in the state.
      if (hasSlackOAuthState(history.location.search)) {
        createSlackIntegrationAfterOAuth();
      }

      if (serverInfo.loading) {
        return <Loader name="server-info-loader" />;
      }

      return (
        <Tailwind>
          <div className="slack">
            <div>
              {slackIntegrations.length === 0 && integrationReason && (
                <div>{integrationReason}</div>
              )}
              <div className="mb-8 mt-8 flex items-center justify-between">
                {slackIntegrations.length > 0 &&
                  `${maybePluralize(
                    slackIntegrations.length,
                    'Slack integration'
                  )}`}
                {hideCreate ? (
                  <></>
                ) : (
                  <Button
                    icon="add-new"
                    variant="secondary"
                    size="small"
                    className="p-8"
                    onClick={() => startSlackOAuth()}>
                    New integration
                  </Button>
                )}
              </div>
              {slackIntegrations.length > 0 && (
                <div className="integrations max-h-[18rem] overflow-auto">
                  <Table className="integrations--table" basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          style={{
                            fontWeight: 'semibold',
                            paddingBottom: '0.1em',
                          }}
                          content="Channel"
                        />
                        <Table.HeaderCell
                          style={{
                            fontWeight: 'semibold',
                            paddingBottom: '0.1em',
                          }}
                          content="Workspace"
                        />
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {slackIntegrations.map(
                        (integration: GQLSlackIntegration) => (
                          <Table.Row key={integration.id}>
                            <Table.Cell>
                              <div className="flex items-center gap-8">
                                {/* TODO: Placeholder, replace with Slack icon */}
                                <Icon name="forum-chat-bubble" />
                                <div className="max-w-[15rem] truncate font-semibold">
                                  {integration.channelName}
                                </div>
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              <div className="w-[22rem] truncate">
                                {integration.teamName}
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              <SlackIntegrationActions
                                entityName={entity.name ?? ''}
                                slackIntegration={integration}
                                entityRefetch={entityRefetch}
                                existingSlackIntegrations={slackIntegrations}
                                hideDisconnect={hideDisconnect}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </Tailwind>
      );
    }
  );

const withMutations = compose(createSlackIntegrationMutation) as any;

export const SlackIntegrationsTable = withMutations(
  SlackIntegrationsTableComponent
);
