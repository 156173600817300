import {logHandledError} from '../../../services/errors/errorReporting';
import {checkRampFlagEnableOPFSCache} from '../../rampFeatureFlags';
import {getEntityNameFromPath} from '../util';

export const opfsEnabled = async (entityName?: string): Promise<boolean> => {
  const teamName = entityName || getEntityNameFromPath();

  return await checkRampFlagEnableOPFSCache(teamName);
};

export async function getOPFSFileTextContent(
  fileName: string
): Promise<string> {
  const root = await navigator.storage.getDirectory();
  const fileHandle = await root.getFileHandle(fileName);
  const file = await fileHandle.getFile();
  const text = await file.text();
  return text;
}

// I may just be being dumb, but I couldn't find a way to clear the OPFS store in my browser.
// This is just here as a convenience when a full wipe is needed.
export async function clearOPFS(): Promise<void> {
  try {
    const root = await navigator.storage.getDirectory();
    const promises = [];
    for await (const [name] of root as any) {
      console.log(`Removing ${name} from OPFS...`);
      promises.push(root.removeEntry(name));
    }
    await Promise.all(promises);
    console.log(`Removed ${promises.length} files from OPFS`);
  } catch (err) {
    logHandledError(`Error clearing OPFS cache - ${err}`);
  }
}
(window as any).clearOPFS = clearOPFS;

export async function storeTextFileInOPFS(
  fileName: string,
  textContent: string
): Promise<void> {
  const root = await navigator.storage.getDirectory();
  const draftHandle = await root.getFileHandle(fileName, {create: true});

  // For some reason, TS in CI doesn't think `FileSystemFileHandle.createWritable` exists
  // Let's just cast for now
  const writable = await (draftHandle as any).createWritable();

  await writable.write(textContent);
  await writable.close();
}
