import {useMemo} from 'react';

// if chart height is smaller than this hide the legend by default
export const MIN_SHOW_LEGEND_CHART_HEIGHT = 235;
export const MIN_SHOW_LEGEND_CHART_WIDTH = 250;

export function useLegendVisibility(
  isSingleRun: boolean,
  showLegend: boolean | undefined,
  panelSize?: {
    height: number;
    width: number;
  }
) {
  return useMemo(
    () => getIsLegendShown(isSingleRun, showLegend, panelSize),
    [isSingleRun, panelSize, showLegend]
  );
}

export const getIsLegendShown = (
  isSingleRun: boolean,
  showLegend: boolean | undefined,
  panelSize?: {
    height: number;
    width: number;
  }
) => {
  // hide legends on single runs, or when they're turned off
  // this is also known as a single run workspace
  if (isSingleRun) {
    return false;
  }

  if (panelSize != null) {
    // hide legends when the panel is too small to show them
    const {height, width} = panelSize;
    if (
      height <= MIN_SHOW_LEGEND_CHART_HEIGHT ||
      width <= MIN_SHOW_LEGEND_CHART_WIDTH
    ) {
      return false;
    }
  }

  return showLegend ?? true;
};
