import {
  Integration as GQLIntegration,
  SlackIntegration as GQLSlackIntegration,
} from '../../generated/graphql';

export type SlackIntegration = {
  id: string;
  teamName: string; // Really the Slack workspace name
  channelName: string;
};

export function isSlackIntegration(
  integration: GQLIntegration
): integration is GQLSlackIntegration {
  return (integration as any).__typename === 'SlackIntegration';
}

export function toSlackIntegration(
  integration: GQLIntegration
): SlackIntegration | undefined {
  if (isSlackIntegration(integration)) {
    return {
      id: integration.id,
      teamName: integration.teamName,
      channelName: normalizeSlackChannelName(integration.channelName),
    };
  }
  return undefined;
}

export function normalizeSlackChannelName(channelName: string): string {
  if (!channelName.startsWith('#') && !channelName.startsWith('@')) {
    return `#${channelName}`;
  }
  return channelName;
}
