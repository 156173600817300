import React, {createContext, useCallback, useContext, useMemo} from 'react';

import {
  SettingType,
  trackSetting,
} from '../../../services/analytics/workspaceSettingsEvents';
import {usePart, useViewAction} from '../../../state/views/hooks';
import * as PanelBankConfigActions from '../../../state/views/panelBankConfig/actions';
import * as WorkspaceSettingsActions from '../../../state/views/workspaceSettings/actions';
import {DispatchableAction} from '../../../types/redux';
import {useDetectChanges} from '../../../util/react-help/detectReactPropChanges';
import {useWorkspaceRefsContext} from '../../Workspace/WorkspaceRefsContext';
import {useWorkspaceLinePlotSettings} from './runLinePlots/utils/getLinePlotSettings';
import {
  Complete,
  LinePlotSettings,
  OrganizationPrefix,
  WorkspaceLayoutSettings,
} from './types';
import {getWorkspaceLayoutSettings} from './workspaceLayout/getWorkspaceLayoutSettings';
import {useUpdateLayoutSettingAndOrganizePrefix} from './workspaceLayout/useUpdateOrganizePrefix';
import {useUpdateShouldAutoGeneratePanels} from './workspaceLayout/useUpdateShouldAutoGeneratePanels';
import {useUpdateLayoutSettingAndSortPanels} from './workspaceLayout/useUpdateSortPanels';

export type WorkspaceSettingsContextType = {
  // settings
  workspaceLayout: Complete<WorkspaceLayoutSettings>;
  linePlot: Complete<LinePlotSettings>;

  // updaters
  updateWorkspaceLayoutSettings: (
    settings: Partial<WorkspaceLayoutSettings>
  ) => DispatchableAction;
  updateLinePlotWorkspaceSettings: (
    settings: Partial<LinePlotSettings>
  ) => DispatchableAction;
  updateAllLinePlotSectionSettings: (
    settings: Partial<LinePlotSettings>
  ) => DispatchableAction;
  updateShouldAutoGeneratePanels: (shouldEnable: boolean) => void;
  updateLayoutSettingAndSortPanels: (sortAlphabetically: boolean) => void;
  updateLayoutSettingAndOrganizePrefix: (
    autoOrganizePrefix: OrganizationPrefix
  ) => void;

  // analytics
  trackWorkspaceSettingChange: (
    setting: SettingType,
    value: string | undefined
  ) => void;
};

export const WorkspaceSettingsContext = createContext<
  WorkspaceSettingsContextType | undefined
>(undefined);

type WorkspaceSettingsProps = {
  children: React.ReactNode;
};

/**
 * This context provider is used in conjuction with SectionSettingsContextProvider.
 * We use these two context providers to handle cascading settings logic.
 */
export const WorkspaceSettingsContextProvider = ({
  children,
}: WorkspaceSettingsProps) => {
  const {panelBankConfigRef, workspaceSettingsRef} = useWorkspaceRefsContext();
  const workspaceSettings = usePart(workspaceSettingsRef);

  // line plot related settings
  const linePlotSettings = useWorkspaceLinePlotSettings(workspaceSettings);
  const updateLinePlotWorkspaceSettings = useViewAction(
    workspaceSettingsRef,
    WorkspaceSettingsActions.updateLinePlotWorkspaceSettings
  );
  const updateAllLinePlotSectionSettings = useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.updateAllLinePlotSectionSettings
  );

  // workspace layout related settings
  const {showEmptySections, autoOrganizePrefix, sortAlphabetically} =
    getWorkspaceLayoutSettings(workspaceSettings);
  // Note: once feature is fully released on prod, move values up into `getWorkspaceLayoutSettings`
  const shouldAutoGeneratePanels =
    workspaceSettings?.shouldAutoGeneratePanels ?? true;

  const updateWorkspaceLayoutSettings = useViewAction(
    workspaceSettingsRef,
    WorkspaceSettingsActions.updateWorkspaceLayoutSettings
  );
  const updateShouldAutoGeneratePanels =
    useUpdateShouldAutoGeneratePanels(workspaceSettingsRef);
  const updateLayoutSettingAndSortPanels =
    useUpdateLayoutSettingAndSortPanels();
  const updateLayoutSettingAndOrganizePrefix =
    useUpdateLayoutSettingAndOrganizePrefix();

  // analytics
  const trackWorkspaceSettingChange = useCallback(
    (setting: SettingType, value: string | undefined) => {
      trackSetting({
        action: 'change',
        page: 'workspace',
        settingLevel: 'workspace',
        setting,
        settingValue: value,
      });
    },
    []
  );

  const value = useMemo(
    () => ({
      // settings
      workspaceLayout: {
        autoOrganizePrefix,
        showEmptySections,
        shouldAutoGeneratePanels,
        sortAlphabetically,
      },
      linePlot: linePlotSettings,

      // updaters
      updateWorkspaceLayoutSettings,
      updateLinePlotWorkspaceSettings,
      updateAllLinePlotSectionSettings,
      updateLayoutSettingAndSortPanels,
      updateShouldAutoGeneratePanels,
      updateLayoutSettingAndOrganizePrefix,

      // analytics
      trackWorkspaceSettingChange,
    }),
    [
      autoOrganizePrefix,
      linePlotSettings,
      shouldAutoGeneratePanels,
      showEmptySections,
      sortAlphabetically,
      trackWorkspaceSettingChange,
      updateAllLinePlotSectionSettings,
      updateLayoutSettingAndOrganizePrefix,
      updateLayoutSettingAndSortPanels,
      updateLinePlotWorkspaceSettings,
      updateShouldAutoGeneratePanels,
      updateWorkspaceLayoutSettings,
    ]
  );

  useDetectChanges('WorkspaceSettingsContextProvider', {
    linePlotSettings,
    panelBankConfigRef,
    shouldAutoGeneratePanels,
    updateAllLinePlotSectionSettings,
    updateLinePlotWorkspaceSettings,
    updateShouldAutoGeneratePanels,
    workspaceSettingsRef,
  });

  return (
    <WorkspaceSettingsContext.Provider value={value}>
      {children}
    </WorkspaceSettingsContext.Provider>
  );
};

export const useWorkspaceSettingsContext = (): WorkspaceSettingsContextType => {
  const value = useContext(WorkspaceSettingsContext);

  if (value == null) {
    throw new Error(
      'useWorkspaceSettingsContext must be used within a WorkspaceSettingsContextProvider'
    );
  }

  return value;
};

export const useMaybeWorkspaceSettingsContext = ():
  | WorkspaceSettingsContextType
  | undefined => {
  return useContext(WorkspaceSettingsContext);
};
