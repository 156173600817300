import {CacheApiCache} from './cacheapi';
import {LongTermCache} from './types';

const globalCache = new CacheApiCache();

/* Currently this function is just a wrapper around the globalCache.
  If we want to add more caches in the future, or route between OPFS and CacheAPI,
  we can do that here.
*/
export function getCache(): LongTermCache {
  return globalCache;
}
