import gql from 'graphql-tag';

export const ACCOUNT_PRIVILEGES = gql`
  query AccountPrivileges($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      subscriptions {
        id
        privileges
        plan {
          id
          planType
        }
      }
    }
  }
`;

export const ACCOUNT_ENTERPRISE_WEAVE_LIMITS = gql`
  query AccountEnterpriseWeaveLimits($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      weaveEnterpriseSubscription {
        billingPeriodStart
        billingPeriodEnd
        weaveLimit {
          weaveLimitBytes
          weaveOverageCostCents
          weaveOverageUnit
        }
      }
    }
  }
`;

export const ACCOUNT_SUBSCRIPTION_PLANS = gql`
  query AccountSubscriptionPlans($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      subscriptions {
        id
        plan {
          id
          planType
          name
        }
      }
    }
  }
`;
