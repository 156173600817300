import {useMemo} from 'react';

import {
  DerivedPointVisualizationOption,
  POINT_VISUALIZATION_OPTIONS,
} from '../../WorkspaceDrawer/Settings/types';
import {RunsLinePlotConfig} from '../types';
import {configSupportsBucketing} from './configSupportsBucketing';

/**
 * Line Aggregations are going to be used to determine the primary line. This will changing the querying under the hood as 'AVERAGE' and 'MEAN' will be bucketing queries.
 */
export type LineAggregations =
  | 'SAMPLED' // this is the default RSDQ response
  | 'AVERAGE'; // this is the default bucketing response

export function useDetermineAggregationType(
  config: RunsLinePlotConfig,
  {
    allowsNonMonotonicXAxis,
    hasRenderError,
    isMonotonic, // if the x-axis is non-monotonic
    pointVisualizationMethod,
  }: {
    allowsNonMonotonicXAxis: boolean;
    hasRenderError: boolean;
    isMonotonic: boolean;
    pointVisualizationMethod: DerivedPointVisualizationOption;
  }
): {
  lineType: LineAggregations;
  warningMessages: string[];
} {
  const unsupportedBucketingConditions = configSupportsBucketing({
    ...config,
    isMonotonic: isMonotonic || allowsNonMonotonicXAxis,
  });

  return useMemo(() => {
    if (unsupportedBucketingConditions.length > 0 || hasRenderError) {
      return {
        lineType: 'SAMPLED',
        warningMessages: unsupportedBucketingConditions,
      };
    }

    if (
      pointVisualizationMethod === POINT_VISUALIZATION_OPTIONS.BucketingGorilla
    ) {
      return {
        lineType: 'AVERAGE',
        warningMessages: [],
      };
    }

    return {
      lineType: 'SAMPLED',
      warningMessages: [],
    };
  }, [
    unsupportedBucketingConditions,
    hasRenderError,
    pointVisualizationMethod,
  ]);
}
