import Color from 'color';

import {Line} from '../../../../util/plotHelpers/types';
import {type LinePrimaryFn} from './types';

export const isDefaultPrimaryLine: LinePrimaryFn = (line, config) => {
  if (
    !config.isSmoothingActive &&
    !config.isGrouped &&
    line.meta.category === 'default' &&
    line.meta.type === 'line'
  ) {
    return 'Default primary line';
  }

  return false;
};

export const isSmoothedPrimaryLine: LinePrimaryFn = (line, config) => {
  if (
    config.isSmoothingActive &&
    line.meta.category === 'smoothed' &&
    line.meta.type === 'line'
  ) {
    return 'Smoothed primary line';
  }

  return false;
};

export const isGroupedPrimaryLine: LinePrimaryFn = (line, config) => {
  if (
    config.isGrouped &&
    !config.isSmoothingActive &&
    line.meta.category === 'grouped' &&
    line.meta.type === 'line'
  ) {
    return 'Grouped primary line';
  }
  return false;
};

export const isAggregatedPrimaryLine: LinePrimaryFn = (line, config) => {
  if (config.isAggregated && line.meta.type === 'line') {
    return 'Aggregated primary line';
  }
  return false;
};

export function mutateColorOnPrimaryLine(
  line: Line,
  config: {
    isGrouped: boolean;
    isAggregated: boolean;
    isSmoothingActive: boolean;
  }
) {
  const primaryConditions = [
    isDefaultPrimaryLine,
    isGroupedPrimaryLine,
    isSmoothedPrimaryLine,
    isAggregatedPrimaryLine,
  ]
    .map(fn => fn(line, config))
    .filter(c => c);

  if (primaryConditions.length > 0) {
    const primaryColor = Color(line.color).alpha(1).string();
    line.color = primaryColor;
  }
  return line;
}
