import {IndividualOp} from './filterTypes';

export const EMPTY_FILTER_VALUE = null;
export const WITHINSECONDS_OP_NAME = 'WITHINSECONDS';

// needs to match IndividualFilter.op
// TODO: using enum may allow us to get rid of the duplication.
const valueOps = [
  '=',
  '!=',
  '<',
  '>',
  '<=',
  '>=',
  'REGEX',
  WITHINSECONDS_OP_NAME,
];
export const multiValueOps = ['IN', 'NIN'];
export const ops = valueOps.concat(multiValueOps);

export const DefaultOperators: IndividualOp[] = [
  '=',
  '!=',
  '<=',
  '>=',
  'IN',
  'NIN',
];

export const DateOperators: IndividualOp[] = [
  '>=',
  '<=',
  WITHINSECONDS_OP_NAME,
];

export const FunctionalOperators: IndividualOp[] = ['=', '!=', 'IN', 'NIN'];

export const EqualsOperators: IndividualOp[] = ['=', '!='];
