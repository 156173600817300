export enum PlanName {
  // - archived plans
  // TODO: once clean up the organization's subscription
  // (i.e. move away from these legacy plans), remove from this enum list
  ArchivedStartupMonthly = 'archived-startup-monthly',
  ArchivedTeamsYearly = 'archived-teams-yearly',
  ArchivedTeamsMonthly2 = 'archived-teams-monthly-2',
  StartupYearly = 'startup_yearly',
  StartupMonthly = 'startup_monthly',
  /**
   * Refers to legacy plan unrelated to Teams plan instated in 2023.
   * @deprecated Use MonthlyTeams2023 to refer to current Teams plan.
   */
  TeamsMonthly = 'teams_monthly',
  /**
   * Refers to legacy plan unrelated to Teams plan instated in 2023.
   * @deprecated Use YearlyTeams2023 to refer to current Teams plan.
   */
  TeamsYearly = 'teams_yearly',
  StandardMonthly = 'standard_monthly',
  StandardYearly = 'standard_yearly',
  // - active plans that are promoted to users in app
  Enterprise = 'enterprise',
  StorageMonthly = 'storage_monthly',
  TrackingMonthly = 'tracking_monthly',
  Basic = 'basic',
  StarterTier1Monthly = 'starter_tier_1_monthly',
  StarterTier1Yearly = 'starter_tier_1_yearly',
  StarterTier2Monthly = 'starter_tier_2_monthly',
  StarterTier2Yearly = 'starter_tier_2_yearly',
  StarterTier3Monthly = 'starter_tier_3_monthly',
  StarterTier3Yearly = 'starter_tier_3_yearly',
  YearlyTeams2023 = 'yearly_teams_2023',
  MonthlyTeams2023 = 'monthly_teams_2023',
  YearlyHour2023 = 'yearly_hour_2023',
  MonthlyHour2023 = 'monthly_hour_2023',
  MonthlyStorage2023 = 'monthly_storage_2023',
  MonthlyStorage200GB = 'monthly_storage_200gb',
  CommunityEdition = 'community_edition',
  TrialPlan = 'trial_plan',
  TrackingHours2023 = 'tracking_hours_2023',
  WeaveAnnual600 = 'weave_annual_600mb',
  WeaveAnnual1000 = 'weave_annual_1000mb',
  WeaveUsage600 = 'weave_monthly_usage_600mb',
  WeaveUsage1000 = 'weave_monthly_usage_1000mb',
  TrackedHoursUsage500 = 'tracked_hours_monthly_usage_500',
  TrackedHoursUsage1000 = 'tracked_hours_monthly_usage_1000',
  ModelsSeatsAnnual = 'models_seats_annual',
  Pro = 'pro_plan_annual',
}
