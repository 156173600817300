import {TargetBlank} from '@wandb/weave/common/util/links';
import {Button, ButtonVariants} from '@wandb/weave/components/Button';
import {Icon, IconName} from '@wandb/weave/components/Icon';
import React, {useContext, useEffect} from 'react';

import {useNavUpdaterContext} from '../../components/NavContextProvider';
import {AccountSelectorContext} from '../../components/Search/SearchNav/AccountSelectorContextProvider';
import {TRIAL_END_PATH} from '../../routes/paths';
import {useViewer} from '../../state/viewer/hooks';
import DocUrls from '../../util/doc_urls';
import * as urls from '../../util/urls';
import * as S from './NoAccessPage.styles';

type NoAccessPageProps = {
  contentKey?: ContentKey;
};

// Hides breadcrumbs while on page
function useHideNavBreadcrumbs(): void {
  const {setShouldShowBreadcrumbs} = useNavUpdaterContext();
  useEffect(() => {
    setShouldShowBreadcrumbs(false);
    // clean up so that after a user navigates away from the page the breadcrumbs reappear
    return () => setShouldShowBreadcrumbs(true);
  }, [setShouldShowBreadcrumbs]);
}

type Content = {
  headerText: string;
  caption: string | React.ReactNode;
  url: string;
  cta: string;
  icon: IconName;
  subCaption?: string;
  buttonProps?: {
    variant: (typeof ButtonVariants)[keyof typeof ButtonVariants];
    icon?: IconName;
    endIcon?: IconName;
  };
};
type ContentKey =
  | 'loggedIn'
  | 'loggedOut'
  | 'noModelsAccess'
  | 'weaveLimitReached'
  | 'weaveTrialExpired';

export const NoAccessPage: React.FC<NoAccessPageProps> = ({contentKey}) => {
  useHideNavBreadcrumbs();
  const viewer = useViewer();
  const isLoggedIn = !!viewer;

  const {selectedAccount} = useContext(AccountSelectorContext);
  const isEnterprise = selectedAccount?.isEnterprise === true;

  const weaveLimitReached: Content = {
    headerText: 'Track and evaluate AI applications',
    caption: isEnterprise ? (
      <>
        You've used your free Weave data ingestion allowance. To continue using
        Weave, please{' '}
        <TargetBlank href={urls.contactSalesWeaveTrialLimitReached()}>
          talk to sales
        </TargetBlank>{' '}
        to upgrade your plan.
      </>
    ) : (
      <>
        You've used your free Weave data ingestion allowance. To continue using
        Weave, please{' '}
        <TargetBlank href={TRIAL_END_PATH}>upgrade your plan.</TargetBlank>{' '}
      </>
    ),
    url: isEnterprise
      ? urls.contactSalesWeaveTrialLimitReached()
      : TRIAL_END_PATH,
    cta: isEnterprise ? 'Talk to sales' : 'Upgrade plan',
    icon: 'lock-closed',
    buttonProps: {
      variant: ButtonVariants.Primary,
    },
  };

  const content: Record<ContentKey, Content> = {
    loggedIn: {
      headerText: 'It looks like you’ve landed on a locked or empty page.',
      caption:
        'If this is the right URL ask the page owner to change the privacy permissions.',
      url: urls.homePage(),
      cta: 'Go back to home',
      icon: 'chevron-next',
      buttonProps: {
        variant: ButtonVariants.Secondary,
        endIcon: 'chevron-next',
      },
    },
    loggedOut: {
      headerText: 'It looks like you’ve landed on a locked or empty page.',
      caption:
        'Try logging in or ask the page owner to change the privacy permissions.',
      url: '/wandb/intro/reports/A-Few-of-Our-Favorite-W-B-Reports--VmlldzozMTAzNjQ3',
      cta: 'See our favorite W&B Reports',
      icon: 'chevron-next',
      buttonProps: {
        variant: ButtonVariants.Secondary,
        endIcon: 'chevron-next',
      },
    },
    noModelsAccess: {
      headerText: "You don't have access to Models.",
      caption:
        'Weights & Biases Models offers seamless experiment tracking, model versioning, and performance visualization, empowering efficient debugging, optimization, and collaboration across the ML workflow.',
      subCaption: 'Reach out to your admin to request access.',
      url: DocUrls.modelManagement,
      cta: 'Learn more about Models',
      icon: 'documentation',
      buttonProps: {
        variant: ButtonVariants.Primary,
        icon: 'documentation',
      },
    },
    weaveLimitReached,
    weaveTrialExpired: {
      headerText: 'Track and evaluate AI applications',
      caption: (
        <>
          Your Weave trial has expired. Please{' '}
          <TargetBlank href={urls.contactSalesPricing()}>
            talk to sales
          </TargetBlank>{' '}
          to upgrade your plan.
        </>
      ),
      url: urls.contactSalesPricing(),
      cta: 'Talk to sales',
      icon: 'lock-closed',
      buttonProps: {
        variant: ButtonVariants.Primary,
      },
    },
  };

  const stateKey = contentKey ?? (isLoggedIn ? 'loggedIn' : 'loggedOut');
  const buttonProps = content[stateKey].buttonProps;

  return (
    <S.Container data-test="no-access">
      <S.LockedIcon>
        <Icon name="lock-closed" width={24} height={24} />
      </S.LockedIcon>
      <S.Header>{content[stateKey].headerText}</S.Header>
      <S.Caption>{content[stateKey].caption}</S.Caption>
      {content[stateKey].subCaption && (
        <S.Caption>{content[stateKey].subCaption}</S.Caption>
      )}
      <TargetBlank href={content[stateKey].url}>
        <Button size="large" {...buttonProps}>
          {content[stateKey].cta}
        </Button>
      </TargetBlank>
    </S.Container>
  );
};
