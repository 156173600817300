import {DisabledFeaturesMap, RunSelectorFeature} from './types';

export function getProjectInternalId(projectId: string | undefined) {
  return projectId ? btoa('ProjectInternalId:' + projectId) : undefined;
}

// basically a strict readonly where the run set is fully locked;
// user can only view list of runs with no other interactions
const RUN_SELECTOR_DISABLE_FEATURES_ALL: Record<RunSelectorFeature, true> = {
  'auto-cols': true,
  'create-sweep': true,
  delete: true,
  'edit-cols': true,
  'edit-run-name': true,
  'edit-run-notes': true,
  export: true,
  filter: true,
  group: true,
  move: true,
  'push-to-run-queue': true,
  search: true,
  sort: true,
  'stop-run': true,
  tag: true,
  'toggle-visibility': true,
};

// when user is viewing a workspace where they only have read access for the project
export const PROJECT_READONLY_WORKSPACE_RUN_SELECTOR_DISABLED_FEATURES: DisabledFeaturesMap =
  {
    'create-sweep': true,
    delete: true,
    'edit-run-name': true,
    'edit-run-notes': true,
    move: true,
    'stop-run': true,
    tag: true,
  };

export const PUBLISHED_REPORT_RUN_SELECTOR_DISABLED_FEATURES: DisabledFeaturesMap =
  {
    ...RUN_SELECTOR_DISABLE_FEATURES_ALL,
    'toggle-visibility': false,
  };

export const CREATE_SWEEP_PAGE_RUN_SELECTOR_DISABLED_FEATURES: DisabledFeaturesMap =
  {
    'create-sweep': true,
    delete: true,
    'edit-run-name': true,
    'stop-run': true,
    'toggle-visibility': true,
  };

export const SWEEP_PRIOR_RUNS_DISABLED_FEATURES: DisabledFeaturesMap =
  RUN_SELECTOR_DISABLE_FEATURES_ALL;
