import React from 'react';

import {SettingType} from '../../../../services/analytics/workspaceSettingsEvents';
import {SettingLabel} from '../components/SettingLabel';
import {SettingSelect} from '../components/SettingSelect';
import {
  LinePlotSettings,
  xAxisFormatOptions,
  XAxisFormatValues,
} from '../types';

export function xAxisIsBuiltin(xAxis: string) {
  const internalXAxisValues = [
    '_step',
    '_timestamp',
    '_runtime',
    '_absolute_runtime',
  ];
  return internalXAxisValues.includes(xAxis);
}

export const XAxisFormat = ({
  trackSetting,
  updateSetting,
  xAxisFormat,
}: {
  trackSetting: (setting: SettingType, value: string | undefined) => void;
  updateSetting: (settings: Partial<LinePlotSettings>) => void;
  xAxisFormat?: XAxisFormatValues;
}) => {
  return (
    <div className="flex pb-8" data-test="x-axis-format">
      <SettingLabel text="Format" className="ml-14 w-[87px]" />
      <div className="flex gap-8">
        <SettingSelect
          options={[...xAxisFormatOptions]}
          value={xAxisFormatOptions.find(
            option => option.value === (xAxisFormat ?? 'number')
          )}
          onChange={option => {
            if (option) {
              updateSetting({xAxisFormat: option.value});
              trackSetting('xAxisFormat', option.value);
            }
          }}
        />
      </div>
    </div>
  );
};
