import {IconHelpAlt, IconLogOut} from '@wandb/weave/components/Icon';
import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Popup} from 'semantic-ui-react';

import config from '../../config';
import {useServerInfoQuery} from '../../generated/graphql';
import {auth} from '../../setup';
import {useViewer} from '../../state/viewer/hooks';
import * as S from './OnboardingFlow.styles';

export const OnboardingFlowNavDropdown = () => {
  const viewer = useViewer();
  const serverInfoQuery = useServerInfoQuery();
  const isGoogleIap = serverInfoQuery.data?.serverInfo?.isGoogleIap ?? false;

  const canLogout = !config.AUTH_STUB_JWT || config.AUTH_STUB_JWT === 'false';

  if (viewer == null) {
    return null;
  }

  return (
    <S.NavDropdown
      className="nav-propic-dropdown"
      direction="left"
      trigger={
        <S.Avatar
          className="nav-propic"
          alt={`${viewer.username}'s profile picture`}
          src={viewer.photoUrl}
          avatar
        />
      }>
      <S.NavDropdownMenu>
        <S.NavDropdownItem
          href="/site/contact"
          target="_blank"
          rel="noopener noreferrer"
          text={
            <S.NavDropdownSection>
              <IconHelpAlt className="refresh-icon" />
              Contact Us
            </S.NavDropdownSection>
          }
        />
        {canLogout && (
          <>
            <S.NavDropdownDivider />
            {isGoogleIap ? (
              <Popup
                content="This instance is using Google IAP for authentication, so you'll need to log out of your Google account to log out of wandb."
                position="left center"
                trigger={
                  <S.NavDropdownItem
                    className="disabled-log-out-dropdown-item"
                    disabled
                    text={
                      <S.NavDropdownSection>
                        <IconLogOut className="refresh-icon" />
                        Log out
                      </S.NavDropdownSection>
                    }
                  />
                }
              />
            ) : (
              <S.NavDropdownItem
                onClick={() => auth.logout()}
                text={
                  <S.NavDropdownSection>
                    <IconLogOut className="refresh-icon" />
                    Log out
                  </S.NavDropdownSection>
                }
              />
            )}
          </>
        )}
      </S.NavDropdownMenu>
    </S.NavDropdown>
  );
};
