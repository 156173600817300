import {useMemo} from 'react';
import {useParams} from 'react-router';

import {useIsInLoadedReportContext} from '../../../../../pages/ReportPage/LoadedReportContext';
import {useRampFlagForceFullFidelityInReports} from '../../../../../util/rampFeatureFlags';
import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {
  DerivedPointVisualizationOption,
  LinePlotSettings,
  POINT_VISUALIZATION_OPTIONS,
  PointVisualizationOptions,
} from '../../types';
import {getLinePlotSettingWithDefault} from '../linePlotDefaults';

/**
 * Narrow down the point visualization method options into the two used in the radio UX
 * 'bucketing-gorilla' and 'sampling'
 */
export const getDerivedPointVisualizationOption = (
  p: PointVisualizationOptions | undefined
): DerivedPointVisualizationOption => {
  if (p === POINT_VISUALIZATION_OPTIONS.BucketingGorilla) {
    return POINT_VISUALIZATION_OPTIONS.BucketingGorilla;
  }

  if (
    p === POINT_VISUALIZATION_OPTIONS.Sampling ||
    p === POINT_VISUALIZATION_OPTIONS.SamplingByDefault
  ) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  return getLinePlotSettingWithDefault(p, 'pointVisualizationMethod');
};

export const useCascadingPointAggregationMethod = (
  parentSettings: LinePlotSettings | undefined,
  panelSettings?: RunsLinePlotConfig
): {
  pointVisualizationMethod: DerivedPointVisualizationOption;
} => {
  const {entityName} = useParams<{entityName?: string}>();
  if (!entityName) {
    // This check should narrow the type of `entityName` from `undefined | string`
    // to `string` in subsequent code.
    throw new RangeError(`Current entity name unexpectedly empty!`);
  }

  const isInReport = useIsInLoadedReportContext();
  const forceFFInReports = useRampFlagForceFullFidelityInReports(entityName);

  return useMemo(
    () =>
      getCascadingPointAggregationMethod(
        {
          isInReport,
          forceFFInReports,
        },
        parentSettings,
        panelSettings
      ),
    [isInReport, forceFFInReports, panelSettings, parentSettings]
  );
};

export const getCascadingPointAggregationMethod = (
  config: {
    isInReport: boolean;
    forceFFInReports: boolean;
  },
  parentSettings: LinePlotSettings | undefined,
  panelSettings?: RunsLinePlotConfig
) => {
  const pointVisualizationMethodSettings = getCascadingPointVisualizationMethod(
    config,
    parentSettings?.pointVisualizationMethod,
    panelSettings?.pointVisualizationMethod
  );

  return {
    pointVisualizationMethod:
      pointVisualizationMethodSettings.pointVisualizationMethod,
  };
};

export const getCascadingPointVisualizationMethod = (
  config: {
    isInReport: boolean;
    forceFFInReports: boolean;
  },
  parentSetting?: LinePlotSettings['pointVisualizationMethod'],
  panelSetting?: RunsLinePlotConfig['pointVisualizationMethod']
): {
  pointVisualizationMethod: DerivedPointVisualizationOption;
  isForced: boolean;
} => {
  // if user specified a value, this takes precedence
  if (panelSetting != null) {
    return {
      pointVisualizationMethod:
        getDerivedPointVisualizationOption(panelSetting),
      isForced: false,
    };
  }

  /**
   * users who are enrolled to default to bucketing mode will be defaulted IF
   * they have yet to select a preferred point visualization method for the workspace
   */
  if (
    parentSetting === POINT_VISUALIZATION_OPTIONS.SamplingByDefault ||
    parentSetting == null
  ) {
    if (config.isInReport && !config.forceFFInReports) {
      return {
        pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.Sampling,
        isForced: false,
      };
    }
    return {
      pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.BucketingGorilla,
      isForced: true,
    };
  }

  return {
    pointVisualizationMethod: getDerivedPointVisualizationOption(parentSetting),
    isForced: false,
  };
};
