import {apolloClient} from '../apolloClient';
import {envIsIntegration} from '../config';
import {
  EntityFeatureFlagsDocument,
  EntityFeatureFlagsQuery,
  EntityFeatureFlagsQueryVariables,
  OrgType,
  RampIdType,
  useEntityFeatureFlagsQuery,
  useOrganizationFeatureFlagsQuery,
  useViewerFeatureFlagsQuery,
  useViewerOrganizationFeatureFlagsQuery,
} from '../generated/graphql';
import {isAnonymousEntity} from '../state/workspaces/utils';
import {getDatadogRum, useDatadogFeatureFlag} from './datadog';
import {propagateErrorsContext} from './errors';

export type RampFlagStatus = {
  isEnabled: boolean;
  loading: boolean;
};

// TODO: we should auto generate this list from ramp_settings.go's RampFeatures
// list. For now, we have to manually keep this list in sync.
// ALSO TODO: we should also have the server tell us the ramp key *type* so that the
// user just calls useRampFlag('disable-fuzzy-search') and we can figure out the type
// based on the RampSettings' IDType in ramp_settings.go
export type RampKey =
  | 'account-selector-new'
  | 'account-selector'
  | 'bulk-move-run-group-org'
  | 'bulk-move-run-group-user'
  | 'cache-panels-entity'
  | 'cache-panels-user'
  | 'coordinate-zooming-entity'
  | 'coordinate-zooming-org'
  | 'coordinate-zooming-user'
  | 'disable-fuzzy-search'
  | 'disable-query-merging'
  | 'disable-update-history-key-info-org'
  | 'disable-update-history-key-info-user'
  | 'embedded-panels-entity'
  | 'embedded-panels-user'
  | 'enable-opfs-cache'
  | 'force-full-fidelity-in-reports-entity'
  | 'force-full-fidelity-in-reports-user'
  | 'format-x-axis-entity'
  | 'format-x-axis-user'
  | 'full-fidelity-in-reports-entity'
  | 'full-fidelity-in-reports-org'
  | 'full-fidelity-in-reports-user'
  | 'full-screen-workspace-panels-entity'
  | 'full-screen-workspace-panels-user'
  | 'global-registry'
  | 'gql-long-term-cache-entity'
  | 'gql-long-term-cache-user'
  | 'hide-summary-histograms'
  | 'line-construction-profiling'
  | 'low-resolution-queries-entity'
  | 'low-resolution-queries-org'
  | 'low-resolution-queries-user'
  | 'media-panel-settings-drawer-entity'
  | 'media-panel-settings-drawer-user'
  | 'media-panel-slider-key-entity'
  | 'media-panel-slider-key-user'
  | 'multi-node-log-filtering-org'
  | 'multi-node-log-filtering-user'
  | 'multi-runset-history-keys-entity'
  | 'multi-runset-history-keys-org'
  | 'multi-runset-history-keys-user'
  | 'no-keysets-org'
  | 'no-keysets-user'
  | 'non-monotonic-x-axis-entity'
  | 'non-monotonic-x-axis-org'
  | 'non-monotonic-x-axis-user'
  | 'org-level-service-accounts'
  | 'pro-pricing-plan'
  | 'project-specific-roles'
  | 'registry-automations'
  | 'registry-observer-role'
  | 'registry-search'
  | 'registry-team-permissions'
  | 'run-metric-notifications'
  | 'run-metric-notifications-entity'
  | 'sampled-runs-line-plot-notifications'
  | 'semantic-legend-entity'
  | 'semantic-legend-user'
  | 'shadow-rsdq-queries-user'
  | 'starred-projects'
  | 'viewer-3d-entity'
  | 'viewer-3d-user'
  | 'weave-as-a-service-user'
  | 'weave-marketing'
  | 'workspace-modes-org'
  | 'workspace-modes-user'
  | 'workspace-templates-entity'
  | 'workspace-templates-org'
  | 'workspace-templates-user'
  | 'use-backend-grouping-entity'
  | 'use-backend-grouping-user';

export const useEntityRampFlagWithLoading = (
  entityName: string | undefined,
  rampKey: RampKey,
  rampIDType: RampIdType,
  getEntityFlags: typeof useEntityFeatureFlagsQuery = useEntityFeatureFlagsQuery
) => {
  const setFlag = useDatadogFeatureFlag(rampKey);
  const {data, loading} = getEntityFlags({
    variables: {teamName: entityName, rampIDType},
    skip: entityName == null || isAnonymousEntity(entityName),
  });
  // All features are enabled for CI, unless explicitly disabled in their own tests
  if (
    envIsIntegration &&
    // low-resolution-queries are disabled while in experimental mode since they would require redoing screenshots
    ![
      'low-resolution-queries-entity',
      'media-panel-settings-drawer-entity',
      'viewer-3d-entity',
    ].includes(rampKey)
  ) {
    setFlag(true);
    return {
      loading: false,
      data: true,
    };
  }
  const teamFlagsGlobalList = data?.entity?.featureFlags;
  if (!teamFlagsGlobalList) {
    setFlag(false);
    // disabling this until we have an actionable use for it. It's very noisy
    // console.warn('useEntityRampFlag: returning false due to no data');
    return {
      loading,
      data: false,
    };
  }

  const isEnabled =
    teamFlagsGlobalList.find(f => f?.rampKey === rampKey)?.isEnabled ?? false;
  setFlag(isEnabled);
  return {
    loading,
    data: isEnabled,
  };
};

export const useEntityRampFlag = (
  entityName: string | undefined,
  rampKey: RampKey,
  rampIDType: RampIdType,
  getEntityFlags: typeof useEntityFeatureFlagsQuery = useEntityFeatureFlagsQuery
) => {
  return useEntityRampFlagWithLoading(
    entityName,
    rampKey,
    rampIDType,
    getEntityFlags
  ).data;
};
export const checkEntityRampFlag = async (
  entityName: string,
  rampKey: RampKey
) => {
  // Skip query if entity is anonymous
  if (isAnonymousEntity(entityName)) {
    return false;
  }

  const {data, errors} = await apolloClient.query<
    EntityFeatureFlagsQuery,
    EntityFeatureFlagsQueryVariables
  >({
    query: EntityFeatureFlagsDocument,
    fetchPolicy: 'no-cache',
    variables: {
      teamName: entityName,
      rampIDType: RampIdType.EntityName,
    },
  });
  const teamFlagsGlobalList = data?.entity?.featureFlags;
  if (!teamFlagsGlobalList || errors) {
    return false;
  }

  const isEnabled =
    teamFlagsGlobalList.find(f => f?.rampKey === rampKey)?.isEnabled ?? false;

  // This isn't ideal, but this fn is called outside the context of a React
  // hook, so useDatadogRum isn't available to us.
  getDatadogRum().addFeatureFlagEvaluation(rampKey, isEnabled);

  return isEnabled;
};

export const useUserRampFlag = (
  rampKey: RampKey,
  rampIDType: RampIdType,
  getUserFlags: typeof useViewerFeatureFlagsQuery = useViewerFeatureFlagsQuery
) => {
  const setFlag = useDatadogFeatureFlag(rampKey);
  const {data} = getUserFlags({variables: {rampIDType}});
  if (!data?.viewer?.featureFlags) {
    setFlag(false);
    // disabling this until we have an actionable use for it. It's very noisy
    // console.warn('useUserRampFlag: returning false due to no data');
    return false;
  }

  // note: don't default to false! undefined means not set, which is also valueable info
  const isEnabled = data.viewer.featureFlags.find(
    f => f?.rampKey === rampKey
  )?.isEnabled;

  setFlag(isEnabled);
  return isEnabled;
};

export const useOrgRampFlagWithLoading = (
  rampKey: RampKey,
  rampIDType: RampIdType,
  useGetOrgFlags: typeof useViewerOrganizationFeatureFlagsQuery = useViewerOrganizationFeatureFlagsQuery
) => {
  const setFlag = useDatadogFeatureFlag(rampKey);
  const {data, loading} = useGetOrgFlags({
    variables: {rampIDType},
  });

  if (loading) {
    setFlag(undefined);
    return {data: undefined, loading};
  }

  if (!data?.viewer?.organizations) {
    setFlag(undefined);
    return {data: undefined, loading};
  }

  let isOrgFlagEnabled: boolean | undefined; // undefined means not set
  for (const org of data.viewer.organizations) {
    const flag = org.featureFlags?.find(f => f?.rampKey === rampKey);
    isOrgFlagEnabled = flag?.isEnabled;
    if (isOrgFlagEnabled) {
      setFlag(true);
      // can exit early if enabled
      return {data: true, loading: false};
    }
  }
  setFlag(isOrgFlagEnabled);
  return {data: isOrgFlagEnabled, loading: false};
};

export const useOrgRampFlag = (
  rampKey: RampKey,
  rampIDType: RampIdType,
  getOrgFlags: typeof useViewerOrganizationFeatureFlagsQuery = useViewerOrganizationFeatureFlagsQuery
) => {
  const ret = useOrgRampFlagWithLoading(rampKey, rampIDType, getOrgFlags);
  return !ret.loading && ret.data;
};

export const useOrgNameRampFlag = (
  orgName: string,
  rampKey: RampKey,
  rampIDType: RampIdType,
  getOrgFlags: typeof useOrganizationFeatureFlagsQuery = useOrganizationFeatureFlagsQuery
) => {
  const setFlag = useDatadogFeatureFlag(rampKey);
  const {data, loading} = getOrgFlags({
    variables: {orgName, rampIDType},
    skip: orgName == null,
    context: propagateErrorsContext(),
  });

  const orgFeatureFlags = data?.organization?.featureFlags;
  if (loading || orgFeatureFlags == null) {
    setFlag(false);
    return {data: false, loading};
  }
  if (data?.organization?.orgType === OrgType.Personal) {
    setFlag(false);
    return {data: false, loading};
  }

  const isEnabled =
    orgFeatureFlags.find(f => f?.rampKey === rampKey)?.isEnabled ?? false;
  setFlag(isEnabled);

  return {
    data: isEnabled,
    loading,
  };
};

/**
 * Checks viewer's user-level and org-level ramp flags for the given keys.
 * Note: currently there's no way for a user flag to take precendence over
 * an org flag, because the backend system will return a boolean for all
 * ramp keys, even ramp keys for which there is no entry that applies to
 * the user.
 */
export const useUserOrOrgRampFlag = (
  userRampKey: RampKey,
  orgRampKey: RampKey
) => {
  const userFlag = useUserRampFlag(userRampKey, RampIdType.UserName);
  const orgFlag = useOrgRampFlag(orgRampKey, RampIdType.OrgName);
  return userFlag || orgFlag || false;
};

export const useHideSummaryHistogramsRampFlag = (
  entityName: string | undefined
) => {
  return useEntityRampFlagWithLoading(
    entityName,
    'hide-summary-histograms',
    RampIdType.EntityName
  );
};
