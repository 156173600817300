import {hoursToSeconds} from '@wandb/weave/common/util/time';
import {isNotNullOrUndefined} from '@wandb/weave/common/util/types';
import {Button} from '@wandb/weave/components/Button';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import * as d3 from 'd3';
import React, {useContext} from 'react';

import {AccountSelectorContext} from '../../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../../components/Search/SearchNav/types';
import {
  CurrentBillingPeriodUsageSectionOrgInfoQuery,
  OrganizationSubscriptionType,
  StorageUnit,
  UsageType,
  useCurrentBillingPeriodUsageSectionOrgInfoQuery,
  useEnterpriseBillingPeriodOrgInfoQuery,
  useOrganizationComputeSecondUsageInfoQuery,
  useUsageTabInfoQuery,
} from '../../../../generated/graphql';
import {TRIAL_END_PATH} from '../../../../routes/paths';
import {
  getPrimarySub,
  getStorageSub,
  getTrackedHoursSub,
  getTrackedHourUsageSub,
  getWeaveOverageSubBillingPeriod,
  HasSubsWithPlanName,
  HasSubsWithPlanType,
  HasSubsWithSubType,
  isProPrimarySubscription,
  Privileges,
  useAccountPrivileges,
  useAccountWeaveLimit,
} from '../../../../util/accounts/pricing';
import {
  addDaysUTC,
  addMonthsUTC,
  differenceInCalendarDaysUTC,
  isAfter,
  secondsToHours,
  startOfMonthUTC,
} from '../../../../util/date';
import history from '../../../../util/history';
import {useRampProPricingPlan} from '../../../../util/rampFeatureFlags';
import {
  bytesInGB,
  bytesInKB,
  bytesInMB,
  bytesInPB,
  bytesInTB,
  freeStorageInGB,
  getEstimatedStorageCharge,
} from '../../../../util/storage';
import {
  accountSettingsUsagePage,
  contactSalesPricing,
  historicUsageTab,
} from '../../../../util/urls';
import {TABS} from '../../../HistoricUsage/HistoricUsage';
import {valueAggregator} from '../../../HistoricUsage/HistoricUsageChartContextProvider';
import {
  DEFAULT_DAYS_AGO,
  DEFAULT_INTERVAL_MARKERS,
  DEFAULT_NUMBER_OF_DAYS,
  getDateRangeText,
  MeterSection,
  Props as MeterSectionProps,
  TODAY,
  TOMORROW,
} from '../../../HistoricUsage/Meter/MeterSection';
import {MeterSectionError} from '../../../HistoricUsage/Meter/MeterSectionError';
import {MeterSectionLoading} from '../../../HistoricUsage/Meter/MeterSectionLoading';
import {PlanName} from '../../util';
import {canSeeTrialEndPage} from '../WarningBanner/ExpiryBannerContent';
import {
  getFormattedBillingDate,
  METER_STYLE,
  UpgradePlanLink,
} from './PlanCard/PlanCardComponents';

const DOLLAR_FORMAT = ',.2f';
export const ZERO_COST_TEXT = '$0.00';

type Organization = NonNullable<
  CurrentBillingPeriodUsageSectionOrgInfoQuery['organization']
>;
type Subscription = Pick<
  Organization['subscriptions'][number],
  'billingPeriodStart' | 'billingPeriodEnd'
>;

const START_OF_CURRENT_CALENDAR_MONTH = startOfMonthUTC(TODAY);
const START_OF_NEXT_CALENDAR_MONTH = startOfMonthUTC(addMonthsUTC(TODAY, 1));
const CURRENT_CALENDAR_MONTH_INTERVAL_MARKERS = [
  START_OF_CURRENT_CALENDAR_MONTH,
  START_OF_NEXT_CALENDAR_MONTH,
];

export function getIntervalMarkers(
  subscription: Subscription | null | undefined,
  usageType: UsageType
): Date[] {
  if (
    subscription?.billingPeriodStart == null ||
    subscription?.billingPeriodEnd == null
  ) {
    if (usageType === UsageType.Weave) {
      return CURRENT_CALENDAR_MONTH_INTERVAL_MARKERS;
    }
    return DEFAULT_INTERVAL_MARKERS;
  }
  const billingPeriodStart = new Date(subscription?.billingPeriodStart);
  const billingPeriodEnd = new Date(subscription?.billingPeriodEnd);
  if (usageType === UsageType.Storage) {
    // Since storage is an average value, we will add in the current value separately in case it hasn't been aggregated yet
    return [billingPeriodStart, TODAY];
  }
  return [billingPeriodStart, billingPeriodEnd];
}

export function useGetUsageTypeUsageThisBillingPeriod(
  orgName: string,
  orgCreatedAt: Date | undefined,
  usageType: UsageType,
  subscription: Subscription | null | undefined,
  currentValue: number, // we need to add today's real-time value to the total since it hasn't been aggregated yet
  skip: boolean
) {
  const intervalMarkers = getIntervalMarkers(subscription, usageType);
  const {data, loading, error} = useUsageTabInfoQuery({
    variables: {
      orgName,
      usageType,
      intervalMarkers: intervalMarkers.map(marker => marker.toISOString()),
    },
    skip: skip || isAfter(intervalMarkers[0], intervalMarkers[1]), // skip if the interval markers are out of order (i.e. the subscription started today)
  });
  const [intervalStart, intervalEnd] = getIntervalMarkers(
    subscription,
    usageType
  );
  intervalStart.setUTCHours(0, 0, 0, 0);
  const initialUsageData = {
    start: intervalStart.toISOString(),
    end: TODAY.toISOString(),
    value: currentValue,
  };
  const usageData = [
    initialUsageData,
    ...(data?.organization?.usage?.map(dataRow => ({
      ...dataRow,
      end: TODAY.toISOString(),
    })) ?? []),
  ]; // valueAggregator uses the `start` and `end` to calculate what to divide by for the interval - but since we added the current day's data, we should update it so the denominator's right
  const usage = usageData.reduce(
    (total, {value, start, end}) =>
      valueAggregator(
        {
          value,
          start,
          end,
        },
        usageType,
        total,
        orgCreatedAt
      ),
    0
  );
  return {
    usage,
    billingPeriodEnd: intervalEnd,
    billingPeriodStart: intervalStart,
    loading,
    error,
  };
}

export function getWeaveOverageCostText(
  overageInBytes: number,
  weaveOverageUnit: StorageUnit,
  weaveOverageCostCents: number
) {
  const overageinOverageUnit = convertToOverageUnit(
    overageInBytes,
    weaveOverageUnit
  );
  const costInCents = Math.max(0, overageinOverageUnit * weaveOverageCostCents);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(costInCents / 100);
}

export function convertToOverageUnit(
  overageBytes: number,
  weaveOverageUnit: StorageUnit
): number {
  switch (weaveOverageUnit) {
    case StorageUnit.B:
      return overageBytes;
    case StorageUnit.Kb:
      return overageBytes / bytesInKB;
    case StorageUnit.Mb:
      return overageBytes / bytesInMB;
    case StorageUnit.Gb:
      return overageBytes / bytesInGB;
    case StorageUnit.Tb:
      return overageBytes / bytesInTB;
    case StorageUnit.Pb:
      return overageBytes / bytesInPB;
  }
}

export function getStorageLimitGB<
  T extends HasSubsWithPlanType & HasSubsWithPlanName & HasSubsWithSubType
>(privileges: Privileges, organization: T | undefined): number | undefined {
  const storageLimit = privileges.storageLimitGB;

  const storageSub = getStorageSub(organization);
  const primarySub = getPrimarySub(organization);

  // enterprise plan has unlimited storage
  if (
    primarySub?.plan?.name === PlanName.Enterprise &&
    primarySub?.subscriptionType !== OrganizationSubscriptionType.Academic &&
    primarySub?.subscriptionType !== OrganizationSubscriptionType.AcademicTrial
  ) {
    return undefined;
  }

  // if user is on the storage plan, it's pay as you go
  // so they technically have unlimited limit
  if (storageSub != null) {
    return undefined;
  }

  return storageLimit;
}

type CurrentBillingPeriodUsageProps = {
  meterRows: {
    title?: string;
    subtitle?: string;
    rows: (MeterSectionProps | null)[];
  }[];
  loading: boolean;
  hasError: boolean;
  onClickViewUsage: ((usageType: UsageType) => void) | null;
};

export type UsageThisBillingPeriod = Pick<
  CurrentBillingPeriodUsageProps,
  'hasError' | 'loading' | 'meterRows'
>;

export const PLAN_USAGE_TITLE = 'Plan usage';

// function to calculate tracked hours cost
export function calculateTrackedHoursCost(
  isProPlan: boolean,
  trackedHoursDataUsage: number,
  billingPeriodTrackedHoursUsage: number,
  privileges: Privileges
) {
  if (!isProPlan) {
    return Math.max(
      0,
      Math.min(
        secondsToHours(trackedHoursDataUsage),
        secondsToHours(billingPeriodTrackedHoursUsage) - privileges.computeHours
      )
    );
  } else {
    return trackedHoursDataUsage >
      hoursToSeconds(privileges.computeHoursMonthlyLimit)
      ? secondsToHours(trackedHoursDataUsage) -
          privileges.computeHoursMonthlyLimit
      : 0;
  }
}

function useGetUsageThisBillingPeriod(): UsageThisBillingPeriod {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const skip =
    selectedAccount?.name == null ||
    selectedAccount?.accountType === AccountType.Personal;
  const orgName = selectedAccount?.name ?? '';
  const isEnterprise = selectedAccount?.isEnterprise === true;

  const {
    data: orgInfo,
    loading: orgLoading,
    error: orgError,
  } = useCurrentBillingPeriodUsageSectionOrgInfoQuery({
    variables: {orgName},
    skip,
  });

  // Query this separately because most orgs won't need to query this field.
  const {
    data: enterpriseOrgInfo,
    loading: enterpriseOrgLoading,
    error: enterpriseOrgError,
  } = useEnterpriseBillingPeriodOrgInfoQuery({
    variables: {orgName},
    skip: skip || !isEnterprise,
  });

  const anyOrgLoading = orgLoading || enterpriseOrgLoading;
  const anyOrgError = orgError || enterpriseOrgError;

  const {loading: privilegesLoading, privileges} =
    useAccountPrivileges(selectedAccount);

  const {loading: weaveLimitLoading, weaveLimit} =
    useAccountWeaveLimit(selectedAccount);
  // Merge two org queries together
  const organization = {
    ...(orgInfo?.organization ?? {}),
    ...(enterpriseOrgInfo?.organization ?? {}),
  };

  const orgCreatedAt =
    organization?.createdAt != null
      ? new Date(organization.createdAt)
      : undefined;
  const primarySub = getPrimarySub(organization);
  const isProPlan = primarySub != null && isProPrimarySubscription(primarySub);

  const enterpriseSubscription =
    enterpriseOrgInfo?.organization?.weaveEnterpriseSubscription;

  // Since tracked hours billing periods end at particular times of day and are reported to stripe throughout the day,
  //  we want to not just use the daily aggregation from the usage page, and instead use the computeHours prop on the organization.
  const trackedHoursSub =
    (isProPlan
      ? getTrackedHourUsageSub(organization)
      : getTrackedHoursSub(organization)) || enterpriseSubscription;

  const [trackedHoursStart, trackedHoursEnd] = getIntervalMarkers(
    trackedHoursSub,
    UsageType.TrackedHours
  );
  const {
    data: trackedHoursComputeSecondsData,
    loading: trackedHoursLoading,
    error: trackedHoursError,
  } = useOrganizationComputeSecondUsageInfoQuery({
    variables: {
      organizationId: organization?.id ?? '',
      timeWindow: {
        start: trackedHoursStart.toISOString(),
        end: trackedHoursEnd.toISOString(),
      },
    },
    skip: skip || anyOrgLoading || organization?.id == null,
  });

  const billingPeriodTrackedHoursData = useGetUsageTypeUsageThisBillingPeriod(
    orgName,
    orgCreatedAt,
    UsageType.TrackedHours,
    isProPlan ? trackedHoursSub : primarySub,
    0,
    skip || anyOrgLoading || trackedHoursSub == null
  );

  const trackedHoursData = {
    usage:
      trackedHoursComputeSecondsData?.organization?.teams?.reduce(
        (total, {computeHours}) => total + computeHours,
        0
      ) ?? 0,
    billingPeriodEnd:
      trackedHoursSub?.billingPeriodEnd != null
        ? new Date(trackedHoursSub.billingPeriodEnd)
        : undefined,
    billingPeriodStart:
      trackedHoursSub?.billingPeriodStart != null
        ? new Date(trackedHoursSub.billingPeriodStart)
        : undefined,
    loading: trackedHoursLoading,
    error: trackedHoursError,
  };

  const trackedHoursCost = calculateTrackedHoursCost(
    isProPlan,
    trackedHoursData.usage,
    billingPeriodTrackedHoursData.usage,
    privileges
  );
  const trackedHoursCostText = `$${d3.format(DOLLAR_FORMAT)(trackedHoursCost)}`;

  const storageSub = getStorageSub(organization) || enterpriseSubscription;
  const currentStorageBytes =
    organization?.teams?.reduce(
      (total, {storageBytes}) => total + storageBytes,
      0
    ) ?? 0;
  const storageData = useGetUsageTypeUsageThisBillingPeriod(
    orgName,
    orgCreatedAt,
    UsageType.Storage,
    storageSub,
    currentStorageBytes,
    skip || anyOrgLoading
  );
  const storageLimitGB = getStorageLimitGB(privileges, organization);
  const numDaysLeftInBillingPeriod =
    storageSub?.billingPeriodEnd != null
      ? differenceInCalendarDaysUTC(
          new Date(storageSub.billingPeriodEnd),
          TODAY
        ) + 1
      : null;
  // Do a weighted average assuming the user won't touch their storage over the rest of their
  // billing period.
  const estimatedAverageStorageBytes =
    numDaysLeftInBillingPeriod != null
      ? (storageData.usage *
          (DEFAULT_NUMBER_OF_DAYS - numDaysLeftInBillingPeriod) +
          currentStorageBytes * numDaysLeftInBillingPeriod) /
        DEFAULT_NUMBER_OF_DAYS
      : storageData.usage;
  const storageLimitBytes =
    selectedAccount?.isEnterprise === true
      ? undefined
      : (storageLimitGB ?? freeStorageInGB) * bytesInGB;
  const storageCost =
    storageLimitBytes != null
      ? getEstimatedStorageCharge(
          estimatedAverageStorageBytes,
          storageLimitBytes
        )
      : 0;
  const storageCostText =
    storageCost === 0 ? (
      `$${d3.format(DOLLAR_FORMAT)(storageCost)}`
    ) : storageSub != null ? (
      `Estimated cost: $${d3.format(DOLLAR_FORMAT)(storageCost)}`
    ) : (
      <>
        Over limit. <UpgradePlanLink /> for more storage
      </>
    );

  const weaveLimitBytes = weaveLimit?.weaveLimitBytes;
  const weaveOverageCostCents = weaveLimit?.weaveOverageCostCents;
  const weaveOverageUnit = weaveLimit?.weaveOverageUnit;

  const weaveOverageData = useGetUsageTypeUsageThisBillingPeriod(
    orgName,
    orgCreatedAt,
    UsageType.Weave,
    getWeaveOverageSubBillingPeriod(privileges, organization),
    0,
    skip || anyOrgLoading
  );
  const weaveFraction =
    weaveLimitBytes != null ? weaveOverageData.usage / weaveLimitBytes : 0;
  const isProPlanEnabled = useRampProPricingPlan();
  const showUpgradeWeave =
    isProPlanEnabled &&
    (selectedAccount?.isEnterprise === true ||
      (primarySub != null &&
        weaveFraction >= 1 &&
        canSeeTrialEndPage(primarySub))); // only show upgrade if the usage is over their limit

  let weaveCostTextThisMonth = ZERO_COST_TEXT;
  const isEnterpriseWithOrbSubscription =
    selectedAccount?.isEnterprise && enterpriseSubscription != null;
  if (
    (isProPlan || isEnterpriseWithOrbSubscription) &&
    weaveLimitBytes != null &&
    weaveOverageCostCents != null &&
    weaveOverageUnit != null &&
    weaveOverageCostCents !== 0
  ) {
    weaveCostTextThisMonth = getWeaveOverageCostText(
      weaveOverageData.usage - weaveLimitBytes, // overage this month
      weaveOverageUnit,
      weaveOverageCostCents
    );
  } else {
    weaveCostTextThisMonth = '';
  }

  const isEnterpriseWithoutOrbSubscription =
    selectedAccount?.isEnterprise && enterpriseSubscription == null;
  const isEnterprisePlanPastWeaveTrialExpiration =
    isEnterpriseWithoutOrbSubscription &&
    isAfter(TODAY, privileges.weaveLimitDateInterval.end);
  // If all billing periods are the same, we can hide the billing period start and end dates on the meters
  // and just show the range at the top.
  const usages = [
    trackedHoursData,
    storageData,
    isEnterprisePlanPastWeaveTrialExpiration ? null : weaveOverageData,
  ].filter(isNotNullOrUndefined);
  const areAllBillingPeriodsTheSame = usages.every(
    ({billingPeriodStart, billingPeriodEnd}, i) =>
      i === 0 ||
      (billingPeriodStart === usages[i - 1].billingPeriodStart &&
        billingPeriodEnd === usages[i - 1].billingPeriodEnd)
  );
  const overallBillingPeriod =
    areAllBillingPeriodsTheSame &&
    usages[0].billingPeriodStart != null &&
    usages[0].billingPeriodEnd != null
      ? {
          start: usages[0].billingPeriodStart,
          end: usages[0].billingPeriodEnd,
        }
      : {start: DEFAULT_DAYS_AGO, end: TOMORROW};
  const showTrialSection =
    isEnterpriseWithoutOrbSubscription &&
    !isEnterprisePlanPastWeaveTrialExpiration;

  const meterRows: Array<{
    title?: string;
    subtitle?: string;
    rows: Array<MeterSectionProps | null>;
  }> = [
    {
      title: PLAN_USAGE_TITLE,
      subtitle: areAllBillingPeriodsTheSame
        ? `${getDateRangeText(
            overallBillingPeriod.start,
            overallBillingPeriod.end
          )} UTC`
        : undefined,
      rows: [
        {
          value: trackedHoursData.usage,
          title: 'Tracked hours',
          usageType: UsageType.TrackedHours,
          billingPeriodEnd: trackedHoursData.billingPeriodEnd,
          billingPeriodStart: trackedHoursData.billingPeriodStart,
          limit: isProPlan
            ? hoursToSeconds(privileges.computeHoursMonthlyLimit)
            : undefined,
          isUnlimited: !isProPlan,
          costText: trackedHoursCostText,
          hideDateInterval: areAllBillingPeriodsTheSame,
        },
        {
          value: storageData.usage,
          title: 'Storage',
          usageType: UsageType.Storage,
          billingPeriodEnd: storageData.billingPeriodEnd,
          billingPeriodStart: storageData.billingPeriodStart,
          limit: storageLimitBytes,
          isUnlimited: storageLimitGB == null,
          costText: storageCostText,
          button: (
            <Button
              variant="secondary"
              onClick={() => history.push(accountSettingsUsagePage(orgName))}>
              Manage storage
            </Button>
          ),
          hideDateInterval: areAllBillingPeriodsTheSame,
        },
      ],
    },
    isEnterprisePlanPastWeaveTrialExpiration // Don't show weave usage at all when past the Enterprise Weave tiral expiration.
      ? null
      : {
          title: showTrialSection ? 'Trials' : undefined,
          subtitle: showTrialSection
            ? `${getDateRangeText(
                privileges.weaveLimitDateInterval.start,
                privileges.weaveLimitDateInterval.end
              )} UTC`
            : undefined,
          rows: [
            {
              value: weaveOverageData.usage,
              title: 'Weave data ingestion',
              usageType: UsageType.Weave,
              billingPeriodEnd: weaveOverageData.billingPeriodEnd,
              billingPeriodStart: weaveOverageData.billingPeriodStart,
              limit: weaveLimit?.weaveLimitBytes,
              isUnlimited: weaveLimit?.weaveLimitBytes == null,
              costText: weaveCostTextThisMonth,
              customDateInterval: showTrialSection
                ? `Trial expires ${getFormattedBillingDate(
                    addDaysUTC(privileges.weaveLimitDateInterval.end, 1)
                  )} UTC`
                : undefined,
              button: showUpgradeWeave ? (
                <Button
                  variant={weaveFraction >= 1 ? 'primary' : 'secondary'}
                  onClick={() =>
                    selectedAccount?.isEnterprise === true
                      ? // This is an external URL, so we need it to be unprefixed
                        // eslint-disable-next-line wandb/no-unprefixed-urls
                        window.open(contactSalesPricing())
                      : history.push(TRIAL_END_PATH)
                  }>
                  {selectedAccount?.isEnterprise === true
                    ? 'Contact sales'
                    : 'Upgrade plan'}
                </Button>
              ) : null,
            },
            null, // add extra space for grid layout
          ],
        },
  ].filter(isNotNullOrUndefined);
  return {
    meterRows,
    loading:
      anyOrgLoading ||
      trackedHoursData.loading ||
      billingPeriodTrackedHoursData.loading ||
      weaveOverageData.loading ||
      storageData.loading ||
      privilegesLoading,
    hasError:
      anyOrgError != null ||
      trackedHoursData.error != null ||
      billingPeriodTrackedHoursData.error != null ||
      weaveOverageData.error != null ||
      storageData.error != null,
  };
}

export const CurrentBillingPeriodUsageSectionInner = ({
  meterRows,
  hasError,
  loading,
  onClickViewUsage,
}: CurrentBillingPeriodUsageProps) => {
  return (
    <Tailwind>
      <div className="flex flex-col">
        {meterRows.map(({title, subtitle, rows}, row) => (
          <div className="flex flex-col" key={row}>
            <div className="mb-8 flex flex-col">
              {title != null && (
                <div className="font-semibold text-moon-800">{title}</div>
              )}
              {subtitle != null && (
                <div className="text-moon-600">{subtitle}</div>
              )}
            </div>
            <div className="flex">
              {rows.map((props, col) => {
                if (props == null) {
                  // Have empty space if row isn't filled
                  return <div className={METER_STYLE} key={col} />;
                }
                if (hasError) {
                  return (
                    <MeterSectionError key={col} className={METER_STYLE} />
                  );
                }
                if (loading) {
                  return (
                    <MeterSectionLoading
                      key={col}
                      title={props.title}
                      className={METER_STYLE}
                    />
                  );
                }
                return (
                  <MeterSection
                    key={col}
                    {...props}
                    className={METER_STYLE}
                    secondaryButton={
                      TABS[props.usageType] != null &&
                      onClickViewUsage != null ? (
                        <Button
                          data-test={`view-usage-${props.usageType}`}
                          endIcon="chevron-next"
                          variant="ghost"
                          onClick={() => onClickViewUsage(props.usageType)}>
                          View usage
                        </Button>
                      ) : null
                    }
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </Tailwind>
  );
};

export const CurrentBillingPeriodUsageSection = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const {meterRows, loading, hasError} = useGetUsageThisBillingPeriod();
  return (
    <CurrentBillingPeriodUsageSectionInner
      meterRows={meterRows}
      loading={loading}
      hasError={hasError}
      onClickViewUsage={
        selectedAccount?.name != null
          ? (usageType: UsageType) =>
              history.push(
                historicUsageTab(
                  selectedAccount?.name,
                  TABS[usageType]?.urlPart ?? ''
                )
              )
          : null
      }
    />
  );
};
