import {useMemo} from 'react';

import {PanelBankFlowSectionConfig} from '../components/PanelBank/types';
import {
  isEvaluationSectionName,
  isMediaSectionName,
  isTableSectionName,
} from './panelbankConfigs';
import {TABLE_SECTION_HEIGHT} from './panelbankTypes';

const DEFAULT_HEIGHT = 300;

export const getDefaultFlowConfig = (
  sectionName?: string
): PanelBankFlowSectionConfig => {
  return {
    snapToColumns: true,
    columnsPerPage:
      isMediaSectionName(sectionName) || isEvaluationSectionName(sectionName)
        ? 1
        : 3,
    rowsPerPage: 2,
    gutterWidth: 16,
    boxWidth: 460,
    boxHeight: isTableSectionName(sectionName)
      ? TABLE_SECTION_HEIGHT
      : isEvaluationSectionName(sectionName)
      ? DEFAULT_HEIGHT * 3
      : DEFAULT_HEIGHT,
  };
};

export const getFullFlowConfig = (
  sectionName: string,
  flowConfig?: Partial<PanelBankFlowSectionConfig>
): PanelBankFlowSectionConfig => {
  return {
    ...getDefaultFlowConfig(sectionName),
    ...(flowConfig ?? {}),
  };
};

export const useFullFlowConfig = (
  sectionName: string,
  flowConfig?: Partial<PanelBankFlowSectionConfig>
): PanelBankFlowSectionConfig => {
  return useMemo(
    () => getFullFlowConfig(sectionName, flowConfig),
    [sectionName, flowConfig]
  );
};

export const compressFlowConfig = (
  sectionName: string,
  flowConfig?: Partial<PanelBankFlowSectionConfig>
): Partial<PanelBankFlowSectionConfig> | undefined => {
  if (!flowConfig) {
    return flowConfig;
  }
  const defaultConfig = getDefaultFlowConfig(sectionName);
  return Object.keys(flowConfig).reduce((acc, cur) => {
    const key = cur as keyof typeof flowConfig;
    if (flowConfig[key] === defaultConfig[key]) {
      return acc;
    }
    return {
      ...(acc ?? {}),
      [key]: flowConfig[key],
    };
  }, undefined as Partial<PanelBankFlowSectionConfig> | undefined);
};
