import React, {createContext, useContext, useMemo, useState} from 'react';

import {useRunsLinePlotContext} from '../RunsLinePlotContext/RunsLinePlotContext';
import {SharedPanelZoomState} from '../SharedPanelZoomContext';
import {HistoryPoint, PartialZoom} from '../types';
import {usePanelZoomReducer} from './usePanelZoomReducer';
import {isMetricKeyAbsoluteRuntime, makeSafeZoom} from './utils';

export type PanelZoomContext2Type = {
  configZoom: PartialZoom;
  handleUserZoom: (newZoom: PartialZoom) => void;
  queryZoom: PartialZoom;
  queryZoomStep: [number | null, number | null];
  setHistoryData: (data: HistoryPoint[]) => void;
  zooming: boolean;
};

export const PanelZoomContext2 = createContext<PanelZoomContext2Type>({
  configZoom: {},
  handleUserZoom: (newZoom: PartialZoom) => {},
  queryZoom: {},
  queryZoomStep: [null, null],
  setHistoryData: (data: HistoryPoint[]) => {},
  zooming: false,
});

/**
 * Zooming can be done a few different ways:
 * 1. By setting the x-, y-axis configs in the panel config
 * 2. By interacting with the graph and setting new zoom boundaries
 *
 * The zoom context needs to handle merging these according to the following rules:
 * 1. A chart defaults to whatever is stored in its config
 * 2. A user zooming on a chart can always zoom in to more detail, but cannot zoom out beyond the config zoom
 * 3. A user zoom always takes precendence over a config zoom
 * 4. Changing a config zoom will null out the active user zoom
 * 5. Certain chart actions (like changing the x-axis key) will also reset the user zoom
 *
 * The primary output of this context is the `queryZoom` object: this represents the domain of the zoom that should be applied to the following query. Previously this was always expressed in terms of _step, but this has been refactored to always be expressible in terms of the underlying x-axis values. It's important that whenever the xMin and xMax value are updated, a new query be dispatched to the API.
 *
 * Updating the zoom:
 * A user zoom is a bit of state that is managed inside the `usePanelZoomReducer`. The hook is primarily responsible for applying the rules that allow combine user zoom actions with the underlying config values (if they exist). The data for the config values are managed by the panel spec— this component is only responsible for exposing them in a simplified place and for responding to changes in the panel spec.
 *
 * Other notes:
 * - This context provider exposes whether or not an active user zoom is in effect via the `zooming` property
 * - Please see the associated tests on the reducer which explain anticipated user actions
 * - Differen rules apply when ultimately passing through query zoom x-axis ranges that are based in default (`_step`) vs custom x-axis values. Default values must be integers, but custom values can be decimals. Because the API has to handle these differently, we differentiate them in the bucketedHistorySpect as minStep/maxStep and minX/maxX respectively.
 *
 */
export const PanelZoomContext2Provider = ({
  coordinateZooming,
  children,
  configZoom,
  sharedPanelZoomConfig,
  xAxisKey,
}: {
  coordinateZooming: boolean;
  children: React.ReactNode;
  configZoom: PartialZoom;
  sharedPanelZoomConfig: SharedPanelZoomState;
  xAxisKey: string;
}) => {
  const {allowsCoordinatedZooming} = useRunsLinePlotContext();
  const [historyData, setHistoryData] = useState<HistoryPoint[]>([]);

  const {
    handleUserZoom,
    handleUserZoomInSteps,
    isZooming,
    queryZoom,
    queryZoomStep,
  } = usePanelZoomReducer({
    allowsCoordinatedZooming:
      (coordinateZooming ?? false) && (allowsCoordinatedZooming ?? false),
    configZoom,
    history: historyData,
    sharedPanelZoomConfig,
    xAxisKey,
  });

  const qZoom = useMemo(() => makeSafeZoom(queryZoom), [queryZoom]);

  const zoomHandler = isMetricKeyAbsoluteRuntime(xAxisKey)
    ? handleUserZoomInSteps
    : handleUserZoom;

  const value = useMemo(
    () => ({
      configZoom,
      handleUserZoom: zoomHandler,
      queryZoom: qZoom,
      queryZoomStep: [
        queryZoomStep.xAxisMin,
        queryZoomStep.xAxisMax,
      ] as PanelZoomContext2Type['queryZoomStep'],
      setHistoryData,
      zooming: isZooming,
    }),
    [configZoom, zoomHandler, setHistoryData, qZoom, queryZoomStep, isZooming]
  );

  return (
    <PanelZoomContext2.Provider value={value}>
      {children}
    </PanelZoomContext2.Provider>
  );
};

export const usePanelZoom2 = () => {
  const value = useContext(PanelZoomContext2);

  return value;
};
