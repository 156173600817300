import React from 'react';
import {Route} from 'react-router';

import config from '../config';
import {
  ACADEMIC_APPLICATION_PATH,
  ACCOUNT_SETTINGS_PATHS,
  ACCOUNT_SETTINGS_USAGE_PAGE_PATHS,
  ADMIN_ORGANIZATION,
  AGENT_PATHS,
  ARTIFACT_VERSION_PATH,
  BENCHMARK_PATHS,
  ENTITY_LAUNCH_PATHS,
  GALLERY_INTERNAL_ADMIN_PATH,
  GALLERY_PATH,
  GALLERY_PATH_SEGMENT,
  GLOBAL_HISTORIC_USAGE_PATH,
  GLOBAL_HISTORIC_USAGE_REDIRECT_PATH,
  GLOBAL_HISTORIC_USAGE_REDIRECT_TO_PATH,
  GROUP_PATHS,
  HISTORIC_USAGE_PATH,
  HISTORIC_USAGE_REDIRECT_PATH,
  HISTORIC_USAGE_REDIRECT_TO_PATH,
  HOME_PATH,
  NO_ACCESS_PATH,
  OLD_GALLERY_DISCUSSION_EDIT_PATH,
  OLD_GALLERY_DISCUSSION_VIEW_PATH,
  OLD_REPORT_PAGE_EDIT_PATH,
  OLD_REPORT_PAGE_VIEW_PATH,
  ONBOARDING_FLOW_PATH,
  ORG_BILLING,
  ORG_DASHBOARD,
  ORG_SERVICE_ACCOUNTS,
  ORG_SETTINGS,
  ORG_SETTINGS_PATHS,
  ORG_TEAMS,
  ORG_USER_ACTIVITY,
  PROFILE_PATH,
  PROFILE_PROJECTS_PATH,
  PROFILE_REPORTS_PATH,
  PROFILE_STARS_PATH,
  PROJECT_BASE_PATH,
  PROJECT_PATHS,
  QUEUE_PATHS,
  QUICKSTART_PATH,
  REDIRECT_PATH,
  REGISTRY_PATHS,
  REPORT_PAGE_EDIT_PATH,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  RUN_PATHS,
  STARTUP_PROGRAM,
  STORAGE_EXPLORER_TRACKED_PATH,
  SWEEP_PATHS,
  TEAM_MEMBERS_PATH,
  TEAM_SERVICE_ACCOUNTS_PATH,
  TEAM_SETTINGS_PATH,
  TRIAL_END_PATH,
  USER_SETTINGS_PATH,
} from './paths';
import {PrivateRoute} from './PrivateRoute';
import {RouteWithLayout} from './RouteWithLayout';
import * as Types from './types';

const IS_ON_PREM = config.ENVIRONMENT_IS_PRIVATE;

export const ROUTES_DATA: Types.RouteData[] = [
  {
    RouteComponent: Route,
    path: '/__test_page',
    component: React.lazy(() => import('../pages/TestPage')),
    routeName: 'TestPage',
    exact: true,
  },
  {
    RouteComponent: Route,
    path: ['/__registry', '/__registry/:tab'],
    component: React.lazy(() => import('../components/Panel2/RegistryPage')),
    exact: true,
    routeName: 'Registry',
  },
  {
    RouteComponent: Route,
    path: ['/__wbench', '/__wbench/:tab'],
    component: React.lazy(
      () => import('../components/Panel2/InternalBenchmarkPage')
    ),
    exact: true,
    routeName: 'InternalBenchmarkPage',
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/',
    component: React.lazy(() => import('../pages/HomePage/HomePageRouter')),
    exact: true,
    routeName: 'Home',
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/login',
    component: React.lazy(() => import('../components/Login')),
    routeName: 'Login',
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/login-redirect',
    component: React.lazy(() => import('../components/LoginRedirect')),
    routeName: 'LoginRedirect',
  },
  {
    RouteComponent: Route,
    path: '/logout',
    component: React.lazy(() => import('../components/Logout')),
    routeName: 'Logout',
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/trending',
    component: React.lazy(() => import('../pages/TrendingRepos')),
    routeName: 'Trending',
  },
  {
    RouteComponent: PrivateRoute,
    path: HOME_PATH,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'HomePage',
    exact: true,
    routeName: 'Home',
  },
  {
    RouteComponent: RouteWithLayout,
    path: QUICKSTART_PATH,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeName: 'QuickStart',
    exact: true,
  },
  {
    RouteComponent: RouteWithLayout,
    path: GALLERY_PATH,
    component: React.lazy(() => import('../pages/FC')),
    routeName: 'FullyConnected',
  },
  {
    path: '/fullyconnected',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/fc',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/gallery',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    // the entity named "weave" is claimed by a past wandb employee
    // this is doing the quick thing to redirect this path to the weave site instead
    RouteComponent: Route,
    path: '/weave',
    component: React.lazy(
      () => import('../pages/EntityPage/RedirectToWeaveSite')
    ),
    exact: true,
    routeName: 'WeaveEntity',
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/__test/simple_error',
    component: React.lazy(() => import('../pages/ErrorTestPage')),
    exact: true,
    routeName: 'ErrorTestPage',
  },
  {
    RouteComponent: PrivateRoute,
    path: ONBOARDING_FLOW_PATH,
    component: React.lazy(
      () => import('../pages/OnboardingFlow/OnboardingFlow')
    ),
    routeCache: 'OnboardingFlow',
    routeName: 'OnboardingFlow',
  },
  {
    RouteComponent: PrivateRoute,
    path: STARTUP_PROGRAM,
    component: React.lazy(() => import('../pages/StartupProgram')),
    routeName: 'StartupProgram',
    exact: true,
    condition: !IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/change-password',
    component: React.lazy(() => import('../pages/ChangePassword')),
    routeCache: 'ChangePassword',
    routeName: 'ChangePassword',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/authorize',
    component: React.lazy(() => import('../pages/AuthorizePage')),
    routeCache: 'AuthorizePage',
    allowIframes: true,
    routeName: 'AuthorizePage',
  },
  {
    RouteComponent: PrivateRoute,
    path: USER_SETTINGS_PATH,
    component: React.lazy(
      () => import('../components/UserSettings/UserSettingsPage')
    ),
    routeCache: 'UserSettingsPage',
    exact: true,
    routeName: 'UserSettings',
  },
  {
    RouteComponent: PrivateRoute,
    path: ACCOUNT_SETTINGS_PATHS,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'AccountSettingsPage',
    exact: true,
    condition: !IS_ON_PREM,
    routeName: 'AccountSettings',
  },
  {
    RouteComponent: PrivateRoute,
    path: ACCOUNT_SETTINGS_USAGE_PAGE_PATHS,
    component: React.lazy(
      () =>
        import(
          '../pages/Billing/AccountSettings/ManageUsagePage/ManageUsagePage'
        )
    ),
    routeCache: 'AccountSettingsUsagePage',
    exact: true,
    condition: !IS_ON_PREM,
    routeName: 'AccountSettingsUsagePage',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/audit-logs',
    component: React.lazy(() => import('../pages/AuditLogs/AuditLogsPage')),
    routeCache: 'AuditLogs',
    exact: true,
    condition: IS_ON_PREM,
    routeName: 'AdminUsage',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/create-team',
    component: React.lazy(() => import('../pages/CreateNewTeamPage')),
    condition: !IS_ON_PREM,
    exact: true,
    routeCache: 'CreateNewTeam',
    routeName: 'CreateNewTeam',
  },
  {
    RouteComponent: PrivateRoute,
    path: ADMIN_ORGANIZATION,
    component: React.lazy(
      () => import('../wandb-admin/pages/OrganizationAdminPage')
    ),
    routeCache: 'OrganizationAdmin',
    routeName: 'AdminOrganization',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/users',
    component: React.lazy(
      () => import('../pages/UsersAdminPage/UsersAdminPageRouter')
    ),
    routeCache: 'UsersAdmin',
    routeName: 'AdminUser',
  },
  {
    RouteComponent: PrivateRoute,
    path: ORG_DASHBOARD,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'OrgDashboard',
    routeName: 'OrgDashboard',
    exact: true,
    condition: IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: ORG_TEAMS,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'OrgTeams',
    routeName: 'OrgTeams',
    exact: true,
    condition: IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: ORG_SERVICE_ACCOUNTS,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'OrgServiceAccounts',
    routeName: 'OrgServiceAccounts',
    exact: true,
    condition: IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: ORG_USER_ACTIVITY,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'OrgActivity',
    routeName: 'OrgActivity',
    exact: true,
    condition: IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: ORG_BILLING,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'OrgBilling',
    routeName: 'OrgBilling',
    exact: true,
    condition: IS_ON_PREM,
  },
  {
    path: '/org/roles',
    redirect: ORG_SETTINGS,
  },
  {
    RouteComponent: PrivateRoute,
    path: ORG_SETTINGS_PATHS,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'OrgSettingsPage',
    routeName: 'OrgSettingsPage',
    exact: true,
    condition: IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: GALLERY_INTERNAL_ADMIN_PATH,
    component: React.lazy(() => import('../pages/GalleryAdmin')),
    routeCache: 'GalleryAdmin',
    routeName: 'AdminFullyConnected',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/ramp',
    component: React.lazy(() => import('../pages/Ramp/RampAdmin')),
    routeCache: 'RampAdmin',
    routeName: 'AdminRamp',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/glue',
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'GlueAdminPage',
    routeName: 'AdminGlue',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/experiments',
    component: React.lazy(
      () => import('../wandb-admin/pages/ExperimentAdminPage')
    ),
    routeCache: 'ExperimentAdminPage',
    routeName: 'AdminExperiment',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/home',
    component: React.lazy(() => import('../wandb-admin/pages/HomeAdminPage')),
    routeCache: 'AdminDashboard',
    routeName: 'AdminHome',
  },
  {
    RouteComponent: PrivateRoute,
    path: [
      '/usage/:entityName',
      STORAGE_EXPLORER_TRACKED_PATH,
      '/usage/:entityName/:filePath*',
    ],
    component: React.lazy(() => import('../pages/StorageExplorer')),
    routeCache: 'StorageExplorer',
    exact: true,
    allowAnonymous: true,
    routeName: 'StorageExplorer',
  },
  {
    path: '/storage/:entityName/tracked/:filePath*',
    redirect: STORAGE_EXPLORER_TRACKED_PATH,
  },
  {
    path: '/storage/:entityName/:filePath*',
    redirect: '/usage/:entityName/:filePath*',
  },
  {
    path: '/storage/:entityName',
    redirect: '/usage/:entityName',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/new-benchmark',
    component: React.lazy(() => import('../pages/NewBenchmark')),
    routeCache: 'NewBenchmark',
    routeName: 'NewBenchmark',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/:entityname/benchmark/new',
    component: React.lazy(() => import('../pages/NewBenchmark')),
    routeCache: 'NewBenchmarkPage',
    routeName: 'NewBenchmark',
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROFILE_REPORTS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    exact: true,
    additionalProps: {tab: 'reports'},
    // note that here and below, we rely on the fact that we include the tab
    // when we log the route name
    routeName: 'Entity',
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROFILE_PROJECTS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    exact: true,
    additionalProps: {tab: 'projects'},
    routeName: 'Entity',
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROFILE_STARS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    exact: true,
    additionalProps: {tab: 'stars'},
    routeName: 'Entity',
  },
  {
    RouteComponent: RouteWithLayout,
    path: TEAM_MEMBERS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    exact: true,
    additionalProps: {tab: 'members'},
    routeName: 'Entity',
  },
  {
    RouteComponent: RouteWithLayout,
    path: TEAM_SERVICE_ACCOUNTS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    exact: true,
    additionalProps: {tab: 'service-accounts'},
    routeName: 'Entity',
  },
  {
    RouteComponent: RouteWithLayout,
    path: TEAM_SETTINGS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    routeCache: 'EntityPage',
    exact: true,
    additionalProps: {tab: 'settings'},
    routeName: 'Entity',
  },
  // TODO: Remove! Keeping for backwards compatibility (email links, etc)
  {
    RouteComponent: RouteWithLayout,
    path: '/teams/:entityName',
    component: React.lazy(() => import('../pages/EntityPage')),
    routeCache: 'EntityPage',
    exact: true,
    additionalProps: {tab: 'settings'},
    routeName: 'Entity',
  },
  {
    RouteComponent: RouteWithLayout,
    path: REGISTRY_PATHS,
    component: React.lazy(
      () => import('../components/Registries/RegistriesRouter')
    ),
    exact: true,
    routeName: 'Registries',
  },
  {
    path: `${ARTIFACT_VERSION_PATH}/graph`,
    redirect: `${ARTIFACT_VERSION_PATH}/lineage`,
  },
  {
    path: '/models',
    redirect: '/registry/model',
  },
  {
    RouteComponent: RouteWithLayout,
    path: ENTITY_LAUNCH_PATHS,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    exact: true,
    routeName: 'EntityLaunch',
  },
  {
    RouteComponent: PrivateRoute,
    path: RUN_PATHS,
    component: React.lazy(() => import('../pages/RunPage2')),
    routeCache: 'RunPage2',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'Run',
  },
  {
    RouteComponent: PrivateRoute,
    path: GROUP_PATHS,
    component: React.lazy(() => import('../pages/GroupPage')),
    routeCache: 'GroupPage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'Group',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/:entityName/:projectName/create-sweep',
    component: React.lazy(() => import('../pages/CreateSweepPage')),
    routeCache: 'CreateSweepPage',
    allowIframes: true,
    exact: true,
    routeName: 'CreateSweep',
  },
  {
    RouteComponent: PrivateRoute,
    path: SWEEP_PATHS,
    component: React.lazy(() => import('../pages/SweepPage')),
    routeCache: 'SweepPage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'Sweep',
  },
  {
    RouteComponent: PrivateRoute,
    path: AGENT_PATHS,
    component: React.lazy(() => import('../components/Launch/LaunchContent')),
    routeCache: 'AgentPage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'Agent',
  },
  {
    RouteComponent: PrivateRoute,
    path: QUEUE_PATHS,
    component: React.lazy(() => import('../components/Launch/LaunchContent')),
    routeCache: 'QueuePage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'Queue',
  },
  {
    RouteComponent: PrivateRoute,
    path: BENCHMARK_PATHS,
    component: React.lazy(() => import('../pages/Benchmark/BenchmarkPage')),
    routeCache: 'BenchmarkPage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'Benchmark',
  },
  // TODO - remove these related logics
  // {
  //   RouteComponent: PrivateRoute,
  //   path: `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}`,
  //   component: React.lazy(() => import('../pages/PublishedWorkPage')),
  //   routeCache: 'PublishedWorkPage',
  //   exact: true,
  //   allowAnonymous: true,
  // },
  {
    // This old edit report page route will be redirected to /reportlist
    RouteComponent: PrivateRoute,
    path: OLD_REPORT_PAGE_EDIT_PATH,
    component: React.lazy(() => import('../pages/ReportPage/ReportPageRouter')),
    exact: true,
    routeCache: 'OldReportPageEdit',
    allowAnonymous: true,
    routeName: 'OldReportPageEdit',
  },
  {
    // This old view report page route will be redirected to /reportlist
    RouteComponent: PrivateRoute,
    path: OLD_REPORT_PAGE_VIEW_PATH,
    component: React.lazy(() => import('../pages/ReportPage/ReportPageRouter')),
    exact: true,
    routeCache: 'OldReportPageView',
    allowAnonymous: true,
    routeName: 'OldReportPageView',
  },
  {
    RouteComponent: PrivateRoute,
    path: REPORT_PAGE_EDIT_PATH,
    component: React.lazy(() => import('../pages/ReportPage/ReportPageRouter')),
    routeCache: 'ReportPageEdit',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'ReportEdit',
  },
  {
    RouteComponent: PrivateRoute,
    path: REPORT_PAGE_VIEW_PATH,
    component: React.lazy(() => import('../pages/ReportPage/ReportPageRouter')),
    routeCache: 'ReportPageView',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'ReportView',
  },
  {
    RouteComponent: PrivateRoute,
    path: REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
    component: React.lazy(() => import('../pages/ReportPage/ReportPageRouter')),
    routeCache: 'ReportPageViewPublishedWork',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
    routeName: 'ReportViewPublished',
  },
  {
    RouteComponent: PrivateRoute,
    path: OLD_GALLERY_DISCUSSION_EDIT_PATH,
    component: React.lazy(() => import('../pages/ReportPage/ReportPageRouter')),
    routeCache: 'ReportPageEdit',
    exact: true,
    allowAnonymous: true,
    routeName: 'FullyConnectedDiscussionEdit',
  },
  {
    RouteComponent: PrivateRoute,
    path: OLD_GALLERY_DISCUSSION_VIEW_PATH,
    component: React.lazy(() => import('../pages/ReportPage/ReportPageRouter')),
    routeCache: 'ReportPageView',
    exact: true,
    allowAnonymous: true,
    routeName: 'FullyConnectedDiscussionView',
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/:entityName/:projectName/querydemo',
    component: React.lazy(() => import('../pages/QueryDemoPage')),
    exact: true,
    allowAnonymous: true,
    routeName: 'QueryDemo',
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/:entityName/:projectName/weave-sandbox',
    component: React.lazy(() => import('../pages/WeaveSandboxPage')),
    exact: true,
    routeName: 'WeaveSandbox',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/org/:orgName',
    component: React.lazy(() => import('../pages/OrganizationPage')),
    routeCache: 'OrganizationPage',
    exact: true,
    allowAnonymous: false,
    condition: !IS_ON_PREM,
    routeName: 'Organization',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/repo/:repoName',
    component: React.lazy(() => import('../pages/RepoInsightsPage')),
    routeCache: 'RepoInsightsPage',
    exact: true,
    allowAnonymous: false,
    routeName: 'Repo',
  },
  {
    RouteComponent: RouteWithLayout,
    path: NO_ACCESS_PATH,
    component: React.lazy(() => import('../pages/NoAccessPage')),
    exact: true,
    routeName: 'NoAccess',
  },
  {
    RouteComponent: PrivateRoute,
    path: GLOBAL_HISTORIC_USAGE_PATH,
    component: React.lazy(
      () => import('../pages/HistoricUsage/GlobalHistoricUsage')
    ),
    routeCache: 'GlobalHistoricUsage',
    exact: true,
    condition: IS_ON_PREM,
    routeName: 'GlobalHistoricUsage',
  },
  {
    path: GLOBAL_HISTORIC_USAGE_REDIRECT_PATH,
    redirect: GLOBAL_HISTORIC_USAGE_REDIRECT_TO_PATH,
    condition: IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: HISTORIC_USAGE_PATH,
    component: React.lazy(
      () => import('../pages/HistoricUsage/OrganizationHistoricUsage')
    ),
    routeCache: 'OrganizationHistoricUsage',
    exact: true,
    condition: !IS_ON_PREM,
    routeName: 'OrganizationHistoricUsage',
  },
  {
    path: HISTORIC_USAGE_REDIRECT_PATH,
    redirect: HISTORIC_USAGE_REDIRECT_TO_PATH,
    condition: !IS_ON_PREM,
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROJECT_PATHS,
    component: React.lazy(() => import('../pages/ProjectPage')),
    exact: true,
    allowIframes: true,
    routeName: 'Workspace',
  },
  {
    RouteComponent: PrivateRoute,
    path: REDIRECT_PATH,
    component: React.lazy(() => import('../pages/RedirectPage/RedirectPage')),
    exact: true,
    allowAnonymous: true,
    routeName: 'Redirect',
  },
  {
    // this is here to truncate extra path segments after the actual path
    path: PROJECT_BASE_PATH,
    redirect: PROJECT_BASE_PATH,
  },
  {
    RouteComponent: PrivateRoute,
    path: TRIAL_END_PATH,
    component: React.lazy(() => import('../pages/TrialEndPage/TrialEndPage')),
    routeCache: 'TrialEnd',
    exact: true,
    condition: !IS_ON_PREM,
    routeName: 'TrialEnd',
  },
  {
    RouteComponent: PrivateRoute,
    path: ACADEMIC_APPLICATION_PATH,
    component: React.lazy(
      () => import('../pages/AcademicApplication/AcademicApplication')
    ),
    routeCache: 'AcademicApplication',
    exact: true,
    condition: !IS_ON_PREM,
    routeName: 'AcademicApplication',
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROFILE_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    exact: true,
    routeName: 'Entity',
  },
];
