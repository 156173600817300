import {maybePluralizeWord} from '@wandb/weave/core/util/string';

/**
 * returns true if the first date is after the second one
 */
export function isAfter(date1: Date, date2: Date): boolean {
  return date1 > date2;
}

const NUM_MILLISECONDS_IN_SECOND = 1000;
export const NUM_SECONDS_IN_MINUTE = 60;
const NUM_MINUTES_IN_HOUR = 60;
export const NUM_SECONDS_IN_HOUR = NUM_SECONDS_IN_MINUTE * NUM_MINUTES_IN_HOUR;
const NUM_HOURS_IN_DAY = 24;
export const NUM_SECONDS_IN_DAY = NUM_HOURS_IN_DAY * NUM_SECONDS_IN_HOUR;
export const NUM_MILLISECONDS_IN_DAY =
  NUM_SECONDS_IN_DAY * NUM_MILLISECONDS_IN_SECOND;
export const NUM_DAYS_IN_WEEK = 7;
const NUM_CALENDAR_DAYS_IN_YEAR = 365; // not exact - inaccurate for leap years
export const APPROX_NUM_DAYS_IN_MONTH = 30;
const NUM_MONTHS_IN_YEAR = 12;
export const APPROX_NUM_MILLISECONDS_IN_NON_LEAP_YEAR =
  NUM_CALENDAR_DAYS_IN_YEAR * NUM_MILLISECONDS_IN_DAY; // not astronomically correct

export function secondsToHoursExact(seconds: number): number {
  return seconds / NUM_SECONDS_IN_HOUR;
}

export function secondsToHours(seconds: number): number {
  return Math.floor(secondsToHoursExact(seconds));
}

// in local time
export function getLocalYear(date: Date): number {
  return date.getFullYear();
}

// in UTC time
export function getUTCYear(date: Date): number {
  return date.getUTCFullYear();
}

export function startOfDayLocal(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export function startOfDayUTC(date: Date): Date {
  const newDate = new Date(date);
  newDate.setUTCHours(0, 0, 0, 0);
  return newDate;
}

export function startOfMonthLocal(date: Date): Date {
  const newDate = new Date(date);
  newDate.setDate(1);
  return startOfDayLocal(newDate);
}

export function startOfMonthUTC(date: Date): Date {
  const newDate = new Date(date);
  newDate.setUTCDate(1);
  return startOfDayUTC(newDate);
}

export function differenceInDaysExact(date1: Date, date2: Date) {
  return (date1.getTime() - date2.getTime()) / NUM_MILLISECONDS_IN_DAY;
}

/**
 * Returns the number of 24 hour cycles between the two dates. aka the number of days in UTC between
 * the two dates.
 *
 * NOTE: This differs from date-fns which doesn't count the number of 24 hour periods, but rather
 * the number of cycles to the "same local time next day" which may be more or less than 24 hours
 * with daylight savings.
 */
export function differenceInDays(date1: Date, date2: Date): number {
  return Math.trunc(differenceInDaysExact(date1, date2)); // trunc to ensure it'll be a whole number
}

// Get the number of calendar days between the given dates in local time. This means that the times are removed from the dates and then the difference in days is calculated.
export function differenceInCalendarDaysLocal(
  date1: Date,
  date2: Date
): number {
  const date1WithoutTime = startOfDayLocal(date1);
  const date2WithoutTime = startOfDayLocal(date2);
  return differenceInDays(date1WithoutTime, date2WithoutTime);
}

// Get the number of calendar days between the given dates in UTC. This means that the times are removed from the dates and then the difference in days is calculated.
export function differenceInCalendarDaysUTC(date1: Date, date2: Date): number {
  const date1WithoutTime = startOfDayUTC(date1);
  const date2WithoutTime = startOfDayUTC(date2);
  return differenceInDays(date1WithoutTime, date2WithoutTime);
}

// can manually add a new format as necessary. Each one maps to a valid date-fns or moment token string
export enum DateFormat {
  MONTH = 'MMM',
  MONTH_DAY_YEAR = 'MMM dd, yyyy',
  MONTH_YEAR = 'MMM yyyy',
  MONTH_DAY = 'MMM dd',
  DAY = 'dd',
  YEAR = 'yyyy',
  MONTH_DAYTH_YEAR_24HOUR_MIN_SECONDS = 'MMMM do, yyyy HH:mm:ss',
  WEEKDAY_MONTH_DAY_YEAR_HOUR_MIN_SECONDS = 'ddd YYYY-MM-DD hh:mm:ss a',
  YEAR_MONTH_DAY_HOUR_MINUTE_SECOND = 'YY/MM/DD HH:mm:ss',
  YYYY_MM_DD_HH_MM_SS_DASHES = 'YYYY-MM-DD HH:mm:ss',
  MONTH_DAYTH_YEAR = 'MMMM do, yyyy',
  DAYTH = 'do',
  MONTH_DAYTH = 'MMMM do',
  SHORT_MONTH_DAYTH_YEAR = 'MMM do, yyyy',
  LONG_MONTH_DAY_YEAR_HOUR_MINUTE = 'MMMM d, yyyy at h:mm a',
  MONTH_SLASH_DAY = 'M/D',
  MONTH_DAYTH_YEAR_HOUR_MIN_SECONDS = 'MMMM do, yyyy h:mm:ss a',
  MONTH_DAY_HOUR_MIN = 'MMM DD h:mma',
  MONTH_DAY_2_DIGIT_YEAR_TIME = "MMM DD 'YY HH:mm",
  MONTH_DAY_YEAR_TIME = 'MM/DD/YYYY HH:mm',
  YEAR_MONTH_DAY = 'yyyy-mm-dd',
  MONTH_DAY_YEAR_SLASHES = 'MM/DD/YYYY',
  MONTH_DAY_SHORT_YEAR = 'MMM dd, yy',
  TIME = 'h:mmaaa',
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules
const pr = new Intl.PluralRules('en-US', {type: 'ordinal'});

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);
const formatOrdinals = (n: number) => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export function format(
  date: Date,
  dateFormat: DateFormat,
  timeZone?: string
): string {
  switch (dateFormat) {
    case DateFormat.MONTH: {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        timeZone,
      }).format(date);
    }
    case DateFormat.MONTH_DAY_YEAR: {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        timeZone,
      }).format(date);
    }
    case DateFormat.MONTH_YEAR: {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        year: 'numeric',
        timeZone,
      }).format(date);
    }
    case DateFormat.MONTH_DAY: {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        timeZone,
      }).format(date);
    }
    case DateFormat.DAY: {
      return new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        timeZone,
      }).format(date);
    }
    case DateFormat.YEAR: {
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone,
      }).format(date);
    }
    case DateFormat.MONTH_DAYTH_YEAR_24HOUR_MIN_SECONDS: {
      const month_dayth_year = format(
        date,
        DateFormat.MONTH_DAYTH_YEAR,
        timeZone
      );
      return `${month_dayth_year} ${new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23', // 00:00:00 -> 23:59:59
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.WEEKDAY_MONTH_DAY_YEAR_HOUR_MIN_SECONDS: {
      const weekday = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        timeZone,
      }).format(date);
      const year = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone,
      }).format(date);
      const month = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        timeZone,
      }).format(date);
      const day = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        timeZone,
      }).format(date);
      const time = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone,
      })
        .format(date)
        .toLowerCase();
      return `${weekday} ${year}-${month}-${day} ${time}`;
    }
    case DateFormat.YEAR_MONTH_DAY_HOUR_MINUTE_SECOND: {
      const year = new Intl.DateTimeFormat('en-US', {
        year: '2-digit',
        timeZone,
      }).format(date);
      const month = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        timeZone,
      }).format(date);
      const day = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        timeZone,
      }).format(date);
      const time = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23', // 00:00:00 -> 23:59:59
        timeZone,
      })
        .format(date)
        .toLowerCase();
      return `${year}/${month}/${day} ${time}`;
    }
    case DateFormat.YYYY_MM_DD_HH_MM_SS_DASHES: {
      const year = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone,
      }).format(date);
      const month = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        timeZone,
      }).format(date);
      const day = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        timeZone,
      }).format(date);
      const time = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
        timeZone,
      })
        .format(date)
        .toLowerCase();
      return `${year}-${month}-${day} ${time}`;
    }
    case DateFormat.DAYTH: {
      // Get date number in proper timezone for formatting the ordinal part
      const dateWithoutOrdinal = new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        timeZone,
      }).format(date);
      const dateNumber = parseInt(dateWithoutOrdinal);
      return formatOrdinals(dateNumber);
    }
    case DateFormat.MONTH_DAYTH: {
      return `${new Intl.DateTimeFormat('en-US', {
        month: 'long',
        timeZone,
      }).format(date)} ${format(date, DateFormat.DAYTH, timeZone)}`;
    }
    case DateFormat.MONTH_DAYTH_YEAR: {
      return `${format(
        date,
        DateFormat.MONTH_DAYTH,
        timeZone
      )}, ${new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.SHORT_MONTH_DAYTH_YEAR: {
      return `${new Intl.DateTimeFormat('en-US', {
        month: 'short',
        timeZone,
      }).format(date)} ${format(
        date,
        DateFormat.DAYTH,
        timeZone
      )}, ${new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.LONG_MONTH_DAY_YEAR_HOUR_MINUTE: {
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone,
      }).format(date);
    }
    case DateFormat.MONTH_SLASH_DAY: {
      const month = new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        timeZone,
      }).format(date);
      const day = new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        timeZone,
      }).format(date);
      return `${month}/${day}`;
    }
    case DateFormat.MONTH_DAYTH_YEAR_HOUR_MIN_SECONDS: {
      const month_dayth_year = format(
        date,
        DateFormat.MONTH_DAYTH_YEAR,
        timeZone
      );
      return `${month_dayth_year} ${new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.MONTH_DAY_HOUR_MIN: {
      return `${new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        timeZone,
      }).format(date)} ${new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.MONTH_DAY_2_DIGIT_YEAR_TIME: {
      return `${new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        timeZone,
      }).format(date)} '${new Intl.DateTimeFormat('en-US', {
        year: '2-digit',
        timeZone,
      }).format(date)} ${new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h23',
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.MONTH_DAY_YEAR_TIME: {
      return `${new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        timeZone,
      }).format(date)}/${new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        timeZone,
      }).format(date)}/${new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone,
      }).format(date)} ${new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.YEAR_MONTH_DAY: {
      return `${new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone,
      }).format(date)}-${new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        timeZone,
      }).format(date)}-${new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.MONTH_DAY_YEAR_SLASHES: {
      return `${new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        timeZone,
      }).format(date)}/${new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        timeZone,
      }).format(date)}/${new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone,
      }).format(date)}`;
    }
    case DateFormat.MONTH_DAY_SHORT_YEAR: {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: '2-digit',
        timeZone,
      }).format(date);
    }
    case DateFormat.TIME: {
      return new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone,
      })
        .format(date)
        .toLowerCase()
        .replace(' ', '');
    }
  }
}

export function addYearsUTC(date: Date, amount: number) {
  const newDate = new Date(date);
  newDate.setUTCFullYear(getUTCYear(date) + amount);
  return newDate;
}

export function subYearsUTC(date: Date, amount: number) {
  return addYearsUTC(date, -amount);
}

export function addYearsLocal(date: Date, amount: number) {
  const newDate = new Date(date);
  newDate.setFullYear(getLocalYear(date) + amount);
  return newDate;
}

export function subYearsLocal(date: Date, amount: number) {
  return addYearsLocal(date, -amount);
}

export function compareAsc(date1: Date, date2: Date): number {
  return Math.sign(date1.getTime() - date2.getTime()); // returns -1, 0, or 1 for sorting
}

export function max(dates: Date[]): Date | null {
  if (dates.length === 0) {
    return null;
  }
  return dates.reduce((maxDate, date2) => (maxDate > date2 ? maxDate : date2));
}

export function addDaysLocal(date: Date, amount: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + amount);
  return newDate;
}

export function addDaysUTC(date: Date, amount: number): Date {
  const newDate = new Date(date);
  newDate.setUTCDate(newDate.getUTCDate() + amount);
  return newDate;
}

export function subDaysLocal(date: Date, amount: number): Date {
  return addDaysLocal(date, -amount);
}

export function subDaysUTC(date: Date, amount: number): Date {
  return addDaysUTC(date, -amount);
}

export function addMonthsUTC(date: Date, amount: number) {
  const newDate = new Date(date);
  newDate.setUTCMonth(newDate.getUTCMonth() + amount);
  return newDate;
}

export function addMonthsLocal(date: Date, amount: number) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + amount);
  return newDate;
}

export function subMonthsUTC(date: Date, amount: number) {
  return addMonthsUTC(date, -amount);
}

export function subMonthsLocal(date: Date, amount: number) {
  return addMonthsLocal(date, -amount);
}

export function isBefore(date1: Date, date2: Date) {
  return date1 < date2;
}

export function unixMilliseconds(date: Date): number {
  return date.getTime();
}

// This value is floored to the nearest second, and does not include a milliseconds component.
export function unixSeconds(date: Date): number {
  return Math.floor(unixMilliseconds(date) / NUM_MILLISECONDS_IN_SECOND);
}

// weekInfo exists in almost all major browsers except Firefox
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo
// We will default to Sunday start of week in unsupported browsers/versions like in moment https://github.com/moment/moment/blob/develop/moment.js#L1499
type WeekInfo = {
  firstDay?: number;
  weekend?: number[];
  minimalDays?: number;
};
type Locale = Intl.Locale & {
  weekInfo?: WeekInfo;
};
export function startOfWeekLocal(date: Date): Date {
  const newDate = new Date(date);
  const dayOfWeek = newDate.getDay(); // .getDay() returns a number corresponding to 0 = Sunday, 1 = Monday, ... 6 = Saturday
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const weekFirstDay =
    (new Intl.Locale(locale) as Locale)?.weekInfo?.firstDay ?? 7; // Returns 1 = Monday, 2 = Tuesday, ... 7 = Sunday
  const weekFirstDayAdjusted = weekFirstDay % NUM_DAYS_IN_WEEK; // adjust to same scale as .getDay()
  const numDaysAfterStartOfWeek = dayOfWeek - weekFirstDayAdjusted;
  const numDaysMod = numDaysAfterStartOfWeek % NUM_DAYS_IN_WEEK; // if negative (e.g. it's Monday and the start of the week is Tuesday --> -1), then subtract (-1 % 7) = 6 to get to the previous Tuesday

  return startOfDayLocal(subDaysLocal(date, numDaysMod));
}

function formatDuration(
  amount: number,
  label: string,
  maxAmount: number,
  nextFunction?: (amount: number) => string
) {
  if (amount < maxAmount || nextFunction == null) {
    return `${amount} ${maybePluralizeWord(amount, label)}`;
  }
  return nextFunction(Math.floor(amount / maxAmount));
}

function formatDurationYears(years: number): string {
  return formatDuration(years, 'year', Infinity);
}

function formatDurationMonths(months: number): string {
  return formatDuration(
    months,
    'month',
    NUM_MONTHS_IN_YEAR,
    formatDurationYears
  );
}

function formatDurationDays(days: number): string {
  return formatDuration(
    days,
    'day',
    APPROX_NUM_DAYS_IN_MONTH,
    formatDurationMonths
  );
}

function formatDurationHours(hours: number): string {
  return formatDuration(hours, 'hour', NUM_HOURS_IN_DAY, formatDurationDays);
}

function formatDurationMinutes(minutes: number): string {
  return formatDuration(
    minutes,
    'minute',
    NUM_MINUTES_IN_HOUR,
    formatDurationHours
  );
}

function formatDurationSeconds(seconds: number): string {
  return formatDuration(
    seconds,
    'second',
    NUM_SECONDS_IN_MINUTE,
    formatDurationMinutes
  );
}

export function formatDurationMilliseconds(milliseconds: number): string {
  return formatDuration(
    milliseconds,
    'millisecond',
    NUM_MILLISECONDS_IN_SECOND,
    formatDurationSeconds
  );
}

export function isSameYearLocal(date1: Date, date2: Date): boolean {
  return getLocalYear(date1) === getLocalYear(date2);
}

export function isSameYearUTC(date1: Date, date2: Date): boolean {
  return getUTCYear(date1) === getUTCYear(date2);
}

export function isSameMonthLocal(date1: Date, date2: Date): boolean {
  return date1.getMonth() === date2.getMonth() && isSameYearLocal(date1, date2);
}

export function isSameMonthUTC(date1: Date, date2: Date): boolean {
  return (
    date1.getUTCMonth() === date2.getUTCMonth() && isSameYearUTC(date1, date2)
  );
}

export function isSameDateLocal(date1: Date, date2: Date): boolean {
  return date1.getDate() === date2.getDate() && isSameMonthLocal(date1, date2);
}

export function isSameDateUTC(date1: Date, date2: Date): boolean {
  return (
    date1.getUTCDate() === date2.getUTCDate() && isSameMonthUTC(date1, date2)
  );
}

export function isValidDate(date: Date): boolean {
  return !isNaN(date.getTime());
}

export function addSeconds(date: Date, amount: number) {
  const newDate = new Date(date);
  newDate.setUTCSeconds(newDate.getUTCSeconds() + amount);
  return newDate;
}

export function fromNow(date: Date) {
  const now = new Date();
  const diffInMilliseconds = date.getTime() - now.getTime();
  if (diffInMilliseconds > 0) {
    return `in ${formatDurationMilliseconds(diffInMilliseconds)}`;
  }
  if (diffInMilliseconds < 0) {
    return `${formatDurationMilliseconds(Math.abs(diffInMilliseconds))} ago`;
  }
  return `just now`;
}
