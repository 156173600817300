import {DefinedGlob} from '../../components/PanelBank/DefinedMetricContext';
import {setPanelStartingXAxisFromGlobMatch} from '../../components/PanelBank/defineMetricUtils';
import {PanelBankSectionConfig} from '../../components/PanelBank/types';
import {getCascadingSetting} from '../../components/WorkspaceDrawer/Settings/getCascadingSetting';
import {getLinePlotPanelConfigWithInheritedSettings} from '../../components/WorkspaceDrawer/Settings/hooks/usePanelInheritedSettings';
import {getWorkspaceInheritedLinePlotSettings} from '../../components/WorkspaceDrawer/Settings/hooks/useWorkspaceInheritedSettings';
import {LinePlotSettings} from '../../components/WorkspaceDrawer/Settings/types';
import {Config, LayedOutPanel} from '../panelTypes';
import {panelBankSectionFromJSON, upgradeToPanelBank} from '../parseSpecUtils';
import {ReportSpecVersion} from './shared';

// This is run in the loadFinished reducer, for reports.
// (A report section's panels = a single PanelBankSection)
// It returns a single PanelBankSection
// Put all migrations to the PanelBankSectionConfig object here.
export const migrateReportToPanelBank = (
  viewType: 'runs' | 'runs/draft',
  existingPanelBankSectionConfig: PanelBankSectionConfig,
  legacyConfig: Config | null,
  specVersion: ReportSpecVersion
): PanelBankSectionConfig => {
  const panelBankSectionConfig =
    specVersion === ReportSpecVersion.V0 && legacyConfig != null
      ? (upgradeToPanelBank(legacyConfig, true) as PanelBankSectionConfig)
      : existingPanelBankSectionConfig; // no-op

  return panelBankSectionFromJSON(panelBankSectionConfig, viewType);
};

/**
 * When we create a report from the workspace pages, we want to apply
 * cascading settings hierarchy to the line plot panels.
 * panel settings >> section settings >> workspace settings
 */
export function getReportPanel(
  p: LayedOutPanel,
  linePlotSettings: LinePlotSettings | undefined,
  section: PanelBankSectionConfig,
  definedGlobs: DefinedGlob[]
) {
  if (p.viewType !== 'Run History Line Plot') {
    return p;
  }

  // Resolve workspace vs section settings first
  const inheritedWorkspaceSettings = getWorkspaceInheritedLinePlotSettings(
    linePlotSettings,
    section?.sectionSettings?.linePlot
  );

  const inheritedPointVisualizationMethod = getCascadingSetting([
    inheritedWorkspaceSettings?.pointVisualizationMethod,
    p.config.pointVisualizationMethod,
  ]);

  const globInjectedPanelConfig = setPanelStartingXAxisFromGlobMatch(
    p.config,
    definedGlobs
  );

  const inheritedPanelSettings = getLinePlotPanelConfigWithInheritedSettings(
    inheritedPointVisualizationMethod,
    inheritedWorkspaceSettings,
    globInjectedPanelConfig
  );

  return {
    ...p,
    config: {
      ...p.config,
      ...inheritedPanelSettings,
    },
  };
}
