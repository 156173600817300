import {
  getCookie,
  setCookie,
  unsetCookie,
} from '@wandb/weave/common/util/cookie';
import {isTruthy} from '@wandb/weave/common/util/types';

import {envIsLocal, envIsPublicCloud} from '../config';
import {useViewer} from '../state/viewer/hooks';
import {Viewer} from '../state/viewer/types';

const USE_ADMIN_PRIVILEGES_COOKIE_KEY = 'use_admin_privileges';
export const USE_VIEW_AS_COOKIE_KEY = 'impersonated_username';

const {host} = window.location;
const isLocalhost = host.includes('localhost');

// Important - this will assume server org admins have admin powers enabled
// and be treated like w&b employees. Consider `useIsAdminModeActiveAndIsNotLocalEnv`
export function useAdminModeActive(): boolean {
  const viewer = useViewer();
  if (!viewer?.admin) {
    return false;
  }
  return viewerUsingAdminPrivileges();
}

export function useIsAdminModeActiveAndIsNotLocalEnv(): boolean {
  const viewer = useViewer();
  return viewerIsWbAdminUsingPrivileges(viewer);
}

export function viewerIsWbAdminUsingPrivileges(viewer: Viewer | undefined) {
  if (envIsLocal) {
    return false; // Not a concept in server, so default to false
  }
  if (!viewer?.admin) {
    return false;
  }
  return getCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY) === 'true';
}

export function viewerIsWbAdmin(viewer: Viewer | undefined) {
  if (envIsLocal) {
    return false;
  }
  if (viewer?.admin && viewer?.email && isWandbDomainEmail(viewer?.email)) {
    return true;
  }
  return false;
}

export function viewerUsingAdminPrivileges(): boolean {
  if (!envIsPublicCloud) {
    return true;
  }
  return getCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY) === 'true';
}

export function viewingAs(): string {
  return getCookie(USE_VIEW_AS_COOKIE_KEY);
}

export function setViewerUsingAdminPrivileges(enable: boolean): void {
  if (enable) {
    setAdminCookieForSubdomain(USE_ADMIN_PRIVILEGES_COOKIE_KEY, 'true');
  } else {
    unsetCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY);
  }
}

export function setViewingAs(username: string): void {
  setCookie(USE_VIEW_AS_COOKIE_KEY, username);
}

export function unsetViewingAs(): void {
  unsetCookie(USE_VIEW_AS_COOKIE_KEY);
}

const wandbDomainEmailRegex = new RegExp('^.+?@wandb.com$');
export function isWandbDomainEmail(email: string): boolean {
  return wandbDomainEmailRegex.test(email);
}

// modified version of setCookie to avoid setting duplicate admin cookies
// in test environments (makes it harder to test admin mode)
function setAdminCookieForSubdomain(key: string, value: string): void {
  if (!key) {
    return;
  }

  // take original path if prod
  if (host.startsWith('wandb.ai')) {
    setCookie(key, value);
  }

  // otherwise set a subdomain cookie
  const keyValStr = `${key}=${value}`;
  const expiryStr = ``;

  let devCookieAttrs = `domain=${host}; SameSite=strict; path=/`;
  if (isLocalhost) {
    devCookieAttrs = 'path=/';
  }

  for (const attrsStr of [devCookieAttrs]) {
    document.cookie = [keyValStr, expiryStr, attrsStr]
      .filter(isTruthy)
      .join('; ');
  }
}
