export const ONE_HOUR = '1h';
export const TWO_HOURS = '2h';
export const FOUR_HOURS = '4h';
export const EIGHT_HOURS = '8h';
export const TWELVE_HOURS = '12h';
export type AccessRequestDurationType = '1h' | '2h' | '4h' | '8h' | '12h';

export const WANDB_ADMIN_ROLE = 'wandb-admin';
export type AccessRequestRoleType = 'wandb-admin';
export type AccessRequestRoleListType = AccessRequestRoleType[];

export const REQUEST_ROLES_KEY = 'teleport_roles';
export const REQUEST_DURATION_KEY = 'duration';
export const REQUEST_REASON_KEY = 'reason';
