import React from 'react';
import {useLocation} from 'react-router-dom';

import {urlPrefixed} from '../config';
import {useHasLocalLicenseQuery} from '../generated/graphql';
import {
  NudgeBarLarge,
  NudgeBarLargeAction,
  NudgeBarLargeActionLink,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';

const GetFreeLicenseButton: React.FC = () => (
  <NudgeBarLargeActionLink
    href="https://api.wandb.ai/deploy/redirect/unlicensed"
    onClick={() => {
      window.analytics?.track('Get License Clicked');
    }}>
    Get a free license
  </NudgeBarLargeActionLink>
);

const AddLicenseButton: React.FC = () => (
  <NudgeBarLargeActionLink
    href={urlPrefixed('/system-admin', false)}
    onClick={() => {
      window.analytics?.track('Add License Clicked');
    }}>
    Add license
  </NudgeBarLargeActionLink>
);

export const GetLicenseNudgeBar: React.FC = React.memo(() => {
  const {data, loading} = useHasLocalLicenseQuery();

  const location = useLocation();

  if (
    (loading ||
      data?.serverInfo?.hasLocalLicense ||
      ['/', '/login', '/signup'].includes(location.pathname)) &&
    // CI doesn't have a license, so when getting screenshots via
    // the docker container locally we should take into account that this banner will show up.
    // This is a side effect of sharing the db with `invoker_local.ini` that if you have set up a license locally
    // it'll show up in tests you run locally.
    window.location.hostname !== 'host.docker.internal'
  ) {
    return null;
  }
  return (
    <NudgeBarLarge>
      <NudgeBarLargeTitle>
        You need a license for this instance of W&amp;B Local
      </NudgeBarLargeTitle>
      <NudgeBarLargeAction>
        <GetFreeLicenseButton />
        <AddLicenseButton />
      </NudgeBarLargeAction>
    </NudgeBarLarge>
  );
});
