import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {
  DerivedPointVisualizationOption,
  isPointVizFullFidelity,
  LinePlotSettings,
} from '../../types';
import {getLinePlotSettingWithDefault} from '../linePlotDefaults';
import {
  DEFAULT_LINE_PLOT_MAX_NUM_BUCKETED_RUNS,
  DEFAULT_LINE_PLOT_MAX_NUM_RUNS,
} from '../maxRunDefaults';

export const getCascadingMaxRuns = ({
  pointVisualizationMethod,
  maxRuns,
  limit,
}: {
  pointVisualizationMethod: DerivedPointVisualizationOption;
  maxRuns: LinePlotSettings['maxRuns'];
  limit?: RunsLinePlotConfig['limit'];
}) => {
  const isBucketing = isPointVizFullFidelity(pointVisualizationMethod);

  if (limit != null) {
    return getMaxRunLimit(limit, isBucketing);
  }

  return getMaxRunLimit(maxRuns, isBucketing);
};

/**
 * Determines the maximum number of runs that can be displayed on a panel.
 * Make sure a user can't sneak in values higher than the max
 */
export const getMaxRunLimit = (
  value: number | undefined,
  isBucketing: boolean
): number | undefined => {
  let allowedRuns;
  if (value != null) {
    allowedRuns =
      value > DEFAULT_LINE_PLOT_MAX_NUM_RUNS
        ? DEFAULT_LINE_PLOT_MAX_NUM_RUNS
        : value;
  }

  if (
    isBucketing &&
    allowedRuns &&
    allowedRuns > DEFAULT_LINE_PLOT_MAX_NUM_BUCKETED_RUNS
  ) {
    return DEFAULT_LINE_PLOT_MAX_NUM_BUCKETED_RUNS;
  }

  return allowedRuns;
};

/**
 * Determines the maximum number of runs that can be displayed on a panel and uses
 * the default value if setting is not defined.
 * Make sure a user can't sneak in values higher than the max
 */ export const getMaxRunLimitWithDefault = (
  value: number | undefined,
  isBucketing: boolean
): number => {
  return getLinePlotSettingWithDefault(
    getMaxRunLimit(value, isBucketing),
    'limit'
  );
};
