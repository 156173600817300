import * as React from 'react';

import {type Line} from '../../util/plotHelpers/types';
import PlotWarning from '../PlotWarning';
import {usePanelConfigContext} from './PanelConfigContext';
import {usePanelGroupingSettings} from './RunsLinePlotContext/usePanelGroupingSettings';

function HistogramMultiRunWarning({lines}: {lines: Line[]}) {
  if (lines.length === 0) {
    return null;
  }

  const firstRun = lines[0].run;
  return (
    <PlotWarning
      message={`Showing histogram for ${
        firstRun?.displayName || 'the first run'
      }`}
    />
  );
}

/**
 * we want to be able to show several different warnings in the legend if runs are grouped:
 * - A : warn user if there are more grouped plot lines than max runs (not all groups visible in the table will show up as lines on the chart)
 *  - B : more totalRuns that groupRunsLimit (not all runs visible in the table will be used to compute the group summary metrics visible on the chart)
 *
 * we warn about B with higher priority than A because B is actually a tighter and more annoying restriction (that is, the total number of runs in a grouped section exceeds the default limit of 100 much faster/much more often--and, crucially, in a less transparent way--than the total number of groups in a section exceeds the smallest default limit of 20)
 *
 * if runs are not grouped:
 * - more plot lines than max runs (not all runs from the table will show up in the chart)
 */
export const LinePlotLegendWarning = React.memo(
  ({
    isHistogram,
    isReadOnly,
    lines,
    loading,
    maxGroupRuns,
    totalRuns,
    disallowSelect,
  }: {
    isHistogram: boolean;
    isReadOnly: boolean;
    lines: Line[];
    loading: boolean;
    maxGroupRuns: number;
    totalRuns: number;
    disallowSelect: boolean;
  }) => {
    const {isAnyRunsetGrouped: isGrouped} = usePanelGroupingSettings();
    const {limit: maxRuns} = usePanelConfigContext();
    const totalRunsExceedsOne = totalRuns > 1;
    const totalRunsExceedsMaxGroupRuns = totalRuns > maxGroupRuns;
    const totalRunsExceedsMaxRuns = totalRuns > maxRuns;
    const lineCountExceedsMaxRuns = lines.length > maxRuns;

    if (isHistogram && totalRunsExceedsOne) {
      return <HistogramMultiRunWarning lines={lines} />;
    }

    // show user warning about group subsampling when the total runs visible in the table exceed the number of runs sampled to compute group metrics in the chart
    if (isGrouped && totalRunsExceedsMaxGroupRuns) {
      return (
        <PlotWarning
          helpText={
            !isReadOnly
              ? `Click on the pencil icon (top right corner of this chart) to change the number of runs sampled in group metrics`
              : undefined
          }
          message={`Computing group metrics from first ${maxRuns} groups`}
          disallowSelect={disallowSelect}
        />
      );
    }

    // show user warning about max groups if the total groups visible in the table exceed the maximum number of runs displayed in the chart--note this happens less often and is easier to notice than the previous case
    if (isGrouped && lineCountExceedsMaxRuns) {
      return (
        <PlotWarning
          helpText={
            !isReadOnly
              ? `Click on the pencil icon (top right corner of this chart) to change the max number of groups displayed`
              : undefined
          }
          message={`Showing first ${maxRuns} groups`}
          disallowSelect={disallowSelect}
        />
      );
    }

    /**
     * Context: there are more runs visible in the table than can be displayed as plot lines in the chart
     * Calculating this is difficult:
     * 1. The API returns the `totalRuns` on the project- but not every run on the project is
     * necessarily available for this panel (e.g. this panel might show a metric logged on a
     * fraction of the runs)
     * 2. The `lines` prop is the number of lines returned from line construction: if it equals
     * maxRuns AND there are more lines in the project than returned here, we can infer that
     * there might be at least one more matching run in the project and show the warning
     */
    if (
      totalRunsExceedsMaxRuns &&
      lines.length === maxRuns &&
      totalRuns > lines.length
    ) {
      return (
        <PlotWarning
          helpText={
            !isReadOnly
              ? `Click on the pencil icon (top right corner of this chart) to change the max number of runs displayed`
              : undefined
          }
          message={`Showing first ${lines.length} runs`}
          disallowSelect={disallowSelect}
        />
      );
    }

    return null;
  }
);

LinePlotLegendWarning.displayName = 'LinePlotLegendWarning';
