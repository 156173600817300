import _ from 'lodash';
import {ActionType as TSActionType} from 'typesafe-actions';

import {setInShallowClone} from '../../util/utility';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as CustomRunColorsActions from './customRunColors/actions';
import * as CustomRunNamesActions from './customRunNames/actions';
import * as DiscussionCommentActions from './discussionComment/actions';
import * as FilterActions from './filter/actions';
import * as GroupSelectionsActions from './groupSelections/actions';
import * as GroupSelectionsActionsInternal from './groupSelections/actionsInternal';
import * as MarkdownBlockActions from './markdownBlock/actions';
import * as Normalize from './normalize';
import {StateType} from './normalizerSupport';
import * as PanelActions from './panel/actions';
import * as PanelBankConfigActions from './panelBankConfig/actions';
import type * as PanelBankConfigActionsInternal from './panelBankConfig/actionsInternal';
import * as PanelBankSectionConfigActions from './panelBankSectionConfig/actions';
import * as PanelSettingsActions from './panelSettings/actions';
import * as ReportActions from './report/actions';
import * as RunSetActions from './runSet/actions';
import * as SectionActions from './section/actions';
import * as SortActions from './sort/actions';
import * as TempSelectionsActions from './tempSelections/actions';
import * as TempSelectionsActionsInternal from './tempSelections/actionsInternal';
import * as Types from './types';
import * as WorkspaceSettingsActions from './workspaceSettings/actions';

export type ActionType = TSActionType<
  | typeof Actions
  | typeof ActionsInternal
  | typeof SortActions
  | typeof FilterActions
  | typeof CustomRunColorsActions
  | typeof CustomRunNamesActions
  | typeof GroupSelectionsActions
  | typeof GroupSelectionsActionsInternal
  | typeof PanelActions
  | typeof PanelBankConfigActions
  | typeof PanelBankConfigActionsInternal
  | typeof PanelBankSectionConfigActions
  | typeof PanelSettingsActions
  | typeof SectionActions
  | typeof MarkdownBlockActions
  | typeof RunSetActions
  | typeof ReportActions
  | typeof TempSelectionsActions
  | typeof TempSelectionsActionsInternal
  | typeof DiscussionCommentActions
  | typeof WorkspaceSettingsActions
>;

interface ViewList {
  loading: boolean;
  // The metadatalist query that produced this list
  query: Types.LoadMetadataListParams;
  // Pointers to the .views field of the reducer
  viewIds: string[];
}

export interface ViewReducerState {
  // lists are the results of querying the server for lists of views
  lists: {[id: string]: ViewList};
  // any views that are loaded from the server or user created
  // (and not yet saved).
  views: {[id: string]: Types.LoadableView};
  loading: boolean;
  // normalized view specs
  parts: StateType;

  undoActions: ActionType[]; // stack of actions that undo past actions
  redoActions: ActionType[]; // stack of actions that undo future actions
}

export function deleteParts(state: ViewReducerState, ref: Types.AllPartRefs) {
  if (!Normalize.partExists(state.parts, ref)) {
    return;
  }
  const {partsWithRef} = Normalize.denormalizeWithParts(state.parts, ref);
  const subRefs = partsWithRef.map(p => p.ref);
  for (const subRef of subRefs) {
    delete state.parts[subRef.type][subRef.id];
  }
}

export function deletePartsImmutable(
  state: ViewReducerState,
  ref: Types.AllPartRefs
) {
  if (!Normalize.partExists(state.parts, ref)) {
    return state;
  }
  const {partsWithRef} = Normalize.denormalizeWithParts(state.parts, ref);
  const subRefs = partsWithRef.map(p => p.ref);
  const newParts = Object.assign({}, state.parts);
  for (const subRef of subRefs) {
    if (newParts[subRef.type] === state.parts[subRef.type]) {
      // @ts-ignore
      newParts[subRef.type] = Object.assign({}, newParts[subRef.type]);
    }
    delete newParts[subRef.type][subRef.id];
  }
  return {
    ...state,
    parts: newParts,
  };
}

export function replacePart(
  state: ViewReducerState,
  oldRef: Types.AllPartRefs,
  newRef: Types.AllPartRefs
) {
  // Normalize.addObj adds the object under a newly generated ID.
  // So move it to the requested ID.
  state.parts[oldRef.type][oldRef.id] = _.cloneDeep(
    state.parts[newRef.type][newRef.id]
  );
  delete state.parts[newRef.type][newRef.id];
}

export function replacePartImmutable(
  state: ViewReducerState,
  oldRef: Types.AllPartRefs,
  newRef: Types.AllPartRefs
): ViewReducerState {
  const newState = setInShallowClone(
    state,
    ['parts', oldRef.type, oldRef.id],
    state.parts[newRef.type][newRef.id]
  );
  delete newState.parts[oldRef.type][newRef.id];
  return newState;
}

function getFilterActionsForDeletedRefsFnInRemoveHistoryForObject(
  state: ViewReducerState,
  ref: Types.AllPartRefs
) {
  if (!Normalize.partExists(state.parts, ref)) {
    // Part doesn't exist, don't filter anything.
    return () => true;
  }
  const {partsWithRef} = Normalize.denormalizeWithParts(state.parts, ref);
  const subRefIDs = new Set(partsWithRef.map(p => p.ref.id));
  return (undoAction: ActionType) => {
    if ('payload' in undoAction && 'ref' in undoAction.payload) {
      return !subRefIDs.has(undoAction.payload.ref.id);
    }

    return true;
  };
}

export function immutableRemoveHistoryForObject(
  state: ViewReducerState, // this is a plain object
  ref: Types.AllPartRefs
): ViewReducerState {
  const filterActionsForDeletedRefsFn =
    getFilterActionsForDeletedRefsFnInRemoveHistoryForObject(state, ref);
  return {
    ...state,
    undoActions: state.undoActions.filter(filterActionsForDeletedRefsFn),
    redoActions: state.redoActions.filter(filterActionsForDeletedRefsFn),
  };
}

export function removeHistoryForObject(
  state: ViewReducerState, // this is an immer draft
  ref: Types.AllPartRefs
) {
  const filterActionsForDeletedRefsFn =
    getFilterActionsForDeletedRefsFnInRemoveHistoryForObject(state, ref);
  state.undoActions = state.undoActions.filter(filterActionsForDeletedRefsFn);
  state.redoActions = state.redoActions.filter(filterActionsForDeletedRefsFn);
}
