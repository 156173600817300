import gql from 'graphql-tag';

export const USAGE_TAB_INFO = gql`
  query UsageTabInfo(
    $orgName: String!
    $usageType: UsageType!
    $intervalMarkers: [DateTime!]!
  ) {
    organization(name: $orgName) {
      id
      createdAt
      usage(usageType: $usageType, intervalMarkers: $intervalMarkers) {
        start
        end
        value
        projectName
        entityName
      }
    }
  }
`;

export const GLOBAL_USAGE_TAB_INFO = gql`
  query GlobalUsageTabInfo(
    $usageType: UsageType!
    $intervalMarkers: [DateTime!]!
  ) {
    usage(usageType: $usageType, intervalMarkers: $intervalMarkers) {
      start
      end
      value
      projectName
      entityName
    }
  }
`;

export const WEAVE_USAGE_INFO = gql`
  query WeaveUsageInfo(
    $orgName: String!
    $usageType: UsageType!
    $intervalMarkers: [DateTime!]!
  ) {
    organization(name: $orgName) {
      id
      createdAt
      usageTotal(usageType: $usageType, intervalMarkers: $intervalMarkers)
    }
  }
`;
