import {useCallback} from 'react';

import {trackSetting} from '../../../../services/analytics/workspaceSettingsEvents';
import {useViewAction} from '../../../../state/views/hooks';
import * as WorkspaceSettingsActions from '../../../../state/views/workspaceSettings/actions';
import * as WorkspaceSettingsTypes from '../../../../state/views/workspaceSettings/types';
import {useWorkspaceRefsContext} from '../../../Workspace/WorkspaceRefsContext';

export const useUpdateShouldAutoGeneratePanels = (
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref
) => {
  const {panelBankConfigRef} = useWorkspaceRefsContext();
  const updateShouldAutoGeneratePanels = useViewAction(
    workspaceSettingsRef,
    WorkspaceSettingsActions.updateShouldAutoGeneratePanels
  );

  return useCallback(
    (shouldEnable: boolean) => {
      updateShouldAutoGeneratePanels(panelBankConfigRef, shouldEnable);
      trackAutoGeneratePanels(shouldEnable);
    },
    [panelBankConfigRef, updateShouldAutoGeneratePanels]
  );
};

const trackAutoGeneratePanels = (shouldEnable: boolean) => {
  trackSetting({
    action: 'change',
    page: 'workspace',
    settingLevel: 'workspace',
    setting: 'shouldAutoGeneratePanels',
    settingValue: shouldEnable.toString(),
  });
};
