import {isFirefox, isSafari} from '@wandb/weave/common/util/browser';

import {urlPrefixed} from '../config';
import globalHistory from './history';
import {stripOriginFromURL} from './urls';

// This is a subset of the viewer type in state/viewer/types.ts
type GlobalViewer = {
  id: string;
  username?: string;
  entity: string;
  org?: string;
};

// Expect these items to be in the window object.
declare global {
  interface Window {
    ga: any;
    Beamer: any;
    __REDUX_DEVTOOLS_EXTENSION__?: any;
    __PREVIOUSLY_LOGGED_IN?: boolean;
    __WB_authRedirect?: string;
    __INITIALIZE_PENDO?: () => void;
    __WB_WYSIWYG_CONVERSION_REVERT?: () => void;
    store: any; // To allow Cypress to access redux store - configured in setup.ts
    /**
     * Allows us to know the current user even if we are outside of react/redux code,
     * initial use case is for flags. This value is currently set in viewer/hooks.ts
     */
    viewer: GlobalViewer | undefined;
  }
}

export function openInNewTab(url: string): void {
  // HAX: Firefox & Safari blocks new tabs by default, treating it as a popup window
  if (isFirefox || isSafari) {
    globalHistory.push(stripOriginFromURL(url));
    return;
  }
  window.open(urlPrefixed(url), undefined, 'noopener,noreferrer');
}

// Don't flag as IFrame if we're mounted in a service like Kubeflow.
// Do flag as IFrame if we're rendered in Kubeflow notebooks.
export function isInIframe() {
  try {
    return (
      window.self !== window.top &&
      (window.self.location.origin !== window.top?.location.origin ||
        window.top.location.pathname.startsWith('/notebook'))
    );
  } catch (e) {
    return true;
  }
}
