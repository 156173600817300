import {RunHistoryRow, RunKeyInfo} from '../../types/run';
import {SingleQuery} from '../../util/queryTypes';
import * as RunTypes from '../../util/runTypes';

export type HistorySpec = {
  keys: string[];
  samples: number;
  minStep?: number;
  maxStep?: number;
};

export type BucketedHistorySpec = {
  keys: string[];
  bins: number;
  samples?: number;
  xAxis?: string;
  minStep?: number;
  maxStep?: number;
  minX?: number;
  maxX?: number;
};

export type StepAxisBHS = BucketedHistorySpec & {
  xAxis: '_step';
  minX: never;
  maxX: never;
};
export type CustomAxisBHS = BucketedHistorySpec & {
  xAxis: Exclude<string, '_step'>;
  minX?: number;
  maxX?: number;
};

export function specIsStepAxis(spec: BucketedHistorySpec): spec is StepAxisBHS {
  return spec.xAxis === '_step';
}

// This is the user query interface. This has a lot of legacy stuff in it.
// We should switch to using the Query from runs-low when we can.
export interface Query {
  // level?: 'run' | 'subgroup' | 'group';
  aggregationKeys?: string[];
  bucketedHistorySpecs?: BucketedHistorySpec[];
  configKeys?: string[];
  disabled?: boolean;
  displayName?: string;
  enableArtifactCounts?: boolean;
  enableTags?: boolean;
  entityName: string;
  fullAggregations?: boolean;
  fullConfig?: boolean;
  fullSummary?: boolean;
  groupKeys?: string[];
  history?: boolean;
  historyFields?: string[];
  historyKeyInfo?: boolean;
  historySamples?: number;
  historySpecs?: HistorySpec[];
  internalProjectId?: string | undefined;
  keysLoading: boolean;
  page?: {size: number};
  pollInterval?: number;
  projectName: string;
  queries: SingleQuery[];
  runName?: string;
  select?: string;
  summaryKeys?: string[];
  wandbKeys?: string[];
}

export type RunWithRunsetInfo = RunTypes.Run & {
  runsetInfo: {
    id: string;
    name?: string;
  };
};

export function isRunWithRunsetInfo(r: RunTypes.Run): r is RunWithRunsetInfo {
  return (
    'runsetInfo' in r &&
    typeof (r as any).runsetInfo?.id === 'string' &&
    ['string', 'undefined'].indexOf(typeof (r as any).runsetInfo?.name) !== -1
  );
}

// Data holds results.
export interface Data {
  initialLoading: boolean;
  loading: boolean;
  entityName: string;
  projectName: string;
  runName?: string;
  displayName?: string;
  filtered: RunWithRunsetInfo[];
  filteredRunsById: {[key: string]: RunWithRunsetInfo};
  keyInfo: RunKeyInfo;
  totalRuns: number;
  limit?: number;
  histories: {
    data: Array<{
      name: string;
      displayName: string;
      history: RunHistoryRow[];
    }>;
  };
  doRunSetGrouping?: boolean;
  loadMore?(page: number): void;
}
