import {useContext, useMemo} from 'react';

import {envIsLocal, envIsPublicCloud} from '../../../config';
import {
  useOrganizationReadOnlyQuery,
  useProjectReadOnlyQuery,
  useRegistryProjectQuery,
} from '../../../generated/graphql';
import {DEFAULT_ONPREM_ORGANIZATION_NAME} from '../../../pages/OrgDashboard/OrgRoles/RolesDashboard';
import {useViewer} from '../../../state/viewer/hooks';
import {propagateErrorsContext} from '../../../util/errors';
import {useRampFlagGlobalRegistry} from '../../../util/rampFeatureFlags';
import {AccountSelectorContext} from '../../Search/SearchNav/AccountSelectorContextProvider';
import {getGlobalRegistryType, REGISTRY_PROJECT_PREFIX} from './utils';

export const useIsGlobalRegistryEnabled = (
  entityName?: string,
  isEntityNameLoading?: boolean
): {
  enableGlobalRegistry: boolean;
  enableGlobalRegistryLoading: boolean;
} => {
  const viewer = useViewer();
  // On Saas, personal entities are a part of personal organizations,
  // for whom this feature flag is always false
  // However, on server, personal entities are a part of the `server`
  // organization so the flag ends up getting set as true. This check
  // forces the flag as false for personal entities on server
  const isPersonalEntity =
    entityName != null && viewer?.username === entityName;
  const {selectedAccount} = useContext(AccountSelectorContext);
  const orgName = envIsPublicCloud
    ? selectedAccount?.name ?? ''
    : DEFAULT_ONPREM_ORGANIZATION_NAME;
  const {data: enableGlobalRegistry, loading: enableGlobalRegistryLoading} =
    useRampFlagGlobalRegistry(orgName ?? '');
  return useMemo(() => {
    if (envIsLocal) {
      return {
        enableGlobalRegistry:
          ((window.CONFIG as any)?.ENABLE_REGISTRY_UI && !isPersonalEntity) ??
          false,
        enableGlobalRegistryLoading: isEntityNameLoading ?? false,
      };
    }
    return {
      enableGlobalRegistry,
      enableGlobalRegistryLoading,
    };
  }, [
    enableGlobalRegistry,
    enableGlobalRegistryLoading,
    isPersonalEntity,
    isEntityNameLoading,
  ]);
};

export const useIsOrgReadOnly = (): {
  isOrganizationReadOnly: boolean;
  loading: boolean;
} => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const orgName = envIsPublicCloud
    ? selectedAccount?.name ?? ''
    : DEFAULT_ONPREM_ORGANIZATION_NAME;
  const {data: organizationData, loading: organizationLoading} =
    useOrganizationReadOnlyQuery({
      variables: {orgName},
      context: propagateErrorsContext(),
    });

  const state = useMemo(
    () => ({
      isOrganizationReadOnly:
        organizationData?.organization?.orgEntity?.readOnly ?? true,
      loading: organizationLoading,
    }),
    [organizationData, organizationLoading]
  );
  return state;
};

export const useIsProjectReadOnly = (
  entityName: string,
  projectName: string
): {isProjectReadOnly: boolean; loading: boolean} => {
  const {data, loading} = useProjectReadOnlyQuery({
    variables: {entityName, projectName},
    context: propagateErrorsContext(),
    skip: entityName === '' || projectName === '',
  });

  const state = useMemo(
    () => ({
      isProjectReadOnly: data?.entity?.project?.readOnly ?? true,
      loading,
    }),
    [data, loading]
  );
  return state;
};

export const useRegistryProject = (orgName: string, projectName?: string) => {
  const fullProjectName = `${REGISTRY_PROJECT_PREFIX}${projectName}`;

  const {data, loading, error} = useRegistryProjectQuery({
    variables: {orgName, projectName: fullProjectName},
    context: propagateErrorsContext(),
    skip: !orgName || !projectName,
  });

  const registryProject = useMemo(() => {
    const entityName = data?.organization?.orgEntity?.name ?? '';
    if (data?.organization?.orgEntity?.project != null && projectName != null) {
      return getGlobalRegistryType(
        data?.organization?.orgEntity?.project,
        projectName,
        entityName,
        orgName
      );
    }
    return undefined;
  }, [data, projectName, orgName]);

  return {
    data: registryProject,
    loading,
    error,
  };
};
