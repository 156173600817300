import React, {createContext, useContext, useMemo} from 'react';

import {
  getDefinedMetricMaps,
  useExtractDefinedMetricConfigs,
} from './defineMetricUtils';
import {useWandbConfigContext} from './WandbConfigContext';

export type DefinedGlob = {
  glob: string;
  baseMetric: string;
};

type DefinedMetricContextType = {
  /** Whether the defined metrics are still loading */
  isLoading: boolean;
  /** Maps metric names to their corresponding x-axis metric names */
  definedMetricsMap: Record<string, string>;
  /** Array of glob patterns mapped to their corresponding x-axis metric names */
  definedGlobs: DefinedGlob[];
  /** Set of metrics that should not automatically create panels in the panel bank */
  hiddenMetrics: Set<string>;
};

const DEFINED_METRIC_CONTEXT_EMPTY_VALUE: DefinedMetricContextType = {
  definedMetricsMap: {},
  definedGlobs: [],
  hiddenMetrics: new Set<string>(),
  isLoading: false,
};

export const DefinedMetricContext = createContext<DefinedMetricContextType>({
  isLoading: true,
  definedMetricsMap: {},
  definedGlobs: [],
  hiddenMetrics: new Set<string>(),
});

export const DefinedMetricContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const wbConfigQuery = useWandbConfigContext();
  const definedMetricConfigs = useExtractDefinedMetricConfigs(wbConfigQuery);
  const {keyMap, globs, hiddenMetrics} = useMemo(
    () => getDefinedMetricMaps(definedMetricConfigs),
    [definedMetricConfigs]
  );
  const value = useMemo(() => {
    return {
      definedMetricsMap: keyMap,
      definedGlobs: globs,
      hiddenMetrics,
      isLoading: wbConfigQuery.loading,
    };
  }, [keyMap, globs, hiddenMetrics, wbConfigQuery.loading]);
  return (
    <DefinedMetricContext.Provider value={value}>
      {children}
    </DefinedMetricContext.Provider>
  );
};

export const useDefinedMetricContext = () => {
  const value = useContext(DefinedMetricContext);
  // we don't want to throw an error if the context is not found
  // since this will be used within panels to check regexes
  // and panels in reports and custom workspaces will not use this context
  if (value == null) {
    return DEFINED_METRIC_CONTEXT_EMPTY_VALUE;
  }
  return value;
};
