import {
  AlertSubscription,
  Views2ViewQuery,
  ViewSource,
} from '../../generated/graphql';
import type * as CustomRunColorsTypes from './customRunColors/types';
import type * as CustomRunNamesTypes from './customRunNames/types';
import type * as DiscussionCommentTypes from './discussionComment/types';
import type * as DiscussionThreadTypes from './discussionThread/types';
import type * as FilterTypes from './filter/types';
import type * as GroupPageTypes from './groupPage/types';
import type * as GroupSelectionsTypes from './groupSelections/types';
import type * as MarkdownBlockTypes from './markdownBlock/types';
import type * as PanelNormTypes from './panel/types';
import type * as PanelBankConfigTypes from './panelBankConfig/types';
import type * as PanelBankSectionConfigTypes from './panelBankSectionConfig/types';
import type * as PanelsTypes from './panels/types';
import type * as PanelSettingsTypes from './panelSettings/types';
import type * as ProjectPageTypes from './projectPage/types';
import type * as ReportTypes from './report/types';
import type * as ReportDraftTypes from './reportDraft/types';
import type * as RunPageTypes from './runPage/types';
import type * as RunSetTypes from './runSet/types';
import type * as SectionTypes from './section/types';
import type * as SortTypes from './sort/types';
import type * as SweepPageTypes from './sweepPage/types';
import type * as TempSelectionsTypes from './tempSelections/types';
import type * as WorkspaceSettingsTypes from './workspaceSettings/types';

export interface LoadMetadataListParams {
  entityName: string;
  projectName: string;
  viewType: string;
  viewName?: string;
  userName?: string;
}

export type AccessToken = NonNullable<
  NonNullable<Views2ViewQuery['view']>['accessTokens']
>[number];

export type ViewUser = {
  id: string;
  username: string;
  name: string;
  photoUrl?: string;
  admin: boolean;
};

export interface View {
  cid: string; // client id // client id: Note that this is NOT sent/received with the server
  id?: string; // server id
  type: ViewType;
  name: string;
  displayName: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy?: {
    id: string;
    username: string;
    name: string;
  };
  user: ViewUser;
  entityName: string;
  project?: {
    id?: string;
    name: string;
    entityName: string;
    readOnly: boolean;
  };
  spec?: string;
  previewUrl?: string;
  coverUrl?: string;
  starCount?: number;
  starred?: boolean;
  parentId?: string;
  locked: boolean;
  viewCount?: number;
  alertSubscription?: AlertSubscription;
  accessTokens?: AccessToken[];
}

export interface SaveableView {
  id?: string;
  type?: ViewType;
  name?: string;
  displayName?: string;
  project?: {
    name?: string;
    entityName: string;
  };
  description?: string;
  spec?: any;
  previewUrl?: string;
  coverUrl?: string;
  parentId?: string;
  locked?: boolean;
  createdUsing?: ViewSource;
}

export type LoadableView = Omit<View, 'spec'> & {
  loading: boolean;
  autoSave: boolean;
  saving: boolean;
  modified: boolean;
  starLoading: boolean;
  partRef?: ViewPartRefs; // pointer to normalized version
  panelCommentsEnabled: boolean;
};

export type ObjSchema =
  | ProjectPageTypes.ProjectPageObjSchema
  | ReportTypes.ReportObjSchema
  | ReportDraftTypes.ReportDraftObjSchema
  | RunPageTypes.RunPageObjSchema
  | RunSetTypes.RunSetObjSchema
  | SectionTypes.SectionObjSchema
  | MarkdownBlockTypes.MarkdownBlockObjSchema
  | SortTypes.SortObjSchema
  | FilterTypes.FilterObjSchema
  | GroupSelectionsTypes.GroupSelectionsObjSchema
  | GroupPageTypes.GroupPageObjSchema
  | SweepPageTypes.SweepPageObjSchema
  | PanelsTypes.PanelsObjSchema
  | PanelBankConfigTypes.PanelBankConfigObjSchema
  | PanelBankSectionConfigTypes.PanelBankSectionConfigObjSchema
  | PanelNormTypes.PanelObjSchema
  | PanelSettingsTypes.PanelSettingsObjSchema
  | CustomRunColorsTypes.CustomRunColorsObjSchema
  | CustomRunNamesTypes.CustomRunNamesObjSchema
  | TempSelectionsTypes.TempSelectionObjSchema
  | DiscussionThreadTypes.DiscussionThreadObjSchema
  | DiscussionCommentTypes.DiscussionCommentObjSchema
  | WorkspaceSettingsTypes.WorkspaceSettingsObjSchema;

export type ObjType = ObjSchema['type'];

// A subset of objs are views, these are the objs that can be
// saved and loaded for the server.
// You'll get type-errors elsewhere if this is not a subset of ObjType
export const VIEW_TYPES = [
  'project-view',
  'group-view',
  'sweep-view',
  'run-view',
  'runs',
  'runs/draft',
] as const;
export type ViewType = (typeof VIEW_TYPES)[number];
export type ReportViewType = 'runs' | 'runs/draft';
export type RunWorkspaceViewType = 'run-view';
export type MultiRunWorkspaceViewType =
  | 'project-view'
  | 'group-view'
  | 'sweep-view';
export type WorkspaceViewType =
  | RunWorkspaceViewType
  | MultiRunWorkspaceViewType;

export type WorkspaceViewRef = PartRefFromType<WorkspaceViewType>;

type ViewObjSchemaHelper<S extends ObjSchema> = S extends {type: ViewType}
  ? S
  : never;
export type ViewObjSchema = ViewObjSchemaHelper<ObjSchema>;

export interface ViewRefFromObjSchema<O extends ViewObjSchema> {
  type: O['type'];
  id: string;
}

export type ViewRef = ViewRefFromObjSchema<ViewObjSchema>;

export interface PartRefFields {
  viewID: string;
  id: string;
}

export type PartRefFromObjSchema<O extends ObjSchema> = Pick<O, 'type'> &
  PartRefFields;

export type AllPartRefHelper<P> = P extends ObjSchema
  ? {
      type: P['type'];
    } & PartRefFields
  : never;
export type AllPartRefs = AllPartRefHelper<ObjSchema>;
export type ViewPartRefs = AllPartRefHelper<ViewObjSchema>;

export type AllWholeWithTypesHelper<T> = T extends ObjType
  ? {
      type: T;
      whole: WholeFromType<T>;
    }
  : never;
export type AllWholeWithTypes = AllWholeWithTypesHelper<ObjType>;

export type PartRefFromType<S extends ObjType> = S extends ObjType
  ? {type: S} & PartRefFields
  : never;

type ObjSchemaFromTypeHelper<
  T extends ObjType,
  S extends ObjSchema
> = S extends {type: T} ? S : never;

export type ObjSchemaFromType<T extends ObjType> = ObjSchemaFromTypeHelper<
  T,
  ObjSchema
>;

export type PartFromType<T extends ObjType> = ObjSchemaFromType<T>['part'];
export type WholeFromType<T extends ObjType> = ObjSchemaFromType<T>['whole'];
export type WholeFromTypeWithRef<T extends ObjType> = WholeFromType<T> & {
  ref: PartRefFromType<T>;
};
