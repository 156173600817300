import {notEmpty} from '@wandb/weave/common/util/obj';
import _ from 'lodash';

import {getProjectInternalId} from '../components/RunSelector/util';
import {useProjectNameFromInternalIdQuery} from '../generated/graphql';
import {CustomRunNames} from '../state/views/customRunNames/types';
import type {RunSetConfig} from '../state/views/runSet/types';
import {And, Or, runContainsFilter, runRegexFilter} from './filters';
import {Filter} from './filterTypes';
import {Grouping, Query, SingleQuery, Sort, SortKey} from './queryTypes';
import {mergeRuns} from './runhelpers';
import {getValue, keyFromJSON, keyFromString, keyToServerPath} from './runs';
import * as RunTypes from './runTypes';

export const SORTKEY_CREATED_AT: RunTypes.Key = {
  section: 'run',
  name: 'createdAt',
};
export const CREATED_AT_DESC: Sort = {
  keys: [
    {
      key: SORTKEY_CREATED_AT,
      ascending: false,
    },
  ],
};
export const CREATED_AT_ASC: Sort = {
  keys: [
    {
      key: SORTKEY_CREATED_AT,
      ascending: true,
    },
  ],
};

export const DEFAULT_RUNS_SORT = CREATED_AT_DESC;
export const DEFAULT_RUNS_SORT_KEY = CREATED_AT_DESC.keys[0];

export function getLegacyProject(query: any) {
  // Old code, just for OpenAI dashboard.
  return query && (query.project || query.model);
}

// export type Query = SingleQuery | MultiQuery;

// Server parsing functions

function sortKeyFromJSON(json: any): SortKey | null {
  if (json == null) {
    return null;
  }
  if (typeof json.ascending !== 'boolean') {
    return null;
  }
  let key: RunTypes.Key | null = null;
  if (typeof json.name === 'string') {
    key = keyFromString(json.name);
  } else {
    key = keyFromJSON(json.key);
  }
  if (key != null) {
    return {key, ascending: json.ascending};
  }
  return null;
}

function sortFromJSON(json: any): Sort | null {
  if (json == null) {
    return null;
  }
  const keyJSONArr: SortKey[] = json.keys ?? [json];
  const keys = _.compact(keyJSONArr.map(sortKeyFromJSON));
  return keys.length > 0 ? {keys} : null;
}

export function sortFromJSONSafe(json: any): Sort {
  return sortFromJSON(json) ?? DEFAULT_RUNS_SORT;
}

export function groupingFromJSON(json: any): Grouping | null {
  if (!_.isArray(json)) {
    return null;
  }
  return json.map(keyFromJSON).filter(notEmpty);
}

/// Helpers

export function shouldRunSetAggregate(queries: SingleQuery[]) {
  return queries.filter(q => q.grouping && q.grouping.length > 0).length > 0;
}

export function sortRuns(sort: Sort, runs: RunTypes.Run[]) {
  for (const {key, ascending} of sort.keys) {
    runs = _.sortBy(runs, r => getValue(r, key));
    if (!ascending) {
      runs = _.reverse(runs);
    }
  }
  return runs;
}

// Only used by unit tests (please change this comment if no longer true)
export function groupRuns(grouping: Grouping, runs: RunTypes.Run[]) {
  const groupKey = grouping[0];
  if (groupKey == null) {
    return runs;
  }
  const groups = _.groupBy(runs, r => getValue(r, groupKey));
  return _.map(groups, (rs, name) => mergeRuns(rs, name));
}

export function addSearchFilter(
  filters: Filter<RunTypes.Key>,
  search: RunSetConfig['search'],
  grouping?: Grouping,
  customRunNames?: CustomRunNames
) {
  if (search.query.length === 0) {
    return filters;
  }
  // By default, enable regex search.
  // This is important for backwards compatibility in older reports.
  const isRegexEnabled = search.isRegex ?? true;
  const makeFilter = isRegexEnabled ? runRegexFilter : runContainsFilter;

  let searchFilter: Filter<RunTypes.Key>;
  if (grouping != null && isGroupedByGroup(grouping)) {
    searchFilter = makeFilter(search.query, 'run', 'group');
  } else {
    // create filters for run.name and run.displayName
    const filters = [
      makeFilter(search.query),
      makeFilter(search.query, 'run', 'displayName'),
    ];

    // search for matches in custom run names, if any are configured
    if (customRunNames) {
      for (const [realName, customName] of Object.entries(customRunNames)) {
        if (search.isRegex) {
          const regexp = new RegExp(search.query);
          if (regexp.test(customName)) {
            filters.push(runContainsFilter(realName));
          }
        } else if (customName.indexOf(search.query) > -1) {
          filters.push(runContainsFilter(realName));
        }
      }
    }

    // combine the filters
    searchFilter = Or(filters);
  }

  return And([filters, searchFilter]);
}

export function isGroupedByGroup(grouping: Grouping) {
  return (
    grouping.length > 0 &&
    _.isEqual(grouping[0], {
      section: 'run',
      name: 'group',
    })
  );
}

export function useFirstEnabledProject(query: Query) {
  // This is used to pick the first enabled project in query. Ideally queries
  // would query across all enabled projects, but that's harder to do. So the
  // project fields query, and history key queries use this to just query the
  // first project.
  let entityName = query.entityName;
  let projectName = query.projectName;
  let sort = query.sort;
  const rs = query.runSets?.find(s => s.enabled);
  const internalId = getProjectInternalId(rs?.project?.id) ?? '';

  const {data: projectNameFromInternalId} = useProjectNameFromInternalIdQuery({
    variables: {internalId},
    skip: rs?.project?.id == null,
  });

  if (query.runSets != null) {
    const enabledRunSets = query.runSets.find(s => s.enabled);
    if (enabledRunSets != null) {
      if (projectNameFromInternalId?.project?.name != null) {
        projectName = projectNameFromInternalId?.project?.name;
      } else if (enabledRunSets.projectName != null) {
        projectName = enabledRunSets.projectName || projectName;
      }
      if (enabledRunSets.entityName != null) {
        entityName = enabledRunSets.entityName || entityName;
      }

      sort = enabledRunSets.sort;
    }
  }
  return {entityName, projectName, sort};
}

export function sortToOrderString(sort: Sort): string {
  return sort.keys
    .map(({key, ascending}) => (ascending ? '+' : '-') + keyToServerPath(key))
    .join(',');
}
