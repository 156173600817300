import produce from 'immer';
import {minimatch} from 'minimatch';
import {useMemo} from 'react';

import {UseWandbConfigQueryResult} from '../../state/graphql/wandbConfigQuery';
import {RunsLinePlotConfig} from '../PanelRunsLinePlot/types';

/*
  // The DefinedMetric type is based on the protobuf message structure.
  // See https://github.com/wandb/wandb/blob/main/wandb/proto/wandb_internal.proto
  // 1: indicates a full metric name, defined in a wandb.define_metric() call
  // 2: indicates the glob metric name, defined in a wandb.define_metric() call
  // 5: if populated indicates the 1-indexed location of the base metric in the DefinedMetric array in the config
  // 6: an array of numbers, with `2` indicating `hidden=True`
  // was used as an argument to the SDK. Meaning the metric should not be plotted by default.
*/
export type DefinedMetric = {
  /** Metric name, if matched, apply options, or use the base metric as x axis */
  '1'?: string;
  /** Glob metric name, if a match use associated base metric as x axis */
  '2'?: string;
  /** 1-index of the base metric */
  '5'?: number;
  /** Options -- 2 indicates hidden, and should not create a panel by default */
  '6'?: number[];
};

function isHiddenDefinedMetric(definedMetric: DefinedMetric): boolean {
  return definedMetric['6']?.includes(2) ?? false;
}

export function useExtractDefinedMetricConfigs(
  wbConfigQuery: UseWandbConfigQueryResult
): Array<DefinedMetric> {
  // the defined metric configs are optionally found on the `m` key of the wandbConfig
  // as an array of dictionaries, indicating the defined metrics and relationship
  // between them.
  const definedMetricConfigs = useMemo(() => {
    if (wbConfigQuery.loading || wbConfigQuery.error) {
      return [];
    }
    return wbConfigQuery.wandbConfig?.[0]?.m ?? [];
  }, [wbConfigQuery]);
  return definedMetricConfigs;
}

export function getDefinedMetricMaps(definedMetrics: DefinedMetric[]): {
  keyMap: Record<string, string>;
  globs: Array<{glob: string; baseMetric: string}>;
  hiddenMetrics: Set<string>;
} {
  // creates 3 maps:
  // keyMap: maps metric name to x-axis metric name
  // globs: array of glob patterns and their base metric
  // hiddenMetrics: set of metric names that should be hidden
  const keyMap: Record<string, string> = {};
  const globs: Array<{glob: string; baseMetric: string}> = [];
  const hiddenMetrics: Set<string> = new Set();
  // Precompute a set of all metric names (O(n))
  const metricsSet = new Set(definedMetrics.map(dm => dm['1']));

  for (const dm of definedMetrics) {
    try {
      const metric = dm['1'];
      const globMetric = dm['2'];
      const baseMetricIndex = dm['5'];

      if (metric != null && isHiddenDefinedMetric(dm)) {
        hiddenMetrics.add(metric);
      }
      // rest of function maps metrics/glob metrics to base metrics
      if ((metric == null && globMetric == null) || baseMetricIndex == null) {
        continue;
      }

      const baseMetric = definedMetrics[baseMetricIndex - 1]['1'];
      if (baseMetric == null) {
        console.error(
          'Defined metric has no base metric',
          JSON.stringify(dm, null, 2)
        );
        continue;
      }
      if (globMetric != null) {
        globs.push({glob: globMetric, baseMetric: baseMetric});
        continue;
      }

      if (metric == null) {
        continue;
      }
      const replacedMetric = metric?.replace(/\\./g, '.');
      // potentially obsolete handling of escpaing dot characters. Leaving
      // in case this covers some undocumented edge case.
      if (metric.includes('\\.') && metricsSet.has(replacedMetric)) {
        keyMap[replacedMetric] = baseMetric;
      } else {
        keyMap[metric] = baseMetric;
      }
    } catch (e) {
      console.error(
        'Error mapping defined metric to base metric',
        JSON.stringify(dm, null, 2)
      );
    }
  }
  return {keyMap, globs, hiddenMetrics};
}

export function matchDefinedMetricGlobWithMetrics(
  definedMetricGlobs: Array<{glob: string; baseMetric: string}>,
  metric: string
) {
  for (const glob of definedMetricGlobs) {
    if (minimatch(metric, glob.glob)) {
      return glob.baseMetric;
    }
  }
  return undefined;
}

export function setPanelStartingXAxisFromGlobMatch(
  panelConfig: RunsLinePlotConfig,
  definedMetricGlobs: Array<{glob: string; baseMetric: string}>
) {
  if (definedMetricGlobs.length === 0) {
    return panelConfig;
  }

  if (panelConfig.metrics == null || panelConfig.metrics.length !== 1) {
    return panelConfig;
  }

  if (panelConfig.startingXAxis != null || panelConfig.xAxis != null) {
    return panelConfig;
  }

  const glob = matchDefinedMetricGlobWithMetrics(
    definedMetricGlobs,
    panelConfig.metrics[0]
  );
  if (glob == null) {
    return panelConfig;
  }
  return produce(panelConfig, draft => {
    draft.startingXAxis = glob;
  });
}
