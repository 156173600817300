import {useContext} from 'react';

import {envIsLocal} from '../../config';
import {
  useAccountUsingLaunchQuery,
  useEntityAgentsQuery,
} from '../../generated/graphql';
import {DEFAULT_ONPREM_ORGANIZATION_NAME} from '../../pages/OrgDashboard/OrgRoles/RolesDashboard';
import {useViewer} from '../../state/viewer/hooks';
import {useRampFlagAccountSelector} from '../../util/rampFeatureFlags';
import {AccountSelectorContext} from '../Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../Search/SearchNav/types';

enum LaunchAgentStatus {
  Running = 'RUNNING',
  Polling = 'POLLING',
  Killed = 'KILLED',
}

type accountLaunchActive = {
  isLaunchActive: boolean | undefined;
  loading: boolean;
  error: boolean;
};

export function useAccountOrganizationOrPersonalEntityUsingLaunch(): accountLaunchActive {
  const viewer = useViewer();
  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const shouldUseOnPremOrg = envIsLocal;
  const orgName = shouldUseOnPremOrg
    ? DEFAULT_ONPREM_ORGANIZATION_NAME
    : enableAccountSelector
    ? selectedAccount?.name
    : null;

  const {
    data: accountLaunchActiveData,
    loading: isAccountLaunchActiveLoading,
    error: accountLaunchActiveError,
  } = useAccountUsingLaunchQuery({
    variables: {name: orgName ?? ''},
    skip:
      orgName == null ||
      (!shouldUseOnPremOrg &&
        selectedAccount?.accountType === AccountType.Personal),
  });

  const {
    loading: personalEntityHasActiveLaunchAgentLoading,
    error: personalEntityHasActiveLaunchAgentError,
    hasActiveLaunchAgent: personalHasActiveLaunchAgent,
  } = useEntityHasActiveLaunchAgent(viewer?.username ?? null);
  const error =
    accountLaunchActiveError != null ||
    personalEntityHasActiveLaunchAgentError != null;
  return {
    loading:
      isAccountLaunchActiveLoading || personalEntityHasActiveLaunchAgentLoading,
    error,
    isLaunchActive:
      accountLaunchActiveData?.organization?.isLaunchActive ??
      personalHasActiveLaunchAgent,
  };
}

export function useEntityHasActiveLaunchAgent(entityName: string | null) {
  const entityLaunchAgents = useEntityAgentsQuery({
    variables: {entityName: entityName ?? ''},
    skip: entityName == null,
  });
  const hasActiveLaunchAgent =
    entityLaunchAgents.data?.entity?.launchProject?.launchAgents.some(
      agent =>
        agent.agentStatus === LaunchAgentStatus.Running ||
        agent.agentStatus === LaunchAgentStatus.Polling
    );
  return {
    loading: entityLaunchAgents.loading,
    error: entityLaunchAgents.error,
    hasActiveLaunchAgent,
  };
}
