import React, {useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox, Dropdown, Form, TextArea} from 'semantic-ui-react';

import {backendHost} from '../config';
import {
  AccessRequestDurationType,
  AccessRequestRoleListType,
  AccessRequestRoleType,
  EIGHT_HOURS,
  FOUR_HOURS,
  ONE_HOUR,
  REQUEST_DURATION_KEY,
  REQUEST_REASON_KEY,
  REQUEST_ROLES_KEY,
  TWELVE_HOURS,
  TWO_HOURS,
  WANDB_ADMIN_ROLE,
} from '../util/teleportAccessRequest';
import {calculateTtl} from './Search/SearchNav/useTeleportAccessRequests';
import * as S from './TeleportAccessRequestModal.styles';
import {
  WBModal,
  WBModalActions,
  WBModalButton,
  WBModalContent,
  WBModalHeader,
} from './WBModal.styles';

interface TeleportAccessRequestModalProps {
  onClose: () => void;
  approvedTeleportAccessRequests: Record<string, string>;
  isFetchingApprovedTeleportAccessRequests: boolean;
}

const ACCESS_REQUEST_DURATION_OPTIONS = [
  {text: '1h', value: ONE_HOUR},
  {text: '2h', value: TWO_HOURS},
  {text: '4h', value: FOUR_HOURS},
  {text: '8h', value: EIGHT_HOURS},
  {text: '12h', value: TWELVE_HOURS},
];

const ACCESS_REQUEST_ROLE_OPTIONS: {
  text: string;
  value: AccessRequestRoleType;
}[] = [{text: 'wandb-admin', value: WANDB_ADMIN_ROLE}];

const TeleportAccessRequestModal: React.FC<TeleportAccessRequestModalProps> = ({
  onClose,
  approvedTeleportAccessRequests,
  isFetchingApprovedTeleportAccessRequests,
}) => {
  const hasApprovedTeleportAccessRequest =
    Object.keys(approvedTeleportAccessRequests).length > 0;

  const initialAccessRequestDuration: AccessRequestDurationType = TWELVE_HOURS;
  const [accessRequestDuration, setAccessRequestDuration] =
    useState<AccessRequestDurationType>(initialAccessRequestDuration);

  const initialAccessRequestRoles: AccessRequestRoleListType = [];
  const [accessRequestRoles, setAccessRequestRoles] =
    useState<AccessRequestRoleListType>(initialAccessRequestRoles);

  const [accessRequestReason, setAccessRequestReason] = useState<string>('');

  const handleCheckboxChange = (role: AccessRequestRoleType) =>
    setAccessRequestRoles(prevRoles => {
      if (prevRoles.includes(role)) {
        return prevRoles.filter(r => r !== role);
      }
      return [...prevRoles, role];
    });

  const onSubmit = async (
    accessRequestRoles: AccessRequestRoleListType,
    accessRequestDuration: AccessRequestDurationType,
    accessRequestReason: String
  ) => {
    onClose();
    const response = await fetch(backendHost(`/teleport/access_requests`), {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        [REQUEST_ROLES_KEY]: accessRequestRoles,
        [REQUEST_DURATION_KEY]: accessRequestDuration,
        [REQUEST_REASON_KEY]: accessRequestReason,
      }),
      credentials: 'include',
    });
  };

  return (
    <WBModal open onClose={onClose}>
      <WBModalHeader>Request Access for Teleport Roles</WBModalHeader>
      <WBModalContent>
        {!isFetchingApprovedTeleportAccessRequests && (
          <S.AccessRequestApprovedRolesSection>
            <h3>Approved Roles</h3>
            {hasApprovedTeleportAccessRequest && (
              <div className="header-row">
                <span className="header-role-label">Role</span>
                <span className="header-ttl-value">Expires in</span>
              </div>
            )}
            {hasApprovedTeleportAccessRequest &&
              Object.entries(approvedTeleportAccessRequests).map(
                ([role, timestamp]) => (
                  <div key={role}>
                    <span className="role-label">{role}</span>
                    <span className="ttl-value">{calculateTtl(timestamp)}</span>
                  </div>
                )
              )}

            {!hasApprovedTeleportAccessRequest && (
              <div className="no-values-row">
                <span className="no-values">No approved roles found</span>
              </div>
            )}
          </S.AccessRequestApprovedRolesSection>
        )}
        <S.AccessRequestRoleSection>
          <h3>Request Roles</h3>

          <div className="header-row">
            <span className="header-selected-label">Selected</span>
            <span className="header-role-value">Role</span>
          </div>

          {ACCESS_REQUEST_ROLE_OPTIONS.map(option => (
            <div key={option.value}>
              <span className="selected-label">
                <Checkbox
                  checked={accessRequestRoles.includes(option.value)}
                  onChange={() =>
                    handleCheckboxChange(option.value as AccessRequestRoleType)
                  }
                />
              </span>
              <span className="role-value">{option.text}</span>
            </div>
          ))}
        </S.AccessRequestRoleSection>
        <S.AccessRequestDurationSection>
          <h3>Request Duration</h3>
          <Dropdown
            options={ACCESS_REQUEST_DURATION_OPTIONS}
            value={accessRequestDuration}
            direction="left"
            onChange={(e, data) => {
              if (data?.value == null) {
                return;
              }
              setAccessRequestDuration(data.value as AccessRequestDurationType);
            }}></Dropdown>
        </S.AccessRequestDurationSection>
        <S.AccessRequestReasonSection>
          <h3>Request Reason</h3>
          <Form>
            <TextArea
              placeholder="Enter your request reason (max 2000 characters)"
              value={accessRequestReason}
              onChange={(e, {value}) => setAccessRequestReason(String(value))}
              maxLength={2000}
            />
          </Form>
        </S.AccessRequestReasonSection>
        <S.SectionSubheader>
          If your access request is not auto-approved, please try logging into
          Teleport via Okta. Then, try again. Due to a change in Teleport user
          provisioning, users that logged into Teleport before 2/13/2025 may
          need to log into Teleport again.
        </S.SectionSubheader>
      </WBModalContent>
      <WBModalActions>
        <WBModalButton content="Cancel" onClick={onClose} />
        <WBModalButton
          content="Submit"
          onClick={() =>
            onSubmit(
              accessRequestRoles,
              accessRequestDuration,
              accessRequestReason
            )
          }
          disabled={
            accessRequestRoles.length === 0 || accessRequestReason.trim() === ''
          }
          primary
        />
      </WBModalActions>
    </WBModal>
  );
};

export default TeleportAccessRequestModal;
