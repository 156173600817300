import * as _ from 'lodash';

import {LinesFromDataProps} from '../../components/PanelRunsLinePlot/types';
import {RunsData} from '../../containers/RunsDataLoader';
import * as ColorUtil from '../../util/colors';
import {legendTemplateToFancyLegendProps, parseLegendTemplate} from '../legend';
import {getCustomRunName} from '../runs';
import {Key} from '../runTypes';
import {convertAreaToPercentArea, convertLinesToArea} from './areaCharts';
import {getBucketSpec} from './buckets/getBucketSpec';
import {
  getLinesColoredByMetric,
  getLinesColoredByRun,
  markLinesByColor,
} from './colors';
import {
  histogramFromHistory,
  isHistoryArray,
  isHistoryHistogram,
} from './history';
import {LineResult, lineResultsForRunset} from './lineResultsForRunset';
import {prettifyMetricName} from './prettifyMetricName';
import * as Time from './time';
import {Line, RunSetInfo} from './types';

type LinesFromDataResult = [Line[], number];

function getLines(
  runs: RunsData['filtered'],
  histories: RunsData['histories'],
  config: LinesFromDataProps &
    Required<Pick<LinesFromDataProps, 'expressions'>>, // ensure defaulting happens a level up on expressions
  newConfig: {
    expressionKeys: Key[];
  } = {
    expressionKeys: [],
  }
): LinesFromDataResult {
  const {entityName, projectName} = config;

  const rootUrl =
    entityName != null && projectName != null
      ? `/${entityName}/${projectName}/runs`
      : null;
  const useMedian = config.groupLine === 'median';

  let lineResults: LineResult[];
  const runSetByID: {[id: string]: RunSetInfo} = {};
  if (config.runSets != null) {
    // When we have a runset (everywhere but the run page), map over the runsets.
    lineResults = config.runSets.map(runset =>
      lineResultsForRunset({
        runs,
        histories,
        config,
        newConfig,
        runSetInfo: runset,
      })
    );
    config.runSets.forEach(rs => (runSetByID[rs.id] = rs));
  } else {
    // Else we're in the run page and there's no runset.
    lineResults = [
      lineResultsForRunset({
        runs,
        histories,
        config,
        newConfig,
        runSetInfo: undefined,
      }),
    ];
  }

  // Set legends for charts
  // TODO(axel): This entire block goes deep into lineResults and mutates data.
  // We should move to more immutability so that the value of lineResults is more consistent.
  lineResults.forEach(lr => {
    const lineGroups = lr.linesByKeyAndMetricName;
    // a line group has a main line along with possibly minmax, stddev and smoothed
    lineGroups.forEach((linesGroupByMetric: Line[][]) => {
      linesGroupByMetric.forEach(lineGroup => {
        const stddevLine = lineGroup.find(line => line.aggType === 'stddev');
        const minmaxLine = lineGroup.find(line => line.aggType === 'minmax');
        const stderrLine = lineGroup.find(line => line.aggType === 'stderr');

        const mainLine = lineGroup.find(
          line => !line.aux // line.aggType == null || line.aggType === 'mean'
        );

        if (mainLine?.run != null) {
          const customRunName = getCustomRunName(
            mainLine.run.name,
            lr.groupKeys.length > 0,
            config.customRunNames
          );
          const metricName = prettifyMetricName(mainLine.metricName ?? '');
          mainLine.title = parseLegendTemplate(
            config.getLegendTemplateByRunsetId(mainLine.run.runsetInfo.id),
            true,
            mainLine.run,
            lr.groupKeys,
            metricName,
            customRunName
          );
          const runSet = runSetByID[mainLine.run.runsetInfo.id];
          const runRootURL =
            runSet != null
              ? `/${runSet.entityName ?? entityName}/${
                  runSet.projectName ?? projectName
                }/runs`
              : null;
          mainLine.fancyTitle = legendTemplateToFancyLegendProps(
            config.getLegendTemplateByRunsetId(mainLine.run.runsetInfo.id),
            mainLine.run,
            lr.groupKeys,
            metricName,
            runRootURL ?? rootUrl ?? undefined,
            customRunName
          );
          mainLine.stderrLine = stderrLine;

          mainLine.stddevLine = stddevLine;
          mainLine.minmaxLine = minmaxLine;
        }

        // LB Should clean up this logic into pipeline steps
        if (
          config.groupLine === 'min' &&
          minmaxLine != null &&
          mainLine != null
        ) {
          mainLine.data = minmaxLine.data.map(point => {
            return {x: point.x, y: point.y0 || 0};
          });
        } else if (
          config.groupLine === 'max' &&
          minmaxLine != null &&
          mainLine != null
        ) {
          mainLine.data = minmaxLine.data.map(point => {
            return {x: point.x, y: point.y};
          });
        }

        // set all the secondary lines to hidden
        if (stddevLine != null) {
          stddevLine.hidden = true;
        }
        if (minmaxLine != null) {
          minmaxLine.hidden = true;
        }
        if (stderrLine != null) {
          stderrLine.hidden = true;
        }

        // unhide based on groupArea
        if (config.groupArea === 'minmax') {
          if (minmaxLine != null) {
            minmaxLine.hidden = false;
          }
        }
        if (config.groupArea === 'stddev') {
          if (stddevLine != null) {
            stddevLine.hidden = false;
          }
        }
        if (config.groupArea === 'stderr') {
          if (stderrLine != null) {
            stderrLine.hidden = false;
          }
        }
      });
    });
    lr.linesByKeyAndMetricName = lineGroups.map(lgKeyMetric =>
      lgKeyMetric.map(lg => lg.filter(l => !l.hidden))
    );
  });

  const lineCount = _.sum(lineResults.map(lr => lr.lineCount));

  // TODO(axel): This entire block goes deep into lineResults and mutates data.
  // We should move to more immutability so that the value of lineResults is more consistent.
  if (
    config.xAxis === '_runtime' ||
    (config.xAxis === '_absolute_runtime' && config.xExpression == null) // ||
    // config.xAxis === '_timestamp'
  ) {
    /**
     * Scale horizontally based on timestep: this looks at the lines,  claculates what kind of scaling factor "seconds", "hours", etc... and then mutates each line
     */
    Time.convertSecondsToTimestep(
      // this is super gross - when you use a method like .flatMap or .flat you get a new array returned
      // if you operate on the elements of that array with something like `forEach` you're still mutating
      // values referenced by the original array, so:
      // ```
      // const lines = [ [{x: 1}, {x: 2} ],[ {x: 1}, {x: 2} ],[ {x: 1}, {x: 2} ] ]
      // lines.flat().forEach(p => p.x = p.x * 2)
      // ```
      // will muatate the data in `lines`
      lineResults.flatMap(lr => lr.linesByKeyAndMetricName).flat(2),
      // while zooming we don't want to change our timestep
      config.zoomTimestep ?? undefined
    );
  }

  const coloredInLines = config.singleRun
    ? // In a singleRun plot we assign colors to all the metrics
      getLinesColoredByMetric(lineResults)
    : // In a multi run plot we want each run to have a different color, But the metrics all have the same colors
      getLinesColoredByRun(
        lineResults,
        config.colorEachMetricDifferently,
        config.customRunColors,
        config.semanticLegendSettings
      );

  let lines: Line[] = [];
  // Extract 'limit' (equivalent to maxRuns) lines from the results.
  let linesRemaining: number =
    config.limit * (config.yAxis.length + config.expressions.length);
  for (const groupedLines of _.flatten(coloredInLines)) {
    if (linesRemaining <= 0) {
      break;
    }
    lines.push(...groupedLines);
    linesRemaining -= groupedLines.filter(line => !line.aux).length;
  }

  // Filter out original lines after smoothing, if applicable.
  // only filter out the original if there's a non-zero smoothing value
  // otherwise the actual line will get dropped and there won't be a smoothed
  // line to render
  if (!config.showOriginalAfterSmoothing && config.smoothingParam > 0) {
    lines = lines.filter(line => line.smoothed);
  }

  markLinesByColor(lines.filter(l => !l.aux));

  // make stacked area or percent area charts
  if (
    lines.length > 0 &&
    (config.plotType === 'stacked-area' || config.plotType === 'pct-area')
  ) {
    // Crazy way of dealing with the fact that the samples don't line up
    // we make some buckets and compute an average value across the buckets
    //
    // We calculate these buckets for all lines together since there are more
    // interactions between the lines in a stacked/area charts than a regular line plot.
    const bucketSpec = getBucketSpec(lines);
    const xToSum: {[key: number]: number} = {};
    // Make it a stacked area chart
    lines = convertLinesToArea(lines, {
      buckets: bucketSpec,
      expressionKeys: newConfig.expressionKeys,
      useMedian,
      xToSum,
    });

    if (config.plotType === 'pct-area') {
      lines = convertAreaToPercentArea(lines, xToSum);
    }
  }

  return [lines, lineCount];
}

/**
 * The purpose of this function is to help PanelRunsLinePlot take the
 * filtered runs data returned by the server query and turn it into
 * Line data that the LinePlot component can use to visualize. This
 * functionality has expanded a lot from tons of user feature requests
 * and could really use a refactor.
 *
 * WARNING: The client-side grouping logic is currently duplicated in
 * components/Export.historyQueryToTable. When updating the grouping
 * logic in either place, make sure the other stays in sync.
 * */
export function getLinesFromData(
  /**
   * WARNING: Do not change the structure of this argument list!
   *
   * We use the second argument to memoize to reference compare
   * data, but deep compare everything in props. See the memoize
   * wrapped around this in the Graph component.
   */
  runs: RunsData['filtered'],
  histories: RunsData['histories'],
  config: LinesFromDataProps &
    Required<Pick<LinesFromDataProps, 'expressions'>>, // ensure defaulting happens a level up on expressions
  newConfig: {
    expressionKeys: Key[];
    // groupKeysByRunsetId: Record<string, Key[]>;
  } = {
    expressionKeys: [],
    // groupKeysByRunsetId: {},
  }
): LinesFromDataResult {
  const histogramResult = getLinesFromHistogramData(
    histories,
    config.xAxis,
    config.yAxis
  );
  if (histogramResult != null) {
    return histogramResult;
  }

  return getLines(runs, histories, config, newConfig);
}

// Check if we are plotting a histogram in which case we have different
// logic.  We can only plot a histogram for a single run and a single
// metric.
// We may want to make histogram its own panel type.
function getLinesFromHistogramData(
  histories: RunsData['histories'],
  xAxis: string,
  yAxis: string[]
): LinesFromDataResult | null {
  if (!isHistogram(histories, yAxis)) {
    return null;
  }
  const line: Line = histogramFromHistory(
    histories.data[0].history,
    xAxis,
    yAxis[0]
  );
  line.color = ColorUtil.color(0);
  return [[line], 1];
}

function isHistogram(
  histories: RunsData['histories'],
  yAxis: string[]
): boolean {
  if (yAxis.length !== 1) {
    return false;
  }
  return (
    histories.data.length > 0 &&
    (isHistoryArray(histories.data[0].history, yAxis[0]) ||
      isHistoryHistogram(histories.data[0].history, yAxis[0]))
  );
}
