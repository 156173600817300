import * as d3 from 'd3';
import {useMemo} from 'react';

import {Line, Point} from '../../util/plotHelpers/types';
import {ExcludeOutliersValues} from '../WorkspaceDrawer/Settings/types';
import {areOutliersExcluded} from '../WorkspaceDrawer/Settings/utils';
import {DomainMaybe} from './types';

/**
 * This function calculates the minimum and maximum values from an array of points.
 *
 * PERFORMANCE NOTE: This implementation avoids creating intermediate arrays or objects
 * that would cause excess garbage collection. When working with large datasets,
 * creating temporary arrays or using methods like map/filter/reduce can lead to
 * significant memory pressure and trigger frequent garbage collection cycles,
 * which negatively impacts performance. Instead, we use direct iteration with
 * for loops and in-place variable updates to minimize memory allocations.
 */
export function getBoundaryValues(
  points: Point[],
  config: {ignoreInfinity?: boolean}
) {
  if (points.length === 0) {
    return {
      min: undefined,
      max: undefined,
    };
  }

  let min = Infinity;
  let max = -Infinity;
  let foundValidNumber = false;
  let point, y, y0;

  for (let i = 0; i < points.length; i++) {
    point = points[i];

    // Process y value directly without creating unnecessary variables
    y = point.y;
    if (y !== undefined) {
      if (config.ignoreInfinity ? Number.isFinite(y) : !isNaN(y)) {
        foundValidNumber = true;
        if (y < min) {
          min = y;
        }
        if (y > max) {
          max = y;
        }
      }
    }

    // Process y0 value directly
    y0 = point.y0;
    if (y0 !== undefined) {
      if (config.ignoreInfinity ? Number.isFinite(y0) : !isNaN(y0)) {
        foundValidNumber = true;
        if (y0 < min) {
          min = y0;
        }
        if (y0 > max) {
          max = y0;
        }
      }
    }
  }

  // Avoid creating unnecessary object properties by using a direct return
  return foundValidNumber ? {min, max} : {min: undefined, max: undefined};
}

export function calculateDomain({
  filteredLines,
  excludeOutliers,
  isFullFidelityMode,
  isHeatmap,
  userXDomain,
  userYDomain,
}: {
  filteredLines: Line[];
  excludeOutliers?: ExcludeOutliersValues;
  isFullFidelityMode: boolean;
  isHeatmap: boolean;
  userXDomain: DomainMaybe | undefined;
  userYDomain: DomainMaybe | undefined;
}) {
  const retXDomain: DomainMaybe =
    userXDomain != null ? [...userXDomain] : [null, null];
  const retYDomain: DomainMaybe =
    userYDomain != null ? [...userYDomain] : [null, null];

  if (
    retXDomain[0] != null &&
    retXDomain[1] != null &&
    retYDomain[0] != null &&
    retYDomain[1] != null
  ) {
    return [retXDomain, retYDomain] as const;
  }

  if (filteredLines.length === 0) {
    return [retXDomain, retYDomain] as const;
  }

  const pointFilter = ({x}: Point): boolean => {
    if (userXDomain != null) {
      return (
        (userXDomain[0] == null || x >= userXDomain[0]) &&
        (userXDomain[1] == null || x <= userXDomain[1])
      );
    }
    return true;
  };

  // Optimized to avoid intermediate array creation
  const filteredPoints = [];
  const filteredNaNPoints = [];

  for (let i = 0; i < filteredLines.length; i++) {
    const line = filteredLines[i];
    // Add filtered data points
    if (line.data) {
      for (let j = 0; j < line.data.length; j++) {
        const point = line.data[j];
        if (pointFilter(point)) {
          filteredPoints.push(point);
        }
      }
    }

    // Add filtered NaN points
    if (line.nanPoints) {
      for (let j = 0; j < line.nanPoints.length; j++) {
        const point = line.nanPoints[j];
        if (pointFilter(point)) {
          filteredNaNPoints.push(point);
        }
      }
    }
  }

  // Calculate min/max x values directly without creating intermediate arrays
  let xMinRaw =
    filteredPoints.length > 0 || filteredNaNPoints.length > 0
      ? Infinity
      : undefined;
  let xMaxRaw =
    filteredPoints.length > 0 || filteredNaNPoints.length > 0
      ? -Infinity
      : undefined;

  // Process filteredPoints
  for (let i = 0; i < filteredPoints.length; i++) {
    const x = filteredPoints[i].x;
    if (x < xMinRaw!) {
      xMinRaw = x;
    }
    if (x > xMaxRaw!) {
      xMaxRaw = x;
    }
  }

  // Process filteredNaNPoints
  for (let i = 0; i < filteredNaNPoints.length; i++) {
    const x = filteredNaNPoints[i].x;
    if (x < xMinRaw!) {
      xMinRaw = x;
    }
    if (x > xMaxRaw!) {
      xMaxRaw = x;
    }
  }

  let xMin = xMinRaw ?? 0;
  let xMax = xMaxRaw ?? 0;

  // TODO(aswanberg): Consider adding padding like tensorboard does.
  let yMin = 0;
  let yMax = 0;

  if (areOutliersExcluded(excludeOutliers) && !isFullFidelityMode) {
    // Use direct loops and avoid creating intermediate arrays wherever possible

    // First, collect all y-values for quantiles
    const minValues: number[] = [];
    const maxValues: number[] = [];

    // Populate arrays directly using loops
    for (let i = 0; i < filteredPoints.length; i++) {
      const point = filteredPoints[i];

      // Handle min value
      if (point.y !== undefined) {
        if (point.y0 !== undefined) {
          minValues.push(Math.min(point.y0, point.y));
        } else {
          minValues.push(point.y);
        }
      } else if (point.y0 !== undefined) {
        minValues.push(point.y0);
      }

      // Handle max value
      if (point.y !== undefined) {
        if (point.y0 !== undefined) {
          maxValues.push(Math.max(point.y0, point.y));
        } else {
          maxValues.push(point.y);
        }
      } else if (point.y0 !== undefined) {
        maxValues.push(point.y0);
      }
    }

    // Sort in-place for quantile calculation
    minValues.sort((a, b) => a - b);
    maxValues.sort((a, b) => a - b);

    // Get quantile bounds
    const lowerBound = d3.quantile(minValues, 0.05) || -Infinity;
    const upperBound = d3.quantile(maxValues, 0.95) || Infinity;

    // Find min and max directly within bounds
    let hasFoundValidMin = false;
    let hasFoundValidMax = false;

    // Process min values
    for (let i = 0; i < minValues.length; i++) {
      const val = minValues[i];
      if (val >= lowerBound) {
        if (!hasFoundValidMin) {
          yMin = val;
          hasFoundValidMin = true;
        } else {
          yMin = Math.min(yMin, val);
        }
      }
    }

    // Process max values
    for (let i = 0; i < maxValues.length; i++) {
      const val = maxValues[i];
      if (val <= upperBound) {
        if (!hasFoundValidMax) {
          yMax = val;
          hasFoundValidMax = true;
        } else {
          yMax = Math.max(yMax, val);
        }
      }
    }

    // Default to 0 if no valid values found
    if (!hasFoundValidMin) {
      yMin = 0;
    }
    if (!hasFoundValidMax) {
      yMax = 0;
    }
  } else {
    // Use getBoundaryValues which is already optimized
    const {min, max} = getBoundaryValues(filteredPoints, {
      ignoreInfinity: true,
    });

    yMin = min ?? 0;
    yMax = max ?? 0;
  }

  const xRange = xMax - xMin;
  const yRange = yMax - yMin;

  if (xRange === 0) {
    if (isHeatmap) {
      // ridiculous hacky fix to single step heatmap
      xMin = -0.49998;
      xMax = 0.49999;
    } else {
      xMin -= Math.abs(xMin);
      xMax += xMax === 0 ? 1 : Math.abs(xMax);
    }
  }

  // arbitrary epsilon to account for centering constant lines
  // javascript min value is 2^-1074 so this should be safe
  if (yRange < 1e-20) {
    yMin -= yMax === 0 ? 2 : Math.abs(yMin);
    yMax += yMax === 0 ? 2 : Math.abs(yMax);
  }

  retXDomain[0] = retXDomain[0] ?? xMin;
  retXDomain[1] = retXDomain[1] ?? xMax;
  retYDomain[0] = retYDomain[0] ?? yMin;
  retYDomain[1] = retYDomain[1] ?? yMax;

  return [retXDomain, retYDomain] as const;
}

export function useCalculatedDomain({
  excludeOutliers,
  filteredLines,
  isFullFidelityMode,
  isHeatmap,
  userXDomain,
  userYDomain,
}: {
  filteredLines: Line[];
  excludeOutliers?: ExcludeOutliersValues;
  isFullFidelityMode: boolean;
  isHeatmap: boolean;
  userXDomain: DomainMaybe | undefined;
  userYDomain: DomainMaybe | undefined;
}) {
  return useMemo(() => {
    return calculateDomain({
      excludeOutliers,
      filteredLines,
      isFullFidelityMode,
      isHeatmap,
      userXDomain,
      userYDomain,
    });
  }, [
    excludeOutliers,
    filteredLines,
    isFullFidelityMode,
    isHeatmap,
    userXDomain,
    userYDomain,
  ]);
}
