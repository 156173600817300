import {useEffect} from 'react';

import {useBucketedData} from '../bucketedData/useBucketedData';
import {usePanelZoom2} from './PanelZoomContext2';
import {isMetricKeyAbsoluteRuntime} from './utils';

/**
 * This is an escape hatch to send the history up to PanelZoomContext. This is needed because _absolute_runtime is a derived metric and we cannot query the API directly on this metric. We need to translate the query back to step values like we do for zooming in sampling mode which means looking through the current history data to try to find bounding points outside the zoom so that we can grab their step values.
 */
export const useZoomHistoryData = (
  xAxisKey: string,
  data: ReturnType<typeof useBucketedData>['data']
) => {
  const {setHistoryData} = usePanelZoom2();

  useEffect(() => {
    if (isMetricKeyAbsoluteRuntime(xAxisKey) && data) {
      const history = data.histories.data.flatMap(run => {
        return run.history.filter(historyPerMetric => {
          const matches =
            historyPerMetric[0] &&
            isFinite(historyPerMetric[0]._runtimeLast) &&
            isFinite(historyPerMetric[0]._stepLast);
          return matches;
        });
      });
      setHistoryData(history.flat());
    }
  }, [data, xAxisKey, setHistoryData]);
};
