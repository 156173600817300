import {createAction} from 'typesafe-actions';

import {OrganizedSettings} from '../../../components/PanelBank/types';
import {
  LinePlotSettings,
  OrganizationPrefix,
  RunColorOptions,
  WorkspaceLayoutSettings,
} from '../../../components/WorkspaceDrawer/Settings/types';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import {Ref as WorkspaceSettingsRef} from './types';

// Updating setting and changes to view spec must be done in a single action so both can be undone together
export const updateAutoOrganizePrefix = createAction(
  '@view/workspaceSettings/updateAutoOrganizePrefix',
  action =>
    (
      workspaceSettingsRef: WorkspaceSettingsRef,
      panelBankConfigRef: PanelBankConfigTypes.Ref,
      autoOrganizePrefix: OrganizationPrefix | undefined
    ) =>
      action({
        workspaceSettingsRef,
        panelBankConfigRef,
        autoOrganizePrefix,
      })
);

export const updateAutoOrganizePrefixUndo = createAction(
  '@view/workspaceSettings/updateAutoOrganizePrefixUndo',
  action =>
    (
      workspaceSettingsRef: WorkspaceSettingsRef,
      panelBankConfigRef: PanelBankConfigTypes.Ref,
      sectionRefs: PanelBankSectionConfigTypes.Ref[],
      autoOrganizePrefix: OrganizationPrefix | undefined
    ) =>
      action({
        workspaceSettingsRef,
        panelBankConfigRef,
        sectionRefs,
        autoOrganizePrefix,
      })
);

// These are "patch" actions. We're just updating parts of the setting.
export const updateWorkspaceLayoutSettings = createAction(
  '@view/workspaceSettings/layout/update',
  action =>
    (ref: WorkspaceSettingsRef, settings: Partial<WorkspaceLayoutSettings>) =>
      action({ref, settings})
);
export const updateLinePlotWorkspaceSettings = createAction(
  '@view/workspaceSettings/linePlot/update',
  action => (ref: WorkspaceSettingsRef, settings: Partial<LinePlotSettings>) =>
    action({ref, settings})
);

export const updateShouldAutoGeneratePanels = createAction(
  '@view/workspaceSettings/updateShouldAutoGeneratePanels',
  action =>
    (
      workspaceSettingsRef: WorkspaceSettingsRef,
      panelBankConfigRef: PanelBankConfigTypes.Ref,
      value: WorkspaceLayoutSettings['shouldAutoGeneratePanels']
    ) =>
      action({workspaceSettingsRef, panelBankConfigRef, value})
);

// This is a "put" action. We're replacing the entire setting. This is useful for undo.
export const setWorkspaceSettings = createAction(
  '@view/workspaceSettings/set',
  action => (ref: WorkspaceSettingsRef, settings: Partial<OrganizedSettings>) =>
    action({ref, settings})
);

export const updateSemanticLegendRunColorOptions = createAction(
  '@view/workspaceSettings/updateSemanticLegendRunColorOptions',
  action =>
    (
      ref: WorkspaceSettingsRef,
      semanticLegendRunColorOptions: RunColorOptions
    ) =>
      action({ref, semanticLegendRunColorOptions})
);
