import {Analytics} from '../../services/analytics/Analytics';
import {safeLocalStorage} from '../localStorage';

/**
 * Analytics page events are tracked based on changes in route - see the
 * callsite for this method - currently in RouteWithLayout.tsx.
 * Unless you are on a route in the site that doesn't go through RouteWithLayout,
 * you probably shouldn't be calling this so that we don't duplicate page()
 * analytics events
 */
export function trackPage(properties: object, options: object) {
  // fetch pre-redirect analytics and only consume them if we have
  // analytics available
  const raw = safeLocalStorage.getItem('preRedirectAttribution');
  if (raw && window.analytics?.page != null) {
    try {
      const attrs = JSON.parse(raw); // try catch for parse
      properties = {referrer: attrs.referrer, ...properties};
      safeLocalStorage.removeItem('preRedirectAttribution');
    } catch (err) {
      // remove unparseable item
      safeLocalStorage.removeItem('preRedirectAttribution');
    }
  }

  window.analytics?.page?.(properties, options);
}

/**
 * Does an external redirect while preserving relevant analytics events
 * that would be lost once the user returns back to the page. These
 * properties are then served the next time trackPage() is invoked.
 *
 * This is CRITICAL for first-touch analytics, which will often
 * lead an account-less user to a page unauthenticated, triggering
 * a redirect that would otherwise lose referrer information on
 * how the user was led to the page initially.
 */
export function externalRedirect(redirectLink: string) {
  // don't override existing attribution, first touch is most important!
  if (safeLocalStorage.getItem('preRedirectAttribution') == null) {
    safeLocalStorage.setItem(
      'preRedirectAttribution',
      JSON.stringify({
        referrer: document.referrer,
      })
    );
  }
  // eslint-disable-next-line wandb/no-unprefixed-urls
  window.location.href = redirectLink;
}

/** @deprecated
 * We are not removing this function yet, but its usage has been replaced by the
 * route based calls to trackPage.
 * We can remove this call (and the places in code that call it) once we've verified
 * that we are getting proper pageview tracking through trackPage
 */
export function trackPageViewed(page: string, tab?: string) {
  window.analytics?.page(page, {tab});
}

export function trackBreadcrumbClicked(destination: string) {
  Analytics.track('Top nav breadcrumb clicked', {destination});
}

export function trackModelRegistryClicked(location: string) {
  Analytics.track('Model registry clicked', {
    location,
  });
}

export function trackGlobalRegistryClicked(location: string) {
  Analytics.track('Global registry clicked', {
    location,
  });
}

export function trackProfileMenuClicked(location: string) {
  Analytics.track('Profile menu clicked', {
    location,
  });
}

export function trackAllProjectsClicked(location: string) {
  Analytics.track('All projects clicked', {
    location,
  });
}

export function trackLaunchClicked(location: string) {
  Analytics.track('Launch clicked', {
    location,
  });
}

export function trackModelRegistryAccessControlsInteraction(opts: {
  [key: string]: any;
}) {
  Analytics.track('Model registry access controls interaction', opts);
}

export function trackCreateTriggerClicked(location: string) {
  Analytics.track('Create Trigger button clicked', {location});
}

export function trackLaunchConfigUpdated(opts: {[key: string]: any}) {
  Analytics.track('Trigger config updated', {opts});
}

export function trackBannerButtonClicked(opts: {[key: string]: any}) {
  Analytics.track('Banner Button Clicked', {opts});
}
