import {ToastContainer} from '@wandb/weave/common/components/elements/Toast';
import _ from 'lodash';
import React from 'react';
import {Redirect, Switch} from 'react-router';

import AddEmailAlert from '../components/AddEmailAlert';
import {DisabledSubscriptionEnforcement} from '../components/DisabledSubscriptionEnforcement';
import {EndOfTrialEnforcement} from '../components/EndOfTrialEnforcement';
import {ExpiredSubscriptionEnforcement} from '../components/ExpiredSubscriptionEnforcement';
import {ExpiringSubscriptionNudgeBar} from '../components/ExpiringSubscriptionNudgeBar';
import {GlobalBannerContextProvider} from '../components/GlobalBanners';
import {BlockedRegionBannerGlobalList} from '../components/GlobalBanners/BlockedRegionBanners/BlockedRegionBanner';
import {WeaveApproachingLimitBannerGlobalList} from '../components/GlobalBanners/WeaveEnforcementBanners/WeaveApproachingLimitBanner';
import {WeaveExceededLimitBannerGlobalList} from '../components/GlobalBanners/WeaveEnforcementBanners/WeaveExceededLimitBanner';
import {WeaveTrialBannerGlobalList} from '../components/GlobalBanners/WeaveEnforcementBanners/WeaveTrialBanner';
import {GetLicenseNudgeBar} from '../components/LocalLicenseGetNudge';
import LocalLicenseNudgeBar from '../components/LocalLicenseNudgeBar';
import LocalNudgeBar from '../components/LocalNudgeBar';
import MessageOfTheDay from '../components/MessageOfTheDay';
import NavContextProvider from '../components/NavContextProvider';
import NoMatch from '../components/NoMatch';
import {AppSearch} from '../components/Search';
import SearchNav from '../components/Search/SearchNav';
import TrialNudgeBar from '../components/TrialNudgeBar';
import {UserWithNoOrgEnforcement} from '../components/UserWithNoOrgEnforcement';
import {envIsLocal} from '../config';
import {LoaderProxy} from '../containers/AppLoader';
import {DisabledSubscriptionWarningBanner} from '../pages/Billing/AccountSettings/WarningBanner/DisabledSubscriptionWarningBanner';
import {ServerBanners} from '../pages/Billing/AccountSettings/WarningBanner/DismissibleBanner';
import {PaymentFailedBanner} from '../pages/Billing/AccountSettings/WarningBanner/PaymentFailedBanner';
import {PersonalEntityDisabledBanner} from '../pages/Billing/AccountSettings/WarningBanner/PersonalEntityDisabledBanner';
import {UserLockdownWarningBanner} from '../pages/Billing/AccountSettings/WarningBanner/UserLockdownBanners/UserLockdownWarningBanner/UserLockdownWarningBanner';
import {useViewer} from '../state/viewer/hooks';
import {useConsentAwareAnalyticsInjection} from '../util/consentAwareAnalyticSetup';
import App from './../App';
import {ReachingResourceLimitWarningBannerGlobalList} from './../components/GlobalBanners/StorageEnforcementBanners/ReachingResourceLimitWarningBanner';
import {ResourceSubscriptionLimitExceededWarningBannerGlobalList} from './../components/GlobalBanners/StorageEnforcementBanners/ResourceSubscriptionLimitExceededWarningBanner';
import {ROUTES} from './routes';
import {ROUTES_DATA} from './routesData';
import {RouteWithLayout} from './RouteWithLayout';
import * as Types from './types';

export function makeRoutes(): Types.RouteData[] {
  // HAX: These checks ensure that the route mapping above is in sync with routes/routes.ts.
  // routes/routes.ts is shared between the main app and the host function.
  if (ROUTES_DATA.length !== ROUTES.length) {
    throw new Error(
      `mismatched routes length: ${ROUTES_DATA.length}, ${ROUTES.length}`
    );
  }
  for (let i = 0; i < ROUTES_DATA.length; i++) {
    const r = ROUTES_DATA[i];

    const rc = ROUTES[i];
    if (
      !_.isEqual(r.path, rc.path) ||
      ('redirect' in r && r.redirect !== rc.redirect) ||
      r.exact !== rc.exact
    ) {
      throw new Error(`mismatched routes: ${r.path}, ${rc.path}`);
    }
  }

  return ROUTES_DATA;
}

const MaybeLocalNudgeBar: React.FC = () => {
  const viewer = useViewer();
  return envIsLocal && viewer != null ? <LocalNudgeBar /> : <></>;
};

export const AppWithRoutes = () => {
  useConsentAwareAnalyticsInjection();

  return (
    <App>
      <GlobalBannerContextProvider>
        <NavContextProvider>
          <SearchNav />
          <AppSearch />
          <MessageOfTheDay />
          {envIsLocal && <GetLicenseNudgeBar />}
          {envIsLocal && <LocalLicenseNudgeBar />}
          <MaybeLocalNudgeBar />
          {!envIsLocal && <ExpiringSubscriptionNudgeBar />}
          {!envIsLocal && <ExpiredSubscriptionEnforcement />}
          {!envIsLocal && <DisabledSubscriptionEnforcement />}
          {!envIsLocal && <TrialNudgeBar />}
          {!envIsLocal && <BlockedRegionBannerGlobalList />}
          {!envIsLocal && <UserLockdownWarningBanner />}
          {envIsLocal && <PersonalEntityDisabledBanner />}
          {!envIsLocal && <ReachingResourceLimitWarningBannerGlobalList />}
          {!envIsLocal && (
            <ResourceSubscriptionLimitExceededWarningBannerGlobalList />
          )}
          {!envIsLocal && <DisabledSubscriptionWarningBanner />}
          {!envIsLocal && <PaymentFailedBanner />}
          {!envIsLocal && <WeaveApproachingLimitBannerGlobalList />}
          {!envIsLocal && <WeaveExceededLimitBannerGlobalList />}
          {!envIsLocal && <WeaveTrialBannerGlobalList />}
          <ServerBanners />
          {!envIsLocal && <EndOfTrialEnforcement />}
          {!envIsLocal && <UserWithNoOrgEnforcement />}
          <AddEmailAlert />
          <div className="main">
            <ToastContainer />
            <React.Suspense fallback={<LoaderProxy name="fallback-loader" />}>
              <Switch>
                {makeRoutes()
                  .filter(route =>
                    'condition' in route ? route.condition !== false : true
                  )
                  .map((route, i) => {
                    if ('redirect' in route) {
                      return (
                        <Redirect
                          key={i}
                          from={route.path}
                          to={route.redirect}
                        />
                      );
                    }
                    const {RouteComponent, ...rest} = route;
                    return <RouteComponent key={i} {...rest} />;
                  })}
                <RouteWithLayout routeName="NoMatch" component={NoMatch} />
              </Switch>
            </React.Suspense>
          </div>
        </NavContextProvider>
      </GlobalBannerContextProvider>
    </App>
  );
};
