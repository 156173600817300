import {getCookie} from '@wandb/weave/common/util/cookie';
import React, {ReactNode, useEffect} from 'react';
import {Route} from 'react-router';

import NoMatch from '../components/NoMatch';
import {startDatadogRumView} from '../util/datadog';
import {trackPage} from '../util/navigation';
import {isInIframe} from '../util/window';

const HOST_SESSION_ID_COOKIE = `host_session_id`;

export type RouteWithLayoutProps = {
  component: React.ComponentType<any>;
  routeName?: string;
  error?: any;
  allowIframes?: boolean;
  additionalProps?: {[prop: string]: any};
  [prop: string]: any;
};

// adds the Nav and Footer before and after the route component
export const RouteWithLayout: React.FC<RouteWithLayoutProps> = ({
  component: Component,
  routeName,
  error,
  allowIframes,
  additionalProps = {},
  ...rest
}) => {
  const preventIframe = isInIframe() && !allowIframes;
  return (
    <Route
      {...rest}
      key={routeName}
      render={routeProps => {
        return preventIframe ? (
          <NoMatch unrecoverable />
        ) : (
          <RouteNameWrapper
            routeName={routeName}
            tab={rest.computedMatch?.params?.tab ?? additionalProps.tab}>
            <Component error={error} {...routeProps} {...additionalProps} />
          </RouteNameWrapper>
        );
      }}
    />
  );
};

// This component is the main place to use the routeName property
// defined on the route.

//
// Design note: This seems like a complicated way to get routeName,
// but it's the simplest one I could find :) RouteWithLayout is responsible
// for defining the <Route>'s render() which is
// the only place where we have access to the Route and we know
// that the route is actually being used. useRouteMatch in theory
// provides this information as well, but it was not consistently
// returning the correct route when I tried it out. We would still
// need to define the routeName on the Routes in that solution as well,
// and we'd need a high level place to put the call, which would end
// up being... RouteWithLayout :)
const RouteNameWrapper = ({
  children,
  routeName,
  tab,
}: {
  children: ReactNode;
  routeName?: string;
  tab?: string;
}) => {
  const tabSuffix = tab ? `:${tab}` : '';
  useRouteTracking(routeName, tabSuffix, tab);
  usePageViewTracking(routeName, tab, tabSuffix);
  return <>{children}</>;
};

const usePageViewTracking = (
  routeName: string | undefined,
  tab: string | undefined,
  tabSuffix: string
) => {
  useEffect(() => {
    const options = {
      context: {
        hostSessionID: getCookie(HOST_SESSION_ID_COOKIE),
      },
    };

    trackPage(
      {
        routeName,
        tab,
        routeAndTab: routeName + tabSuffix,
        isNew: true,
      },
      options
    );
  }, [routeName, tab, tabSuffix]);
};

const useRouteTracking = (
  routeName: string | undefined,
  tabSuffix: string,
  tab: string | undefined
) => {
  useEffect(() => {
    const routeNameWithTab = routeName + tabSuffix;
    startDatadogRumView(routeNameWithTab);

    // Why does this need clean up? If the route we're going to
    // isn't a RouteWithLayout, it won't call updateProfilerRoute.
    // So, by defining a cleanup that clears the route name, we ensure
    // that the profiler is updated to not have a route name when the
    // user leaves the page.
    // There are few pages that don't use RouteWithLayout, so this is
    // mostly for future-proofing.
    return () => {
      startDatadogRumView(routeNameWithTab);
    };
  }, [routeName, tab, tabSuffix]);
};
