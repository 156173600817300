import {MatchParams} from '@wandb/weave/common/types/base';
import {decodeURIComponentSafe} from '@wandb/weave/common/util/url';
import {useMemo} from 'react';
import {match, matchPath, useLocation} from 'react-router';

import {
  ACADEMIC_APPLICATION_PATH,
  ACCOUNT_SETTINGS_PATHS,
  CREATE_TEAM_PATH,
  HISTORIC_USAGE_PATH,
  ONBOARDING_FLOW_PATH,
  ORG_SETTINGS,
  PROFILE_PATH,
  PROFILE_PROJECTS_PATH,
  PROFILE_REPORTS_PATH,
  PROFILE_STARS_PATH,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  STARTUP_PROGRAM,
  TRIAL_END_PATH,
  USAGE_PATH,
  USER_SETTINGS_PATH,
} from './paths';
import {ROUTES} from './routes';

export function useCurrentRouteMatchParams(): MatchParams {
  const currentRouteMatch = useCurrentRouteMatch();

  const params: MatchParams = useMemo(
    () => currentRouteMatch?.params ?? {},
    [currentRouteMatch]
  );
  const {projectName, artifactTypeName} = params;

  return useMemo(
    () => ({
      ...params,

      // HAX: the project name URI we get from react-router might be encoded.
      // Decode this URI component before using it.
      // If this happens again on another URI component (e.g. entity name), we
      // should do more general fix at the react-router layer.
      ...(projectName != null
        ? {projectName: decodeURIComponentSafe(projectName)}
        : {}),

      // If artifact type name is available, decode any '@' chars
      // for the correct artifact type name to display in the nav.
      ...(artifactTypeName != null
        ? {artifactTypeName: decodeURIComponentSafe(artifactTypeName)}
        : {}),
    }),
    [params, projectName, artifactTypeName]
  );
}

export function useCurrentRouteMatch(): match | null {
  const location = useLocation();
  return useMemo(() => getRouteMatch(location.pathname), [location.pathname]);
}

export function getRouteMatch(path: string): match | null {
  for (const r of ROUTES) {
    const m = matchPath(path, {path: r.path, exact: r.exact});
    if (m != null) {
      return m;
    }
  }
  return null;
}

export function isReportViewPath(path: string): boolean {
  return isSpecificPath(path, [
    REPORT_PAGE_VIEW_PATH,
    REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  ]);
}

export function hasEntityNameRouteParameter(path: string): boolean {
  const m = getRouteMatch(path);
  // eslint-disable-next-line no-prototype-builtins
  return m?.params.hasOwnProperty('entityName') ?? false;
}

export function isUsagePath(path: string): boolean {
  return isSpecificPath(path, [USAGE_PATH]);
}

export function isHistoricUsagePath(path: string): boolean {
  return isSpecificPath(path, [HISTORIC_USAGE_PATH]);
}

export function isBillingPath(path: string): boolean {
  return isSpecificPath(path, [...ACCOUNT_SETTINGS_PATHS]);
}

export function isUserSettingsPath(path: string): boolean {
  return isSpecificPath(path, [USER_SETTINGS_PATH]);
}

export function isTrialEndPath(path: string): boolean {
  return isSpecificPath(path, [TRIAL_END_PATH]);
}

export function isAcademicApplicationPath(path: string): boolean {
  return isSpecificPath(path, [ACADEMIC_APPLICATION_PATH]);
}

export function isCreateTeamPath(path: string): boolean {
  return isSpecificPath(path, [CREATE_TEAM_PATH]);
}

export function isStartupProgramPath(path: string): boolean {
  return isSpecificPath(path, [STARTUP_PROGRAM]);
}

export function isSpecificPath(path: string, pathFormats: string[]): boolean {
  const m = getRouteMatch(path);
  if (m == null) {
    return false;
  }
  return pathFormats.indexOf(m.path) !== -1;
}

export function isProfilePath(path: string): boolean {
  const m = getRouteMatch(path);
  return (
    m?.path === PROFILE_PATH ||
    m?.path === PROFILE_PROJECTS_PATH ||
    m?.path === PROFILE_REPORTS_PATH ||
    m?.path === PROFILE_STARS_PATH
  );
}

export function isOnboardingFlowPath(path: string): boolean {
  const m = getRouteMatch(path);
  return m?.path === ONBOARDING_FLOW_PATH;
}

export function isOrgSettingsPath(): boolean {
  const path = location.pathname;
  return path.startsWith(ORG_SETTINGS);
}
