import React, {createContext, useContext, useMemo} from 'react';

import type {UseWandbConfigQueryResult} from '../../state/graphql/wandbConfigQuery';
import {useRunsWandbConfigQuery} from '../../state/runs/hooks';
import {usePanelsSharedRefsContext} from '../Views/contexts/PanelsSharedRefsContext';

type WandbConfigContextType = UseWandbConfigQueryResult;

const WandbConfigContext = createContext<WandbConfigContextType | undefined>(
  undefined
);

export const WandbConfigContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {runSetRefs} = usePanelsSharedRefsContext();
  const wbConfigQuery = useRunsWandbConfigQuery(runSetRefs);

  const value = useMemo(() => wbConfigQuery, [wbConfigQuery]);

  return (
    <WandbConfigContext.Provider value={value}>
      {children}
    </WandbConfigContext.Provider>
  );
};

export const useWandbConfigContext = () => {
  const value = useContext(WandbConfigContext);

  if (value == null) {
    throw new Error(
      'useWandbConfigContext must be used within a WandbConfigContext'
    );
  }

  return value;
};
