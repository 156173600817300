import {Line} from '../../../../util/plotHelpers/types';
import {ExcludeOutliersValues} from './../../../../components/WorkspaceDrawer/Settings/types';
import {areOutliersExcluded} from './../../../../components/WorkspaceDrawer/Settings/utils';
import {mutateColorOnPrimaryLine} from './mutateColorOnPrimaryLine';

export type MakeAllowedLinesConfig = {
  excludeOutliers: ExcludeOutliersValues;
  isRunsetGrouped: (runsetId: string) => boolean;
  isAggregated: boolean;
  isSmoothingActive: boolean;
  showOriginalAfterSmoothing: boolean;
};

export const makeAllowedLines = (
  linesToUse: Line[],
  config: MakeAllowedLinesConfig
) =>
  linesToUse
    .filter(
      l =>
        !hasIneligibleDisplayCondition(l, {
          excludeOutliers: config.excludeOutliers,
          isGrouped: config.isRunsetGrouped(l.run?.runsetInfo?.id ?? ''),
          isAggregated: config.isAggregated,
          isSmoothingActive: config.isSmoothingActive,
          showOriginalAfterSmoothing: config.showOriginalAfterSmoothing,
        })
    )
    .map(l =>
      mutateColorOnPrimaryLine(l, {
        isGrouped: config.isRunsetGrouped(l.run?.runsetInfo?.id ?? ''),
        isAggregated: config.isAggregated,
        isSmoothingActive: config.isSmoothingActive,
      })
    );

type LineFilterFn = (
  line: {meta: Line['meta']},
  config: Omit<MakeAllowedLinesConfig, 'isRunsetGrouped'> & {isGrouped: boolean}
) => string | false;

const isLineMissingMeta: LineFilterFn = line => {
  if (line.meta == null) {
    return 'Line missing meta';
  }
  return false;
};

const isSampledLine: LineFilterFn = line => {
  if (line.meta.mode === 'sampled') {
    return 'Sampled line';
  }
  return false;
};

const isSmoothedAreaLine: LineFilterFn = line => {
  if (line.meta.category === 'smoothed' && line.meta.type === 'area') {
    return 'Smoothed area line';
  }
  return false;
};

const isSmoothedLineWithNoActiveSmoothing: LineFilterFn = (line, config) => {
  if (!config.isSmoothingActive && line.meta.category === 'smoothed') {
    return 'Smoothed without smoothing';
  }
  return false;
};

const isExcludingOutliers: LineFilterFn = (line, config) => {
  if (
    line.meta.type === 'area' &&
    areOutliersExcluded(config.excludeOutliers)
  ) {
    return 'Excluding outliers';
  }
  return false;
};

const isUngroupedLine: LineFilterFn = (line, config) => {
  if (
    config.isGrouped &&
    !config.isAggregated &&
    line.meta.category === 'default'
  ) {
    return 'No default lines display while grouped';
  }
  return false;
};

const isHiddenOriginalLine: LineFilterFn = (line, config) => {
  if (
    config.isSmoothingActive &&
    !config.showOriginalAfterSmoothing &&
    line.meta.category !== 'smoothed' &&
    line.meta.type === 'line'
  ) {
    return 'No original default/grouped lines without `showOriginal`';
  }
  return false;
};

const isGroupedLineWithNoActiveGrouping: LineFilterFn = (line, config) => {
  if (!config.isGrouped && line.meta.category === 'grouped') {
    return 'Grouped without grouping';
  }
  return false;
};

export const hasIneligibleDisplayCondition = (
  line: {meta: Line['meta']},
  config: Omit<MakeAllowedLinesConfig, 'isRunsetGrouped'> & {isGrouped: boolean}
) => {
  const failedConditions = [
    isSampledLine,
    isLineMissingMeta,
    isSmoothedAreaLine,
    isSmoothedLineWithNoActiveSmoothing,
    isGroupedLineWithNoActiveGrouping,
    isExcludingOutliers,
    isUngroupedLine,
    isHiddenOriginalLine,
  ].some(filterFn => {
    try {
      const r = filterFn(line, config);
      if (r) {
        return true;
      }
    } catch (e) {
      // eject on lines with errors, but make them visible to sentry
      console.error(e);
      return true;
    }

    return false;
  });

  return failedConditions;
};
