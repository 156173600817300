import {WBIcon} from '@wandb/ui';
import React from 'react';
import {NavLink} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button} from 'semantic-ui-react';

import deniedImg from '../assets/il-access-denied.png';
import deniedImg2x from '../assets/il-access-denied@2x.png';
import globalHistory from '../util/history';
import {isInIframe} from '../util/window';
import EmptyWatermark from './EmptyWatermark';
import {LoginButton, SignupButton} from './LoginButton';

interface AccessDeniedProps {
  unrecoverable?: boolean;
}

const AccessDenied: React.FC<AccessDeniedProps> = ({unrecoverable}) => {
  // TODO: it would be nice to automatically reload the page after login
  return (
    <div style={{margin: '30px auto', maxWidth: 480}}>
      <EmptyWatermark
        imageSource={deniedImg}
        imageSource2x={deniedImg2x}
        header="Access Denied"
        details={
          unrecoverable
            ? 'This page can not be accessed from within an iframe'
            : undefined
        }
      />
      {!unrecoverable && (
        <div style={{textAlign: 'center'}}>
          <LoginButton size="small" />
          <SignupButton
            hideIcon
            target={isInIframe() ? '_blank' : '_self'}
            size="small"
          />
          <div style={{marginTop: 10, color: '#757575'}}>
            {isInIframe() ? (
              <>
                Already logged in?{' '}
                <a href="#refresh" onClick={() => globalHistory.go(0)}>
                  <WBIcon name="refresh" /> Refresh
                </a>
              </>
            ) : (
              <NavLink to={`/`}>
                <Button primary>Home</Button>
              </NavLink>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessDenied;
