import {Button as WeaveButton} from '@wandb/weave/components';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {isEmpty, isEqual, isUndefined, omitBy} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Modal} from 'semantic-ui-react';

import * as ViewActions from '../state/views/actions';
import * as ViewHooks from '../state/views/hooks';
import * as PanelViewTypes from '../state/views/panel/types';
import {insertUpdatedPanel} from '../state/views/panelBankSectionConfig/actions';
import {RunHistoryKeyInfo} from '../types/run';
import {haveDataForPanel} from '../util/panelbank';
import {PanelCompRedux} from '../util/panels';
import type {PanelSpec} from '../util/panelTypes';
import {logHandledError} from './../services/errors/errorReporting';
import {useKeyInfoQueryContext} from './MultiRunWorkspace/KeyInfoQueryContext';
import {usePanelContext} from './Panel/PanelContextProvider';
import {SinglePanelInspectorContextProvider} from './Panel/SinglePanelInspectorContainer';
import {InstrumentedCenteredWaveLoader as Loader} from './utility/InstrumentedLoader';

export interface PanelEditorProps {
  currentHeight: number;
  disableRunLinks?: boolean;
  // when we first open up the panel editor, should we start in edit mode?
  initialEditingState: boolean;
  initialConfigState?: {[key: string]: any};
  newPanel?: boolean;
  panelRef: PanelViewTypes.Ref;
  panelSpec: PanelSpec<any, any, any>;
  readOnly?: boolean;
  onCancel?(): void;
  onOK?(): void;
  onRemove?(): void;
}

export interface LoadedKeyInfoQuery {
  loading: false;
  error: null | undefined;
  historyKeyInfo: RunHistoryKeyInfo;
}

function isLoadedKeyInfoQuery(object: any): object is LoadedKeyInfoQuery {
  return !object.loading && object.error == null && 'historyKeyInfo' in object;
}

/**
 * This is not great! But there's no way to easily communicate between the body of the modal and the apply button, so this context provider provides a way to avoid drilling.
 */
type PanelModalActions = {
  runOnApply: (() => void) | null;
  setRunOnApply: (runOnApply: () => void) => void;
};
const PanelModalActionContext = React.createContext<PanelModalActions>({
  runOnApply: () => {},
  setRunOnApply: () => {},
});

const PanelModalActionsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [runOnApply, setRunOnApply] =
    React.useState<PanelModalActions['runOnApply']>(null);

  return (
    <PanelModalActionContext.Provider value={{runOnApply, setRunOnApply}}>
      {children}
    </PanelModalActionContext.Provider>
  );
};
export const usePanelActionsContext = () => {
  const panelModalActions = React.useContext(PanelModalActionContext);

  // this is intended for stuff like analytics, so this should fail silently if it fails
  if (!panelModalActions) {
    logHandledError('PanelModalActionsContextProvider not found');
  }

  const fns = React.useMemo(
    () => ({
      runOnApply: !panelModalActions ? () => {} : panelModalActions.runOnApply,
      setRunOnApply: !panelModalActions
        ? () => {}
        : panelModalActions.setRunOnApply,
    }),
    [panelModalActions]
  );

  return fns;
};

const PanelEditor = ({
  currentHeight,
  disableRunLinks,
  initialEditingState,
  initialConfigState,
  newPanel,
  panelRef,
  panelSpec,
  onCancel,
  onOK,
  onRemove,
  readOnly,
}: PanelEditorProps) => {
  const dispatch = useDispatch();
  const panelCopy = ViewHooks.useObjectCopy(panelRef);
  const {keyInfoQuery} = useKeyInfoQueryContext();
  const {panelBankSectionConfigRef} = usePanelContext();

  const panelActions = usePanelActionsContext();
  const [isEditMode, setIsEditMode] = useState(initialEditingState);

  // Disable the "Ok" button if we don't have enough info to render the plot
  const panelPreview = ViewHooks.usePart(
    panelCopy.copying ? panelRef : panelCopy.ref
  );
  const okDisabled =
    (panelPreview.viewType === 'Run History Line Plot' &&
      isEmpty(panelPreview.config.metrics)) ||
    (panelPreview.viewType === 'Scatter Plot' &&
      (isEmpty(panelPreview.config.xAxis) ||
        isEmpty(panelPreview.config.yAxis))) ||
    (panelPreview.viewType === 'Parallel Coordinates Plot' &&
      (panelPreview.config.columns == null ||
        panelPreview.config.columns.length < 2)) ||
    (panelPreview.viewType === 'Vega2' &&
      !(
        isLoadedKeyInfoQuery(keyInfoQuery) &&
        haveDataForPanel(keyInfoQuery.historyKeyInfo, panelPreview)
      ));

  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey) && event.code === 'Enter') {
        event.preventDefault();
        if (onOK) {
          onOK();
        }
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [onOK]);

  return (
    <Modal
      className={[
        panelSpec.type.toLowerCase().replace(' ', '-') + '-panel-editor',
        isEditMode ? 'edit-mode' : 'fullscreen-mode',
        'panel-editor',
      ].join(' ')}
      open={true}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
      onClose={onCancel}
      size="fullscreen">
      <Modal.Content className={isEditMode ? 'edit-mode' : 'fullscreen-mode'}>
        {panelCopy.copying ||
        keyInfoQuery.loading ||
        keyInfoQuery.error != null ? (
          <div style={{height: 500}}>
            <Loader name="key-info-query" size="huge" />
          </div>
        ) : (
          <PanelCompRedux
            configMode={isEditMode}
            currentHeight={currentHeight}
            dimensions={undefined as any}
            disableRunLinks={disableRunLinks}
            initialConfigState={initialConfigState}
            keyInfo={keyInfoQuery.historyKeyInfo}
            panelRef={panelSpec.type === 'Weave' ? panelRef : panelCopy.ref}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Tailwind>
          <div className="flex items-center justify-between">
            <div>
              {!panelSpec.noEditMode && !readOnly && !isEditMode && (
                <WeaveButton
                  onClick={() => setIsEditMode(true)}
                  size="large"
                  variant="secondary">
                  Edit
                </WeaveButton>
              )}
              {isEditMode && onRemove && (
                <WeaveButton
                  className="order ml-12"
                  onClick={onRemove}
                  size="large"
                  variant="secondary">
                  Delete
                </WeaveButton>
              )}
            </div>
            <div>
              {onCancel && (
                <WeaveButton
                  data-test="full-screen-modal-close-button"
                  onClick={onCancel}
                  size="large"
                  variant="secondary">
                  {isEditMode ? 'Cancel' : 'Close'}
                </WeaveButton>
              )}
              {isEditMode && onOK && (
                <WeaveButton
                  className="ml-12"
                  data-test="submit-panel-config"
                  disabled={panelCopy.copying || okDisabled}
                  onClick={() => {
                    if (
                      isEqual(
                        omitBy(panelPreview.config, isUndefined),
                        omitBy(initialConfigState?.config, isUndefined)
                      ) &&
                      onCancel
                    ) {
                      onCancel();
                      return;
                    }

                    panelActions.runOnApply?.();
                    if (!panelCopy.copying) {
                      if (newPanel) {
                        dispatch(
                          ViewActions.copyObject(panelCopy.ref, panelRef)
                        );
                      } else if (panelBankSectionConfigRef) {
                        dispatch(
                          insertUpdatedPanel(
                            panelBankSectionConfigRef,
                            panelCopy.ref,
                            panelRef
                          )
                        );
                        window.analytics?.track('Panel added', {
                          type: panelSpec.type,
                        });
                      }
                      if (onOK) {
                        onOK();
                      }
                    }
                  }}
                  size="large"
                  variant="primary">
                  Apply
                </WeaveButton>
              )}
            </div>
          </div>
        </Tailwind>
      </Modal.Actions>
    </Modal>
  );
};

const WrappedPanelEditor = React.memo((props: PanelEditorProps) => (
  <SinglePanelInspectorContextProvider>
    <PanelModalActionsContextProvider>
      <PanelEditor {...props} />
    </PanelModalActionsContextProvider>
  </SinglePanelInspectorContextProvider>
));
WrappedPanelEditor.displayName = 'WrappedPanelEditor';

export default WrappedPanelEditor;
