import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {useWorkspaceSettingsContext} from '../WorkspaceDrawer/Settings/WorkspaceSettingsContextProvider';

export type SharedPanelZoomState = {
  xAxis: string;
  xMin: number | null;
  xMax: number | null;
};
type SharedPanelZoomContextType = {
  isSharingZoom: boolean;
  setSharedZoom: (args: Partial<SharedPanelZoomState>) => void;
} & SharedPanelZoomState;

export const SharedPanelZoomContext = createContext<SharedPanelZoomContextType>(
  {
    isSharingZoom: false,
    setSharedZoom: () => {},
    xAxis: '',
    xMin: null,
    xMax: null,
  }
);

export const SharedPanelZoomContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {linePlot} = useWorkspaceSettingsContext();
  const [sharedZoom, setZoom] = useState<SharedPanelZoomState>({
    xAxis: '',
    xMin: null,
    xMax: null,
  });

  const setSharedZoom: SharedPanelZoomContextType['setSharedZoom'] =
    useCallback(args => {
      setZoom(prev => ({
        ...prev,
        ...args,
      }));
    }, []);

  const value = useMemo(
    () => ({
      ...sharedZoom,
      setSharedZoom,
      isSharingZoom: linePlot.coordinateZooming ?? false,
    }),
    [sharedZoom, setSharedZoom, linePlot.coordinateZooming]
  );

  return (
    <SharedPanelZoomContext.Provider value={value}>
      {children}
    </SharedPanelZoomContext.Provider>
  );
};

export const useSharedPanelZoom = () => {
  const context = useContext(SharedPanelZoomContext);
  if (!context) {
    throw new Error(
      'useSharedPanelZoom must be used within a SharedPanelZoomContextProvider'
    );
  }
  return context;
};
