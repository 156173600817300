import {
  IconHome,
  IconModel,
  IconRegistries,
  IconRocketLaunch,
} from '@wandb/weave/components/Icon';
import {IconWeaveLogo} from '@wandb/weave/components/Panel2/Icons';
import {Pill} from '@wandb/weave/components/Tag';
import React from 'react';

import {useAccountOrganizationOrPersonalEntityUsingLaunch} from '../../../components/Launch/launchActive';
import {useIsGlobalRegistryEnabled} from '../../../components/Registries/common/hooks';
import {envIsLocal} from '../../../config';
import {
  trackGlobalRegistryClicked,
  trackModelRegistryClicked,
  trackProfileMenuClicked,
} from '../../../util/navigation';
import * as urls from '../../../util/urls';
import {useModelsAccess} from '../../../util/useModelsAccess';
import {useHomePageContext} from '../HomePageContextProvider';
import * as S from './HomePageSidebar.styles';
import {ProjectsList} from './ProjectsList';
import {SidebarLink} from './SidebarLink';
import {SidebarSectionHeader} from './SidebarSectionHeader';
import {TeamsList} from './TeamsList';
import {TrialCard} from './TrialCard';

export const HomePageSidebar = () => {
  const {
    homePageViewerDetailsQuery: {data: viewerData, refetch: refetchViewerData},
  } = useHomePageContext();

  const {enableGlobalRegistry} = useIsGlobalRegistryEnabled();
  const {hasModelsAccess} = useModelsAccess();

  const {isLaunchActive, error} =
    useAccountOrganizationOrPersonalEntityUsingLaunch();

  if (error) {
    console.error('Error getting account or personal entity using launch');
  }

  return (
    <div
      data-test="home-page-sidebar"
      className={`[ul>*:p-0] flex h-full flex-col`}>
      <div className="mb-16">
        <SidebarLink
          dataTest="home-page-sidebar-header"
          icon={<IconHome />}
          labelText="Home"
          url={urls.homePage()}
        />
      </div>
      <div className="mb-16">
        <SidebarLink
          icon={<IconWeaveLogo className="night-aware flex-none" />}
          labelText="Weave: Develop AI apps"
          url={urls.homeQuickStart('weave')}
          newFeatureIndicator={
            <Pill className="ml-8" color="cactus" label="New" />
          }
        />
      </div>
      <ProjectsList />
      {viewerData?.username != null && hasModelsAccess && (
        <div className="mb-16">
          <SidebarSectionHeader headerText="Applications" />
          {enableGlobalRegistry ? (
            <SidebarLink
              icon={<IconRegistries />}
              labelText="Registry"
              url={urls.registries()}
              onClick={() => {
                trackGlobalRegistryClicked('Home sidebar');
              }}
              newFeatureIndicator={
                <Pill className="ml-8" color="moon" label="Preview" />
              }
            />
          ) : (
            <SidebarLink
              dataTest="model-registry"
              icon={<IconModel />}
              labelText="Model registry"
              url={urls.objectRegistry('model')}
              onClick={() => {
                trackModelRegistryClicked('Home sidebar');
              }}
            />
          )}
          {isLaunchActive && (
            <SidebarLink
              icon={<IconRocketLaunch />}
              labelText="Launch"
              url={urls.launchOverview()}
            />
          )}
        </div>
      )}
      {viewerData?.username && (
        <div className="mb-16">
          <SidebarSectionHeader headerText="Profile" />
          <SidebarLink
            key={viewerData.username}
            icon={
              <S.AvatarImage
                alt="Home nav profile avatar"
                src={viewerData.photoUrl}
              />
            }
            labelText={viewerData.username}
            url={`/${viewerData.username}`}
            onClick={() => {
              trackProfileMenuClicked('Home sidebar');
            }}
          />
        </div>
      )}
      {!!viewerData?.teams?.edges?.length && (
        <TeamsList
          name="Teams"
          data={viewerData.teams}
          userQueryRefetch={refetchViewerData}
        />
      )}
      {/* Forces Sticky Footer positioning */}
      <div className="flex flex-auto flex-col justify-end">
        <div className="sticky bottom-16">{!envIsLocal && <TrialCard />}</div>
      </div>
    </div>
  );
};

export default HomePageSidebar; // displayName
