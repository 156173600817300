import {produce} from 'immer';
import _ from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {Data} from 'src/state/runs/types';

import {
  getStartTimeForAbsoluteRunTime,
  parseRunTimePoint,
} from '../../util/plotHelpers/pointsByRunAndMetric';
import {DomainMaybe} from '../vis/types';
import {useRunsData} from './../../state/runs/hooks';
import {RunHistoryRow} from './../../types/run';
import {BucketedData, HistoryPoint, RunsLinePlotConfig} from './types';
import {calcDomainRange} from './zoom/calcDomainRange';
import {PartialMaybeDomain} from './zoom/types';

type PanelData = ReturnType<typeof useRunsData>['data'];
type PanelDataContextType = {
  calculateZoomRange: (xAxisKey: string, domain: DomainMaybe) => DomainMaybe;
  handleSetHistoryData: (
    data: PanelData | BucketedData['data'],
    xAxisKey: string
  ) => void;
};
const PanelDataContext = createContext<PanelDataContextType>({
  calculateZoomRange: () => [null, null],
  handleSetHistoryData: () => {},
});

/**
 * In order to do an API query we need to have points with _step values that
 * we can can use for the query min/max values
 */
export const hasStepValue = (
  point: RunHistoryRow,
  stepKey: '_step' | '_stepAvg'
): point is HistoryPoint => {
  return _.isFinite(point[stepKey]);
};

// Post process data with to achieve proper zooming and resampling
export const usePostProcessedData = (
  data: Data,
  config: RunsLinePlotConfig
): Data => {
  // const {timeFactor} = usePanelTimeContext();

  return useMemo(() => {
    if (config.xAxis === '_absolute_runtime') {
      return produce(data, draft => {
        draft.histories.data.forEach(history => {
          // NOTE: we don't have run.createdAt here in the history data, so leaving sampling mode unchanged
          // this will cause bugs in certain data scenarios, TODO: fix this
          const startTime = getStartTimeForAbsoluteRunTime(history.history);
          history.history.forEach(point => {
            const absoluteRuntime = parseRunTimePoint(config.xAxis!, point, {
              startTime,
              isAbsoluteRuntime: true,
            })[1];
            point._absolute_runtime = absoluteRuntime;
            /*convertScalarSecondsToTimestep(
              absoluteRuntime,
              timeFactor ?? 'seconds'
            );
            */
          });
        });
      });
    }

    return data;
  }, [data, config]); // , timeFactor]);
};

export const PanelDataContextProvider = React.memo(
  ({children}: {children: React.ReactNode}) => {
    const [historyData, setHistoryData] = useState<HistoryPoint[]>([]);

    /**
     * Every time we get an updated set of history data, we need to update the fn that allows the calculation of the zoom range. The zoom range is triggered by: (1) changes to the config (a user configuring x/y ranges in the panel config) or (2) user zoom changes through interaction. The key is that historyData cannot be fed into a reactive lifecycle that calculates the zoom range because:
     * - a config/zoom event will trigger zoom
     * - the zoom calculation (for non _step x-axis keys) will be calculated based on the existing history
     * - a new query will be dispatched with the updated query domain range
     * - new history data will be returned
     *
     * The key here is to create a function that updates based on the latest history data, but can only be triggered downstream by the specific zoom events.
     */
    const calculateZoomRange = useCallback(
      (xAxisKey: string, domain?: DomainMaybe) => {
        const domainRange = calcDomainRange(
          domain as PartialMaybeDomain,
          historyData ?? [],
          xAxisKey
        );

        return domainRange;
      },
      [historyData]
    );

    const handleSetHistoryData = useCallback(
      (data: PanelData | BucketedData['data'], xAxisKey: string) => {
        if (!xAxisKey) {
          setHistoryData([]);
        }

        /**
         * When we get a new data object from the query we need to prep the data so that we can use it later to easily convert non-_step zoom ranges into _step ranges
         *
         * We do this by making sure that
         * (a) we have a finite step value and
         * (b) that we have finite x-axis values for the conversion
         */
        const isBucketedData =
          // eslint-disable-next-line no-prototype-builtins
          data.hasOwnProperty('_dataType') &&
          (data as BucketedData['data'])._dataType === 'bucketing-gorilla';

        if (isBucketedData) {
          const modKey = `${xAxisKey}Avg`;

          const bucketHistory =
            data?.histories?.data
              ?.flatMap(d => d?.history.flat())
              .filter(p => {
                return (
                  hasStepValue(p, '_stepAvg') &&
                  p[modKey] != null &&
                  _.isFinite(p[modKey])
                );
              }) ?? [];

          setHistoryData(bucketHistory as HistoryPoint[]);
        } else {
          const sampleHistory =
            data?.histories?.data
              ?.flatMap(d => d?.history)
              .filter(
                p => hasStepValue(p, '_step') && _.isFinite(p[xAxisKey])
              ) ?? [];

          setHistoryData(sampleHistory as HistoryPoint[]);
        }
      },
      [setHistoryData]
    );

    const value = useMemo(() => {
      return {
        calculateZoomRange,
        handleSetHistoryData,
      };
    }, [calculateZoomRange, handleSetHistoryData]);
    return (
      <PanelDataContext.Provider value={value}>
        {children}
      </PanelDataContext.Provider>
    );
  }
);
PanelDataContextProvider.displayName = 'PanelDataContextProvider';

export const usePanelData = () => {
  const context = useContext(PanelDataContext);

  return context;
};
