import * as _ from 'lodash';
import React, {useRef} from 'react';

import * as ColorUtil from '../../../util/colors';
import {smoothLines} from '../../../util/plotHelpers/lines';
import {
  filterNegative,
  getMetricIdentifiersFromExpressions,
} from '../../../util/plotHelpers/plotHelpers';
import {prettifyMetricName} from '../../../util/plotHelpers/prettifyMetricName';
import {Line} from '../../../util/plotHelpers/types';
import * as Run from '../../../util/runs';
import * as RunTypes from '../../../util/runTypes';
import {LineConfig} from '../config/types';
import {usePanelConfigContext} from '../PanelConfigContext';
import {usePanelGroupingSettings} from '../RunsLinePlotContext/usePanelGroupingSettings';
import {BucketedData} from '../types';
import {RunWithRunsetInfo} from './../../../state/runs/types';
import {getDatadogRum} from './../../../util/datadog';
import {metricsInExpression} from './../../../util/expr';
import {
  legendTemplateToFancyLegendProps,
  parseLegendTemplate,
} from './../../../util/legend';
import {
  convertAreaToPercentArea,
  convertLinesToArea,
} from './../../../util/plotHelpers/areaCharts';
import {getBucketSpec} from './../../../util/plotHelpers/buckets/getBucketSpec';
import {
  BIN_POINT_TO_USE,
  type ChartAggOption,
} from './../../../util/plotHelpers/chartTypes';
import {markLinesByColor} from './../../../util/plotHelpers/colors';
import * as Time from './../../../util/plotHelpers/time';
import {useRampFlagLineConstructionProfiling} from './../../../util/rampFeatureFlags';
import {filterRedundantHistories} from './../componentOutliers/filterRedundantHistories';
import {getSupplementalMetrics} from './../componentOutliers/getSupplementalMetrics';
import {parseHistory} from './../componentOutliers/parseHistory';
import {aggregateLines} from './aggregateLines';
import {PointWithMeta} from './buildPoints';
import {evaluateYExpressions} from './evaluateYExpressions';
import {makeAllowedLines} from './lineFilters/filterUnusedLines';
import {HistoryRecord, SupplementalMetricsByRun} from './types';
import {
  convertLineDataToTimestamp,
  evaluateXExpressions,
  removeUselessAreaLines,
} from './useLinesUtils';
import {getSafeYKey, sliceObjectKeys} from './util';

/**
 * This is a hack because somehow when you mouse over lines in the chart the line config value is getting thrashed. I've not figured out why this is yet. This is a temporary fix to make sure the line config doesn't change unless it actually changes.
 */
function useDeepCompare(obj: any) {
  const old = useRef(obj);
  const refId = useRef(1);

  if (!_.isEqual(old.current, obj)) {
    // for (const k in obj) {
    //   if (!_.isEqual(obj[k], old.current[k])) {
    //     console.log('changed: ', k, obj[k]);
    //   }
    // }
    old.current = obj;
    refId.current += 1;
    return refId.current;
  }

  return refId.current;
}

export const useLines = (
  lineConfig: LineConfig,
  data: BucketedData['data']['histories']['data'],
  isAggregated: boolean,
  meta: {
    isInReport: boolean;
    numBucketsByPanelWidth: number;
  },
  debugMode = false // this is a temporary flag to make debugging performance easier
) => {
  const hasData = useRef(false);
  const hasLoggedVitals = useRef(false);

  const shouldLineConfigUpdate = useDeepCompare(lineConfig);
  const lineProfilingEnabled = useRampFlagLineConstructionProfiling(
    lineConfig.entityName
  );
  const {
    groupAgg,
    getGroupKeysByRunsetId,
    isAnyRunsetGrouped,
    isRunsetGrouped,
  } = usePanelGroupingSettings();
  const {expressionKeys, excludeOutliers, xAxisIsDatetime} =
    usePanelConfigContext();

  return React.useMemo(
    function renderUseLinesMemo() {
      /**
       * We only want to instrument the first render of the lines
       */
      if (!hasData.current && data.length > 0) {
        hasData.current = true;
      }
      const canLogVitals = hasData.current && !hasLoggedVitals.current;

      const t0 = performance.now();

      const xExpressionMetricIdentifiers = lineConfig.xExpression
        ? metricsInExpression(lineConfig.xExpression, false)
        : [];
      const {expressionMetricIdentifiers} = getMetricIdentifiersFromExpressions(
        lineConfig.expressions,
        lineConfig.xExpression
      );

      const xMetrics = [
        ...new Set([
          ...xExpressionMetricIdentifiers.concat(lineConfig.xAxis, '_step'),
        ]),
      ];
      const yMetrics = [
        ...new Set([...lineConfig.yAxis.concat(expressionMetricIdentifiers)]),
      ];

      /**
       * Note: the `minMax` agg value isn't really used, all we really do is check if it's the Avg or not, otherwise we return aux lines
       */
      const aggregations = ['Avg', 'minMax'];

      /**
       * When plotting metrics by custom x-axis we get a bunch of redundant data results. Since the x-axis values come in on the history with each metric we can just strip off the redundant returns and save some processing time.
       *
       * TODO: eliminate these redundant requests in the sampledHistorySpecs
       */
      data.forEach(run => {
        const filteredHistories = filterRedundantHistories(
          run.history,
          yMetrics
        );
        run.history = filteredHistories;
      });

      /**
       * Determine if we're need to calculate any expressions - if not we can save some point computation for faster processing times
       */
      const usingExpressions =
        !!lineConfig.xExpression ||
        (lineConfig.expressions && lineConfig.expressions.length > 0);

      const supplementalMetricsByRun: SupplementalMetricsByRun = {};
      /**
       * first thing is to build all the lines w/out computing any of the expressions
       * the lines are a function of run count * aggregation count * yMetrics count
       */

      const t1 = performance.now();
      if (debugMode) {
        console.log('Preliminary line work: ', t1 - t0, 'ms');
      }

      /**
       * Hack to store non-finites (NF) points for each run and metric.
       *
       * The problem is that once you get into Graph/LinePlot/LinePlotPlot there's a lot of manipulation of the line data for [reasons]. The effect of this is that NanPoints that come in on aux lines (minMax areas) get handled differently and normalizing all the paths is tough. This solution is just to let both types of agg lines write to the same NF list, which is then only attached to the primary line. This way we can just check the primary line for NFs and handle them accordingly, and since the aux lines kick out NFs as x/y pairs (a NF point with x:5, y:-Infinity, y0: Infinity will spit out two NF points at 5/-Inf and 5/Inf) there's no type discrepancy with primary lines (which are just x/y points)
       */
      const nfPointsByRunAggMetric: {
        [name: string]: {
          [metric: string]: PointWithMeta[];
        };
      } = {};
      let lines = data.flatMap(function parseHistoryRun(run) {
        nfPointsByRunAggMetric[run.name] = {};
        // @ts-ignore hack because we need it for grouping
        run.runsetInfo =
          lineConfig.runSets.find(rs => rs.id === run.runsetId) ??
          lineConfig.runSets[0] ??
          null;

        // see notes on handling time plots
        // Readme: frontends/app/src/components/PanelRunsLinePlot/timePlots/readme.md
        // Demo: https://wandb.ai/public-team-private-projects/Time%20plots?nw=36sx43brn2r
        const startTimeInMs = new Date(run.createdAt).getTime();

        const supplementalMetrics = getSupplementalMetrics(xMetrics, {
          configMetrics: run.config ?? {},
          summaryMetrics: run.summary ?? {},
        });
        supplementalMetricsByRun[run.name] = supplementalMetrics;

        /**
         * There are some perf gains here if we were to compute both agg lines (minMax and avg) within the same loop. This would save us from having to iterate over the history twice.
         *
         * Note: currently NaN and Infinite points aren't handled correctly so we're ejecting inside parseHistory by throwing an error.
         *
         * TODO: implement this to save processing time
         */
        return (aggregations as Array<'Avg' | 'minMax'>).flatMap(
          function parseHistoryAgg(agg) {
            return yMetrics.flatMap(function parseHistoryMetric(
              metric,
              metricIndex
            ) {
              if (!nfPointsByRunAggMetric[run.name][metric]) {
                nfPointsByRunAggMetric[run.name][metric] = [];
              }
              const nfList = nfPointsByRunAggMetric[run.name][metric];

              /**
               * Only one history list in the run will have the metric we're looking for since each metric gets broken out individually
               */
              const yKey = getSafeYKey(metric);

              /**
               * There exists a case where we're running system metrics through the line plot and it's trying to build area points on a min/max aggregation on data which is coming back without being binned and having -Min, -Avg, -Max values. We eject in that scenario for now.
               */

              if (agg !== 'Avg' && yKey.startsWith('system/')) {
                return [];
              }
              const matchedHistory =
                run.history.find(function matchHistoryByKey(history) {
                  return yKey in history[0];
                }) ?? [];

              const ta = performance.now();

              const {finitePoints, nanPoints} = parseHistory(
                matchedHistory as HistoryRecord[],
                metric,
                lineConfig.xAxis,
                xMetrics.filter(x => x !== lineConfig.xAxis),
                {
                  agg,
                  startTime: {
                    value: startTimeInMs,
                    unit: 'milliseconds',
                  },
                  usingExpressions,
                },
                // we don't allow targeting min/max outside of groups currently
                isRunsetGrouped(run.runsetId)
                  ? groupAgg
                  : (BIN_POINT_TO_USE.toLowerCase() as ChartAggOption)
              );
              if (debugMode) {
                const tb = performance.now();
                console.log('Line creation: parse history: ', tb - ta, 'ms');
              }

              // Push all the NFs into the nanList: because this list reference is created outside the loop here we can mutate it from inside both agg line loops. I don't love this either.
              nfList.push(...nanPoints);

              const color = lineConfig.colorEachMetricDifferently
                ? ColorUtil.color(metricIndex, 0.2)
                : ColorUtil.runColor(
                    // @ts-ignore
                    run,
                    getGroupKeysByRunsetId(run.runsetId),
                    lineConfig.customRunColors,
                    lineConfig.semanticLegendSettings,
                    0.2
                  );

              const runUniqueId = Run.uniqueId(
                run as unknown as RunWithRunsetInfo,
                getGroupKeysByRunsetId(run.runsetId)
              );
              const customRunName = Run.getCustomRunName(
                run.name,
                getGroupKeysByRunsetId(run.runsetId).length > 0,
                lineConfig.customRunNames
              );

              const isAux = agg !== 'Avg';
              const prettyMetric = prettifyMetricName(metric);
              const line: Line = Object.assign({}, run, {
                aux: isAux,
                color,
                data: finitePoints,
                fancyTitle: legendTemplateToFancyLegendProps(
                  lineConfig.getLegendTemplateByRunsetId(run.runsetId),
                  // @ts-ignore
                  run,
                  getGroupKeysByRunsetId(run.runsetId),
                  prettyMetric,
                  lineConfig.rootUrl,
                  customRunName
                ),
                groupKeys: getGroupKeysByRunsetId(run.runsetId),
                history: null,
                mark: isAux ? null : 'solid',
                meta: {
                  aggregation: agg.toLowerCase(),
                  category: 'default',
                  excludeOutliers,
                  mode: 'full-fidelity',
                  type: isAux ? 'area' : 'line',
                },
                metricName: metric,
                name: run.name,
                nanPoints: isAux ? [] : nfList, // aux lines won't get NFs
                run: run as unknown as RunTypes.Run,
                title: parseLegendTemplate(
                  lineConfig.getLegendTemplateByRunsetId(run.runsetId),
                  true,
                  // @ts-ignore
                  run,
                  getGroupKeysByRunsetId(run.runsetId),
                  prettyMetric,
                  customRunName
                ),
                type: isAux ? 'area' : 'line',
                uniqueId: runUniqueId,
              });

              return line;
            });
          }
        );
      });

      const t2 = performance.now();

      // see notes on handling time plots
      // Readme: frontends/app/src/components/PanelRunsLinePlot/timePlots/readme.md
      // Demo: https://wandb.ai/public-team-private-projects/Time%20plots?nw=36sx43brn2r
      if (xAxisIsDatetime) {
        convertLineDataToTimestamp(lines);
      }

      const t3 = performance.now();

      const usesTimeConversion =
        lineConfig.xAxis === '_runtime' ||
        (lineConfig.xAxis === '_absolute_runtime' &&
          lineConfig.xExpression == null);
      if (usesTimeConversion) {
        /**
         * Scale horizontally based on timestep: this looks at the lines,  claculates what kind of scaling factor "seconds", "hours", etc... and then mutates each line
         */
        Time.convertSecondsToTimestep(
          // this is super gross - when you use a method like .flatMap or .flat you get a new array returned
          // if you operate on the elements of that array with something like `forEach` you're still mutating
          // values referenced by the original array, so:
          // ```
          // const lines = [ [{x: 1}, {x: 2} ],[ {x: 1}, {x: 2} ],[ {x: 1}, {x: 2} ] ]
          // lines.flat().forEach(p => p.x = p.x * 2)
          // ```
          // will muatate the data in `lines`
          lines.flatMap(lr => lr).flat(2),
          // while zooming we don't want to change our timestep
          lineConfig.zoomTimestep ?? undefined
        );
      }

      const t3a = performance.now();

      /**
       * Grouping on the client is taking all the lines and condensing them down into aggregate lines. This can happen based on:
       * a) group keys from the run selector (values read in from run selector filters)
       * b) group keys from the panel config (values set in the grouping tab)
       * c) aggregating metrics in the panel config
       *
       * Assume we're always going to bucket lines in grouping because if the number of lines gets large the checks to see if they require bucketing get expensive as we have to see if all x-values are in common. Odds are they won't be because of the indeterminancy of bucketing
       */
      // We can't plot any lines that don't have data, nor can we determine the
      // metadata about them (such as min/max/numBuckets/etc)
      const activeLines = lines.filter(function filterActiveLines(l) {
        return l.data && l.data.length > 0;
      });
      if (isAnyRunsetGrouped && activeLines.length > 0 && !isAggregated) {
        /**
         * If we're grouping then group the lines by run keys
         */
        const groupedLines = sliceObjectKeys<Record<string, Line[]>>(
          lineConfig.aggregateMetrics && isAnyRunsetGrouped
            ? _.groupBy(activeLines, line => {
                // is there any real case where a line wouldn't have a run?
                return getGroupKeysByRunsetId(
                  line.run?.runsetInfo?.id ?? ''
                )?.map(gKey => Run.getValue(line.run!, gKey));
              })
            : lineConfig.aggregateMetrics
            ? _.groupBy(activeLines, line => {
                return `${line.name}`;
              })
            : isAnyRunsetGrouped
            ? _.groupBy(activeLines, line => {
                return getGroupKeysByRunsetId(
                  line.run?.runsetInfo?.id ?? ''
                )?.map(gKey =>
                  [
                    line.metricName,
                    Run.getValue(line.run!, gKey),
                    line.run?.runsetInfo?.id ?? '',
                  ].join('-')
                );
              })
            : {default: activeLines},
          lineConfig.limit
        );

        const aggLines = Object.values(groupedLines)
          .map(gLines => (gLines ? aggregateLines(gLines, {groupAgg}) : []))
          .flat();

        // filter out to make sure that we don't have any lines corresponding to individual
        // runs that are actually in grouped runsets
        const linesWithoutGrouping = lines.filter(
          line => !isRunsetGrouped(line.run?.runsetInfo?.id ?? '')
        );
        lines = linesWithoutGrouping.concat(aggLines);
      }

      /**
       *  We want to do this sooner rather than later so we're not computing expressions or smoothing on lines which ultimately have no display value
       */
      lines = removeUselessAreaLines(lines);

      const t4 = performance.now();

      if (lineConfig.xExpression) {
        evaluateXExpressions(
          lineConfig.xExpression,
          lines,
          supplementalMetricsByRun
        );
      }

      const t5 = performance.now();

      const hasYExpression =
        lineConfig.expressions && lineConfig.expressions.length > 0;

      const expressionLines = hasYExpression
        ? evaluateYExpressions(
            lines,
            lineConfig.expressions,
            supplementalMetricsByRun
          )
        : [];

      let linesToUse = hasYExpression ? (expressionLines as Line[]) : lines;

      const t6 = performance.now();

      markLinesByColor(linesToUse as Array<{color: string; mark: string}>);

      const isSmoothingActive = lineConfig.smoothingParam > 0;
      if (isSmoothingActive) {
        const [originalLines, smoothedLines] = smoothLines(
          // @ts-ignore
          linesToUse,
          lineConfig.smoothingParam,
          lineConfig.smoothingType
        );

        linesToUse = [...originalLines, ...smoothedLines] as Line[];
      }
      const t7 = performance.now();

      if (lineConfig.yLogScale) {
        linesToUse = filterNegative(linesToUse);
      }
      let finalLines = makeAllowedLines(linesToUse, {
        excludeOutliers: excludeOutliers ?? 'include-outliers',
        isRunsetGrouped,
        isAggregated: isAggregated,
        isSmoothingActive: isSmoothingActive,
        showOriginalAfterSmoothing: lineConfig.showOriginalAfterSmoothing,
      });

      const t8 = performance.now();
      const hasAreaChart =
        (lines.length > 0 && lineConfig.plotType === 'stacked-area') ||
        lineConfig.plotType === 'pct-area';
      if (hasAreaChart) {
        // Crazy way of dealing with the fact that the samples don't line up
        // we make some buckets and compute an average value across the buckets
        //
        // We calculate these buckets for all lines together since there are more
        // interactions between the lines in a stacked/area charts than a regular line plot.
        const bucketSpec = getBucketSpec(lines);
        const xToSum: {[key: number]: number} = {};
        // Make it a stacked area chart
        finalLines = convertLinesToArea(finalLines, {
          buckets: bucketSpec,
          expressionKeys: expressionKeys,
          useMedian: false,
          xToSum,
        });

        if (lineConfig.plotType === 'pct-area') {
          convertAreaToPercentArea(finalLines, xToSum);
        }
      }
      const t9 = performance.now();
      if (lineProfilingEnabled && canLogVitals && t9 - t0 > 1) {
        console.log('logging vitals');
        hasLoggedVitals.current = true;
        const numHistories = data.length;
        const numPoints = data.reduce(
          (acc, run) => acc + run.history.reduce((acc, h) => acc + h.length, 0),
          0
        );
        logVitals(
          {t0, t1, t2, t3, t3a, t4, t5, t6, t7, t8, t9},
          {
            isInReport: meta.isInReport,
            usesAreaChart: hasAreaChart,
            usesGrouping: isAnyRunsetGrouped,
            usesSmoothing: isSmoothingActive,
            usesTimeConversion: usesTimeConversion,
            usesXExpression: !!lineConfig.xExpression,
            usesYExpressions: hasYExpression,
          },
          {
            numBucketsByPanelWidth: meta.numBucketsByPanelWidth,
            numHistories,
            numPoints,
          },
          debugMode
        );
      }

      return finalLines;
    },
    // diffing lineConfig is handled by a deep comparison above since it's thrashing currently
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, lineConfig.runSets, shouldLineConfigUpdate, excludeOutliers]
  );
};

/**
 * Generates custom performance metrics for analysis in Datadog: https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#create-custom-performance-metrics
 */
async function logVitals(
  spans: Record<string, number>,
  flags: {
    isInReport: boolean;
    usesAreaChart: boolean;
    usesGrouping: boolean;
    usesSmoothing: boolean;
    usesTimeConversion: boolean;
    usesXExpression: boolean;
    usesYExpressions: boolean;
  },
  vitals: {
    numBucketsByPanelWidth: number;
    numHistories: number;
    numPoints: number;
  },
  debugMode = false,
  DD_RUM = getDatadogRum()
) {
  const ddRum = DD_RUM;
  const {t0, t1, t2, t3, t3a, t4, t5, t6, t7, t8, t9} = spans;
  if (!ddRum) {
    return;
  }

  const density = Math.floor(
    (vitals.numPoints / vitals.numHistories / vitals.numBucketsByPanelWidth) *
      100
  );
  const data = {
    // have to use epoch time for datadog here
    startTime: Date.now() - performance.now() + t0,
    duration: Math.ceil(t9 - t0),
    description: 'How long line construction takes in full fidelity',
    // we use Math.floor to reduce fractional measures to 0
    context: {
      config: {
        isInReport: flags.isInReport,
        usesAreaChart: flags.usesAreaChart,
        usesGrouping: flags.usesGrouping,
        usesSmoothing: flags.usesSmoothing,
        usesTimeConversion: flags.usesTimeConversion,
        usesXExpression: flags.usesXExpression,
        usesYExpressions: flags.usesYExpressions,
      },
      meta: {
        // density is a percentage of points per bucket per history
        // we want to be able to compare lines with full buckets to be able
        // to compare relative performance of bucket sizes
        density,
        numHistories: vitals.numHistories,
        numPoints: vitals.numPoints,
      },
      timing: {
        areaConversion: Math.floor(t9 - t8),
        grouping: Math.floor(t4 - t3a),
        parseHistory: Math.floor(t2 - t1),
        smoothing: Math.floor(t7 - t6),
        timestampConversion: Math.floor(t3a - t3),
        xAxisExpression: Math.floor(t5 - t4),
        yExpressions: Math.floor(t6 - t5),
      },
    },
  };
  if (debugMode) {
    console.log('final lines', t9 - t0, data);
  }
  ddRum.addDurationVital('useLinesConstruction', data);
}
