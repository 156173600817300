import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {getDatadogRum} from '../datadog';
import {logCustomActionToDatadog} from '../profiler';
import {getCache} from './getCache';
import {CachedDataWithSize} from './types';
import {getCacheStorageKey} from './util';

export type UseCachedDataParams<T> = {
  namespace: string;
  key: any;
  dataFromServer: T;
  waitingForDataFromServer: boolean;
};

/**
 * READ BEFORE USING THIS HOOK. SRSLY GUYS.
 * To use this hook properly, there are a couple of things to keep in mind:
 * 1. The caller must ensure that `namespace` is not shared between different queries.
 * 2. When `key == null`, this hook will no-op.
 * 3. When the query changes, the caller must ensure that `key` changes either at the same time or BEFORE `dataFromServer` changes.
 *    Otherwise, we will cache the new data under the old key, so the old key will have invalid data.
 */
export function useCachedData<T>({
  namespace,
  key,
  dataFromServer,
  waitingForDataFromServer,
}: UseCachedDataParams<T>): T {
  return useCachedDataWithOrigin({
    namespace,
    key,
    dataFromServer,
    waitingForDataFromServer,
  }).data;
}

export function useCachedDataWithOrigin<T>(
  {
    namespace,
    key,
    dataFromServer,
    waitingForDataFromServer,
  }: UseCachedDataParams<T>,
  skip: boolean = false
): {data: T; origin: 'cache' | 'server'} {
  const cache = getCache();
  const internalKey = useMemo(
    () => (skip ? null : getCacheStorageKey(namespace, key)),
    [namespace, key, skip]
  );

  // State holds both the current internalKey and the cache data for it
  const [cachedState, setCachedState] = useState<{
    internalKey: string | null;
    data: CachedDataWithSize<T> | null;
  }>({internalKey, data: null});

  // If the internalKey changes, immediately reset the cached data to avoid stale returns
  useEffect(() => {
    if (skip) {
      return;
    }
    setCachedState({internalKey, data: null});
  }, [internalKey, skip]);

  // Attempt to load from cache whenever we're waiting for server data
  useEffect(() => {
    if (skip) {
      return;
    }
    if (!waitingForDataFromServer || !internalKey || !cache) {
      return;
    }
    (async () => {
      const fromCache = await cache.get<T>(internalKey);
      if (fromCache) {
        // Only update state if internalKey is still the same (avoid race conditions)
        setCachedState(prevState =>
          prevState.internalKey === internalKey
            ? {internalKey, data: fromCache}
            : prevState
        );
      }
    })();
  }, [waitingForDataFromServer, internalKey, cache, skip]);

  // When fresh server data arrives, store it in the cache
  useEffect(() => {
    if (skip) {
      return;
    }
    if (waitingForDataFromServer || !internalKey || !cache) {
      return;
    }

    // We want to trigger the cache store conservatively.

    // We need to re-run the effect when `dataFromServer` changes.
    // Otherwise, fresh data will not overwrite stale data.

    // We do NOT want to re-run the effect when `waitingForDataFromServer` changes.
    // `dataFromServer` changing is a better indicator that fresh data is available to store.

    // We do NOT want to re-run the effect when `internalKey` changes.
    // The `internalKey` value updates when the query changes, but we have to wait until
    // `dataFromServer` updates to the new query result before we want to store it under the new `internalKey`.
    cache.set(internalKey, dataFromServer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFromServer, cache, skip]);

  // Measure durations (preserving original logic)
  useMeasureLoadDurations({
    namespace,
    dataFromCache: cachedState.data?.data,
    dataFromServer: waitingForDataFromServer ? null : dataFromServer,
    skip,
  });

  // Decide what to return
  let result = dataFromServer;
  let origin: 'cache' | 'server' = 'server';
  let bytesReadFromCache = 0;

  if (
    waitingForDataFromServer &&
    cachedState.data &&
    cachedState.internalKey === internalKey
  ) {
    result = cachedState.data.data;
    bytesReadFromCache = cachedState.data.bytesReadFromCache;
    origin = 'cache';
  }

  // Track cache usage (preserving original logic)
  useSetBytesReadFromCache(namespace, result, bytesReadFromCache, skip);

  if (skip) {
    return {data: dataFromServer, origin: 'server'};
  }

  return {data: result, origin};
}

type LoadTimestamps = {
  start: number;
  cache: number | null;
  server: number | null;
};

type UseMeasureLoadDurationsParams<T> = {
  namespace: string;
  dataFromCache: T | null;
  dataFromServer: T | null;
  skip: boolean;
};

// For now, to keep it simple, we only measure initial load durations.
// This means that even if `key` changes and new fetches from both cache and server are triggered,
// those load durations are not measured.
function useMeasureLoadDurations<T>({
  namespace,
  dataFromCache,
  dataFromServer,
  skip,
}: UseMeasureLoadDurationsParams<T>): void {
  const timestamps = useRef<LoadTimestamps>({
    start: Date.now(),
    cache: null,
    server: null,
  });
  const logged = useRef(false);

  const logLoadDurationsOnceIfReady = useCallback(() => {
    if (logged.current) {
      return;
    }

    const {start, cache, server} = timestamps.current;
    if (cache == null || server == null) {
      return;
    }

    const loadDurationCache = cache - start;
    const loadDurationServer = server - start;
    const serverDurationMinusCacheDuration =
      loadDurationServer - loadDurationCache;

    logCustomActionToDatadog(`cachehook.timeSaved`, {
      namespace,
      loadDurationCache,
      loadDurationServer,
      serverDurationMinusCacheDuration,
    });

    logged.current = true;
  }, [namespace]);

  useEffect(() => {
    if (skip) {
      return;
    }
    if (dataFromCache != null && timestamps.current.cache == null) {
      timestamps.current.cache = Date.now();
    }
    if (dataFromServer != null && timestamps.current.server == null) {
      timestamps.current.server = Date.now();
    }
    logLoadDurationsOnceIfReady();
  }, [dataFromCache, dataFromServer, logLoadDurationsOnceIfReady, skip]);
}

export function useSetBytesReadFromCache(
  namespace: string,
  result: any | null,
  bytesReadFromCache: number,
  skip: boolean
) {
  const haveSeenData = useRef(false);
  useEffect(() => {
    if (skip) {
      return;
    }
    if (result != null && !haveSeenData.current) {
      haveSeenData.current = true;
      getDatadogRum()?.setViewContextProperty(
        `${namespace}BytesReadFromCache`,
        bytesReadFromCache
      );
    }
  }, [result, bytesReadFromCache, namespace, skip]);
}
