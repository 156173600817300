import {Query, RunSetQuery} from '../../../util/queryTypes';

export function getRunSets(query: Query): RunSetQuery[] {
  if (query.runSets == null) {
    return [];
  }

  return query.runSets.map(rs => ({
    enabled: rs.enabled,
    entityName: rs.entityName,
    filters: rs.filters,
    grouping: rs.grouping,
    id: rs.id,
    projectName: rs.projectName,
    runNameTruncationType: rs.runNameTruncationType,
    sort: rs.sort,
  }));
}
