import React, {createContext, useContext, useMemo} from 'react';

import {SemanticLegendSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {Ref as CustomRunColorsRef} from '../../../state/views/customRunColors/types';
import {Ref as CustomRunNamesRef} from '../../../state/views/customRunNames/types';
import {Ref as PanelBankConfigRef} from '../../../state/views/panelBankConfig/types';
import {Ref as RunSetRef} from '../../../state/views/runSet/types';
import {Ref as WorkspaceSettingsRef} from '../../../state/views/workspaceSettings/types';
type PanelsSharedRefsContextProps = {
  children: React.ReactNode;
  customRunColorsRef: CustomRunColorsRef;
  customRunNamesRef?: CustomRunNamesRef;
  runSetRefs: RunSetRef[];
  // these are optional because they only exist in workspaces
  panelBankConfigRef?: PanelBankConfigRef;
  workspaceSettingsRef?: WorkspaceSettingsRef;
  semanticLegendSettings?: SemanticLegendSettings;
};

type PanelsSharedRefsContextValue = Omit<
  PanelsSharedRefsContextProps,
  'children'
>;

const PanelsSharedRefsContext = createContext<
  PanelsSharedRefsContextValue | undefined
>(undefined);

export const PanelsSharedRefsContextProvider = ({
  children,
  customRunColorsRef,
  customRunNamesRef,
  panelBankConfigRef,
  runSetRefs,
  workspaceSettingsRef,
  semanticLegendSettings,
}: PanelsSharedRefsContextProps) => {
  /**
   * Don't include anything that isn't refs in this context. Unpacked refs aren't always stable and can cause render thrash downstream.
   */
  const value = useMemo(
    () => ({
      customRunColorsRef,
      customRunNamesRef,
      panelBankConfigRef,
      runSetRefs,
      workspaceSettingsRef,
      semanticLegendSettings,
    }),
    [
      customRunColorsRef,
      customRunNamesRef,
      panelBankConfigRef,
      runSetRefs,
      workspaceSettingsRef,
      semanticLegendSettings,
    ]
  );
  return (
    <PanelsSharedRefsContext.Provider value={value}>
      {children}
    </PanelsSharedRefsContext.Provider>
  );
};

/**
 * This context provides access to refs that are shared across multiple panels.
 * Unlike WorkspaceRefsContext, it's safe to use in both reports and workspaces.
 */
export const usePanelsSharedRefsContext = () => {
  const value = useContext(PanelsSharedRefsContext);
  if (value == null) {
    throw new Error(
      'usePanelsSharedRefsContext must be used within a PanelsSharedRefsContext'
    );
  }
  return value;
};
