import {Button} from '@wandb/weave/components';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {ApolloQueryResult} from 'apollo-client';
import React, {useCallback, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Header, Modal} from 'semantic-ui-react';

import {
  DeleteIntegrationComponent,
  EntityIntegrationsQuery,
  EntityQuery,
  SlackIntegration as GQLSlackIntegration,
} from '../../generated/graphql';
import {useTeamMember} from '../../state/graphql/teamMembers';
import {useViewer} from '../../state/viewer/hooks';

export interface DisconnectSlackModalProps {
  trigger: React.ReactNode;
  disabled: boolean | undefined;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DisconnectSlackModal = ({
  trigger,
  disabled,
  open,
  onClose,
  onConfirm,
}: DisconnectSlackModalProps) => {
  return (
    <Modal
      className="slack--disconnect-modal"
      open={open}
      onClose={onClose}
      // make the global settings page the only place allowed to disconnect the Slack integration
      // in order to prevent people from accidentally clicking it in other instances of this component
      // such as automated notifications on artifacts
      trigger={trigger}>
      <Header content="Are you sure you want to disconnect Slack?" />
      <Modal.Content>
        <p>We will no longer be able to send you alerts!</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          size="small"
          className="p-8"
          onClick={onClose}>
          Nevermind
        </Button>
        <Button
          variant="destructive"
          size="small"
          className="p-8"
          disabled={disabled}
          onClick={() => {
            onConfirm();
            onClose();
          }}>
          Disconnect
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

type SlackIntegrationActionsProps = {
  entityName: string;
  slackIntegration: GQLSlackIntegration;
  existingSlackIntegrations?: GQLSlackIntegration[];
  entityRefetch?: () =>
    | Promise<ApolloQueryResult<EntityQuery>>
    | Promise<ApolloQueryResult<EntityIntegrationsQuery>>;
  hideDisconnect?: boolean;
};

export const SlackIntegrationActions = ({
  entityName,
  slackIntegration,
  existingSlackIntegrations,
  entityRefetch,
  hideDisconnect,
}: SlackIntegrationActionsProps) => {
  const viewer = useViewer();
  const {viewerIsTeamAdmin} = useTeamMember({
    viewer,
    entityName,
  });

  const [disconnectSlackModalOpen, setDisconnectSlackModalOpen] =
    useState(false);

  const openDisconnectSlackModal = useCallback(
    () => setDisconnectSlackModalOpen(true),
    []
  );
  const closeDisconnectSlackModal = useCallback(
    () => setDisconnectSlackModalOpen(false),
    []
  );

  const deleteIntegrationComponent = (
    <DeleteIntegrationComponent onCompleted={entityRefetch}>
      {(deleteIntegrationMutation: any) => {
        const deleteIntegrationButton = hideDisconnect ? (
          <></>
        ) : (
          <Button
            className="mr-8"
            icon="delete"
            variant="ghost"
            disabled={!viewerIsTeamAdmin}
            onClick={openDisconnectSlackModal}
          />
        );

        const disconnectSlackModal = (
          <DisconnectSlackModal
            trigger={deleteIntegrationButton}
            disabled={!viewerIsTeamAdmin}
            open={disconnectSlackModalOpen}
            onClose={closeDisconnectSlackModal}
            onConfirm={() =>
              deleteIntegrationMutation({
                variables: {
                  id: slackIntegration.id,
                },
              })
            }
          />
        );

        return <div>{disconnectSlackModal}</div>;
      }}
    </DeleteIntegrationComponent>
  );

  return (
    <Tailwind>
      <div className="flex h-full justify-end">
        {deleteIntegrationComponent}
      </div>
    </Tailwind>
  );
};
