import _ from 'lodash';

import {apolloClientNoCache} from '../../../apolloClient';
import {RunsDataQuery} from '../../../containers/RunsDataLoader';
import {
  BucketedRunsDeltaQueryDocument,
  BucketedRunsDeltaQueryInternalIdDocument,
  BucketedRunsDeltaQueryInternalIdQuery,
  BucketedRunsDeltaQueryInternalIdQueryVariables,
  BucketedRunsDeltaQueryQuery,
  BucketedRunsDeltaQueryQueryVariables,
  GroupedBucketedRunsDeltaQueryDocument,
  GroupedBucketedRunsDeltaQueryInternalIdDocument,
  GroupedBucketedRunsDeltaQueryInternalIdQuery,
  GroupedBucketedRunsDeltaQueryInternalIdQueryVariables,
  GroupedBucketedRunsDeltaQueryQuery,
  GroupedBucketedRunsDeltaQueryQueryVariables,
} from '../../../generated/graphql';
import {propagateErrorsContext} from '../../../util/errors';
import * as Run from '../../../util/runs';
import {buildTimePoint} from '../componentOutliers/buildPoints';
import {HistoryRecord} from '../componentOutliers/types';
import {BucketedLine} from '../types';
import {SingleQuery} from './../../../util/queryTypes';
import {BucketedQueryType, queryVars} from './queryVars';

function doBucketedApiQuery(
  vars: ReturnType<typeof queryVars>,
  currentRuns: string[],
  lastUpdated: string
) {
  return apolloClientNoCache.query<
    BucketedRunsDeltaQueryQuery,
    BucketedRunsDeltaQueryQueryVariables
  >({
    context: propagateErrorsContext(),
    query: BucketedRunsDeltaQueryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      ...vars,
      currentRuns,
      lastUpdated,
    },
  });
}

function doBucketedApiQueryByInternalId(
  vars: ReturnType<typeof queryVars>,
  currentRuns: string[],
  lastUpdated: string
) {
  return apolloClientNoCache.query<
    BucketedRunsDeltaQueryInternalIdQuery,
    BucketedRunsDeltaQueryInternalIdQueryVariables
  >({
    context: propagateErrorsContext(),
    query: BucketedRunsDeltaQueryInternalIdDocument,
    fetchPolicy: 'no-cache',
    variables: {
      ...vars,
      currentRuns,
      lastUpdated,
    },
  });
}

function applyDelta({
  currentRunsById,
  delta,
  order,
}: {
  currentRunsById: Record<string, BucketedLine>;
  order: string[];
  delta: NonNullable<
    NonNullable<
      NonNullable<BucketedRunsDeltaQueryQuery['project']>['runs']
    >['deltas']
  >['delta'];
}) {
  const deleteOps = Object.keys(currentRunsById).filter(
    rId => !order.includes(rId)
  );
  if (delta.length === 0 && deleteOps.length === 0) {
    return currentRunsById;
  } else {
    // new diffOps or deleteOps means we need to refresh the reference for `runsById`
    currentRunsById = Object.assign({}, currentRunsById);
    deleteOps.forEach(rId => {
      delete currentRunsById[rId];
    });
  }

  delta.forEach(runDelta => {
    if (!runDelta.run) {
      console.error('Bucketed Delta Op returned without valid run');
      return;
    }

    try {
      const parsedRun = Run.fromJson(runDelta.run);
      if (parsedRun == null) {
        console.error('Unable to parse run from server in outliers reducer');
        return;
      }

      // Update the entire record for any diffOp
      currentRunsById[runDelta.run.id] = {
        ...(parsedRun as unknown as BucketedLine),
        history: runDelta.run.bucketedHistory,
      };
    } catch (e) {
      console.error('Error parsing run data in outliers reducer', e);
    }
  });
  return currentRunsById;
}

function postProcessRuns(runs: any) {
  // post process data - we need to calculate absolute runtime if it might be used
  // _absolute_runtime is a derived metric that is calculated off the run's start time
  try {
    runs?.deltas?.delta?.forEach((runDelta: any) => {
      const createdAt = runDelta?.run?.createdAt ?? '';
      const startTimeInMs = new Date(createdAt).getTime();
      runDelta?.run?.bucketedHistory?.forEach((bucket: HistoryRecord[]) => {
        if (bucket.length > 0) {
          const firstPoint = bucket[0];

          if (
            '_runtimeLast' in firstPoint &&
            '_timestampLast' in firstPoint &&
            '_stepLast' in firstPoint
          ) {
            // this represents a bucket that we might need _absolute_runtime for, so we need to calculate it
            // when the data comes into the Full Fidelity Context
            bucket.forEach((point: HistoryRecord) => {
              buildTimePoint(point, {
                value: startTimeInMs,
                unit: 'milliseconds',
              });
            });
          }
        }
      });
    });
  } catch (e) {
    console.error(
      'Error creating _absolute_runtime metrics in bucketed query manager',
      e
    );
  }
}

function postProcessGroupedRuns(runs: any) {
  try {
    runs?.deltas?.delta?.forEach((runDelta: any) => {
      const createdAt = runDelta?.groupedRun?.createdAt ?? '';
      const startTimeInMs = new Date(createdAt).getTime();
      runDelta?.groupedRun?.bucketedHistory?.forEach(
        (bucket: HistoryRecord[]) => {
          if (bucket.length > 0) {
            const firstPoint = bucket[0];

            if (
              '_runtimeLast' in firstPoint &&
              '_timestampLast' in firstPoint &&
              '_stepLast' in firstPoint
            ) {
              // this represents a bucket that we might need _absolute_runtime for, so we need to calculate it
              // when the data comes into the Full Fidelity Context
              bucket.forEach((point: HistoryRecord) => {
                buildTimePoint(point, {
                  value: startTimeInMs,
                  unit: 'milliseconds',
                });
              });
            }
          }
        }
      );
    });
  } catch (e) {
    console.error(
      'Error creating _absolute_runtime metrics in bucketed query manager',
      e
    );
  }
}

const queryBehaviorByType = {
  BucketedQuery: {
    query: doBucketedApiQuery,
    unpack: (result: any) => result?.data?.project?.runs,
    postProcess: postProcessRuns,
    applyDeltaFn: (isAggregated: boolean, vars: any) => {
      return applyDelta(vars);
    },
  },
  BucketedQueryWithInternalId: {
    query: doBucketedApiQueryByInternalId,
    unpack: (result: any) => result?.data?.project?.runs,
    postProcess: postProcessRuns,
    applyDeltaFn: (isAggregated: boolean, vars: any) => {
      return applyDelta(vars);
    },
  },
  GroupedBucketedQuery: {
    query: doGroupedBucketedApiQuery,
    unpack: (result: any) => result?.data?.project?.groupedRuns,
    postProcess: (runs: any) => {
      if (runs?.__typename === 'GroupedRunConnection') {
        return postProcessGroupedRuns(runs);
      }
      return postProcessRuns(runs);
    },
    applyDeltaFn: (isAggregated: boolean, vars: any) => {
      if (isAggregated) {
        return applyGroupedDelta(vars);
      }
      return applyDelta(vars);
    },
  },
  GroupedBucketedQueryWithInternalId: {
    query: doGroupedBucketedApiQueryByInternalId,
    unpack: (result: any) => result?.data?.project?.groupedRuns,
    postProcess: (runs: any) => {
      if (runs?.__typename === 'GroupedRunConnection') {
        return postProcessGroupedRuns(runs);
      }
      return postProcessRuns(runs);
    },
    applyDeltaFn: (isAggregated: boolean, vars: any) => {
      if (isAggregated) {
        return applyGroupedDelta(vars);
      }
      return applyDelta(vars);
    },
  },
};

export async function bucketedQuery(
  runsDataQuery: RunsDataQuery,
  singleQuery: SingleQuery,
  nBuckets: number,
  currentRunsById: Record<string, BucketedLine>,
  expressions: string[] | undefined,
  queryType: BucketedQueryType
) {
  const runIds = Object.keys(currentRunsById);
  const lastUpdated =
    _.max(Object.values(currentRunsById).map(l => l.updatedAt)) ??
    new Date(0).toISOString();
  const vars = queryVars(
    runsDataQuery,
    singleQuery,
    nBuckets,
    queryType,
    expressions
  );
  const {query, unpack, postProcess, applyDeltaFn} =
    queryBehaviorByType[queryType];
  const result = await query(vars, runIds, lastUpdated);
  const runs = unpack(result);
  postProcess(runs);
  const isAggregated = runs?.__typename === 'GroupedRunConnection';
  const runsById = applyDeltaFn(isAggregated, {
    currentRunsById,
    delta: runs?.deltas?.delta ?? [],
    order: runs?.deltas?.order ?? [],
  });
  return {
    runsById,
    isAggregated,
  };
}

function doGroupedBucketedApiQuery(
  vars: ReturnType<typeof queryVars>,
  currentRuns: string[],
  lastUpdated: string
) {
  return apolloClientNoCache.query<
    GroupedBucketedRunsDeltaQueryQuery,
    GroupedBucketedRunsDeltaQueryQueryVariables
  >({
    context: propagateErrorsContext(),
    query: GroupedBucketedRunsDeltaQueryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      ...vars,
      currentRuns,
      lastUpdated,
    },
  });
}

function doGroupedBucketedApiQueryByInternalId(
  vars: ReturnType<typeof queryVars>,
  currentRuns: string[],
  lastUpdated: string
) {
  return apolloClientNoCache.query<
    GroupedBucketedRunsDeltaQueryInternalIdQuery,
    GroupedBucketedRunsDeltaQueryInternalIdQueryVariables
  >({
    context: propagateErrorsContext(),
    query: GroupedBucketedRunsDeltaQueryInternalIdDocument,
    fetchPolicy: 'no-cache',
    variables: {
      ...vars,
      currentRuns,
      lastUpdated,
    },
  });
}

function applyGroupedDelta({
  currentRunsById,
  delta,
  order,
}: {
  currentRunsById: Record<string, BucketedLine>;
  order: string[];
  delta: NonNullable<
    NonNullable<
      NonNullable<GroupedBucketedRunsDeltaQueryQuery['project']>['groupedRuns']
    >['deltas']
  >['delta'];
}) {
  const deleteOps = Object.keys(currentRunsById).filter(
    rId => !order.includes(rId)
  );
  if (delta.length === 0 && deleteOps.length === 0) {
    return currentRunsById;
  } else {
    // new diffOps or deleteOps means we need to refresh the reference for `runsById`
    currentRunsById = Object.assign({}, currentRunsById);
    deleteOps.forEach(rId => {
      delete currentRunsById[rId];
    });
  }

  delta.forEach(runDelta => {
    if (runDelta.__typename !== 'GroupBucketDiff') {
      console.error('Grouped Bucketed Delta Op returned without valid run');
      return;
    }
    if (!runDelta.groupedRun) {
      console.error('Grouped Bucketed Delta Op returned without valid run');
      return;
    }

    try {
      const parsedRun = Run.fromJson(runDelta.groupedRun);
      if (parsedRun == null) {
        console.error('Unable to parse run from server in outliers reducer');
        return;
      }

      // Update the entire record for any diffOp
      currentRunsById[runDelta.groupedRun.id] = {
        ...(parsedRun as unknown as BucketedLine),
        history: runDelta.groupedRun.bucketedHistory,
      };
    } catch (e) {
      console.error('Error parsing run data in outliers reducer', e);
    }
  });
  return currentRunsById;
}
