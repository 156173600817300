import {OrganizedSettings} from '../../PanelBank/types';

export const SUPPORTED_WORKSPACE_SPEC_VERSION = 1;

const LINE_PLOT_SETTING_FIELDS = [
  {key: 'colorRunNames', type: 'boolean'},
  {key: 'displayFullRunName', type: 'boolean'},
  {key: 'excludeOutliers', type: 'string'},
  {key: 'highlightedCompanionRunOnly', type: 'boolean'},
  {key: 'maxRuns', type: 'number'},
  {key: 'pointVisualizationMethod', type: 'string'},
  {key: 'smoothingType', type: 'string'},
  {key: 'smoothingWeight', type: 'number'},
  {key: 'suppressLegends', type: 'boolean'},
  {key: 'tooltipNumberOfRuns', type: 'string'},
  {key: 'useRunsTableGroupingInPanels', type: 'boolean'},
  {key: 'xAxis', type: 'string'},
  {key: 'xAxisMax', type: 'number'},
  {key: 'xAxisMin', type: 'number'},
];
const ACTIVE_SETTING_FIELDS = [
  'localSmoothingSettingsActive',
  'localxAxisSettingsActive',
  'smoothingActive',
  'xAxisActive',
];
/**
 * This is migration logic. Do not use this function further.
 * Unfortunately, the glue task migration for this did not get to see the light of day.
 */
export const getMigratedSettings = (
  settings: Record<string, any>
): OrganizedSettings => {
  const newWorkspaceSettings: Record<string, any> = {};
  for (const [key, value] of Object.entries(settings)) {
    // Exclude `xAxisActive`, `smoothingActive`, etc
    // Also don't include the refs since they're internal values
    if (ACTIVE_SETTING_FIELDS.indexOf(key) > -1 || key === 'ref') {
      continue;
    }

    // Add line specific plot settings into a separate field
    const linePlotSettingInfo = LINE_PLOT_SETTING_FIELDS.find(
      field => field.key === key
    );

    if (linePlotSettingInfo == null) {
      newWorkspaceSettings[key] = value;
      continue;
    }

    if (!('linePlot' in newWorkspaceSettings)) {
      newWorkspaceSettings.linePlot = {};
    }

    // Check that the old setting value has same type as the new setting
    // If it doesn't, let's just ignore it and let cascading logic apply w&b default values
    if (linePlotSettingInfo.type === typeof value) {
      newWorkspaceSettings.linePlot[key] = value;
    }
  }
  return newWorkspaceSettings;
};
