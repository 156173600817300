import * as globals from '@wandb/weave/common/css/globals.styles';
import {MOON_500} from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox, Image} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {MenuItemDiv} from './NavMenu';

export const Container = styled.div`
  border-radius: 100%;
  height: ${globals.SEARCH_NAV_HEIGHT};
  width: 62px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: 0;
  text-align: left;
  -webkit-tap-highlight-color: transparent;

  transition: padding 0.15s;
  padding: 10px 20px 10px 2px;
  &:hover {
    padding: 8px 18px 8px 0px;
  }
`;
Container.displayName = 'S.Container';

export const Avatar = styled(Image)`
  &&& {
    background-color: ${globals.WHITE};
  }
`;
Avatar.displayName = 'S.Avatar';

export const UserName = styled.div`
  color: ${globals.WHITE};
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
UserName.displayName = 'S.UserName';

export const AccountName = styled.div<{admin: boolean; impersonation: boolean}>`
  color: ${props =>
    props.impersonation
      ? globals.MOON_800
      : props.admin
      ? globals.SIENNA_700
      : globals.TEAL_450};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
AccountName.displayName = 'S.AccountName';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px 0px 7px 10px;
`;
TextContainer.displayName = 'S.TextContainer';

export const ProfileButton = styled.div`
  height: 40px;
  &:hover {
    color: ${globals.WHITE};
    background: rgba(250, 250, 254, 0.12);
  }
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  gap: 12px;
`;
ProfileButton.displayName = 'S.ProfileButton';

export const ProfileButtonContainer = styled.div`
  height: ${globals.SEARCH_NAV_HEIGHT};
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
ProfileButtonContainer.displayName = 'S.ProfileButtonContainer';

export const ProfileImage = styled.img`
  height: 40px;
  width: 40px;
  padding: 3px;
  border-radius: 500rem;
`;
ProfileImage.displayName = 'S.ProfileImage';

export const SWITCH_WIDTH = 34;
export const SWITCH_HEIGHT = 20;

export const Switch = styled(Checkbox).attrs({toggle: true})`
  &&&&& {
    width: ${SWITCH_WIDTH}px;
    min-height: ${SWITCH_HEIGHT}px;
    label {
      min-height: ${SWITCH_HEIGHT}px;
    }

    label:before {
      background-color: ${p =>
        p.checked ? globals.TEAL_500 : globals.MOON_350} !important;
      width: ${SWITCH_WIDTH}px;
      height: ${SWITCH_HEIGHT}px;
    }

    label:after {
      box-shadow: none;
      width: ${SWITCH_HEIGHT - 2}px;
      height: ${SWITCH_HEIGHT - 2}px;
      top: 1px;
      left: ${p => (p.checked ? SWITCH_WIDTH - SWITCH_HEIGHT + 1 : 1)}px;
    }
  }
`;
Switch.displayName = 'S.Switch';

export const ProfileMenuAccountContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
ProfileMenuAccountContainer.displayName = 'S.ProfileMenuAccountContainer';

export const ProfileMenuAccountButton = styled.div<{
  isAccountSettingsHeader: boolean;
}>`
  height: ${props => (props.isAccountSettingsHeader ? '46px' : '40px')};
  display: flex;
  align-items: center;
`;
ProfileMenuAccountButton.displayName = 'S.ProfileMenuAccountButton';

export const ProfileMenuAccountTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
`;
ProfileMenuAccountTextContainer.displayName =
  'S.ProfileMenuAccountTextContainer';

export const ProfileMenuAccountName = styled.div<{
  isAccountSettingsHeader: boolean;
}>`
  color: ${props =>
    props.isAccountSettingsHeader ? globals.MOON_750 : globals.MOON_800};
  font-family: Source Sans Pro;
  font-size: ${props => (props.isAccountSettingsHeader ? '24px' : '16px')};
  font-style: normal;
  font-weight: ${props => (props.isAccountSettingsHeader ? 400 : 600)};
  line-height: ${props => (props.isAccountSettingsHeader ? '120%' : '18px')};
  max-width: ${props => (props.isAccountSettingsHeader ? undefined : '150px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
ProfileMenuAccountName.displayName = 'S.ProfileMenuAccountName';

export const ProfileMenuAccountInfo = styled.div<{
  isAccountSettingsHeader: boolean;
}>`
  color: ${globals.MOON_500};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: ${props => (props.isAccountSettingsHeader ? '120%' : '20px')};
`;
ProfileMenuAccountInfo.displayName = 'S.ProfileMenuAccountInfo';

export const ProfileMenuRetryIcon = styled.div`
  visibility: hidden;

  ${MenuItemDiv}:hover & {
    visibility: visible;
    color: ${MOON_500};
  }
`;
ProfileMenuRetryIcon.displayName = 'S.ProfileMenuRetryIcon';
