import {
  PanelBankDiff,
  SectionPanelSorting,
  UpdatePanel,
} from '../../../components/PanelBank/types';
import {
  addMetricsToPanelConfigImmutable,
  getDefaultPanelConfig,
  updateDefaultxAxisImmutable,
} from '../../../util/panelbank';
import {getDefaultPanelSectionConfig} from '../../../util/panelbankConfigs';
import {LayedOutPanel} from '../../../util/panelTypes';
import * as Normalize from '../normalize';
import {StateType} from '../normalizerSupport';
import * as PanelTypes from '../panel/types';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import {immutableInsertPanelsAlphabetically} from '../panelBankSectionConfig/utils';
import * as Types from './types';

interface SectionInfo {
  keyType: string; // this is needed if the section has to be created
  panelRefs: PanelTypes.Ref[];
}

export const immutableAddOrUpdatePanelsToSections = (
  panelBankDiff: PanelBankDiff,
  reducerStateParts: StateType,
  panelBankConfigRef: Types.Ref,
  isAuto: boolean,
  shouldAlphaInsert = false
): {
  newParts: StateType;
  newPanelRefs: PanelTypes.Ref[];
  newSectionRefs: PanelBankSectionConfigTypes.Ref[];
} => {
  const seenInDiff = new Set();

  // Optimization: since new panels are placed at the beginning of the section
  // (for non-alphabetized sections), we create the full list of metrics
  // to add (grouped by section name in this map), then add all the
  // metrics for each section in one go.
  //
  // If you change this function or its children, be aware it can be
  // run with up to 100k of metrics to add to a section or sections.
  const newPanelsBySection = new Map<
    string, // section name
    SectionInfo
  >();

  const panelsById = new Map<string, LayedOutPanel>();
  Object.values(reducerStateParts.panel).forEach(panel => {
    panelsById.set(panel.__id__, panel);
  });

  const sectionInfos = [];
  const defaultConfigs = [];
  const updatedPanels: {[id: string]: LayedOutPanel} = {};

  for (const [key, operation] of Object.entries(panelBankDiff)) {
    if (seenInDiff.has(key)) {
      console.error(`Duplicated key in panelBankDiff ${key}`);
    } else {
      seenInDiff.add(key);
    }
    if (operation.type === 'update') {
      const previouslyUpdatedPanel = updatedPanels[operation.panelId];
      const panel = previouslyUpdatedPanel ?? panelsById.get(operation.panelId);
      if (panel) {
        const updatedPanel = updatePanelImmutable(operation, panel);
        updatedPanels[operation.panelId] = updatedPanel;
      }
    } else if (operation.type === 'add') {
      // this is either a new panel, or a just-deleted panel we need to add
      // to the "Hidden" section
      const targetSectionName = operation.spec.defaultSection;
      let sectionInfo = newPanelsBySection.get(targetSectionName);
      if (sectionInfo == null) {
        sectionInfo = {keyType: operation.spec.keyType ?? '', panelRefs: []};
        newPanelsBySection.set(targetSectionName, sectionInfo);
      }
      sectionInfos.push(sectionInfo);
      const panel = getDefaultPanelConfig(key, operation.spec, isAuto);
      defaultConfigs.push(panel);
    }
  }

  const {parts: partsWithNewPanels, refs: newPanelRefs} =
    Normalize.addObjsImmutable(
      reducerStateParts,
      'panel',
      panelBankConfigRef.viewID,
      defaultConfigs
    );

  Object.entries(updatedPanels).forEach(([id, panel]) => {
    partsWithNewPanels.panel[id] = panel;
  });

  sectionInfos.forEach((sectionInfo, index) => {
    sectionInfo.panelRefs.push(newPanelRefs[index]);
  });

  // Now that we've grouped all the metrics by section,
  // add the metric list to each section
  const {newParts: partsWithNewSections, newSectionRefs} =
    immutableAddAllSectionInfosToReducerState(
      newPanelsBySection,
      partsWithNewPanels,
      panelBankConfigRef,
      isAuto,
      shouldAlphaInsert
    );

  return {newParts: partsWithNewSections, newPanelRefs, newSectionRefs};
};

// this takes the new panels we need to add (grouped by section)
// and adds all the panels to each section
function immutableAddAllSectionInfosToReducerState(
  newPanelsBySection: Map<string, SectionInfo>,
  prevReducerStateParts: StateType,
  panelBankConfigRef: Types.Ref,
  isAuto: boolean,
  shouldAlphaInsert = false
): {
  newParts: StateType;
  newSectionRefs: PanelBankSectionConfigTypes.Ref[];
} {
  if (!newPanelsBySection.size) {
    return {
      newParts: prevReducerStateParts,
      newSectionRefs: [],
    };
  }

  const prevSectionRefs =
    prevReducerStateParts[panelBankConfigRef.type][panelBankConfigRef.id]
      .sectionRefs;

  // create map of section refs for quick lookup
  const sectionRefsMap = new Map<string, PanelBankSectionConfigTypes.Ref>();
  prevSectionRefs.forEach(sectionRef => {
    const sectionName =
      prevReducerStateParts[sectionRef.type][sectionRef.id].name;
    sectionRefsMap.set(sectionName.toUpperCase(), sectionRef);
  });

  const newSectionsNames: string[] = [];
  const newSectionsInfos: SectionInfo[] = [];

  const targetSectionRefs: PanelBankSectionConfigTypes.Ref[] = Array(
    newPanelsBySection.size
  ).fill(null);

  const newSectionsIndices: number[] = [];
  const sectionInfos: SectionInfo[] = [];

  let i = 0;
  for (const [sectionName, sectionInfo] of newPanelsBySection) {
    const targetSectionRef = sectionRefsMap.get(sectionName.toUpperCase());
    sectionInfos.push(sectionInfo);

    if (!targetSectionRef) {
      newSectionsNames.push(sectionName);
      newSectionsInfos.push(sectionInfo);
      newSectionsIndices.push(i);
    } else {
      targetSectionRefs[i] = targetSectionRef;
    }
    i++;
  }

  const {newParts, refs: newSectionRefs} = immutableCreateNewSections(
    newSectionsNames,
    newSectionsInfos,
    prevReducerStateParts,
    panelBankConfigRef,
    isAuto
  );

  newSectionRefs.forEach((ref, index) => {
    targetSectionRefs[newSectionsIndices[index]] = ref;
  });

  const newSectionConfigParts = {
    ...newParts['panel-bank-section-config'],
  };

  targetSectionRefs.forEach((targetSectionRef, index) => {
    const sectionInfo = sectionInfos[index];

    // now that we're guaranteed to have a ref for the target section,
    // we can resolve it:
    let targetSection = newParts[targetSectionRef.type][targetSectionRef.id];

    // sections with manually added panels should be marked `isPanelsAuto: false`
    if (isAuto === false && targetSection.isPanelsAuto !== false) {
      targetSection = {
        ...targetSection,
        isPanelsAuto: false,
      };
    }

    if (
      shouldAlphaInsert ||
      targetSection.sorted === SectionPanelSorting.Alphabetical
    ) {
      targetSection = immutableInsertPanelsAlphabetically(
        newParts,
        targetSection,
        sectionInfo.panelRefs
      );
      newSectionConfigParts[targetSectionRef.id] = targetSection;
    } else {
      newSectionConfigParts[targetSectionRef.id] = {
        ...targetSection,
        panelRefs: [
          ...sectionInfo.panelRefs.reverse(),
          ...targetSection.panelRefs,
        ],
      };
    }
  });

  newParts['panel-bank-section-config'] = newSectionConfigParts;

  return {
    newParts,
    newSectionRefs,
  };
}

// create a new PanelSectionConfig
function immutableCreateNewSections(
  sectionNames: string[],
  sectionInfos: SectionInfo[],
  prevReducerStateParts: StateType,
  ref: Types.Ref,
  isAuto: boolean // whether sections are being added as part of auto panel gen
): {newParts: StateType; refs: PanelBankSectionConfigTypes.Ref[]} {
  const defaultConfigs = [];
  for (let i = 0; i < sectionNames.length; i++) {
    const sectionName = sectionNames[i];
    const sectionInfo = sectionInfos[i];
    const defaultConfig = getDefaultPanelSectionConfig({
      name: sectionName,
    });
    defaultConfig.isPanelsAuto = isAuto;
    defaultConfigs.push(defaultConfig);
  }

  const {parts: newParts, refs} = Normalize.addObjsImmutable(
    prevReducerStateParts,
    'panel-bank-section-config',
    ref.viewID,
    defaultConfigs
  );

  let newSectionRefs = [...newParts[ref.type][ref.id].sectionRefs];

  refs.forEach((targetSectionRef, index) => {
    const firstSectionRef = newSectionRefs[0];

    const firstSection = firstSectionRef
      ? newParts[firstSectionRef.type][firstSectionRef.id]
      : undefined;

    const sectionName = sectionNames[index];
    if (sectionName === 'System') {
      // "System" section goes towards the bottom, above only "Hidden"
      newSectionRefs.splice(newSectionRefs.length - 1, 0, targetSectionRef);
    } else if (firstSection && firstSection.name === 'Sweep') {
      // "Sweep" section should remain at the top; insert at index 1
      newSectionRefs.splice(1, 0, targetSectionRef);
    } else {
      // Otherwise just add new section to the top -- in this case `concat`
      // is more performant than `splice` or `unshift`. This matters because
      // some projects could have tooooons of sections (like 25k)
      newSectionRefs = [targetSectionRef].concat(newSectionRefs);
    }
  });

  newParts[ref.type] = {
    ...newParts[ref.type],
    [ref.id]: {
      ...newParts[ref.type][ref.id],
      sectionRefs: newSectionRefs,
    },
  };

  return {newParts, refs};
}

// updates the config for a particular panel
function updatePanelImmutable(
  operation: UpdatePanel,
  existingPanel: LayedOutPanel
): LayedOutPanel {
  if (
    operation.spec.type === 'legacy-vega' ||
    operation.spec.type === 'api-added-panel'
  ) {
    return {
      ...existingPanel,
      viewType:
        operation.spec.type === 'legacy-vega' ? 'Vega' : operation.spec.type,
      config: operation.spec.config,
    } as LayedOutPanel;
  } else if (
    existingPanel.viewType === 'Run History Line Plot' &&
    operation.spec.type === 'default-panel'
  ) {
    let newPanel = addMetricsToPanelConfigImmutable(
      existingPanel,
      operation.spec.metrics
    );
    newPanel = updateDefaultxAxisImmutable(
      newPanel,
      operation.spec.defaultXAxis
    );
    return newPanel;
  }
  return existingPanel;
}
