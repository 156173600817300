import {BucketedQueryState} from './bucketedQueryManager';

type BucketedRunsReducerState = {
  runSetsById: Record<string, boolean>;
  runDataById: Record<string, BucketedQueryState>;
};
type BucketedRunsReducerAction =
  | {
      type: 'bucketedRuns/setRunSetEnabled';
      payload: Record<string, boolean>;
    }
  | {
      type: 'bucketedRuns/setRunDataById';
      id: string;
      data: BucketedQueryState;
    };

export function bucketedRunsReducer(
  state: BucketedRunsReducerState,
  action: BucketedRunsReducerAction
) {
  switch (action.type) {
    case 'bucketedRuns/setRunDataById': {
      return {
        ...state,
        runDataById: {
          ...state.runDataById,
          [action.id]: action.data,
        },
      };
    }
    case 'bucketedRuns/setRunSetEnabled': {
      let hasChanged = false;
      const sameLength =
        Object.keys(action.payload).length ===
        Object.keys(state.runSetsById).length;

      // if the lengths aren't different we need to see if there's a condition forcing a refresh
      if (sameLength) {
        hasChanged = Object.keys(action.payload).some(key => {
          if (!(key in state.runSetsById)) {
            return true;
          }
          if (action.payload[key] !== state.runSetsById[key]) {
            return true;
          }
          return false;
        });
      } else {
        hasChanged = true;
      }

      if (hasChanged) {
        return {...state, runSetsById: action.payload};
      }

      return state;
    }
    default: {
      return state;
    }
  }
}
