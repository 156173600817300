import {MediaString} from '@wandb/weave/common/types/media';

// all history data points for a run
export interface RunWithHistory {
  name: string; // name of the run
  displayName: string;
  history: RunHistoryRow[] | null;
  notes?: string;
}

// Used to tell the server what parts of run history we want.
export interface HistorySpec {
  keys: string[]; // Matching rows must have all keys
  samples: number; // Number of samples we're requesting.
  minStep?: number;
  maxStep?: number;
}

// a single history data point, i.e. data for a single step
export interface RunHistoryRow {
  // TODO: We're not always going to send these anymore
  // _runtime: number;
  // _step: number;
  // _timestamp: number;
  [key: string]: any;
}

export type RunHistoryKeyType =
  | 'unknown'
  | 'string'
  | 'number'
  | 'histogram'
  | MediaString;

export type RunHistoryKeyInfoKeys = Record<
  string,
  {
    typeCounts?: Array<{
      type: RunHistoryKeyType;
      count: number;
    }>;
    monotonic?: boolean;
  }
>;

// the server returned type for historyKeys (matches gorilla/history.go:KeysInfo)
export interface RunHistoryKeyInfo {
  keys: RunHistoryKeyInfoKeys;
  sortedKeys: string[];
  lastStep?: number;
  hash?: string;
}

export const EMPTY_HISTORY_KEY_INFO: RunHistoryKeyInfo = {
  keys: {},
  sortedKeys: [],
};

// RunKeyInfo is for summary/config
export type RunKeyType =
  | 'unknown'
  | 'string'
  | 'number'
  | 'boolean'
  | MediaString
  | NonMediaWBValueType;

export interface RunKeyInfoInfo {
  valueCount: {
    [val: string]: number;
  };
  distinctCount: number;
  majorType: RunKeyType;
}

export interface RunKeyInfo {
  [key: string]: RunKeyInfoInfo;
}

export interface RunSignature {
  entityName: string;
  projectName: string;
  runName: string;
}

export const nonMediaWBValueTypes = [
  'graph',
  'graph-file',
  'histogram',
  'histogram-file',
] as const;
export type NonMediaWBValueType = (typeof nonMediaWBValueTypes)[number];
